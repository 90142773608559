import { AMSAutocomplete, AMSButton } from '../../helpers/ui';
import {
  AccessPermissions,
  DeliveryRequestListResponse,
  DeliveryRequestStatus,
  DeliveryRequestStatusKey,
  amsV3Service,
  cookiesService,
  getDeliveryRequestStatusByKey,
  getDeliveryRequestStatusLabelByKey
} from '../../services';
import { Container, Grid, TextField, Tooltip, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Lookup, { supplierToLookup, warehouseToLookup } from '../../models/lookup';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ignoreOffset, isValidDate, toDateString } from '../../helpers/date-helper';
import {
  useDeliveryRequests,
  usePermissions,
  useStyles,
  useSuppliers,
  useUsers,
  useWarehouses
} from '../../helpers/hooks';

import AMSLink from '../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../helpers/ui/AMSTable/AMSTable';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import { getExtendedDeliveryRequestId } from '../../helpers/utils';
import { parseFilter } from '../../helpers/url';
import { useHistory } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface DeliveryRequestWithExpectedTotal extends DeliveryRequestListResponse {
  expectedTotal: number;
}

const DeliveryRequests = ({ location }: any) => {
  const history = useHistory();
  const classes = useStyles();

  const { fromDate, toDate, supplierIds, warehouseIds } = useMemo(
    () => parseFilter(location.search),
    [location.search]
  );

  const filter = useMemo(() => cookiesService.getDeliveryRequestsList(), []);
  const [filterFromDate, setFilterFromDate] = useState<Date | null>(
    fromDate
      ? fromDate
      : filter.filterFromDate
      ? ignoreOffset(new Date(filter.filterFromDate))
      : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [filterToDate, setFilterToDate] = useState<Date | null>(
    fromDate
      ? toDate
      : filter.filterToDate
      ? ignoreOffset(new Date(filter.filterToDate))
      : new Date()
  );
  const [filterSuppliers, setFilterSuppliers] = useState<Lookup[]>(
    filter.filterSuppliers ? filter.filterSuppliers : []
  );
  const [filterWarehouses, setFilterWarehouses] = useState<Lookup[]>(
    filter.filterWarehouses ? filter.filterWarehouses : []
  );

  const { suppliers } = useSuppliers();
  const { warehouses } = useWarehouses();
  const { users } = useUsers();

  const supplierLookup: any = useMemo(
    () =>
      suppliers.reduce((res: any, supplier) => {
        if (!res) {
          res = {};
        }
        res[`${supplier.id}`] = supplier.name;
        return res;
      }, {}),
    [suppliers]
  );

  const warehousesLookup: any = useMemo(
    () =>
      warehouses.reduce((res: any, warehouse) => {
        if (!res) {
          res = {};
        }
        res[`${warehouse.id}`] = warehouse.name;
        return res;
      }, {}),
    [warehouses]
  );

  const usersLookup: any = useMemo(
    () =>
      users.reduce((res: any, user) => {
        if (!res) {
          res = {};
        }
        res[`${user.id}`] = user.name;
        return res;
      }, {}),
    [users]
  );

  const { deliveryRequests, setParameters, loading } = useDeliveryRequests(
    undefined,
    supplierIds,
    warehouseIds ?? filterWarehouses.map((s: Lookup) => s.id),
    filterFromDate ? toDateString(filterFromDate) : undefined,
    filterToDate ? toDateString(filterToDate) : undefined
  );

  const [expectedTotalByDeliveryRequest, setExpectedTotalByDeliveryRequest] = useState<any>({});

  const loadExpectedTotals = useCallback(async () => {
    const expectedTotalsResp = await Promise.all(
      deliveryRequests.map((deliveryRequest) =>
        amsV3Service.getDeliveryRequestExpectedTotal(deliveryRequest.id)
      )
    );
    setExpectedTotalByDeliveryRequest(
      expectedTotalsResp.reduce((res, resp, index) => {
        if (!res) {
          res = {};
        }
        return {
          ...res,
          [deliveryRequests[index].id]: resp.data
        };
      }, {})
    );
  }, [deliveryRequests]);

  const deliveryRequestsData: DeliveryRequestWithExpectedTotal[] = useMemo(() => {
    return deliveryRequests.map((deliveryRequest) => {
      return {
        ...deliveryRequest,
        expectedTotal: expectedTotalByDeliveryRequest[deliveryRequest.id] ?? 0
      };
    });
  }, [deliveryRequests, expectedTotalByDeliveryRequest]);

  useEffect(() => {
    if (deliveryRequests) {
      loadExpectedTotals();
    }
  }, [deliveryRequests, loadExpectedTotals]);

  const getTotalExpectedAmount = useCallback(
    (deliveryRequestIds: number[]) =>
      deliveryRequestsData
        .filter((deliveryRequestData: DeliveryRequestWithExpectedTotal) =>
          deliveryRequestIds.includes(deliveryRequestData.id)
        )
        .reduce(
          (res: number, deliveryRequestData: DeliveryRequestWithExpectedTotal) =>
            res + +deliveryRequestData.expectedTotal,
          0
        ),
    [deliveryRequestsData]
  );

  useEffect(() => {
    const { filterSuppliers } = filter;
    if (suppliers) {
      setFilterSuppliers(
        supplierIds
          ? suppliers.filter((c: any) => supplierIds.includes(c.id)).map(supplierToLookup)
          : filterSuppliers ?? []
      );
    }
  }, [supplierIds, suppliers, filter]);

  useEffect(() => {
    const { filterWarehouses } = filter;
    if (warehouses) {
      setFilterWarehouses(
        warehouseIds
          ? warehouses.filter((w: any) => warehouseIds.includes(w.id)).map(warehouseToLookup)
          : filterWarehouses ?? []
      );
    }
  }, [warehouseIds, warehouses, filter]);

  const [canCreate, canUpdate] = usePermissions([
    AccessPermissions.CAN_CREATE_DELIVERY,
    AccessPermissions.CAN_UPDATE_DELIVERY
  ]);

  const onSearch = useCallback(() => {
    if (
      (filterFromDate === null || isValidDate(filterFromDate)) &&
      (filterToDate === null || isValidDate(filterToDate))
    ) {
      const fromDate = isValidDate(filterFromDate) ? toDateString(filterFromDate!) : undefined;
      const toDate = isValidDate(filterToDate) ? toDateString(filterToDate!) : undefined;
      cookiesService.setDeliveryRequestsList({
        filterFromDate: fromDate,
        filterToDate: toDate,
        filterSuppliers,
        filterWarehouses
      });
      let params: any = {};
      if (filterFromDate) {
        params.fromDate = toDateString(filterFromDate);
      }
      if (filterToDate) {
        params.toDate = toDateString(filterToDate);
      }
      if (filterSuppliers?.length > 0) {
        params.supplierIds = filterSuppliers.map((c) => c.id).join(',');
      }
      if (filterWarehouses?.length > 0) {
        params.warehouseIds = filterWarehouses.map((w) => w.id).join(',');
      }
      history.push({
        pathname: '/delivery-requests',
        search: new URLSearchParams(params).toString()
      });
      setParameters([
        undefined,
        filterSuppliers?.map((c: Lookup) => c.id),
        filterWarehouses?.map((w: Lookup) => w.id),
        fromDate,
        toDate
      ]);
    }
  }, [filterFromDate, filterToDate, filterSuppliers, filterWarehouses, history, setParameters]);

  const getDeliveryRequestTotalByDateString = useCallback(
    (dateString: string) => {
      return getTotalExpectedAmount(
        deliveryRequests
          .filter((dr: DeliveryRequestListResponse) => dr.deliveryDate === dateString)
          .map((dr: DeliveryRequestListResponse) => dr.id)
      );
    },
    [deliveryRequests, getTotalExpectedAmount]
  );

  const today = useMemo(() => toDateString(new Date()), []);
  const tomorrow = useMemo(
    () => toDateString(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)),
    []
  );

  const totalForToday = useMemo(() => {
    return getDeliveryRequestTotalByDateString(today);
  }, [today, getDeliveryRequestTotalByDateString]);

  const totalForTomorrow = useMemo(() => {
    return getDeliveryRequestTotalByDateString(tomorrow);
  }, [tomorrow, getDeliveryRequestTotalByDateString]);

  const handleOnDelete = useCallback(
    async (record: any) => {
      if (record) {
        await amsV3Service.updateDeliveryRequest(record.id, {
          status: DeliveryRequestStatus.CANCELLED.key
        });
        const data = [...record];
        data.splice(record.tableData.id);
        onSearch();
      }
    },
    [onSearch]
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="text-align-left">
        <Container maxWidth="xl">
          <Grid container spacing={1}>
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                variant="inline"
                format="dd/MM/yy"
                margin="dense"
                label="От"
                helperText={''}
                value={filterFromDate ? filterFromDate : null}
                onChange={(value: Date | null) => {
                  if (value) {
                    if (isValidDate(value)) {
                      setFilterFromDate(ignoreOffset(value));
                    }
                  } else {
                    setFilterFromDate(null);
                  }
                }}
                inputVariant="outlined"
                fullWidth
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                maxDate={filterToDate}
              />
            </Grid>
            <Grid item lg={2} md={3} sm={6} xs={12}>
              <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                variant="inline"
                format="dd/MM/yy"
                margin="dense"
                label="До"
                helperText={''}
                value={filterToDate ? filterToDate : null}
                onChange={(value: Date | null) => {
                  if (value) {
                    if (isValidDate(value)) {
                      setFilterToDate(ignoreOffset(value));
                    }
                  } else {
                    setFilterToDate(null);
                  }
                }}
                inputVariant="outlined"
                fullWidth
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                minDate={filterFromDate}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <AMSAutocomplete
                multiple
                minChar={0}
                limitTags={3}
                options={suppliers.map(supplierToLookup)}
                disableCloseOnSelect
                getOptionLabel={(option: any) => option.value}
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      className={classes.checkbox}
                      checked={selected}
                      color="primary"
                    />
                    {option.value}
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Доставчици"
                    placeholder=""
                    margin="dense"
                    fullWidth
                  />
                )}
                value={filterSuppliers}
                onChange={setFilterSuppliers}
                groupBy={(g) => (g.group ? g.group : '')}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <AMSAutocomplete
                multiple
                minChar={0}
                limitTags={3}
                options={warehouses.map(warehouseToLookup)}
                disableCloseOnSelect
                getOptionLabel={(option: any) => option.value}
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      className={classes.checkbox}
                      checked={selected}
                      color="primary"
                    />
                    {option.value}
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Складове"
                    placeholder=""
                    margin="dense"
                    fullWidth
                  />
                )}
                value={filterWarehouses}
                onChange={setFilterWarehouses}
                groupBy={(g) => (g.group ? g.group : '')}
              />
            </Grid>
            <Grid item lg={8} md={6} sm={12} xs={12} container alignContent="flex-end">
              <Typography
                className={classes.totalInfo}
                variant="h6"
                style={{ marginRight: '32px' }}
              >
                За днес: {totalForToday?.toFixed(2)}лв.
              </Typography>
            </Grid>
            <Grid item lg={2} md={6} sm={12} xs={12} container alignContent="flex-end">
              <Typography className={classes.totalInfo} variant="h6">
                За утре: {totalForTomorrow?.toFixed(2)}лв.
              </Typography>
            </Grid>
            <Grid item lg={2} md={12} sm={12} xs={12}>
              <AMSButton
                color="primary"
                variant="contained"
                text="Търсене"
                loading={loading}
                disabled={false}
                onClick={onSearch}
                style={{
                  marginTop: 12,
                  float: 'right'
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <AMSTable
                title="Заявки"
                columns={[
                  {
                    title: '№',
                    field: 'id',
                    render: (deliveryRequest: DeliveryRequestListResponse) => (
                      <AMSLink href={`/delivery-request?id=${deliveryRequest.id}`}>
                        {getExtendedDeliveryRequestId(deliveryRequest.id)}
                      </AMSLink>
                    ),
                    cellStyle: { width: '5%' }
                  },
                  {
                    title: 'Статус',
                    field: 'status',
                    render: (rowData: DeliveryRequestListResponse) => (
                      <Tooltip
                        title={getDeliveryRequestStatusLabelByKey(
                          rowData.status as DeliveryRequestStatusKey
                        )}
                      >
                        <div className="d-flex">
                          <div
                            className={classes.statusIndicator}
                            style={{
                              backgroundColor: getDeliveryRequestStatusByKey(
                                rowData.status as DeliveryRequestStatusKey
                              ).color
                            }}
                          />
                          <div className={classes.statusText}>
                            {getDeliveryRequestStatusLabelByKey(
                              rowData.status as DeliveryRequestStatusKey
                            )}
                          </div>
                        </div>
                      </Tooltip>
                    ),
                    cellStyle: { width: '10%' }
                  },
                  {
                    title: 'Очаквана доставка',
                    field: 'deliveryDate',
                    render: (rowData: DeliveryRequestListResponse) => rowData.deliveryDate,
                    cellStyle: { width: '20%' }
                  },
                  {
                    title: 'Склад',
                    lookup: warehousesLookup,
                    field: 'warehouseId',
                    cellStyle: { width: '20%' }
                  },
                  {
                    title: 'Доставчик',
                    lookup: supplierLookup,
                    field: 'supplierId',
                    render: (deliveryRequest: DeliveryRequestListResponse) => (
                      <AMSLink href={`/supplier?id=${deliveryRequest.supplierId}`}>
                        {supplierLookup
                          ? supplierLookup[deliveryRequest.supplierId]
                          : 'Зареждане ...'}
                      </AMSLink>
                    ),
                    cellStyle: { width: '20%' }
                  },
                  {
                    title: 'Създадена от',
                    lookup: usersLookup,
                    field: 'createdById',
                    cellStyle: { width: '15%' }
                  },
                  {
                    title: 'Очаквана стойност',
                    field: 'expectedTotal',
                    type: 'currency',
                    currencySetting: {
                      locale: 'bg',
                      currencyCode: 'bgn',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    },
                    cellStyle: { width: '10%' }
                  }
                ]}
                pageSize={10}
                data={deliveryRequestsData}
                onAdd={canCreate ? () => history.push(`/delivery-request`) : undefined}
                onDelete={canUpdate ? handleOnDelete : undefined}
                isDeletable={(deliveryRequest: DeliveryRequestListResponse) =>
                  deliveryRequest.status === DeliveryRequestStatus.REQUESTED.key
                }
                isLoading={loading}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default DeliveryRequests;

/* tslint:disable */
/* eslint-disable */
/**
 * AMS V2.2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActOfAcceptanceItemResponse
 */
export interface ActOfAcceptanceItemResponse {
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceItemResponse
     */
    'itemPackageQuantityDelivery': number;
    /**
     * 
     * @type {boolean}
     * @memberof ActOfAcceptanceItemResponse
     */
    'itemTracksDeliveryBatches': boolean;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'deliveryItemBatchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'deliveryItemExpirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'deliveryItemCountry': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'quantity': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'orderItemNetPriceForOne': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceItemResponse
     */
    'netTotal': string;
}
/**
 * 
 * @export
 * @interface ActOfAcceptanceListResponse
 */
export interface ActOfAcceptanceListResponse {
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceListResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceListResponse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceListResponse
     */
    'orderType': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceListResponse
     */
    'number': string;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceListResponse
     */
    'companyId': number;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceListResponse
     */
    'receiverId': number;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceListResponse
     */
    'receiverLegalEntityId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceListResponse
     */
    'orderTotalIncludingVat': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceListResponse
     */
    'orderNetTotal': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceListResponse
     */
    'orderVatTotal': string;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceListResponse
     */
    'createdById': number;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceListResponse
     */
    'orderPaymentOption'?: string;
}
/**
 * 
 * @export
 * @interface ActOfAcceptanceResponse
 */
export interface ActOfAcceptanceResponse {
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceResponse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceResponse
     */
    'orderType': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceResponse
     */
    'number': string;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceResponse
     */
    'companyId': number;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceResponse
     */
    'receiverId': number;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceResponse
     */
    'receiverLegalEntityId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceResponse
     */
    'orderTotalIncludingVat': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceResponse
     */
    'orderNetTotal': string;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceResponse
     */
    'orderVatTotal': string;
    /**
     * 
     * @type {number}
     * @memberof ActOfAcceptanceResponse
     */
    'createdById': number;
    /**
     * 
     * @type {string}
     * @memberof ActOfAcceptanceResponse
     */
    'orderPaymentOption'?: string;
}
/**
 * 
 * @export
 * @interface ActiveOrderItemResponse
 */
export interface ActiveOrderItemResponse {
    /**
     * 
     * @type {number}
     * @memberof ActiveOrderItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ActiveOrderItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof ActiveOrderItemResponse
     */
    'orderId': number;
    /**
     * 
     * @type {string}
     * @memberof ActiveOrderItemResponse
     */
    'orderDeliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveOrderItemResponse
     */
    'price': string;
    /**
     * 
     * @type {string}
     * @memberof ActiveOrderItemResponse
     */
    'quantity': string;
    /**
     * 
     * @type {string}
     * @memberof ActiveOrderItemResponse
     */
    'collected': string;
}
/**
 * 
 * @export
 * @interface AvailabilityListResponse
 */
export interface AvailabilityListResponse {
    /**
     * 
     * @type {number}
     * @memberof AvailabilityListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityListResponse
     */
    'batchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityListResponse
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityListResponse
     */
    'countryOfOrigin': string;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityListResponse
     */
    'balanceQuantity': string;
    /**
     * 
     * @type {number}
     * @memberof AvailabilityListResponse
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof AvailabilityListResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityListResponse
     */
    'lastSyncedAt': string;
}
/**
 * 
 * @export
 * @interface BackendAppApiV3SchemasPartnerSchemasSaveContactRequest
 */
export interface BackendAppApiV3SchemasPartnerSchemasSaveContactRequest {
    /**
     * 
     * @type {string}
     * @memberof BackendAppApiV3SchemasPartnerSchemasSaveContactRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BackendAppApiV3SchemasPartnerSchemasSaveContactRequest
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof BackendAppApiV3SchemasPartnerSchemasSaveContactRequest
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface BackendAppApiV3SchemasSupplierSchemasSaveContactRequest
 */
export interface BackendAppApiV3SchemasSupplierSchemasSaveContactRequest {
    /**
     * 
     * @type {string}
     * @memberof BackendAppApiV3SchemasSupplierSchemasSaveContactRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BackendAppApiV3SchemasSupplierSchemasSaveContactRequest
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof BackendAppApiV3SchemasSupplierSchemasSaveContactRequest
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface BarsyConfigurationRequest
 */
export interface BarsyConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof BarsyConfigurationRequest
     */
    'endpointUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BarsyConfigurationRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof BarsyConfigurationRequest
     */
    'password': string;
    /**
     * 
     * @type {number}
     * @memberof BarsyConfigurationRequest
     */
    'amsSupplierId': number;
    /**
     * 
     * @type {number}
     * @memberof BarsyConfigurationRequest
     */
    'depotId': number;
}
/**
 * 
 * @export
 * @interface BatchInfoResponse
 */
export interface BatchInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof BatchInfoResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof BatchInfoResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof BatchInfoResponse
     */
    'batchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof BatchInfoResponse
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof BatchInfoResponse
     */
    'countryOfOrigin': string;
}
/**
 * 
 * @export
 * @interface BulkCreatePartnerBonusQuantityRequest
 */
export interface BulkCreatePartnerBonusQuantityRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof BulkCreatePartnerBonusQuantityRequest
     */
    'partnerIds': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof BulkCreatePartnerBonusQuantityRequest
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof BulkCreatePartnerBonusQuantityRequest
     */
    'baseQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof BulkCreatePartnerBonusQuantityRequest
     */
    'bonusQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof BulkCreatePartnerBonusQuantityRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof BulkCreatePartnerBonusQuantityRequest
     */
    'endDate': string;
}
/**
 * 
 * @export
 * @interface ClientDeviceResponse
 */
export interface ClientDeviceResponse {
    /**
     * 
     * @type {number}
     * @memberof ClientDeviceResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ClientDeviceResponse
     */
    'clientId': number;
    /**
     * 
     * @type {string}
     * @memberof ClientDeviceResponse
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof ClientDeviceResponse
     */
    'lastSeenAt': string;
}
/**
 * 
 * @export
 * @interface ClientResponse
 */
export interface ClientResponse {
    /**
     * 
     * @type {number}
     * @memberof ClientResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ClientResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponse
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponse
     */
    'phone': string;
    /**
     * 
     * @type {boolean}
     * @memberof ClientResponse
     */
    'isMain': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientResponse
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof ClientResponse
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof ClientResponse
     */
    'partnerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientResponse
     */
    'parentId'?: number;
}
/**
 * 
 * @export
 * @interface ClientShoppingCartItemResponse
 */
export interface ClientShoppingCartItemResponse {
    /**
     * 
     * @type {number}
     * @memberof ClientShoppingCartItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ClientShoppingCartItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof ClientShoppingCartItemResponse
     */
    'quantity': string;
}
/**
 * 
 * @export
 * @interface ClientShoppingCartResponse
 */
export interface ClientShoppingCartResponse {
    /**
     * 
     * @type {number}
     * @memberof ClientShoppingCartResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ClientShoppingCartResponse
     */
    'clientId': number;
}
/**
 * 
 * @export
 * @interface CollectedBatchItemResponse
 */
export interface CollectedBatchItemResponse {
    /**
     * 
     * @type {number}
     * @memberof CollectedBatchItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CollectedBatchItemResponse
     */
    'orderItemId': number;
    /**
     * 
     * @type {string}
     * @memberof CollectedBatchItemResponse
     */
    'batchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CollectedBatchItemResponse
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof CollectedBatchItemResponse
     */
    'countryOfOrigin': string;
    /**
     * 
     * @type {string}
     * @memberof CollectedBatchItemResponse
     */
    'collected': string;
}
/**
 * 
 * @export
 * @interface CompanyCategoryResponse
 */
export interface CompanyCategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof CompanyCategoryResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CompanyCategoryResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CompanyCategoryResponse
     */
    'parentId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CompanyCategoryResponse
     */
    'children'?: Array<number>;
}
/**
 * 
 * @export
 * @interface CompanyInfo
 */
export interface CompanyInfo {
    /**
     * 
     * @type {boolean}
     * @memberof CompanyInfo
     */
    'valid': boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyInfo
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyInfo
     */
    'vatNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyInfo
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyInfo
     */
    'errorMessage'?: string;
}
/**
 * 
 * @export
 * @interface CompanyListResponse
 */
export interface CompanyListResponse {
    /**
     * 
     * @type {number}
     * @memberof CompanyListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CompanyListResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyListResponse
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyListResponse
     */
    'vat': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyListResponse
     */
    'idnumber': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyListResponse
     */
    'accountable': string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyListResponse
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface CompanyResponse
 */
export interface CompanyResponse {
    /**
     * 
     * @type {number}
     * @memberof CompanyResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'vat': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'idnumber': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'accountable': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'invoicePrefix': string;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponse
     */
    'invoiceOffset': number;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'addressLine': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'plusCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'fullAddress'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponse
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface CreateClientRequest
 */
export interface CreateClientRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequest
     */
    'phone': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateClientRequest
     */
    'isMain'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateClientRequest
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequest
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof CreateClientRequest
     */
    'partnerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateClientRequest
     */
    'parentId'?: number;
}
/**
 * 
 * @export
 * @interface CreateCompanyRequest
 */
export interface CreateCompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'idnumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'vat': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'accountable': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'invoicePrefix': string;
    /**
     * 
     * @type {number}
     * @memberof CreateCompanyRequest
     */
    'invoiceOffset': number;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'addressLine': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'plusCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCompanyRequest
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface CreateDeliveryCreditNoteItemRequest
 */
export interface CreateDeliveryCreditNoteItemRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryCreditNoteItemRequest
     */
    'deliveryItemId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryCreditNoteItemRequest
     */
    'quantity': string;
}
/**
 * 
 * @export
 * @interface CreateDeliveryDocumentRequest
 */
export interface CreateDeliveryDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryDocumentRequest
     */
    'invoiceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryDocumentRequest
     */
    'invoiceDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryDocumentRequest
     */
    'invoiceTotal': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryDocumentRequest
     */
    'paymentDue': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryDocumentRequest
     */
    'paymentInfo': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryDocumentRequest
     */
    'tradeDocumentNumber': string;
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryDocumentRequest
     */
    'deliveryId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryDocumentRequest
     */
    'documentType': string;
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryDocumentRequest
     */
    'proformaId'?: number;
    /**
     * 
     * @type {Array<CreateDeliveryCreditNoteItemRequest>}
     * @memberof CreateDeliveryDocumentRequest
     */
    'creditNoteItems'?: Array<CreateDeliveryCreditNoteItemRequest>;
}
/**
 * 
 * @export
 * @interface CreateDeliveryItemRequest
 */
export interface CreateDeliveryItemRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryItemRequest
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryItemRequest
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryItemRequest
     */
    'batchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryItemRequest
     */
    'quantity': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryItemRequest
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryItemRequest
     */
    'price': string;
}
/**
 * 
 * @export
 * @interface CreateDeliveryRequest
 */
export interface CreateDeliveryRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryRequest
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryRequest
     */
    'supplierId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryRequest
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryRequest
     */
    'deliveryRequestId'?: number;
    /**
     * 
     * @type {Array<CreateDeliveryItemRequest>}
     * @memberof CreateDeliveryRequest
     */
    'deliveryItems': Array<CreateDeliveryItemRequest>;
}
/**
 * 
 * @export
 * @interface CreateDeliveryRequestItem
 */
export interface CreateDeliveryRequestItem {
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryRequestItem
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryRequestItem
     */
    'quantity': string;
}
/**
 * 
 * @export
 * @interface CreateDeliveryRequestRequest
 */
export interface CreateDeliveryRequestRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryRequestRequest
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryRequestRequest
     */
    'supplierId': number;
    /**
     * 
     * @type {Array<CreateDeliveryRequestItem>}
     * @memberof CreateDeliveryRequestRequest
     */
    'items': Array<CreateDeliveryRequestItem>;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryRequestRequest
     */
    'deliveryDate': string;
}
/**
 * 
 * @export
 * @interface CreateGroupRequest
 */
export interface CreateGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateGroupRequest
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateGroupRequest
     */
    'users'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateGroupRequest
     */
    'suppliers'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateGroupRequest
     */
    'warehouses'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateGroupRequest
     */
    'partners'?: Array<number>;
}
/**
 * 
 * @export
 * @interface CreateInvoiceRequest
 */
export interface CreateInvoiceRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateInvoiceRequest
     */
    'orderIds': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CreateInvoiceRequest
     */
    'paymentOptionId': number;
}
/**
 * 
 * @export
 * @interface CreateItemDeliverySubscriptionRequest
 */
export interface CreateItemDeliverySubscriptionRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateItemDeliverySubscriptionRequest
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemDeliverySubscriptionRequest
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemDeliverySubscriptionRequest
     */
    'daysForDelivery': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemDeliverySubscriptionRequest
     */
    'batchSize': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemDeliverySubscriptionRequest
     */
    'bufferPercentage': number;
}
/**
 * 
 * @export
 * @interface CreateOrderRequest
 */
export interface CreateOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequest
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequest
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderRequest
     */
    'orderPriority'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderRequest
     */
    'orderType': number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderRequest
     */
    'receiverId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderRequest
     */
    'userResponsibleId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderRequest
     */
    'warehouseId': number;
}
/**
 * 
 * @export
 * @interface CreatePortalUserRequest
 */
export interface CreatePortalUserRequest {
    /**
     * 
     * @type {number}
     * @memberof CreatePortalUserRequest
     */
    'userId': number;
}
/**
 * 
 * @export
 * @interface CreateRoleRequest
 */
export interface CreateRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRoleRequest
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateRoleRequest
     */
    'users'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateRoleRequest
     */
    'permissions'?: Array<number>;
}
/**
 * 
 * @export
 * @interface CreateSupplierReserveSubscriptionRequest
 */
export interface CreateSupplierReserveSubscriptionRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateSupplierReserveSubscriptionRequest
     */
    'supplierId': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSupplierReserveSubscriptionRequest
     */
    'weekdays': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CreateSupplierReserveSubscriptionRequest
     */
    'baseDays': number;
    /**
     * 
     * @type {number}
     * @memberof CreateSupplierReserveSubscriptionRequest
     */
    'reserveDays': number;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierReserveSubscriptionRequest
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface CreateTransactionRequest
 */
export interface CreateTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequest
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionRequest
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionRequest
     */
    'transactionType': number;
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionRequest
     */
    'treasuryId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionRequest
     */
    'orderInvoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionRequest
     */
    'deliveryInvoiceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequest
     */
    'info'?: string;
}
/**
 * 
 * @export
 * @interface CreateTreasuryRequest
 */
export interface CreateTreasuryRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTreasuryRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTreasuryRequest
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateTreasuryRequest
     */
    'initialBankAccountAmount': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTreasuryRequest
     */
    'initialCashAmount': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTreasuryRequest
     */
    'color': string;
}
/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserRequest
     */
    'companyId': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    'isActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    'isSystem': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateUserRequest
     */
    'groups'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateUserRequest
     */
    'roles'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DeliveryDocumentCreditNoteItemResponse
 */
export interface DeliveryDocumentCreditNoteItemResponse {
    /**
     * 
     * @type {number}
     * @memberof DeliveryDocumentCreditNoteItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryDocumentCreditNoteItemResponse
     */
    'deliveryItemId': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentCreditNoteItemResponse
     */
    'quantity': string;
}
/**
 * 
 * @export
 * @interface DeliveryDocumentListResponse
 */
export interface DeliveryDocumentListResponse {
    /**
     * 
     * @type {number}
     * @memberof DeliveryDocumentListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentListResponse
     */
    'invoiceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentListResponse
     */
    'invoiceDate': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentListResponse
     */
    'invoiceTotal': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentListResponse
     */
    'paymentDue': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentListResponse
     */
    'paymentInfo': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentListResponse
     */
    'tradeDocumentNumber': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentListResponse
     */
    'documentType': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentListResponse
     */
    'remainingAmount': string;
}
/**
 * 
 * @export
 * @interface DeliveryDocumentResponse
 */
export interface DeliveryDocumentResponse {
    /**
     * 
     * @type {number}
     * @memberof DeliveryDocumentResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentResponse
     */
    'documentType': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentResponse
     */
    'invoiceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentResponse
     */
    'invoiceDate': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentResponse
     */
    'invoiceTotal': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentResponse
     */
    'paymentDue': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentResponse
     */
    'paymentInfo': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDocumentResponse
     */
    'tradeDocumentNumber': string;
}
/**
 * 
 * @export
 * @interface DeliveryItemResponse
 */
export interface DeliveryItemResponse {
    /**
     * 
     * @type {number}
     * @memberof DeliveryItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryItemResponse
     */
    'batchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryItemResponse
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryItemResponse
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryItemResponse
     */
    'quantity': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryItemResponse
     */
    'priceIncludingVat': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryItemResponse
     */
    'priceWithoutVat': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryItemResponse
     */
    'totalIncludingVat': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryItemResponse
     */
    'totalWithoutVat': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryItemResponse
     */
    'availableQuantity': string;
}
/**
 * 
 * @export
 * @interface DeliveryRequestItemResponse
 */
export interface DeliveryRequestItemResponse {
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestItemResponse
     */
    'quantity': string;
}
/**
 * 
 * @export
 * @interface DeliveryRequestListResponse
 */
export interface DeliveryRequestListResponse {
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestListResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestListResponse
     */
    'createdById': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestListResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestListResponse
     */
    'supplierId': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestListResponse
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestListResponse
     */
    'deliveryDate': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestListResponse
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface DeliveryRequestResponse
 */
export interface DeliveryRequestResponse {
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestResponse
     */
    'createdById': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryRequestResponse
     */
    'supplierId': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestResponse
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestResponse
     */
    'deliveryDate': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryRequestResponse
     */
    'status': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliveryRequestResponse
     */
    'allowedSubsequentStatuses': Array<string>;
}
/**
 * 
 * @export
 * @interface DeliveryResponse
 */
export interface DeliveryResponse {
    /**
     * 
     * @type {number}
     * @memberof DeliveryResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryResponse
     */
    'supplierId': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryResponse
     */
    'createdById': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryResponse
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryResponse
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface FavoriteItemResponse
 */
export interface FavoriteItemResponse {
    /**
     * 
     * @type {number}
     * @memberof FavoriteItemResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FavoriteItemResponse
     */
    'artNo': string;
    /**
     * 
     * @type {string}
     * @memberof FavoriteItemResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FavoriteItemResponse
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GroupResponse
 */
export interface GroupResponse {
    /**
     * 
     * @type {number}
     * @memberof GroupResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GroupResponse
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof GroupResponse
     */
    'users'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GroupResponse
     */
    'suppliers'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GroupResponse
     */
    'warehouses'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GroupResponse
     */
    'partners'?: Array<number>;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface ICashConfigurationRequest
 */
export interface ICashConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof ICashConfigurationRequest
     */
    'endpointUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ICashConfigurationRequest
     */
    'apiKey': string;
    /**
     * 
     * @type {number}
     * @memberof ICashConfigurationRequest
     */
    'warehouseId': number;
    /**
     * 
     * @type {string}
     * @memberof ICashConfigurationRequest
     */
    'secretKey': string;
    /**
     * 
     * @type {number}
     * @memberof ICashConfigurationRequest
     */
    'userId': number;
    /**
     * 
     * @type {number}
     * @memberof ICashConfigurationRequest
     */
    'storeId': number;
    /**
     * 
     * @type {number}
     * @memberof ICashConfigurationRequest
     */
    'contragentId': number;
    /**
     * 
     * @type {number}
     * @memberof ICashConfigurationRequest
     */
    'payTypeId': number;
}
/**
 * 
 * @export
 * @interface InvoiceListResponse
 */
export interface InvoiceListResponse {
    /**
     * 
     * @type {number}
     * @memberof InvoiceListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'paymentDue': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'number': string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceListResponse
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'receiverName': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'receiverPartnerName': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'receiverVatNumber': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'paymentOption': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'netTotal': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'vatTotal': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'totalIncludingVat': string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceListResponse
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceListResponse
     */
    'isCancelled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceListResponse
     */
    'isPaidInFull': boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListResponse
     */
    'remainingAmount': string;
}
/**
 * 
 * @export
 * @interface InvoiceResponse
 */
export interface InvoiceResponse {
    /**
     * 
     * @type {number}
     * @memberof InvoiceResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'paymentDue': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'number': string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceResponse
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'receiverName': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'receiverVatNumber': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'receiverPartnerName': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'netTotal': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'vatTotal': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'totalIncludingVat': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'paymentOption': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceResponse
     */
    'remainingAmount': string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceResponse
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceResponse
     */
    'isCancelled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceResponse
     */
    'canBeCancelled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceResponse
     */
    'isPaidInFull': boolean;
}
/**
 * 
 * @export
 * @interface ItemCategoryResponse
 */
export interface ItemCategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemCategoryResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ItemCategoryResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ItemCategoryResponse
     */
    'parentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemCategoryResponse
     */
    'aliasId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ItemCategoryResponse
     */
    'children'?: Array<number>;
}
/**
 * 
 * @export
 * @interface ItemDeliveryBatchResponse
 */
export interface ItemDeliveryBatchResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemDeliveryBatchResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ItemDeliveryBatchResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof ItemDeliveryBatchResponse
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ItemDeliveryBatchResponse
     */
    'batchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ItemDeliveryBatchResponse
     */
    'countryOfOrigin': string;
    /**
     * 
     * @type {number}
     * @memberof ItemDeliveryBatchResponse
     */
    'deliveryId': number;
    /**
     * 
     * @type {string}
     * @memberof ItemDeliveryBatchResponse
     */
    'deliveryDate': string;
    /**
     * 
     * @type {number}
     * @memberof ItemDeliveryBatchResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {string}
     * @memberof ItemDeliveryBatchResponse
     */
    'quantity': string;
    /**
     * 
     * @type {string}
     * @memberof ItemDeliveryBatchResponse
     */
    'availableQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof ItemDeliveryBatchResponse
     */
    'priceWithoutVat': string;
    /**
     * 
     * @type {string}
     * @memberof ItemDeliveryBatchResponse
     */
    'priceIncludingVat': string;
    /**
     * 
     * @type {string}
     * @memberof ItemDeliveryBatchResponse
     */
    'totalIncludingVat': string;
    /**
     * 
     * @type {string}
     * @memberof ItemDeliveryBatchResponse
     */
    'totalWithoutVat': string;
}
/**
 * 
 * @export
 * @interface ItemDeliverySubscriptionResponse
 */
export interface ItemDeliverySubscriptionResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemDeliverySubscriptionResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ItemDeliverySubscriptionResponse
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof ItemDeliverySubscriptionResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof ItemDeliverySubscriptionResponse
     */
    'daysForDelivery': number;
    /**
     * 
     * @type {number}
     * @memberof ItemDeliverySubscriptionResponse
     */
    'batchSize': number;
    /**
     * 
     * @type {number}
     * @memberof ItemDeliverySubscriptionResponse
     */
    'bufferPercentage': number;
    /**
     * 
     * @type {string}
     * @memberof ItemDeliverySubscriptionResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof ItemDeliverySubscriptionResponse
     */
    'createdById': number;
    /**
     * 
     * @type {string}
     * @memberof ItemDeliverySubscriptionResponse
     */
    'lastUpdatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof ItemDeliverySubscriptionResponse
     */
    'lastUpdatedById': number;
}
/**
 * 
 * @export
 * @interface ItemListResponse
 */
export interface ItemListResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponse
     */
    'artNo': string;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponse
     */
    'barcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponse
     */
    'additionalBarcodeValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemListResponse
     */
    'supplierId': number;
    /**
     * 
     * @type {number}
     * @memberof ItemListResponse
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponse
     */
    'basePrice': string;
    /**
     * 
     * @type {boolean}
     * @memberof ItemListResponse
     */
    'maintainStock': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemListResponse
     */
    'tracksBatches': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemListResponse
     */
    'isOwnedByUs': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemListResponse
     */
    'isActive': boolean;
    /**
     * 
     * @type {number}
     * @memberof ItemListResponse
     */
    'deliveryRequestUnit': number;
    /**
     * 
     * @type {number}
     * @memberof ItemListResponse
     */
    'packageQty': number;
    /**
     * 
     * @type {number}
     * @memberof ItemListResponse
     */
    'packageQtyDelivery': number;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponse
     */
    'lastKnownPrice': string;
}
/**
 * 
 * @export
 * @interface ItemMovementBalanceResponse
 */
export interface ItemMovementBalanceResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemMovementBalanceResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof ItemMovementBalanceResponse
     */
    'itemArtNo': string;
    /**
     * 
     * @type {string}
     * @memberof ItemMovementBalanceResponse
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof ItemMovementBalanceResponse
     */
    'quantity': string;
    /**
     * 
     * @type {string}
     * @memberof ItemMovementBalanceResponse
     */
    'itemNetPrice': string;
}
/**
 * 
 * @export
 * @interface ItemPartnerConfigurationResponse
 */
export interface ItemPartnerConfigurationResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemPartnerConfigurationResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ItemPartnerConfigurationResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof ItemPartnerConfigurationResponse
     */
    'externalItemUniqueKey': string;
    /**
     * 
     * @type {string}
     * @memberof ItemPartnerConfigurationResponse
     */
    'externalItemArtNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemPartnerConfigurationResponse
     */
    'externalItemName': string;
    /**
     * 
     * @type {string}
     * @memberof ItemPartnerConfigurationResponse
     */
    'min'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemPartnerConfigurationResponse
     */
    'max'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemPartnerConfigurationResponse
     */
    'partnerId': number;
}
/**
 * 
 * @export
 * @interface ItemResponse
 */
export interface ItemResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'artNo': string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'barcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'additionalBarcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'basePrice': string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'lastDeliveryCountry'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'vatPercentage': string;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'deliveryOnDemand': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'noStorage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'promo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'mlm'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'stockSelectable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'tracksBatches': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'isOwnedByUs': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'maintainStock': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ItemResponse
     */
    'isActive': boolean;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'barcodeStandardId': number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'unitId': number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'deliveryRequestUnit': number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'supplierId': number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'manufacturerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'packageQty': number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'packageQtyDelivery': number;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'width'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'height'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'depth'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'weight'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'minimumAllowedDaysToExpiration': number;
    /**
     * 
     * @type {number}
     * @memberof ItemResponse
     */
    'factoryDaysToExpiration': number;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'minimumRequiredPartOfFactoryDaysOfExpirationForDelivery': string;
    /**
     * 
     * @type {string}
     * @memberof ItemResponse
     */
    'lastKnownPrice': string;
}
/**
 * 
 * @export
 * @interface LegalEntityListResponse
 */
export interface LegalEntityListResponse {
    /**
     * 
     * @type {number}
     * @memberof LegalEntityListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityListResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityListResponse
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityListResponse
     */
    'fullAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof LegalEntityListResponse
     */
    'companyId': number;
    /**
     * 
     * @type {number}
     * @memberof LegalEntityListResponse
     */
    'paymentOptionId': number;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityListResponse
     */
    'idnumber': string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityListResponse
     */
    'vat': string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityListResponse
     */
    'accountable': string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityListResponse
     */
    'creditLimitAmount': string;
    /**
     * 
     * @type {number}
     * @memberof LegalEntityListResponse
     */
    'paymentDueDays': number;
    /**
     * 
     * @type {boolean}
     * @memberof LegalEntityListResponse
     */
    'underForeclosure': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LegalEntityListResponse
     */
    'usesDeferredBilling': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LegalEntityListResponse
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface LegalEntityResponse
 */
export interface LegalEntityResponse {
    /**
     * 
     * @type {number}
     * @memberof LegalEntityResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityResponse
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityResponse
     */
    'fullAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof LegalEntityResponse
     */
    'companyId': number;
    /**
     * 
     * @type {number}
     * @memberof LegalEntityResponse
     */
    'paymentOptionId': number;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityResponse
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityResponse
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityResponse
     */
    'addressLine': string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityResponse
     */
    'idnumber': string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityResponse
     */
    'visualization'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityResponse
     */
    'addressOverride'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityResponse
     */
    'vat': string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityResponse
     */
    'accountable': string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityResponse
     */
    'creditLimitAmount': string;
    /**
     * 
     * @type {number}
     * @memberof LegalEntityResponse
     */
    'paymentDueDays': number;
    /**
     * 
     * @type {boolean}
     * @memberof LegalEntityResponse
     */
    'underForeclosure': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LegalEntityResponse
     */
    'usesDeferredBilling': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LegalEntityResponse
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface LinkedItemAssociationResponse
 */
export interface LinkedItemAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof LinkedItemAssociationResponse
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof LinkedItemAssociationResponse
     */
    'linkedItemId': number;
}
/**
 * 
 * @export
 * @interface ManufacturerExportResponse
 */
export interface ManufacturerExportResponse {
    /**
     * 
     * @type {number}
     * @memberof ManufacturerExportResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerExportResponse
     */
    'manufacturerId': number;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerExportResponse
     */
    'resellerNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerExportResponse
     */
    'fromDt': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerExportResponse
     */
    'toDt': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerExportResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerExportResponse
     */
    'exportType': string;
}
/**
 * 
 * @export
 * @interface ManufacturerResponse
 */
export interface ManufacturerResponse {
    /**
     * 
     * @type {number}
     * @memberof ManufacturerResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponse
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface MistralConfigurationRequest
 */
export interface MistralConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof MistralConfigurationRequest
     */
    'endpointUrl': string;
    /**
     * 
     * @type {string}
     * @memberof MistralConfigurationRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof MistralConfigurationRequest
     */
    'password': string;
    /**
     * 
     * @type {number}
     * @memberof MistralConfigurationRequest
     */
    'locationId': number;
    /**
     * 
     * @type {number}
     * @memberof MistralConfigurationRequest
     */
    'userId': number;
}
/**
 * 
 * @export
 * @interface OrderItemResponse
 */
export interface OrderItemResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    'itemArtNo': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    'barcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    'additionalBarcodeValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    'itemPackageQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    'itemPackageQuantityDelivery': number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemResponse
     */
    'itemStockSelectable': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    'itemWeight': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemResponse
     */
    'itemIsOwnedByUs': boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderItemResponse
     */
    'minimumAllowedDaysToExpiration': number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    'quantity': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    'orderedQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    'bonusQuantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    'collected': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    'receivedQuantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    'itemPrice': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemResponse
     */
    'totalPrice': string;
}
/**
 * 
 * @export
 * @interface OrderListResponse
 */
export interface OrderListResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponse
     */
    'priority': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponse
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponse
     */
    'packageQty'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponse
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponse
     */
    'receiverId': number;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponse
     */
    'actOfAcceptanceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponse
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponse
     */
    'userResponsibleId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponse
     */
    'billableNetTotal'?: string;
}
/**
 * 
 * @export
 * @interface OrderResponse
 */
export interface OrderResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'priority': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'packageQty'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'receiverId': number;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'actOfAcceptanceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'userResponsibleId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'billableNetTotal'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderResponse
     */
    'allowedSubsequentStatuses': Array<string>;
}
/**
 * 
 * @export
 * @interface OrderStatusLogResponse
 */
export interface OrderStatusLogResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderStatusLogResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderStatusLogResponse
     */
    'orderId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderStatusLogResponse
     */
    'statusKey': string;
    /**
     * 
     * @type {string}
     * @memberof OrderStatusLogResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof OrderStatusLogResponse
     */
    'userId': number;
}
/**
 * 
 * @export
 * @interface OrderingPolicyResponse
 */
export interface OrderingPolicyResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderingPolicyResponse
     */
    'allowedWeekdays': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrderingPolicyResponse
     */
    'nextDayDeliveryCutoff': string;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseActOfAcceptanceItemResponse
 */
export interface PaginatedListResponseActOfAcceptanceItemResponse {
    /**
     * 
     * @type {Array<ActOfAcceptanceItemResponse>}
     * @memberof PaginatedListResponseActOfAcceptanceItemResponse
     */
    'data': Array<ActOfAcceptanceItemResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseActOfAcceptanceItemResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseActOfAcceptanceItemResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseActOfAcceptanceItemResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseActOfAcceptanceListResponse
 */
export interface PaginatedListResponseActOfAcceptanceListResponse {
    /**
     * 
     * @type {Array<ActOfAcceptanceListResponse>}
     * @memberof PaginatedListResponseActOfAcceptanceListResponse
     */
    'data': Array<ActOfAcceptanceListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseActOfAcceptanceListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseActOfAcceptanceListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseActOfAcceptanceListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseActiveOrderItemResponse
 */
export interface PaginatedListResponseActiveOrderItemResponse {
    /**
     * 
     * @type {Array<ActiveOrderItemResponse>}
     * @memberof PaginatedListResponseActiveOrderItemResponse
     */
    'data': Array<ActiveOrderItemResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseActiveOrderItemResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseActiveOrderItemResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseActiveOrderItemResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseAvailabilityListResponse
 */
export interface PaginatedListResponseAvailabilityListResponse {
    /**
     * 
     * @type {Array<AvailabilityListResponse>}
     * @memberof PaginatedListResponseAvailabilityListResponse
     */
    'data': Array<AvailabilityListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseAvailabilityListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseAvailabilityListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseAvailabilityListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseBatchInfoResponse
 */
export interface PaginatedListResponseBatchInfoResponse {
    /**
     * 
     * @type {Array<BatchInfoResponse>}
     * @memberof PaginatedListResponseBatchInfoResponse
     */
    'data': Array<BatchInfoResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseBatchInfoResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseBatchInfoResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseBatchInfoResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseClientDeviceResponse
 */
export interface PaginatedListResponseClientDeviceResponse {
    /**
     * 
     * @type {Array<ClientDeviceResponse>}
     * @memberof PaginatedListResponseClientDeviceResponse
     */
    'data': Array<ClientDeviceResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseClientDeviceResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseClientDeviceResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseClientDeviceResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseClientResponse
 */
export interface PaginatedListResponseClientResponse {
    /**
     * 
     * @type {Array<ClientResponse>}
     * @memberof PaginatedListResponseClientResponse
     */
    'data': Array<ClientResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseClientResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseClientResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseClientResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseClientShoppingCartItemResponse
 */
export interface PaginatedListResponseClientShoppingCartItemResponse {
    /**
     * 
     * @type {Array<ClientShoppingCartItemResponse>}
     * @memberof PaginatedListResponseClientShoppingCartItemResponse
     */
    'data': Array<ClientShoppingCartItemResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseClientShoppingCartItemResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseClientShoppingCartItemResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseClientShoppingCartItemResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseClientShoppingCartResponse
 */
export interface PaginatedListResponseClientShoppingCartResponse {
    /**
     * 
     * @type {Array<ClientShoppingCartResponse>}
     * @memberof PaginatedListResponseClientShoppingCartResponse
     */
    'data': Array<ClientShoppingCartResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseClientShoppingCartResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseClientShoppingCartResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseClientShoppingCartResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseCompanyCategoryResponse
 */
export interface PaginatedListResponseCompanyCategoryResponse {
    /**
     * 
     * @type {Array<CompanyCategoryResponse>}
     * @memberof PaginatedListResponseCompanyCategoryResponse
     */
    'data': Array<CompanyCategoryResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseCompanyCategoryResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseCompanyCategoryResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseCompanyCategoryResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseCompanyListResponse
 */
export interface PaginatedListResponseCompanyListResponse {
    /**
     * 
     * @type {Array<CompanyListResponse>}
     * @memberof PaginatedListResponseCompanyListResponse
     */
    'data': Array<CompanyListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseCompanyListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseCompanyListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseCompanyListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseDeliveryDocumentCreditNoteItemResponse
 */
export interface PaginatedListResponseDeliveryDocumentCreditNoteItemResponse {
    /**
     * 
     * @type {Array<DeliveryDocumentCreditNoteItemResponse>}
     * @memberof PaginatedListResponseDeliveryDocumentCreditNoteItemResponse
     */
    'data': Array<DeliveryDocumentCreditNoteItemResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseDeliveryDocumentCreditNoteItemResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseDeliveryDocumentCreditNoteItemResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseDeliveryDocumentCreditNoteItemResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseDeliveryDocumentListResponse
 */
export interface PaginatedListResponseDeliveryDocumentListResponse {
    /**
     * 
     * @type {Array<DeliveryDocumentListResponse>}
     * @memberof PaginatedListResponseDeliveryDocumentListResponse
     */
    'data': Array<DeliveryDocumentListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseDeliveryDocumentListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseDeliveryDocumentListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseDeliveryDocumentListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseDeliveryItemResponse
 */
export interface PaginatedListResponseDeliveryItemResponse {
    /**
     * 
     * @type {Array<DeliveryItemResponse>}
     * @memberof PaginatedListResponseDeliveryItemResponse
     */
    'data': Array<DeliveryItemResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseDeliveryItemResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseDeliveryItemResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseDeliveryItemResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseDeliveryRequestItemResponse
 */
export interface PaginatedListResponseDeliveryRequestItemResponse {
    /**
     * 
     * @type {Array<DeliveryRequestItemResponse>}
     * @memberof PaginatedListResponseDeliveryRequestItemResponse
     */
    'data': Array<DeliveryRequestItemResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseDeliveryRequestItemResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseDeliveryRequestItemResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseDeliveryRequestItemResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseDeliveryRequestListResponse
 */
export interface PaginatedListResponseDeliveryRequestListResponse {
    /**
     * 
     * @type {Array<DeliveryRequestListResponse>}
     * @memberof PaginatedListResponseDeliveryRequestListResponse
     */
    'data': Array<DeliveryRequestListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseDeliveryRequestListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseDeliveryRequestListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseDeliveryRequestListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseDeliveryResponse
 */
export interface PaginatedListResponseDeliveryResponse {
    /**
     * 
     * @type {Array<DeliveryResponse>}
     * @memberof PaginatedListResponseDeliveryResponse
     */
    'data': Array<DeliveryResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseDeliveryResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseDeliveryResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseDeliveryResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseFavoriteItemResponse
 */
export interface PaginatedListResponseFavoriteItemResponse {
    /**
     * 
     * @type {Array<FavoriteItemResponse>}
     * @memberof PaginatedListResponseFavoriteItemResponse
     */
    'data': Array<FavoriteItemResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseFavoriteItemResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseFavoriteItemResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseFavoriteItemResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseGroupResponse
 */
export interface PaginatedListResponseGroupResponse {
    /**
     * 
     * @type {Array<GroupResponse>}
     * @memberof PaginatedListResponseGroupResponse
     */
    'data': Array<GroupResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseGroupResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseGroupResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseGroupResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseInvoiceListResponse
 */
export interface PaginatedListResponseInvoiceListResponse {
    /**
     * 
     * @type {Array<InvoiceListResponse>}
     * @memberof PaginatedListResponseInvoiceListResponse
     */
    'data': Array<InvoiceListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseInvoiceListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseInvoiceListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseInvoiceListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseItemCategoryResponse
 */
export interface PaginatedListResponseItemCategoryResponse {
    /**
     * 
     * @type {Array<ItemCategoryResponse>}
     * @memberof PaginatedListResponseItemCategoryResponse
     */
    'data': Array<ItemCategoryResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseItemCategoryResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseItemCategoryResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseItemCategoryResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseItemDeliveryBatchResponse
 */
export interface PaginatedListResponseItemDeliveryBatchResponse {
    /**
     * 
     * @type {Array<ItemDeliveryBatchResponse>}
     * @memberof PaginatedListResponseItemDeliveryBatchResponse
     */
    'data': Array<ItemDeliveryBatchResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseItemDeliveryBatchResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseItemDeliveryBatchResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseItemDeliveryBatchResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseItemDeliverySubscriptionResponse
 */
export interface PaginatedListResponseItemDeliverySubscriptionResponse {
    /**
     * 
     * @type {Array<ItemDeliverySubscriptionResponse>}
     * @memberof PaginatedListResponseItemDeliverySubscriptionResponse
     */
    'data': Array<ItemDeliverySubscriptionResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseItemDeliverySubscriptionResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseItemDeliverySubscriptionResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseItemDeliverySubscriptionResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseItemListResponse
 */
export interface PaginatedListResponseItemListResponse {
    /**
     * 
     * @type {Array<ItemListResponse>}
     * @memberof PaginatedListResponseItemListResponse
     */
    'data': Array<ItemListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseItemListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseItemListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseItemListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseItemMovementBalanceResponse
 */
export interface PaginatedListResponseItemMovementBalanceResponse {
    /**
     * 
     * @type {Array<ItemMovementBalanceResponse>}
     * @memberof PaginatedListResponseItemMovementBalanceResponse
     */
    'data': Array<ItemMovementBalanceResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseItemMovementBalanceResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseItemMovementBalanceResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseItemMovementBalanceResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseItemPartnerConfigurationResponse
 */
export interface PaginatedListResponseItemPartnerConfigurationResponse {
    /**
     * 
     * @type {Array<ItemPartnerConfigurationResponse>}
     * @memberof PaginatedListResponseItemPartnerConfigurationResponse
     */
    'data': Array<ItemPartnerConfigurationResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseItemPartnerConfigurationResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseItemPartnerConfigurationResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseItemPartnerConfigurationResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseLegalEntityListResponse
 */
export interface PaginatedListResponseLegalEntityListResponse {
    /**
     * 
     * @type {Array<LegalEntityListResponse>}
     * @memberof PaginatedListResponseLegalEntityListResponse
     */
    'data': Array<LegalEntityListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseLegalEntityListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseLegalEntityListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseLegalEntityListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseManufacturerExportResponse
 */
export interface PaginatedListResponseManufacturerExportResponse {
    /**
     * 
     * @type {Array<ManufacturerExportResponse>}
     * @memberof PaginatedListResponseManufacturerExportResponse
     */
    'data': Array<ManufacturerExportResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseManufacturerExportResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseManufacturerExportResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseManufacturerExportResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseManufacturerResponse
 */
export interface PaginatedListResponseManufacturerResponse {
    /**
     * 
     * @type {Array<ManufacturerResponse>}
     * @memberof PaginatedListResponseManufacturerResponse
     */
    'data': Array<ManufacturerResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseManufacturerResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseManufacturerResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseManufacturerResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseOrderItemResponse
 */
export interface PaginatedListResponseOrderItemResponse {
    /**
     * 
     * @type {Array<OrderItemResponse>}
     * @memberof PaginatedListResponseOrderItemResponse
     */
    'data': Array<OrderItemResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseOrderItemResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseOrderItemResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseOrderItemResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseOrderListResponse
 */
export interface PaginatedListResponseOrderListResponse {
    /**
     * 
     * @type {Array<OrderListResponse>}
     * @memberof PaginatedListResponseOrderListResponse
     */
    'data': Array<OrderListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseOrderListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseOrderListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseOrderListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponsePartnerBonusQuantityResponse
 */
export interface PaginatedListResponsePartnerBonusQuantityResponse {
    /**
     * 
     * @type {Array<PartnerBonusQuantityResponse>}
     * @memberof PaginatedListResponsePartnerBonusQuantityResponse
     */
    'data': Array<PartnerBonusQuantityResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePartnerBonusQuantityResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePartnerBonusQuantityResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePartnerBonusQuantityResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponsePartnerItemConfigurationResponse
 */
export interface PaginatedListResponsePartnerItemConfigurationResponse {
    /**
     * 
     * @type {Array<PartnerItemConfigurationResponse>}
     * @memberof PaginatedListResponsePartnerItemConfigurationResponse
     */
    'data': Array<PartnerItemConfigurationResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePartnerItemConfigurationResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePartnerItemConfigurationResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePartnerItemConfigurationResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponsePartnerListResponse
 */
export interface PaginatedListResponsePartnerListResponse {
    /**
     * 
     * @type {Array<PartnerListResponse>}
     * @memberof PaginatedListResponsePartnerListResponse
     */
    'data': Array<PartnerListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePartnerListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePartnerListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePartnerListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponsePaymentOptionResponse
 */
export interface PaginatedListResponsePaymentOptionResponse {
    /**
     * 
     * @type {Array<PaymentOptionResponse>}
     * @memberof PaginatedListResponsePaymentOptionResponse
     */
    'data': Array<PaymentOptionResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePaymentOptionResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePaymentOptionResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePaymentOptionResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponsePermissionResponse
 */
export interface PaginatedListResponsePermissionResponse {
    /**
     * 
     * @type {Array<PermissionResponse>}
     * @memberof PaginatedListResponsePermissionResponse
     */
    'data': Array<PermissionResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePermissionResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePermissionResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePermissionResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponsePortalUserListResponse
 */
export interface PaginatedListResponsePortalUserListResponse {
    /**
     * 
     * @type {Array<PortalUserListResponse>}
     * @memberof PaginatedListResponsePortalUserListResponse
     */
    'data': Array<PortalUserListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePortalUserListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePortalUserListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePortalUserListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponsePricingPolicyListResponse
 */
export interface PaginatedListResponsePricingPolicyListResponse {
    /**
     * 
     * @type {Array<PricingPolicyListResponse>}
     * @memberof PaginatedListResponsePricingPolicyListResponse
     */
    'data': Array<PricingPolicyListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePricingPolicyListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePricingPolicyListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePricingPolicyListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponsePricingPolicySimpleResponse
 */
export interface PaginatedListResponsePricingPolicySimpleResponse {
    /**
     * 
     * @type {Array<PricingPolicySimpleResponse>}
     * @memberof PaginatedListResponsePricingPolicySimpleResponse
     */
    'data': Array<PricingPolicySimpleResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePricingPolicySimpleResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePricingPolicySimpleResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePricingPolicySimpleResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponsePrintDocumentQueueResponse
 */
export interface PaginatedListResponsePrintDocumentQueueResponse {
    /**
     * 
     * @type {Array<PrintDocumentQueueResponse>}
     * @memberof PaginatedListResponsePrintDocumentQueueResponse
     */
    'data': Array<PrintDocumentQueueResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePrintDocumentQueueResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePrintDocumentQueueResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponsePrintDocumentQueueResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseReportDeliveryDocumentResponse
 */
export interface PaginatedListResponseReportDeliveryDocumentResponse {
    /**
     * 
     * @type {Array<ReportDeliveryDocumentResponse>}
     * @memberof PaginatedListResponseReportDeliveryDocumentResponse
     */
    'data': Array<ReportDeliveryDocumentResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportDeliveryDocumentResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportDeliveryDocumentResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportDeliveryDocumentResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseReportDeliveryItemByOrdersResponse
 */
export interface PaginatedListResponseReportDeliveryItemByOrdersResponse {
    /**
     * 
     * @type {Array<ReportDeliveryItemByOrdersResponse>}
     * @memberof PaginatedListResponseReportDeliveryItemByOrdersResponse
     */
    'data': Array<ReportDeliveryItemByOrdersResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportDeliveryItemByOrdersResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportDeliveryItemByOrdersResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportDeliveryItemByOrdersResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseReportDeliveryItemExtendedResponse
 */
export interface PaginatedListResponseReportDeliveryItemExtendedResponse {
    /**
     * 
     * @type {Array<ReportDeliveryItemExtendedResponse>}
     * @memberof PaginatedListResponseReportDeliveryItemExtendedResponse
     */
    'data': Array<ReportDeliveryItemExtendedResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportDeliveryItemExtendedResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportDeliveryItemExtendedResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportDeliveryItemExtendedResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseReportDeliveryItemResponse
 */
export interface PaginatedListResponseReportDeliveryItemResponse {
    /**
     * 
     * @type {Array<ReportDeliveryItemResponse>}
     * @memberof PaginatedListResponseReportDeliveryItemResponse
     */
    'data': Array<ReportDeliveryItemResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportDeliveryItemResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportDeliveryItemResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportDeliveryItemResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseReportDepletedAmountWithPricesResponse
 */
export interface PaginatedListResponseReportDepletedAmountWithPricesResponse {
    /**
     * 
     * @type {Array<ReportDepletedAmountWithPricesResponse>}
     * @memberof PaginatedListResponseReportDepletedAmountWithPricesResponse
     */
    'data': Array<ReportDepletedAmountWithPricesResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportDepletedAmountWithPricesResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportDepletedAmountWithPricesResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportDepletedAmountWithPricesResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseReportOrderBalanceResponse
 */
export interface PaginatedListResponseReportOrderBalanceResponse {
    /**
     * 
     * @type {Array<ReportOrderBalanceResponse>}
     * @memberof PaginatedListResponseReportOrderBalanceResponse
     */
    'data': Array<ReportOrderBalanceResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportOrderBalanceResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportOrderBalanceResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportOrderBalanceResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseReportSalesResponse
 */
export interface PaginatedListResponseReportSalesResponse {
    /**
     * 
     * @type {Array<ReportSalesResponse>}
     * @memberof PaginatedListResponseReportSalesResponse
     */
    'data': Array<ReportSalesResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportSalesResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportSalesResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseReportSalesResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseRoleListResponse
 */
export interface PaginatedListResponseRoleListResponse {
    /**
     * 
     * @type {Array<RoleListResponse>}
     * @memberof PaginatedListResponseRoleListResponse
     */
    'data': Array<RoleListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseRoleListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseRoleListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseRoleListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseShippingRouteListResponse
 */
export interface PaginatedListResponseShippingRouteListResponse {
    /**
     * 
     * @type {Array<ShippingRouteListResponse>}
     * @memberof PaginatedListResponseShippingRouteListResponse
     */
    'data': Array<ShippingRouteListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseShippingRouteListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseShippingRouteListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseShippingRouteListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseStockReceivingProcessItemResponse
 */
export interface PaginatedListResponseStockReceivingProcessItemResponse {
    /**
     * 
     * @type {Array<StockReceivingProcessItemResponse>}
     * @memberof PaginatedListResponseStockReceivingProcessItemResponse
     */
    'data': Array<StockReceivingProcessItemResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseStockReceivingProcessItemResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseStockReceivingProcessItemResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseStockReceivingProcessItemResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseStockReceivingProcessResponse
 */
export interface PaginatedListResponseStockReceivingProcessResponse {
    /**
     * 
     * @type {Array<StockReceivingProcessResponse>}
     * @memberof PaginatedListResponseStockReceivingProcessResponse
     */
    'data': Array<StockReceivingProcessResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseStockReceivingProcessResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseStockReceivingProcessResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseStockReceivingProcessResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseStockTakingReportItemResponse
 */
export interface PaginatedListResponseStockTakingReportItemResponse {
    /**
     * 
     * @type {Array<StockTakingReportItemResponse>}
     * @memberof PaginatedListResponseStockTakingReportItemResponse
     */
    'data': Array<StockTakingReportItemResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseStockTakingReportItemResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseStockTakingReportItemResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseStockTakingReportItemResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseStockTakingReportResponse
 */
export interface PaginatedListResponseStockTakingReportResponse {
    /**
     * 
     * @type {Array<StockTakingReportResponse>}
     * @memberof PaginatedListResponseStockTakingReportResponse
     */
    'data': Array<StockTakingReportResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseStockTakingReportResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseStockTakingReportResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseStockTakingReportResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseStockTakingTaskResponse
 */
export interface PaginatedListResponseStockTakingTaskResponse {
    /**
     * 
     * @type {Array<StockTakingTaskResponse>}
     * @memberof PaginatedListResponseStockTakingTaskResponse
     */
    'data': Array<StockTakingTaskResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseStockTakingTaskResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseStockTakingTaskResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseStockTakingTaskResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseStocktakingResponse
 */
export interface PaginatedListResponseStocktakingResponse {
    /**
     * 
     * @type {Array<StocktakingResponse>}
     * @memberof PaginatedListResponseStocktakingResponse
     */
    'data': Array<StocktakingResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseStocktakingResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseStocktakingResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseStocktakingResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseSupplierListResponse
 */
export interface PaginatedListResponseSupplierListResponse {
    /**
     * 
     * @type {Array<SupplierListResponse>}
     * @memberof PaginatedListResponseSupplierListResponse
     */
    'data': Array<SupplierListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseSupplierListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseSupplierListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseSupplierListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseSupplierPriceDifferenceResponse
 */
export interface PaginatedListResponseSupplierPriceDifferenceResponse {
    /**
     * 
     * @type {Array<SupplierPriceDifferenceResponse>}
     * @memberof PaginatedListResponseSupplierPriceDifferenceResponse
     */
    'data': Array<SupplierPriceDifferenceResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseSupplierPriceDifferenceResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseSupplierPriceDifferenceResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseSupplierPriceDifferenceResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseSupplierQuantityDifferenceResponse
 */
export interface PaginatedListResponseSupplierQuantityDifferenceResponse {
    /**
     * 
     * @type {Array<SupplierQuantityDifferenceResponse>}
     * @memberof PaginatedListResponseSupplierQuantityDifferenceResponse
     */
    'data': Array<SupplierQuantityDifferenceResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseSupplierQuantityDifferenceResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseSupplierQuantityDifferenceResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseSupplierQuantityDifferenceResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseSupplierReserveSubscriptionResponse
 */
export interface PaginatedListResponseSupplierReserveSubscriptionResponse {
    /**
     * 
     * @type {Array<SupplierReserveSubscriptionResponse>}
     * @memberof PaginatedListResponseSupplierReserveSubscriptionResponse
     */
    'data': Array<SupplierReserveSubscriptionResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseSupplierReserveSubscriptionResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseSupplierReserveSubscriptionResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseSupplierReserveSubscriptionResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseTransactionListResponse
 */
export interface PaginatedListResponseTransactionListResponse {
    /**
     * 
     * @type {Array<TransactionListResponse>}
     * @memberof PaginatedListResponseTransactionListResponse
     */
    'data': Array<TransactionListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseTransactionListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseTransactionListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseTransactionListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseTreasuryListResponse
 */
export interface PaginatedListResponseTreasuryListResponse {
    /**
     * 
     * @type {Array<TreasuryListResponse>}
     * @memberof PaginatedListResponseTreasuryListResponse
     */
    'data': Array<TreasuryListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseTreasuryListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseTreasuryListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseTreasuryListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseUserListResponse
 */
export interface PaginatedListResponseUserListResponse {
    /**
     * 
     * @type {Array<UserListResponse>}
     * @memberof PaginatedListResponseUserListResponse
     */
    'data': Array<UserListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseUserListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseUserListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseUserListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseWarehouseListResponse
 */
export interface PaginatedListResponseWarehouseListResponse {
    /**
     * 
     * @type {Array<WarehouseListResponse>}
     * @memberof PaginatedListResponseWarehouseListResponse
     */
    'data': Array<WarehouseListResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseWarehouseListResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseWarehouseListResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseWarehouseListResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PaginatedListResponseWarehouseMovementResponse
 */
export interface PaginatedListResponseWarehouseMovementResponse {
    /**
     * 
     * @type {Array<WarehouseMovementResponse>}
     * @memberof PaginatedListResponseWarehouseMovementResponse
     */
    'data': Array<WarehouseMovementResponse>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseWarehouseMovementResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseWarehouseMovementResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListResponseWarehouseMovementResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PartnerBonusQuantityResponse
 */
export interface PartnerBonusQuantityResponse {
    /**
     * 
     * @type {number}
     * @memberof PartnerBonusQuantityResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PartnerBonusQuantityResponse
     */
    'partnerId': number;
    /**
     * 
     * @type {number}
     * @memberof PartnerBonusQuantityResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof PartnerBonusQuantityResponse
     */
    'baseQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerBonusQuantityResponse
     */
    'bonusQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerBonusQuantityResponse
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerBonusQuantityResponse
     */
    'endDate': string;
}
/**
 * 
 * @export
 * @interface PartnerContactResponse
 */
export interface PartnerContactResponse {
    /**
     * 
     * @type {string}
     * @memberof PartnerContactResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerContactResponse
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerContactResponse
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface PartnerItemCategoryResponse
 */
export interface PartnerItemCategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof PartnerItemCategoryResponse
     */
    'partnerId': number;
    /**
     * 
     * @type {number}
     * @memberof PartnerItemCategoryResponse
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof PartnerItemCategoryResponse
     */
    'itemCategoryParentId'?: number;
}
/**
 * 
 * @export
 * @interface PartnerItemConfigurationResponse
 */
export interface PartnerItemConfigurationResponse {
    /**
     * 
     * @type {number}
     * @memberof PartnerItemConfigurationResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PartnerItemConfigurationResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof PartnerItemConfigurationResponse
     */
    'externalItemUniqueKey': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerItemConfigurationResponse
     */
    'externalItemArtNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerItemConfigurationResponse
     */
    'externalItemName': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerItemConfigurationResponse
     */
    'min'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerItemConfigurationResponse
     */
    'max'?: string;
}
/**
 * 
 * @export
 * @interface PartnerItemResponse
 */
export interface PartnerItemResponse {
    /**
     * 
     * @type {string}
     * @memberof PartnerItemResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerItemResponse
     */
    'artNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerItemResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PartnerListResponse
 */
export interface PartnerListResponse {
    /**
     * 
     * @type {number}
     * @memberof PartnerListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PartnerListResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerListResponse
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerListResponse
     */
    'integrationType': number;
    /**
     * 
     * @type {number}
     * @memberof PartnerListResponse
     */
    'legalEntityId': number;
    /**
     * 
     * @type {number}
     * @memberof PartnerListResponse
     */
    'pricingPolicyId': number;
    /**
     * 
     * @type {number}
     * @memberof PartnerListResponse
     */
    'preferredWarehouseId': number;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerListResponse
     */
    'hidePrices': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerListResponse
     */
    'collectOnDeliveryDate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerListResponse
     */
    'moveOrdersToDeliveredAfterShipped': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerListResponse
     */
    'maintainStock': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerListResponse
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface PartnerResponse
 */
export interface PartnerResponse {
    /**
     * 
     * @type {number}
     * @memberof PartnerResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PartnerResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerResponse
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerResponse
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerResponse
     */
    'addressLine': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerResponse
     */
    'note'?: string;
    /**
     * 
     * @type {Array<PartnerContactResponse>}
     * @memberof PartnerResponse
     */
    'contacts'?: Array<PartnerContactResponse>;
    /**
     * 
     * @type {number}
     * @memberof PartnerResponse
     */
    'legalEntityId': number;
    /**
     * 
     * @type {number}
     * @memberof PartnerResponse
     */
    'companyCategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof PartnerResponse
     */
    'pricingPolicyId': number;
    /**
     * 
     * @type {number}
     * @memberof PartnerResponse
     */
    'preferredWarehouseId': number;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerResponse
     */
    'hidePrices': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerResponse
     */
    'collectOnDeliveryDate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerResponse
     */
    'moveOrdersToDeliveredAfterShipped': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerResponse
     */
    'maintainStock': boolean;
    /**
     * 
     * @type {number}
     * @memberof PartnerResponse
     */
    'integrationType': number;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerResponse
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface PatchItemDeliverySubscriptionRequest
 */
export interface PatchItemDeliverySubscriptionRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchItemDeliverySubscriptionRequest
     */
    'daysForDelivery'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchItemDeliverySubscriptionRequest
     */
    'batchSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchItemDeliverySubscriptionRequest
     */
    'bufferPercentage'?: number;
}
/**
 * 
 * @export
 * @interface PatchOrderRequest
 */
export interface PatchOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchOrderRequest
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchOrderRequest
     */
    'packageQty'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchOrderRequest
     */
    'userResponsibleId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchOrderRequest
     */
    'orderPriority'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchOrderRequest
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchOrderRequest
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface PatchPartnerBonusQuantityRequest
 */
export interface PatchPartnerBonusQuantityRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchPartnerBonusQuantityRequest
     */
    'baseQuantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchPartnerBonusQuantityRequest
     */
    'bonusQuantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchPartnerBonusQuantityRequest
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchPartnerBonusQuantityRequest
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface PatchSupplierReserveSubscriptionRequest
 */
export interface PatchSupplierReserveSubscriptionRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchSupplierReserveSubscriptionRequest
     */
    'weekdays'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PatchSupplierReserveSubscriptionRequest
     */
    'baseDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchSupplierReserveSubscriptionRequest
     */
    'reserveDays'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchSupplierReserveSubscriptionRequest
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface PatchTransactionRequest
 */
export interface PatchTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchTransactionRequest
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchTransactionRequest
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchTransactionRequest
     */
    'transactionType'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchTransactionRequest
     */
    'treasuryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchTransactionRequest
     */
    'info'?: string;
}
/**
 * 
 * @export
 * @interface PatchTreasuryRequest
 */
export interface PatchTreasuryRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchTreasuryRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchTreasuryRequest
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface PaymentOptionResponse
 */
export interface PaymentOptionResponse {
    /**
     * 
     * @type {number}
     * @memberof PaymentOptionResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PaymentOptionResponse
     */
    'account': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentOptionResponse
     */
    'holder': string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentOptionResponse
     */
    'payOnDelivery': boolean;
    /**
     * 
     * @type {number}
     * @memberof PaymentOptionResponse
     */
    'companyId': number;
}
/**
 * 
 * @export
 * @interface PermissionResponse
 */
export interface PermissionResponse {
    /**
     * 
     * @type {number}
     * @memberof PermissionResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PermissionResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PortalUserListResponse
 */
export interface PortalUserListResponse {
    /**
     * 
     * @type {number}
     * @memberof PortalUserListResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PortalUserListResponse
     */
    'userId': number;
}
/**
 * 
 * @export
 * @interface PortalUserResponse
 */
export interface PortalUserResponse {
    /**
     * 
     * @type {number}
     * @memberof PortalUserResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PortalUserResponse
     */
    'userId': number;
}
/**
 * 
 * @export
 * @interface PricingPolicyItemCategoryRequest
 */
export interface PricingPolicyItemCategoryRequest {
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyItemCategoryRequest
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyItemCategoryRequest
     */
    'multiplier': string;
}
/**
 * 
 * @export
 * @interface PricingPolicyItemCategoryResponse
 */
export interface PricingPolicyItemCategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyItemCategoryResponse
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyItemCategoryResponse
     */
    'multiplier': string;
}
/**
 * 
 * @export
 * @interface PricingPolicyItemExceptionRequest
 */
export interface PricingPolicyItemExceptionRequest {
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyItemExceptionRequest
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyItemExceptionRequest
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyItemExceptionRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface PricingPolicyItemExceptionResponse
 */
export interface PricingPolicyItemExceptionResponse {
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyItemExceptionResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyItemExceptionResponse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyItemExceptionResponse
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface PricingPolicyListResponse
 */
export interface PricingPolicyListResponse {
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyListResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyListResponse
     */
    'companyId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PricingPolicyListResponse
     */
    'partners'?: Array<number>;
}
/**
 * 
 * @export
 * @interface PricingPolicyResponse
 */
export interface PricingPolicyResponse {
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyResponse
     */
    'companyId': number;
}
/**
 * 
 * @export
 * @interface PricingPolicySimpleResponse
 */
export interface PricingPolicySimpleResponse {
    /**
     * 
     * @type {number}
     * @memberof PricingPolicySimpleResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicySimpleResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PrintDocumentQueueResponse
 */
export interface PrintDocumentQueueResponse {
    /**
     * 
     * @type {number}
     * @memberof PrintDocumentQueueResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PrintDocumentQueueResponse
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof PrintDocumentQueueResponse
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof PrintDocumentQueueResponse
     */
    'printer': string;
    /**
     * 
     * @type {number}
     * @memberof PrintDocumentQueueResponse
     */
    'createdById': number;
    /**
     * 
     * @type {string}
     * @memberof PrintDocumentQueueResponse
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof PrintDocumentQueueResponse
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface ReportDeliveryDocumentResponse
 */
export interface ReportDeliveryDocumentResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryDocumentResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryDocumentResponse
     */
    'deliveryId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryDocumentResponse
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryDocumentResponse
     */
    'paymentDue': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryDocumentResponse
     */
    'number': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryDocumentResponse
     */
    'senderId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryDocumentResponse
     */
    'receiverId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryDocumentResponse
     */
    'paymentOption': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryDocumentResponse
     */
    'deliveryDocumentType': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryDocumentResponse
     */
    'totalIncludingVat': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryDocumentResponse
     */
    'remainingAmount': string;
}
/**
 * 
 * @export
 * @interface ReportDeliveryItemByOrdersResponse
 */
export interface ReportDeliveryItemByOrdersResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'itemArtNo': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'itemAvailableQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'orderRequiredQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'stockBalance': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'supplierId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'supplierSubscriptionEmail'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'supplierHasSubscription': boolean;
}
/**
 * 
 * @export
 * @interface ReportDeliveryItemExtendedResponse
 */
export interface ReportDeliveryItemExtendedResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'deliveryId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'supplierId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'deliveryDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'batchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'countryOfOrigin': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'priceWithoutVat': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemQuantity': string;
}
/**
 * 
 * @export
 * @interface ReportDeliveryItemResponse
 */
export interface ReportDeliveryItemResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemResponse
     */
    'priceWithoutVat': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemResponse
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemResponse
     */
    'batchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemResponse
     */
    'countryOfOrigin': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemResponse
     */
    'availableQuantity': string;
}
/**
 * 
 * @export
 * @interface ReportDepletedAmountWithPricesResponse
 */
export interface ReportDepletedAmountWithPricesResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPricesResponse
     */
    'orderId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPricesResponse
     */
    'orderDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPricesResponse
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPricesResponse
     */
    'receiverId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPricesResponse
     */
    'companyId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPricesResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPricesResponse
     */
    'deliveryId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPricesResponse
     */
    'supplierId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPricesResponse
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPricesResponse
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPricesResponse
     */
    'itemQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPricesResponse
     */
    'deliveryItemPrice': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPricesResponse
     */
    'profitRatio': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPricesResponse
     */
    'orderItemPrice': string;
}
/**
 * 
 * @export
 * @interface ReportOrderBalanceResponse
 */
export interface ReportOrderBalanceResponse {
    /**
     * 
     * @type {string}
     * @memberof ReportOrderBalanceResponse
     */
    'orderType': string;
    /**
     * 
     * @type {number}
     * @memberof ReportOrderBalanceResponse
     */
    'receiverId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportOrderBalanceResponse
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOrderBalanceResponse
     */
    'warehouseId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportOrderBalanceResponse
     */
    'totalAmount': string;
}
/**
 * 
 * @export
 * @interface ReportPricingPolicyItemCategoryMultipliersListResponse
 */
export interface ReportPricingPolicyItemCategoryMultipliersListResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportPricingPolicyItemCategoryMultipliersListResponse
     */
    'pricingPolicyIds': Array<number>;
    /**
     * 
     * @type {Array<ReportPricingPolicyItemCategoryMultipliersResponse>}
     * @memberof ReportPricingPolicyItemCategoryMultipliersListResponse
     */
    'values': Array<ReportPricingPolicyItemCategoryMultipliersResponse>;
}
/**
 * 
 * @export
 * @interface ReportPricingPolicyItemCategoryMultipliersResponse
 */
export interface ReportPricingPolicyItemCategoryMultipliersResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportPricingPolicyItemCategoryMultipliersResponse
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportPricingPolicyItemCategoryMultipliersResponse
     */
    'itemCategoryName': string;
    /**
     * 
     * @type {Array<ReportPricingPolicyMultiplierValueResponse>}
     * @memberof ReportPricingPolicyItemCategoryMultipliersResponse
     */
    'values': Array<ReportPricingPolicyMultiplierValueResponse>;
}
/**
 * 
 * @export
 * @interface ReportPricingPolicyItemPricesListResponse
 */
export interface ReportPricingPolicyItemPricesListResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportPricingPolicyItemPricesListResponse
     */
    'pricingPolicyIds': Array<number>;
    /**
     * 
     * @type {Array<ReportPricingPolicyItemPricesResponse>}
     * @memberof ReportPricingPolicyItemPricesListResponse
     */
    'values': Array<ReportPricingPolicyItemPricesResponse>;
}
/**
 * 
 * @export
 * @interface ReportPricingPolicyItemPricesResponse
 */
export interface ReportPricingPolicyItemPricesResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportPricingPolicyItemPricesResponse
     */
    'itemId': number;
    /**
     * 
     * @type {Array<ReportPricingPolicyValueResponse>}
     * @memberof ReportPricingPolicyItemPricesResponse
     */
    'values': Array<ReportPricingPolicyValueResponse>;
}
/**
 * 
 * @export
 * @interface ReportPricingPolicyMultiplierValueResponse
 */
export interface ReportPricingPolicyMultiplierValueResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportPricingPolicyMultiplierValueResponse
     */
    'pricingPolicyId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportPricingPolicyMultiplierValueResponse
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface ReportPricingPolicyValueResponse
 */
export interface ReportPricingPolicyValueResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportPricingPolicyValueResponse
     */
    'pricingPolicyId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportPricingPolicyValueResponse
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface ReportSalesResponse
 */
export interface ReportSalesResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportSalesResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ReportSalesResponse
     */
    'orderCreatedDate': string;
    /**
     * 
     * @type {number}
     * @memberof ReportSalesResponse
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportSalesResponse
     */
    'receiverId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportSalesResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportSalesResponse
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportSalesResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportSalesResponse
     */
    'itemPrice': string;
    /**
     * 
     * @type {string}
     * @memberof ReportSalesResponse
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportSalesResponse
     */
    'batchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ReportSalesResponse
     */
    'countryOfOrigin': string;
    /**
     * 
     * @type {string}
     * @memberof ReportSalesResponse
     */
    'quantityBalance': string;
}
/**
 * 
 * @export
 * @interface Request
 */
export interface Request {
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'requestType'?: RequestRequestTypeEnum;
    /**
     * 
     * @type {Array<SetPricesItemRequest>}
     * @memberof Request
     */
    'items': Array<SetPricesItemRequest>;
}

export const RequestRequestTypeEnum = {
    SetPrices: 'set-prices'
} as const;

export type RequestRequestTypeEnum = typeof RequestRequestTypeEnum[keyof typeof RequestRequestTypeEnum];

/**
 * 
 * @export
 * @interface Request1
 */
export interface Request1 {
    /**
     * 
     * @type {string}
     * @memberof Request1
     */
    'requestType'?: Request1RequestTypeEnum;
    /**
     * 
     * @type {MistralConfigurationRequest}
     * @memberof Request1
     */
    'configuration': MistralConfigurationRequest;
}

export const Request1RequestTypeEnum = {
    SetMistralConfiguration: 'set-mistral-configuration'
} as const;

export type Request1RequestTypeEnum = typeof Request1RequestTypeEnum[keyof typeof Request1RequestTypeEnum];

/**
 * 
 * @export
 * @interface RetailConfigurationRequest
 */
export interface RetailConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof RetailConfigurationRequest
     */
    'endpointUrl': string;
    /**
     * 
     * @type {string}
     * @memberof RetailConfigurationRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof RetailConfigurationRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RetailConfigurationRequest
     */
    'storeId': string;
}
/**
 * 
 * @export
 * @interface RoleListResponse
 */
export interface RoleListResponse {
    /**
     * 
     * @type {number}
     * @memberof RoleListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof RoleListResponse
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof RoleListResponse
     */
    'users'?: Array<number>;
}
/**
 * 
 * @export
 * @interface RoleResponse
 */
export interface RoleResponse {
    /**
     * 
     * @type {number}
     * @memberof RoleResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof RoleResponse
     */
    'users'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof RoleResponse
     */
    'permissions'?: Array<number>;
}
/**
 * 
 * @export
 * @interface SaveCompanyCategoryRequest
 */
export interface SaveCompanyCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof SaveCompanyCategoryRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SaveCompanyCategoryRequest
     */
    'parentId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SaveCompanyCategoryRequest
     */
    'children'?: Array<number>;
}
/**
 * 
 * @export
 * @interface SaveItemCategoryRequest
 */
export interface SaveItemCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof SaveItemCategoryRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SaveItemCategoryRequest
     */
    'parentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SaveItemCategoryRequest
     */
    'aliasId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SaveItemCategoryRequest
     */
    'children'?: Array<number>;
}
/**
 * 
 * @export
 * @interface SaveItemRequest
 */
export interface SaveItemRequest {
    /**
     * 
     * @type {string}
     * @memberof SaveItemRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SaveItemRequest
     */
    'artNo': string;
    /**
     * 
     * @type {string}
     * @memberof SaveItemRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveItemRequest
     */
    'width': string;
    /**
     * 
     * @type {string}
     * @memberof SaveItemRequest
     */
    'height': string;
    /**
     * 
     * @type {string}
     * @memberof SaveItemRequest
     */
    'depth': string;
    /**
     * 
     * @type {string}
     * @memberof SaveItemRequest
     */
    'weight': string;
    /**
     * 
     * @type {number}
     * @memberof SaveItemRequest
     */
    'unitId': number;
    /**
     * 
     * @type {number}
     * @memberof SaveItemRequest
     */
    'deliveryRequestUnit': number;
    /**
     * 
     * @type {number}
     * @memberof SaveItemRequest
     */
    'packageQty': number;
    /**
     * 
     * @type {number}
     * @memberof SaveItemRequest
     */
    'packageQtyDelivery': number;
    /**
     * 
     * @type {number}
     * @memberof SaveItemRequest
     */
    'barcodeStandardId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SaveItemRequest
     */
    'barcodeValue': string;
    /**
     * 
     * @type {string}
     * @memberof SaveItemRequest
     */
    'additionalBarcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveItemRequest
     */
    'basePrice': string;
    /**
     * 
     * @type {string}
     * @memberof SaveItemRequest
     */
    'vatPercentage': string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveItemRequest
     */
    'deliveryOnDemand'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveItemRequest
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SaveItemRequest
     */
    'manufacturerId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SaveItemRequest
     */
    'maintainStock'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SaveItemRequest
     */
    'minimumAllowedDaysToExpiration': number;
    /**
     * 
     * @type {number}
     * @memberof SaveItemRequest
     */
    'factoryDaysToExpiration': number;
    /**
     * 
     * @type {string}
     * @memberof SaveItemRequest
     */
    'minimumRequiredPartOfFactoryDaysOfExpirationForDelivery': string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveItemRequest
     */
    'tracksBatches'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveItemRequest
     */
    'isOwnedByUs'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SaveItemRequest
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof SaveItemRequest
     */
    'supplierId': number;
}
/**
 * 
 * @export
 * @interface SaveLegalEntityRequest
 */
export interface SaveLegalEntityRequest {
    /**
     * 
     * @type {string}
     * @memberof SaveLegalEntityRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SaveLegalEntityRequest
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveLegalEntityRequest
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof SaveLegalEntityRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof SaveLegalEntityRequest
     */
    'addressLine': string;
    /**
     * 
     * @type {string}
     * @memberof SaveLegalEntityRequest
     */
    'idnumber': string;
    /**
     * 
     * @type {string}
     * @memberof SaveLegalEntityRequest
     */
    'vat': string;
    /**
     * 
     * @type {string}
     * @memberof SaveLegalEntityRequest
     */
    'accountable': string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveLegalEntityRequest
     */
    'usesDeferredBilling': boolean;
    /**
     * 
     * @type {number}
     * @memberof SaveLegalEntityRequest
     */
    'paymentOptionId': number;
    /**
     * 
     * @type {number}
     * @memberof SaveLegalEntityRequest
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof SaveLegalEntityRequest
     */
    'visualization'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveLegalEntityRequest
     */
    'addressOverride'?: string;
    /**
     * 
     * @type {number}
     * @memberof SaveLegalEntityRequest
     */
    'paymentDueDays': number;
    /**
     * 
     * @type {string}
     * @memberof SaveLegalEntityRequest
     */
    'creditLimitAmount'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveLegalEntityRequest
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface SaveManufacturerRequest
 */
export interface SaveManufacturerRequest {
    /**
     * 
     * @type {string}
     * @memberof SaveManufacturerRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SaveManufacturerRequest
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface SaveOrderItemRequest
 */
export interface SaveOrderItemRequest {
    /**
     * 
     * @type {number}
     * @memberof SaveOrderItemRequest
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof SaveOrderItemRequest
     */
    'orderedQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof SaveOrderItemRequest
     */
    'bonusQuantity'?: string;
}
/**
 * 
 * @export
 * @interface SavePartnerItemConfigurationRequest
 */
export interface SavePartnerItemConfigurationRequest {
    /**
     * 
     * @type {number}
     * @memberof SavePartnerItemConfigurationRequest
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof SavePartnerItemConfigurationRequest
     */
    'externalItemUniqueKey': string;
    /**
     * 
     * @type {string}
     * @memberof SavePartnerItemConfigurationRequest
     */
    'externalItemArtNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavePartnerItemConfigurationRequest
     */
    'externalItemName': string;
    /**
     * 
     * @type {string}
     * @memberof SavePartnerItemConfigurationRequest
     */
    'min'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavePartnerItemConfigurationRequest
     */
    'max'?: string;
}
/**
 * 
 * @export
 * @interface SavePartnerRequest
 */
export interface SavePartnerRequest {
    /**
     * 
     * @type {string}
     * @memberof SavePartnerRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SavePartnerRequest
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof SavePartnerRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof SavePartnerRequest
     */
    'addressLine': string;
    /**
     * 
     * @type {string}
     * @memberof SavePartnerRequest
     */
    'note'?: string;
    /**
     * 
     * @type {Array<BackendAppApiV3SchemasPartnerSchemasSaveContactRequest>}
     * @memberof SavePartnerRequest
     */
    'contacts'?: Array<BackendAppApiV3SchemasPartnerSchemasSaveContactRequest>;
    /**
     * 
     * @type {number}
     * @memberof SavePartnerRequest
     */
    'legalEntityId': number;
    /**
     * 
     * @type {number}
     * @memberof SavePartnerRequest
     */
    'companyCategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof SavePartnerRequest
     */
    'pricingPolicyId': number;
    /**
     * 
     * @type {number}
     * @memberof SavePartnerRequest
     */
    'preferredWarehouseId': number;
    /**
     * 
     * @type {boolean}
     * @memberof SavePartnerRequest
     */
    'hidePrices': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SavePartnerRequest
     */
    'collectOnDeliveryDate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SavePartnerRequest
     */
    'moveOrdersToDeliveredAfterShipped': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SavePartnerRequest
     */
    'maintainStock': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SavePartnerRequest
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface SavePaymentOptionRequest
 */
export interface SavePaymentOptionRequest {
    /**
     * 
     * @type {string}
     * @memberof SavePaymentOptionRequest
     */
    'account': string;
    /**
     * 
     * @type {string}
     * @memberof SavePaymentOptionRequest
     */
    'holder': string;
    /**
     * 
     * @type {boolean}
     * @memberof SavePaymentOptionRequest
     */
    'payOnDelivery': boolean;
    /**
     * 
     * @type {number}
     * @memberof SavePaymentOptionRequest
     */
    'companyId': number;
}
/**
 * 
 * @export
 * @interface SavePricingPolicyRequest
 */
export interface SavePricingPolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof SavePricingPolicyRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SavePricingPolicyRequest
     */
    'companyId': number;
    /**
     * 
     * @type {Array<PricingPolicyItemCategoryRequest>}
     * @memberof SavePricingPolicyRequest
     */
    'itemCategories': Array<PricingPolicyItemCategoryRequest>;
    /**
     * 
     * @type {Array<PricingPolicyItemExceptionRequest>}
     * @memberof SavePricingPolicyRequest
     */
    'itemExceptions'?: Array<PricingPolicyItemExceptionRequest>;
}
/**
 * 
 * @export
 * @interface SaveSupplierRequest
 */
export interface SaveSupplierRequest {
    /**
     * 
     * @type {string}
     * @memberof SaveSupplierRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SaveSupplierRequest
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof SaveSupplierRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof SaveSupplierRequest
     */
    'addressLine': string;
    /**
     * 
     * @type {string}
     * @memberof SaveSupplierRequest
     */
    'note'?: string;
    /**
     * 
     * @type {Array<BackendAppApiV3SchemasSupplierSchemasSaveContactRequest>}
     * @memberof SaveSupplierRequest
     */
    'contacts'?: Array<BackendAppApiV3SchemasSupplierSchemasSaveContactRequest>;
    /**
     * 
     * @type {string}
     * @memberof SaveSupplierRequest
     */
    'paymentInfo': string;
    /**
     * 
     * @type {number}
     * @memberof SaveSupplierRequest
     */
    'companyCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof SaveSupplierRequest
     */
    'subscriptionEmail'?: string;
    /**
     * 
     * @type {number}
     * @memberof SaveSupplierRequest
     */
    'daysForDelivery': number;
    /**
     * 
     * @type {string}
     * @memberof SaveSupplierRequest
     */
    'deliveryRequestThreshold': string;
}
/**
 * 
 * @export
 * @interface SaveWarehouseRequest
 */
export interface SaveWarehouseRequest {
    /**
     * 
     * @type {string}
     * @memberof SaveWarehouseRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SaveWarehouseRequest
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof SaveWarehouseRequest
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveWarehouseRequest
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof SaveWarehouseRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof SaveWarehouseRequest
     */
    'addressLine': string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveWarehouseRequest
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface SetBarsyConfigurationRequest
 */
export interface SetBarsyConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof SetBarsyConfigurationRequest
     */
    'requestType'?: SetBarsyConfigurationRequestRequestTypeEnum;
    /**
     * 
     * @type {BarsyConfigurationRequest}
     * @memberof SetBarsyConfigurationRequest
     */
    'configuration': BarsyConfigurationRequest;
}

export const SetBarsyConfigurationRequestRequestTypeEnum = {
    SetBarsyConfiguration: 'set-barsy-configuration'
} as const;

export type SetBarsyConfigurationRequestRequestTypeEnum = typeof SetBarsyConfigurationRequestRequestTypeEnum[keyof typeof SetBarsyConfigurationRequestRequestTypeEnum];

/**
 * 
 * @export
 * @interface SetICashConfigurationRequest
 */
export interface SetICashConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof SetICashConfigurationRequest
     */
    'requestType'?: SetICashConfigurationRequestRequestTypeEnum;
    /**
     * 
     * @type {ICashConfigurationRequest}
     * @memberof SetICashConfigurationRequest
     */
    'configuration': ICashConfigurationRequest;
}

export const SetICashConfigurationRequestRequestTypeEnum = {
    SetIcashConfiguration: 'set-icash-configuration'
} as const;

export type SetICashConfigurationRequestRequestTypeEnum = typeof SetICashConfigurationRequestRequestTypeEnum[keyof typeof SetICashConfigurationRequestRequestTypeEnum];

/**
 * 
 * @export
 * @interface SetMistralConfigurationRequest
 */
export interface SetMistralConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof SetMistralConfigurationRequest
     */
    'requestType'?: SetMistralConfigurationRequestRequestTypeEnum;
    /**
     * 
     * @type {MistralConfigurationRequest}
     * @memberof SetMistralConfigurationRequest
     */
    'configuration': MistralConfigurationRequest;
}

export const SetMistralConfigurationRequestRequestTypeEnum = {
    SetMistralConfiguration: 'set-mistral-configuration'
} as const;

export type SetMistralConfigurationRequestRequestTypeEnum = typeof SetMistralConfigurationRequestRequestTypeEnum[keyof typeof SetMistralConfigurationRequestRequestTypeEnum];

/**
 * 
 * @export
 * @interface SetNoConfigurationRequest
 */
export interface SetNoConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof SetNoConfigurationRequest
     */
    'requestType'?: SetNoConfigurationRequestRequestTypeEnum;
}

export const SetNoConfigurationRequestRequestTypeEnum = {
    SetNoConfiguration: 'set-no-configuration'
} as const;

export type SetNoConfigurationRequestRequestTypeEnum = typeof SetNoConfigurationRequestRequestTypeEnum[keyof typeof SetNoConfigurationRequestRequestTypeEnum];

/**
 * 
 * @export
 * @interface SetOrderingPolicyRequest
 */
export interface SetOrderingPolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof SetOrderingPolicyRequest
     */
    'allowedWeekdays': string;
    /**
     * 
     * @type {string}
     * @memberof SetOrderingPolicyRequest
     */
    'nextDayDeliveryCutoff': string;
}
/**
 * 
 * @export
 * @interface SetPricesItemRequest
 */
export interface SetPricesItemRequest {
    /**
     * 
     * @type {number}
     * @memberof SetPricesItemRequest
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SetPricesItemRequest
     */
    'price': string;
    /**
     * 
     * @type {string}
     * @memberof SetPricesItemRequest
     */
    'finalQuantity': string;
}
/**
 * 
 * @export
 * @interface SetPricesRequest
 */
export interface SetPricesRequest {
    /**
     * 
     * @type {string}
     * @memberof SetPricesRequest
     */
    'requestType'?: SetPricesRequestRequestTypeEnum;
    /**
     * 
     * @type {Array<SetPricesItemRequest>}
     * @memberof SetPricesRequest
     */
    'items': Array<SetPricesItemRequest>;
}

export const SetPricesRequestRequestTypeEnum = {
    SetPrices: 'set-prices'
} as const;

export type SetPricesRequestRequestTypeEnum = typeof SetPricesRequestRequestTypeEnum[keyof typeof SetPricesRequestRequestTypeEnum];

/**
 * 
 * @export
 * @interface SetRetailConfigurationRequest
 */
export interface SetRetailConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof SetRetailConfigurationRequest
     */
    'requestType'?: SetRetailConfigurationRequestRequestTypeEnum;
    /**
     * 
     * @type {RetailConfigurationRequest}
     * @memberof SetRetailConfigurationRequest
     */
    'configuration': RetailConfigurationRequest;
}

export const SetRetailConfigurationRequestRequestTypeEnum = {
    SetRetailConfiguration: 'set-retail-configuration'
} as const;

export type SetRetailConfigurationRequestRequestTypeEnum = typeof SetRetailConfigurationRequestRequestTypeEnum[keyof typeof SetRetailConfigurationRequestRequestTypeEnum];

/**
 * 
 * @export
 * @interface ShippingRouteListResponse
 */
export interface ShippingRouteListResponse {
    /**
     * 
     * @type {number}
     * @memberof ShippingRouteListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ShippingRouteListResponse
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof ShippingRouteListResponse
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof ShippingRouteListResponse
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface ShippingRouteResponse
 */
export interface ShippingRouteResponse {
    /**
     * 
     * @type {number}
     * @memberof ShippingRouteResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ShippingRouteResponse
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof ShippingRouteResponse
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof ShippingRouteResponse
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingRouteResponse
     */
    'ordersTotal': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingRouteResponse
     */
    'ordersTotalIncludingVat': string;
}
/**
 * 
 * @export
 * @interface SimilarItemAssociationResponse
 */
export interface SimilarItemAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof SimilarItemAssociationResponse
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof SimilarItemAssociationResponse
     */
    'similarItemId': number;
}
/**
 * 
 * @export
 * @interface StartStockReceivingProcessItemRequest
 */
export interface StartStockReceivingProcessItemRequest {
    /**
     * 
     * @type {string}
     * @memberof StartStockReceivingProcessItemRequest
     */
    'quantity': string;
    /**
     * 
     * @type {string}
     * @memberof StartStockReceivingProcessItemRequest
     */
    'batchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof StartStockReceivingProcessItemRequest
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof StartStockReceivingProcessItemRequest
     */
    'countryOfOrigin': string;
    /**
     * 
     * @type {number}
     * @memberof StartStockReceivingProcessItemRequest
     */
    'itemId': number;
}
/**
 * 
 * @export
 * @interface StartStockReceivingProcessRequest
 */
export interface StartStockReceivingProcessRequest {
    /**
     * 
     * @type {number}
     * @memberof StartStockReceivingProcessRequest
     */
    'counterUserId': number;
    /**
     * 
     * @type {Array<StartStockReceivingProcessItemRequest>}
     * @memberof StartStockReceivingProcessRequest
     */
    'items': Array<StartStockReceivingProcessItemRequest>;
}
/**
 * 
 * @export
 * @interface StockReceivingProcessItemResponse
 */
export interface StockReceivingProcessItemResponse {
    /**
     * 
     * @type {number}
     * @memberof StockReceivingProcessItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StockReceivingProcessItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessItemResponse
     */
    'documentQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessItemResponse
     */
    'batchNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessItemResponse
     */
    'expirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessItemResponse
     */
    'countryOfOrigin': string;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessItemResponse
     */
    'countedQuantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessItemResponse
     */
    'verifiedQuantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessItemResponse
     */
    'finalQuantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockReceivingProcessItemResponse
     */
    'price'?: string;
}
/**
 * 
 * @export
 * @interface StockReceivingProcessResponse
 */
export interface StockReceivingProcessResponse {
    /**
     * 
     * @type {number}
     * @memberof StockReceivingProcessResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StockReceivingProcessResponse
     */
    'deliveryRequestId': number;
    /**
     * 
     * @type {number}
     * @memberof StockReceivingProcessResponse
     */
    'deliveryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof StockReceivingProcessResponse
     */
    'documentProcessingUserId': number;
    /**
     * 
     * @type {number}
     * @memberof StockReceivingProcessResponse
     */
    'counterUserId': number;
    /**
     * 
     * @type {number}
     * @memberof StockReceivingProcessResponse
     */
    'verifierUserId'?: number;
    /**
     * 
     * @type {number}
     * @memberof StockReceivingProcessResponse
     */
    'finalizerUserId'?: number;
}
/**
 * 
 * @export
 * @interface StockTakingReportItemResponse
 */
export interface StockTakingReportItemResponse {
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportItemResponse
     */
    'stockTakingReportId': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof StockTakingReportItemResponse
     */
    'itemName': string;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportItemResponse
     */
    'expectedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportItemResponse
     */
    'countedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportItemResponse
     */
    'confirmedQuantity': number;
}
/**
 * 
 * @export
 * @interface StockTakingReportResponse
 */
export interface StockTakingReportResponse {
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportResponse
     */
    'stockTakingId': number;
    /**
     * 
     * @type {string}
     * @memberof StockTakingReportResponse
     */
    'executionDate': string;
    /**
     * 
     * @type {string}
     * @memberof StockTakingReportResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportResponse
     */
    'userResponsibleId': number;
    /**
     * 
     * @type {string}
     * @memberof StockTakingReportResponse
     */
    'userResponsibleName': string;
    /**
     * 
     * @type {Array<StockTakingReportItemResponse>}
     * @memberof StockTakingReportResponse
     */
    'items'?: Array<StockTakingReportItemResponse>;
}
/**
 * 
 * @export
 * @interface StockTakingTaskItemResponse
 */
export interface StockTakingTaskItemResponse {
    /**
     * 
     * @type {number}
     * @memberof StockTakingTaskItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingTaskItemResponse
     */
    'stockTakingTaskId': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingTaskItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskItemResponse
     */
    'lastModifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskItemResponse
     */
    'artNo': string;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskItemResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskItemResponse
     */
    'unitName': string;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskItemResponse
     */
    'barcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskItemResponse
     */
    'additionalBarcodeValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof StockTakingTaskItemResponse
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface StockTakingTaskResponse
 */
export interface StockTakingTaskResponse {
    /**
     * 
     * @type {number}
     * @memberof StockTakingTaskResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskResponse
     */
    'status': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StockTakingTaskResponse
     */
    'allowedSubsequentStatuses': Array<string>;
    /**
     * 
     * @type {Array<StockTakingTaskItemResponse>}
     * @memberof StockTakingTaskResponse
     */
    'items': Array<StockTakingTaskItemResponse>;
    /**
     * 
     * @type {number}
     * @memberof StockTakingTaskResponse
     */
    'userResponsibleId': number;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskResponse
     */
    'userResponsibleName': string;
}
/**
 * 
 * @export
 * @interface StocktakingItemResponse
 */
export interface StocktakingItemResponse {
    /**
     * 
     * @type {number}
     * @memberof StocktakingItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StocktakingItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof StocktakingItemResponse
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof StocktakingItemResponse
     */
    'itemArtNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof StocktakingItemResponse
     */
    'itemUnitName'?: string;
}
/**
 * 
 * @export
 * @interface StocktakingRequest
 */
export interface StocktakingRequest {
    /**
     * 
     * @type {number}
     * @memberof StocktakingRequest
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof StocktakingRequest
     */
    'userId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof StocktakingRequest
     */
    'items': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof StocktakingRequest
     */
    'executionDate': string;
}
/**
 * 
 * @export
 * @interface StocktakingResponse
 */
export interface StocktakingResponse {
    /**
     * 
     * @type {number}
     * @memberof StocktakingResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StocktakingResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {string}
     * @memberof StocktakingResponse
     */
    'warehouseName': string;
    /**
     * 
     * @type {Array<StocktakingItemResponse>}
     * @memberof StocktakingResponse
     */
    'items': Array<StocktakingItemResponse>;
    /**
     * 
     * @type {string}
     * @memberof StocktakingResponse
     */
    'executionDate': string;
}
/**
 * 
 * @export
 * @interface SupplierContactResponse
 */
export interface SupplierContactResponse {
    /**
     * 
     * @type {string}
     * @memberof SupplierContactResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierContactResponse
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierContactResponse
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface SupplierListResponse
 */
export interface SupplierListResponse {
    /**
     * 
     * @type {number}
     * @memberof SupplierListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SupplierListResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierListResponse
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierListResponse
     */
    'paymentInfo': string;
    /**
     * 
     * @type {Array<SupplierContactResponse>}
     * @memberof SupplierListResponse
     */
    'contacts'?: Array<SupplierContactResponse>;
    /**
     * 
     * @type {string}
     * @memberof SupplierListResponse
     */
    'subscriptionEmail'?: string;
    /**
     * 
     * @type {number}
     * @memberof SupplierListResponse
     */
    'daysForDelivery': number;
    /**
     * 
     * @type {string}
     * @memberof SupplierListResponse
     */
    'deliveryRequestThreshold': string;
    /**
     * 
     * @type {number}
     * @memberof SupplierListResponse
     */
    'companyCategoryId': number;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierListResponse
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface SupplierPriceDifferenceResponse
 */
export interface SupplierPriceDifferenceResponse {
    /**
     * 
     * @type {number}
     * @memberof SupplierPriceDifferenceResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof SupplierPriceDifferenceResponse
     */
    'deliveryRequestId': number;
    /**
     * 
     * @type {number}
     * @memberof SupplierPriceDifferenceResponse
     */
    'deliveryId': number;
    /**
     * 
     * @type {number}
     * @memberof SupplierPriceDifferenceResponse
     */
    'supplierId': number;
    /**
     * 
     * @type {number}
     * @memberof SupplierPriceDifferenceResponse
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof SupplierPriceDifferenceResponse
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof SupplierPriceDifferenceResponse
     */
    'requestedPrice': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierPriceDifferenceResponse
     */
    'deliveredPrice': string;
}
/**
 * 
 * @export
 * @interface SupplierQuantityDifferenceResponse
 */
export interface SupplierQuantityDifferenceResponse {
    /**
     * 
     * @type {number}
     * @memberof SupplierQuantityDifferenceResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof SupplierQuantityDifferenceResponse
     */
    'deliveryRequestId': number;
    /**
     * 
     * @type {number}
     * @memberof SupplierQuantityDifferenceResponse
     */
    'deliveryId': number;
    /**
     * 
     * @type {number}
     * @memberof SupplierQuantityDifferenceResponse
     */
    'supplierId': number;
    /**
     * 
     * @type {number}
     * @memberof SupplierQuantityDifferenceResponse
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof SupplierQuantityDifferenceResponse
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof SupplierQuantityDifferenceResponse
     */
    'requestedQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierQuantityDifferenceResponse
     */
    'deliveredQuantity': string;
}
/**
 * 
 * @export
 * @interface SupplierReserveSubscriptionResponse
 */
export interface SupplierReserveSubscriptionResponse {
    /**
     * 
     * @type {number}
     * @memberof SupplierReserveSubscriptionResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof SupplierReserveSubscriptionResponse
     */
    'supplierId': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierReserveSubscriptionResponse
     */
    'weekdays': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SupplierReserveSubscriptionResponse
     */
    'baseDays': number;
    /**
     * 
     * @type {number}
     * @memberof SupplierReserveSubscriptionResponse
     */
    'reserveDays': number;
    /**
     * 
     * @type {string}
     * @memberof SupplierReserveSubscriptionResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof SupplierReserveSubscriptionResponse
     */
    'createdById': number;
    /**
     * 
     * @type {string}
     * @memberof SupplierReserveSubscriptionResponse
     */
    'lastUpdatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof SupplierReserveSubscriptionResponse
     */
    'lastUpdatedById': number;
    /**
     * 
     * @type {string}
     * @memberof SupplierReserveSubscriptionResponse
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface SupplierResponse
 */
export interface SupplierResponse {
    /**
     * 
     * @type {number}
     * @memberof SupplierResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'paymentInfo': string;
    /**
     * 
     * @type {Array<SupplierContactResponse>}
     * @memberof SupplierResponse
     */
    'contacts'?: Array<SupplierContactResponse>;
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'subscriptionEmail'?: string;
    /**
     * 
     * @type {number}
     * @memberof SupplierResponse
     */
    'daysForDelivery': number;
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'deliveryRequestThreshold': string;
    /**
     * 
     * @type {number}
     * @memberof SupplierResponse
     */
    'companyCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'addressLine': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierResponse
     */
    'country': string;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierResponse
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface TestConnectionDetailsResponse
 */
export interface TestConnectionDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof TestConnectionDetailsResponse
     */
    'result': string;
    /**
     * 
     * @type {string}
     * @memberof TestConnectionDetailsResponse
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface TransactionListResponse
 */
export interface TransactionListResponse {
    /**
     * 
     * @type {number}
     * @memberof TransactionListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TransactionListResponse
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionListResponse
     */
    'amount': string;
    /**
     * 
     * @type {number}
     * @memberof TransactionListResponse
     */
    'transactionType': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionListResponse
     */
    'treasuryId': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionListResponse
     */
    'orderInvoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionListResponse
     */
    'deliveryInvoiceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionListResponse
     */
    'info'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionListResponse
     */
    'displayInfo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionListResponse
     */
    'invoiceNumber'?: string;
}
/**
 * 
 * @export
 * @interface TransactionResponse
 */
export interface TransactionResponse {
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'amount': string;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    'transactionType': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    'treasuryId': number;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    'orderInvoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    'deliveryInvoiceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'info'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'displayInfo'?: string;
}
/**
 * 
 * @export
 * @interface TreasuryListResponse
 */
export interface TreasuryListResponse {
    /**
     * 
     * @type {number}
     * @memberof TreasuryListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TreasuryListResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof TreasuryListResponse
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof TreasuryListResponse
     */
    'color': string;
}
/**
 * 
 * @export
 * @interface TreasuryResponse
 */
export interface TreasuryResponse {
    /**
     * 
     * @type {number}
     * @memberof TreasuryResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TreasuryResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof TreasuryResponse
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof TreasuryResponse
     */
    'initialBankAccountAmount': string;
    /**
     * 
     * @type {string}
     * @memberof TreasuryResponse
     */
    'initialCashAmount': string;
    /**
     * 
     * @type {string}
     * @memberof TreasuryResponse
     */
    'color': string;
}
/**
 * 
 * @export
 * @interface UpdateBatchesItemRequest
 */
export interface UpdateBatchesItemRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBatchesItemRequest
     */
    'quantity': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBatchesItemRequest
     */
    'batchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBatchesItemRequest
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBatchesItemRequest
     */
    'countryOfOrigin': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBatchesItemRequest
     */
    'itemId': number;
}
/**
 * 
 * @export
 * @interface UpdateBatchesRequest
 */
export interface UpdateBatchesRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBatchesRequest
     */
    'requestType'?: UpdateBatchesRequestRequestTypeEnum;
    /**
     * 
     * @type {Array<UpdateBatchesItemRequest>}
     * @memberof UpdateBatchesRequest
     */
    'items': Array<UpdateBatchesItemRequest>;
}

export const UpdateBatchesRequestRequestTypeEnum = {
    UpdateBatches: 'update-batches'
} as const;

export type UpdateBatchesRequestRequestTypeEnum = typeof UpdateBatchesRequestRequestTypeEnum[keyof typeof UpdateBatchesRequestRequestTypeEnum];

/**
 * 
 * @export
 * @interface UpdateClientRequest
 */
export interface UpdateClientRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientRequest
     */
    'phone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateClientRequest
     */
    'isMain'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateClientRequest
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateCompanyRequest
 */
export interface UpdateCompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'idnumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'vat': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'accountable': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'addressLine': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequest
     */
    'plusCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyRequest
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface UpdateDeliveryDocumentRequest
 */
export interface UpdateDeliveryDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryDocumentRequest
     */
    'invoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryDocumentRequest
     */
    'invoiceDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryDocumentRequest
     */
    'invoiceTotal'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryDocumentRequest
     */
    'paymentDue'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryDocumentRequest
     */
    'paymentInfo'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryDocumentRequest
     */
    'tradeDocumentNumber'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDeliveryRequestRequest
 */
export interface UpdateDeliveryRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryRequestRequest
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface UpdateGroupRequest
 */
export interface UpdateGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateGroupRequest
     */
    'name'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateGroupRequest
     */
    'users'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateGroupRequest
     */
    'suppliers'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateGroupRequest
     */
    'warehouses'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateGroupRequest
     */
    'partners'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UpdatePrintDocumentQueueRequest
 */
export interface UpdatePrintDocumentQueueRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePrintDocumentQueueRequest
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePrintDocumentQueueRequest
     */
    'printer'?: string;
}
/**
 * 
 * @export
 * @interface UpdateRoleRequest
 */
export interface UpdateRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleRequest
     */
    'name'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateRoleRequest
     */
    'users'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateRoleRequest
     */
    'permissions'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserRequest
     */
    'companyId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequest
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequest
     */
    'isSystem'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateUserRequest
     */
    'groups'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateUserRequest
     */
    'roles'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UserAccessResponse
 */
export interface UserAccessResponse {
    /**
     * 
     * @type {number}
     * @memberof UserAccessResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    'lastName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAccessResponse
     */
    'permissions'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserAccessResponse
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    'companyNote': string;
}
/**
 * 
 * @export
 * @interface UserListResponse
 */
export interface UserListResponse {
    /**
     * 
     * @type {number}
     * @memberof UserListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserListResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserListResponse
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserListResponse
     */
    'companyId': number;
    /**
     * 
     * @type {boolean}
     * @memberof UserListResponse
     */
    'isActive': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserListResponse
     */
    'groups'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserListResponse
     */
    'roles'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    'companyId': number;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'isActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'isSystem': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserResponse
     */
    'groups'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserResponse
     */
    'roles'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationError
     */
    'loc': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface VerifyQuantitiesItemRequest
 */
export interface VerifyQuantitiesItemRequest {
    /**
     * 
     * @type {number}
     * @memberof VerifyQuantitiesItemRequest
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof VerifyQuantitiesItemRequest
     */
    'verifiedQuantity': string;
}
/**
 * 
 * @export
 * @interface VerifyQuantitiesRequest
 */
export interface VerifyQuantitiesRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyQuantitiesRequest
     */
    'requestType'?: VerifyQuantitiesRequestRequestTypeEnum;
    /**
     * 
     * @type {Array<VerifyQuantitiesItemRequest>}
     * @memberof VerifyQuantitiesRequest
     */
    'items': Array<VerifyQuantitiesItemRequest>;
}

export const VerifyQuantitiesRequestRequestTypeEnum = {
    VerifyQuantities: 'verify-quantities'
} as const;

export type VerifyQuantitiesRequestRequestTypeEnum = typeof VerifyQuantitiesRequestRequestTypeEnum[keyof typeof VerifyQuantitiesRequestRequestTypeEnum];

/**
 * 
 * @export
 * @interface WarehouseListResponse
 */
export interface WarehouseListResponse {
    /**
     * 
     * @type {number}
     * @memberof WarehouseListResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseListResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseListResponse
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof WarehouseListResponse
     */
    'companyId': number;
    /**
     * 
     * @type {boolean}
     * @memberof WarehouseListResponse
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface WarehouseMovementResponse
 */
export interface WarehouseMovementResponse {
    /**
     * 
     * @type {number}
     * @memberof WarehouseMovementResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof WarehouseMovementResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof WarehouseMovementResponse
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof WarehouseMovementResponse
     */
    'batchInfoId': number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseMovementResponse
     */
    'quantity': string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseMovementResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseMovementResponse
     */
    'payload'?: string;
}
/**
 * 
 * @export
 * @interface WarehouseResponse
 */
export interface WarehouseResponse {
    /**
     * 
     * @type {number}
     * @memberof WarehouseResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseResponse
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof WarehouseResponse
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseResponse
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseResponse
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseResponse
     */
    'addressLine': string;
    /**
     * 
     * @type {boolean}
     * @memberof WarehouseResponse
     */
    'isActive': boolean;
}

/**
 * AMSApi - axios parameter creator
 * @export
 */
export const AMSApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Bulk Create Partner Bonus Quantity Promotions
         * @param {BulkCreatePartnerBonusQuantityRequest} bulkCreatePartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreatePartnerBonusQuantityPromotionsPromotionsBonusQuantityPost: async (bulkCreatePartnerBonusQuantityRequest: BulkCreatePartnerBonusQuantityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkCreatePartnerBonusQuantityRequest' is not null or undefined
            assertParamExists('bulkCreatePartnerBonusQuantityPromotionsPromotionsBonusQuantityPost', 'bulkCreatePartnerBonusQuantityRequest', bulkCreatePartnerBonusQuantityRequest)
            const localVarPath = `/promotions/bonus-quantity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkCreatePartnerBonusQuantityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel Invoice By Invoice Id
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInvoiceByInvoiceIdInvoicesInvoiceIdPatch: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('cancelInvoiceByInvoiceIdInvoicesInvoiceIdPatch', 'invoiceId', invoiceId)
            const localVarPath = `/invoices/{invoice_id}`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Copy Partner Item Configurations
         * @param {number} partnerId 
         * @param {number} sourcePartnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost: async (partnerId: number, sourcePartnerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost', 'partnerId', partnerId)
            // verify required parameter 'sourcePartnerId' is not null or undefined
            assertParamExists('copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost', 'sourcePartnerId', sourcePartnerId)
            const localVarPath = `/partners/{partner_id}/item-configuration/copy/{source_partner_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"source_partner_id"}}`, encodeURIComponent(String(sourcePartnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {CreateClientRequest} createClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientsPost: async (createClientRequest: CreateClientRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createClientRequest' is not null or undefined
            assertParamExists('createClientsPost', 'createClientRequest', createClientRequest)
            const localVarPath = `/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompaniesPost: async (createCompanyRequest: CreateCompanyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyRequest' is not null or undefined
            assertParamExists('createCompaniesPost', 'createCompanyRequest', createCompanyRequest)
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {SaveCompanyCategoryRequest} saveCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyCategoriesPost: async (saveCompanyCategoryRequest: SaveCompanyCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveCompanyCategoryRequest' is not null or undefined
            assertParamExists('createCompanyCategoriesPost', 'saveCompanyCategoryRequest', saveCompanyCategoryRequest)
            const localVarPath = `/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveCompanyCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Delivery
         * @param {CreateDeliveryRequest} createDeliveryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryDeliveriesPost: async (createDeliveryRequest: CreateDeliveryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeliveryRequest' is not null or undefined
            assertParamExists('createDeliveryDeliveriesPost', 'createDeliveryRequest', createDeliveryRequest)
            const localVarPath = `/deliveries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeliveryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Delivery Document
         * @param {CreateDeliveryDocumentRequest} createDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryDocumentDeliveryDocumentsPost: async (createDeliveryDocumentRequest: CreateDeliveryDocumentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeliveryDocumentRequest' is not null or undefined
            assertParamExists('createDeliveryDocumentDeliveryDocumentsPost', 'createDeliveryDocumentRequest', createDeliveryDocumentRequest)
            const localVarPath = `/delivery-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeliveryDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Delivery Request
         * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
         * @param {boolean} [shouldSendEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryRequestDeliveryRequestsPost: async (createDeliveryRequestRequest: CreateDeliveryRequestRequest, shouldSendEmail?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeliveryRequestRequest' is not null or undefined
            assertParamExists('createDeliveryRequestDeliveryRequestsPost', 'createDeliveryRequestRequest', createDeliveryRequestRequest)
            const localVarPath = `/delivery-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (shouldSendEmail !== undefined) {
                localVarQueryParameter['should_send_email'] = shouldSendEmail;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeliveryRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Group
         * @param {CreateGroupRequest} createGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroupGroupsPost: async (createGroupRequest: CreateGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createGroupRequest' is not null or undefined
            assertParamExists('createGroupGroupsPost', 'createGroupRequest', createGroupRequest)
            const localVarPath = `/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {CreateInvoiceRequest} createInvoiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoicesPost: async (createInvoiceRequest: CreateInvoiceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInvoiceRequest' is not null or undefined
            assertParamExists('createInvoicesPost', 'createInvoiceRequest', createInvoiceRequest)
            const localVarPath = `/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInvoiceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {SaveItemCategoryRequest} saveItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemCategoriesPost: async (saveItemCategoryRequest: SaveItemCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveItemCategoryRequest' is not null or undefined
            assertParamExists('createItemCategoriesPost', 'saveItemCategoryRequest', saveItemCategoryRequest)
            const localVarPath = `/item-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveItemCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Item Delivery Subscription
         * @param {CreateItemDeliverySubscriptionRequest} createItemDeliverySubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemDeliverySubscriptionItemDeliverySubscriptionsPost: async (createItemDeliverySubscriptionRequest: CreateItemDeliverySubscriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createItemDeliverySubscriptionRequest' is not null or undefined
            assertParamExists('createItemDeliverySubscriptionItemDeliverySubscriptionsPost', 'createItemDeliverySubscriptionRequest', createItemDeliverySubscriptionRequest)
            const localVarPath = `/item-delivery-subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createItemDeliverySubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {SaveItemRequest} saveItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsPost: async (saveItemRequest: SaveItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveItemRequest' is not null or undefined
            assertParamExists('createItemsPost', 'saveItemRequest', saveItemRequest)
            const localVarPath = `/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {SaveLegalEntityRequest} saveLegalEntityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalEntitiesPost: async (saveLegalEntityRequest: SaveLegalEntityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveLegalEntityRequest' is not null or undefined
            assertParamExists('createLegalEntitiesPost', 'saveLegalEntityRequest', saveLegalEntityRequest)
            const localVarPath = `/legal-entities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveLegalEntityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Linked Item Association
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost: async (itemId: number, linkedItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost', 'itemId', itemId)
            // verify required parameter 'linkedItemId' is not null or undefined
            assertParamExists('createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost', 'linkedItemId', linkedItemId)
            const localVarPath = `/items/{item_id}/linked-item-association/{linked_item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"linked_item_id"}}`, encodeURIComponent(String(linkedItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Manufacturer
         * @param {SaveManufacturerRequest} saveManufacturerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManufacturerManufacturersPost: async (saveManufacturerRequest: SaveManufacturerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveManufacturerRequest' is not null or undefined
            assertParamExists('createManufacturerManufacturersPost', 'saveManufacturerRequest', saveManufacturerRequest)
            const localVarPath = `/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveManufacturerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Order Item
         * @param {number} orderId 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderItemOrdersOrderIdItemsPost: async (orderId: number, saveOrderItemRequest: SaveOrderItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('createOrderItemOrdersOrderIdItemsPost', 'orderId', orderId)
            // verify required parameter 'saveOrderItemRequest' is not null or undefined
            assertParamExists('createOrderItemOrdersOrderIdItemsPost', 'saveOrderItemRequest', saveOrderItemRequest)
            const localVarPath = `/orders/{order_id}/items`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveOrderItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Order Item
         * @param {number} orderId 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderItemOrdersOrderIdItemsPost_1: async (orderId: number, saveOrderItemRequest: SaveOrderItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('createOrderItemOrdersOrderIdItemsPost_1', 'orderId', orderId)
            // verify required parameter 'saveOrderItemRequest' is not null or undefined
            assertParamExists('createOrderItemOrdersOrderIdItemsPost_1', 'saveOrderItemRequest', saveOrderItemRequest)
            const localVarPath = `/orders/{order_id}/items`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveOrderItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderRequest} createOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderOrdersPost: async (createOrderRequest: CreateOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderRequest' is not null or undefined
            assertParamExists('createOrderOrdersPost', 'createOrderRequest', createOrderRequest)
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Partner Item Configuration
         * @param {number} partnerId 
         * @param {SavePartnerItemConfigurationRequest} savePartnerItemConfigurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost: async (partnerId: number, savePartnerItemConfigurationRequest: SavePartnerItemConfigurationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost', 'partnerId', partnerId)
            // verify required parameter 'savePartnerItemConfigurationRequest' is not null or undefined
            assertParamExists('createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost', 'savePartnerItemConfigurationRequest', savePartnerItemConfigurationRequest)
            const localVarPath = `/partners/{partner_id}/item-configuration`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savePartnerItemConfigurationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Partner
         * @param {SavePartnerRequest} savePartnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartnerPartnersPost: async (savePartnerRequest: SavePartnerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'savePartnerRequest' is not null or undefined
            assertParamExists('createPartnerPartnersPost', 'savePartnerRequest', savePartnerRequest)
            const localVarPath = `/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savePartnerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Payment Option
         * @param {SavePaymentOptionRequest} savePaymentOptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentOptionPaymentOptionsPost: async (savePaymentOptionRequest: SavePaymentOptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'savePaymentOptionRequest' is not null or undefined
            assertParamExists('createPaymentOptionPaymentOptionsPost', 'savePaymentOptionRequest', savePaymentOptionRequest)
            const localVarPath = `/payment-options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savePaymentOptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Portal User
         * @param {CreatePortalUserRequest} createPortalUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPortalUserPortalUsersPost: async (createPortalUserRequest: CreatePortalUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPortalUserRequest' is not null or undefined
            assertParamExists('createPortalUserPortalUsersPost', 'createPortalUserRequest', createPortalUserRequest)
            const localVarPath = `/portal-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPortalUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Pricing Policy
         * @param {SavePricingPolicyRequest} savePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPricingPolicyPricingPoliciesPost: async (savePricingPolicyRequest: SavePricingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'savePricingPolicyRequest' is not null or undefined
            assertParamExists('createPricingPolicyPricingPoliciesPost', 'savePricingPolicyRequest', savePricingPolicyRequest)
            const localVarPath = `/pricing-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savePricingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Role
         * @param {CreateRoleRequest} createRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleRolesPost: async (createRoleRequest: CreateRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRoleRequest' is not null or undefined
            assertParamExists('createRoleRolesPost', 'createRoleRequest', createRoleRequest)
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Similar Item Association
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost: async (itemId: number, similarItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost', 'itemId', itemId)
            // verify required parameter 'similarItemId' is not null or undefined
            assertParamExists('createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost', 'similarItemId', similarItemId)
            const localVarPath = `/items/{item_id}/similar-item-association/{similar_item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"similar_item_id"}}`, encodeURIComponent(String(similarItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Stocktaking
         * @param {StocktakingRequest} stocktakingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStocktakingStocktakingsPost: async (stocktakingRequest: StocktakingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stocktakingRequest' is not null or undefined
            assertParamExists('createStocktakingStocktakingsPost', 'stocktakingRequest', stocktakingRequest)
            const localVarPath = `/stocktakings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stocktakingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Supplier Reserve Subscription
         * @param {CreateSupplierReserveSubscriptionRequest} createSupplierReserveSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupplierReserveSubscriptionSupplierReserveSubscriptionsPost: async (createSupplierReserveSubscriptionRequest: CreateSupplierReserveSubscriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSupplierReserveSubscriptionRequest' is not null or undefined
            assertParamExists('createSupplierReserveSubscriptionSupplierReserveSubscriptionsPost', 'createSupplierReserveSubscriptionRequest', createSupplierReserveSubscriptionRequest)
            const localVarPath = `/supplier-reserve-subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSupplierReserveSubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Supplier
         * @param {SaveSupplierRequest} saveSupplierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupplierSuppliersPost: async (saveSupplierRequest: SaveSupplierRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveSupplierRequest' is not null or undefined
            assertParamExists('createSupplierSuppliersPost', 'saveSupplierRequest', saveSupplierRequest)
            const localVarPath = `/suppliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveSupplierRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Transaction
         * @param {CreateTransactionRequest} createTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransactionTransactionsPost: async (createTransactionRequest: CreateTransactionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTransactionRequest' is not null or undefined
            assertParamExists('createTransactionTransactionsPost', 'createTransactionRequest', createTransactionRequest)
            const localVarPath = `/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Treasury
         * @param {CreateTreasuryRequest} createTreasuryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTreasuryTreasuriesPost: async (createTreasuryRequest: CreateTreasuryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTreasuryRequest' is not null or undefined
            assertParamExists('createTreasuryTreasuriesPost', 'createTreasuryRequest', createTreasuryRequest)
            const localVarPath = `/treasuries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTreasuryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create User
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserUsersPost: async (createUserRequest: CreateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('createUserUsersPost', 'createUserRequest', createUserRequest)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {SaveWarehouseRequest} saveWarehouseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWarehousesPost: async (saveWarehouseRequest: SaveWarehouseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveWarehouseRequest' is not null or undefined
            assertParamExists('createWarehousesPost', 'saveWarehouseRequest', saveWarehouseRequest)
            const localVarPath = `/warehouses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveWarehouseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Client
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClientClientsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteClientClientsIdDelete', 'id', id)
            const localVarPath = `/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Delivery
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeliveryDeliveriesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDeliveryDeliveriesIdDelete', 'id', id)
            const localVarPath = `/deliveries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} deliveryDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeliveryDocumentsDeliveryDocumentIdDelete: async (deliveryDocumentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryDocumentId' is not null or undefined
            assertParamExists('deleteDeliveryDocumentsDeliveryDocumentIdDelete', 'deliveryDocumentId', deliveryDocumentId)
            const localVarPath = `/delivery-documents/{delivery_document_id}`
                .replace(`{${"delivery_document_id"}}`, encodeURIComponent(String(deliveryDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Item Delivery Subscription
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItemDeliverySubscriptionItemDeliverySubscriptionsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteItemDeliverySubscriptionItemDeliverySubscriptionsIdDelete', 'id', id)
            const localVarPath = `/item-delivery-subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Linked Item Association
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete: async (itemId: number, linkedItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete', 'itemId', itemId)
            // verify required parameter 'linkedItemId' is not null or undefined
            assertParamExists('deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete', 'linkedItemId', linkedItemId)
            const localVarPath = `/items/{item_id}/linked-item-association/{linked_item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"linked_item_id"}}`, encodeURIComponent(String(linkedItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderItemOrdersOrderIdItemsIdDelete: async (orderId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('deleteOrderItemOrdersOrderIdItemsIdDelete', 'orderId', orderId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOrderItemOrdersOrderIdItemsIdDelete', 'id', id)
            const localVarPath = `/orders/{order_id}/items/{id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderItemOrdersOrderIdItemsIdDelete_2: async (orderId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('deleteOrderItemOrdersOrderIdItemsIdDelete_2', 'orderId', orderId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOrderItemOrdersOrderIdItemsIdDelete_2', 'id', id)
            const localVarPath = `/orders/{order_id}/items/{id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Partner Bonus Quantity
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerBonusQuantityPromotionsBonusQuantityIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePartnerBonusQuantityPromotionsBonusQuantityIdDelete', 'id', id)
            const localVarPath = `/promotions/bonus-quantity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Partner Item Configuration
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerItemConfigurationPartnersItemConfigurationIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePartnerItemConfigurationPartnersItemConfigurationIdDelete', 'id', id)
            const localVarPath = `/partners/item-configuration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Similar Item Association
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete: async (itemId: number, similarItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete', 'itemId', itemId)
            // verify required parameter 'similarItemId' is not null or undefined
            assertParamExists('deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete', 'similarItemId', similarItemId)
            const localVarPath = `/items/{item_id}/similar-item-association/{similar_item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"similar_item_id"}}`, encodeURIComponent(String(similarItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Supplier Reserve Subscription
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupplierReserveSubscriptionSupplierReserveSubscriptionsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSupplierReserveSubscriptionSupplierReserveSubscriptionsIdDelete', 'id', id)
            const localVarPath = `/supplier-reserve-subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Transaction
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransactionTransactionsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTransactionTransactionsIdDelete', 'id', id)
            const localVarPath = `/transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get B2B Orders
         * @param {Array<number>} [id] 
         * @param {Array<string>} [status] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getB2bOrdersOrdersB2bOrdersGet: async (id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, legalEntities?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/b2b-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (receivers) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (legalEntities) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (fromDeliveryDate !== undefined) {
                localVarQueryParameter['from_delivery_date'] = (fromDeliveryDate as any instanceof Date) ?
                    (fromDeliveryDate as any).toISOString() :
                    fromDeliveryDate;
            }

            if (toDeliveryDate !== undefined) {
                localVarQueryParameter['to_delivery_date'] = (toDeliveryDate as any instanceof Date) ?
                    (toDeliveryDate as any).toISOString() :
                    toDeliveryDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get B2C Orders
         * @param {Array<number>} [id] 
         * @param {Array<string>} [status] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getB2cOrdersOrdersB2cOrdersGet: async (id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, legalEntities?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/b2c-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (receivers) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (legalEntities) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (fromDeliveryDate !== undefined) {
                localVarQueryParameter['from_delivery_date'] = (fromDeliveryDate as any instanceof Date) ?
                    (fromDeliveryDate as any).toISOString() :
                    fromDeliveryDate;
            }

            if (toDeliveryDate !== undefined) {
                localVarQueryParameter['to_delivery_date'] = (toDeliveryDate as any instanceof Date) ?
                    (toDeliveryDate as any).toISOString() :
                    toDeliveryDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Deliveries
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [warehouses] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveriesDeliveriesGet: async (suppliers?: Array<number>, warehouses?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deliveries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (suppliers) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (fromDeliveryDate !== undefined) {
                localVarQueryParameter['from_delivery_date'] = (fromDeliveryDate as any instanceof Date) ?
                    (fromDeliveryDate as any).toISOString() :
                    fromDeliveryDate;
            }

            if (toDeliveryDate !== undefined) {
                localVarQueryParameter['to_delivery_date'] = (toDeliveryDate as any instanceof Date) ?
                    (toDeliveryDate as any).toISOString() :
                    toDeliveryDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Delivery Request Expected Total
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryRequestExpectedTotalDeliveryRequestsIdExpectedTotalGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeliveryRequestExpectedTotalDeliveryRequestsIdExpectedTotalGet', 'id', id)
            const localVarPath = `/delivery-requests/{id}/expected-total`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Internal Transfers
         * @param {Array<number>} [id] 
         * @param {Array<string>} [status] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [receivers] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalTransfersOrdersInternalTransfersGet: async (id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/internal-transfers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (receivers) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (fromDeliveryDate !== undefined) {
                localVarQueryParameter['from_delivery_date'] = (fromDeliveryDate as any instanceof Date) ?
                    (fromDeliveryDate as any).toISOString() :
                    fromDeliveryDate;
            }

            if (toDeliveryDate !== undefined) {
                localVarQueryParameter['to_delivery_date'] = (toDeliveryDate as any instanceof Date) ?
                    (toDeliveryDate as any).toISOString() :
                    toDeliveryDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Item Delivery Subscriptions
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [items] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemDeliverySubscriptionsItemDeliverySubscriptionsGet: async (warehouses?: Array<number>, items?: Array<number>, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/item-delivery-subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (items) {
                localVarQueryParameter['items'] = items;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Order Execution Percentage
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderExecutionPercentageOrdersOrderIdExecutionPercentageGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getOrderExecutionPercentageOrdersOrderIdExecutionPercentageGet', 'orderId', orderId)
            const localVarPath = `/orders/{order_id}/execution-percentage`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Orders For Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForCollectionLegalEntitiesIdOrdersForCollectionAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrdersForCollectionLegalEntitiesIdOrdersForCollectionAmountGet', 'id', id)
            const localVarPath = `/legal-entities/{id}/orders-for-collection/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Orders For Distribution Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForDistributionAmountLegalEntitiesIdOrdersForDistributionAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrdersForDistributionAmountLegalEntitiesIdOrdersForDistributionAmountGet', 'id', id)
            const localVarPath = `/legal-entities/{id}/orders-for-distribution/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Overdue Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverduePaymentsAmountLegalEntitiesIdOverduePaymentsAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOverduePaymentsAmountLegalEntitiesIdOverduePaymentsAmountGet', 'id', id)
            const localVarPath = `/legal-entities/{id}/overdue-payments/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Pending Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingPaymentsAmountLegalEntitiesIdPendingPaymentsAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPendingPaymentsAmountLegalEntitiesIdPendingPaymentsAmountGet', 'id', id)
            const localVarPath = `/legal-entities/{id}/pending-payments/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckHealthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Load Info
         * @param {string} vatNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadInfoLegalEntitiesLoadInfoPost: async (vatNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vatNumber' is not null or undefined
            assertParamExists('loadInfoLegalEntitiesLoadInfoPost', 'vatNumber', vatNumber)
            const localVarPath = `/legal-entities/load-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (vatNumber !== undefined) {
                localVarQueryParameter['vat_number'] = vatNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Order
         * @param {number} orderId 
         * @param {PatchOrderRequest} patchOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrderOrdersOrderIdPatch: async (orderId: number, patchOrderRequest: PatchOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('patchOrderOrdersOrderIdPatch', 'orderId', orderId)
            // verify required parameter 'patchOrderRequest' is not null or undefined
            assertParamExists('patchOrderOrdersOrderIdPatch', 'patchOrderRequest', patchOrderRequest)
            const localVarPath = `/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActOfAcceptanceActsOfAcceptanceActOfAcceptanceIdGet: async (actOfAcceptanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actOfAcceptanceId' is not null or undefined
            assertParamExists('readActOfAcceptanceActsOfAcceptanceActOfAcceptanceIdGet', 'actOfAcceptanceId', actOfAcceptanceId)
            const localVarPath = `/acts-of-acceptance/{act_of_acceptance_id}`
                .replace(`{${"act_of_acceptance_id"}}`, encodeURIComponent(String(actOfAcceptanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActOfAcceptanceByIdActsOfAcceptanceActOfAcceptanceIdDocumentGet: async (actOfAcceptanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actOfAcceptanceId' is not null or undefined
            assertParamExists('readActOfAcceptanceByIdActsOfAcceptanceActOfAcceptanceIdDocumentGet', 'actOfAcceptanceId', actOfAcceptanceId)
            const localVarPath = `/acts-of-acceptance/{act_of_acceptance_id}/document`
                .replace(`{${"act_of_acceptance_id"}}`, encodeURIComponent(String(actOfAcceptanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActOfAcceptanceItemsActsOfAcceptanceActOfAcceptanceIdItemsGet: async (actOfAcceptanceId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actOfAcceptanceId' is not null or undefined
            assertParamExists('readActOfAcceptanceItemsActsOfAcceptanceActOfAcceptanceIdItemsGet', 'actOfAcceptanceId', actOfAcceptanceId)
            const localVarPath = `/acts-of-acceptance/{act_of_acceptance_id}/items`
                .replace(`{${"act_of_acceptance_id"}}`, encodeURIComponent(String(actOfAcceptanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Active Order Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActiveOrderItemsItemsIdActiveOrderItemsGet: async (id: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readActiveOrderItemsItemsIdActiveOrderItemsGet', 'id', id)
            const localVarPath = `/items/{id}/active-order-items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {Array<number>} [id] 
         * @param {string} [fromCreatedAt] 
         * @param {string} [toCreatedAt] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [users] 
         * @param {Array<string>} [orderType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActsOfAcceptanceActsOfAcceptanceGet: async (id?: Array<number>, fromCreatedAt?: string, toCreatedAt?: string, receivers?: Array<number>, legalEntities?: Array<number>, companies?: Array<number>, users?: Array<number>, orderType?: Array<string>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acts-of-acceptance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (fromCreatedAt !== undefined) {
                localVarQueryParameter['from_created_at'] = (fromCreatedAt as any instanceof Date) ?
                    (fromCreatedAt as any).toISOString() :
                    fromCreatedAt;
            }

            if (toCreatedAt !== undefined) {
                localVarQueryParameter['to_created_at'] = (toCreatedAt as any instanceof Date) ?
                    (toCreatedAt as any).toISOString() :
                    toCreatedAt;
            }

            if (receivers) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (legalEntities) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (orderType) {
                localVarQueryParameter['order_type'] = orderType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Acts Of Acceptance By Manufacturer Export Id
         * @param {number} manufacturerExportId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActsOfAcceptanceByManufacturerExportIdManufacturerExportsManufacturerExportIdActsOfAcceptanceGet: async (manufacturerExportId: number, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerExportId' is not null or undefined
            assertParamExists('readActsOfAcceptanceByManufacturerExportIdManufacturerExportsManufacturerExportIdActsOfAcceptanceGet', 'manufacturerExportId', manufacturerExportId)
            const localVarPath = `/manufacturer-exports/{manufacturer_export_id}/acts-of-acceptance`
                .replace(`{${"manufacturer_export_id"}}`, encodeURIComponent(String(manufacturerExportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Aggregated Item Movements
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [partners] 
         * @param {Array<number>} [warehouses] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAggregatedItemMovementsReportsItemMovementsBalanceGet: async (fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/item-movements-balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (partners) {
                localVarQueryParameter['partners'] = partners;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All Item Batches
         * @param {number} itemId 
         * @param {Array<number>} [warehouses] 
         * @param {boolean} [skipDepleted] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllItemBatchesItemsItemIdBatchesGet: async (itemId: number, warehouses?: Array<number>, skipDepleted?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('readAllItemBatchesItemsItemIdBatchesGet', 'itemId', itemId)
            const localVarPath = `/items/{item_id}/batches`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (skipDepleted !== undefined) {
                localVarQueryParameter['skip_depleted'] = skipDepleted;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Availabilities
         * @param {Array<number>} [items] 
         * @param {Array<number>} [warehouses] 
         * @param {boolean} [realTime] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAvailabilitiesAvailabilitiesGet: async (items?: Array<number>, warehouses?: Array<number>, realTime?: boolean, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/availabilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (items) {
                localVarQueryParameter['items'] = items;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (realTime !== undefined) {
                localVarQueryParameter['real_time'] = realTime;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Batch Infos
         * @param {Array<number>} [id] 
         * @param {Array<number>} [items] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBatchInfosBatchInfosGet: async (id?: Array<number>, items?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/batch-infos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (items) {
                localVarQueryParameter['items'] = items;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Client By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientByIdClientsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readClientByIdClientsIdGet', 'id', id)
            const localVarPath = `/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Client Devices
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientDevicesClientsIdDevicesGet: async (id: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readClientDevicesClientsIdDevicesGet', 'id', id)
            const localVarPath = `/clients/{id}/devices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Client Favorites
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientFavoritesClientsIdFavoritesGet: async (id: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readClientFavoritesClientsIdFavoritesGet', 'id', id)
            const localVarPath = `/clients/{id}/favorites`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Client Shopping Carts
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientShoppingCartsClientsIdShoppingCartsGet: async (id: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readClientShoppingCartsClientsIdShoppingCartsGet', 'id', id)
            const localVarPath = `/clients/{id}/shopping-carts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Clients
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {Array<number>} [partners] 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientsClientsGet: async (id?: Array<number>, showInactive?: boolean, partners?: Array<number>, name?: string, email?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (partners) {
                localVarQueryParameter['partners'] = partners;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Collected Batch Items
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCollectedBatchItemsOrdersOrderIdCollectedBatchItemsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readCollectedBatchItemsOrdersOrderIdCollectedBatchItemsGet', 'orderId', orderId)
            const localVarPath = `/orders/{order_id}/collected-batch-items`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Companies
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [showInactive] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompaniesCompaniesGet: async (id?: Array<number>, query?: string, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Company By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanyByIdCompaniesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readCompanyByIdCompaniesIdGet', 'id', id)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Company Categories Paginated
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [leafOnly] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanyCategoriesPaginatedCompanyCategoriesGet: async (id?: Array<number>, query?: string, leafOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (leafOnly !== undefined) {
                localVarQueryParameter['leaf_only'] = leafOnly;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Company Category By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanyCategoryByIdCompanyCategoriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readCompanyCategoryByIdCompanyCategoriesIdGet', 'id', id)
            const localVarPath = `/company-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Credit Notes
         * @param {number} invoiceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCreditNotesInvoicesInvoiceIdCreditNotesGet: async (invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('readCreditNotesInvoicesInvoiceIdCreditNotesGet', 'invoiceId', invoiceId)
            const localVarPath = `/invoices/{invoice_id}/credit-notes`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryBatchOrdersOrderIdDocumentsDeliveryBatchGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readDeliveryBatchOrdersOrderIdDocumentsDeliveryBatchGet', 'orderId', orderId)
            const localVarPath = `/orders/{order_id}/documents/delivery-batch`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDeliveriesDeliveryIdGet: async (deliveryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryId' is not null or undefined
            assertParamExists('readDeliveryDeliveriesDeliveryIdGet', 'deliveryId', deliveryId)
            const localVarPath = `/deliveries/{delivery_id}`
                .replace(`{${"delivery_id"}}`, encodeURIComponent(String(deliveryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Document Credit Note Items
         * @param {number} deliveryDocumentId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentCreditNoteItemsDeliveryDocumentsDeliveryDocumentIdCreditNoteItemsGet: async (deliveryDocumentId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryDocumentId' is not null or undefined
            assertParamExists('readDeliveryDocumentCreditNoteItemsDeliveryDocumentsDeliveryDocumentIdCreditNoteItemsGet', 'deliveryDocumentId', deliveryDocumentId)
            const localVarPath = `/delivery-documents/{delivery_document_id}/credit-note-items`
                .replace(`{${"delivery_document_id"}}`, encodeURIComponent(String(deliveryDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Document Remaining Amount
         * @param {number} deliveryDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentRemainingAmountDeliveryDocumentsDeliveryDocumentIdRemainingAmountGet: async (deliveryDocumentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryDocumentId' is not null or undefined
            assertParamExists('readDeliveryDocumentRemainingAmountDeliveryDocumentsDeliveryDocumentIdRemainingAmountGet', 'deliveryDocumentId', deliveryDocumentId)
            const localVarPath = `/delivery-documents/{delivery_document_id}/remaining-amount`
                .replace(`{${"delivery_document_id"}}`, encodeURIComponent(String(deliveryDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Documents
         * @param {number} deliveryId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentsDeliveriesDeliveryIdDocumentsGet: async (deliveryId: number, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryId' is not null or undefined
            assertParamExists('readDeliveryDocumentsDeliveriesDeliveryIdDocumentsGet', 'deliveryId', deliveryId)
            const localVarPath = `/deliveries/{delivery_id}/documents`
                .replace(`{${"delivery_id"}}`, encodeURIComponent(String(deliveryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Documents
         * @param {string} [fromInvoiceDate] 
         * @param {string} [toInvoiceDate] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [companies] 
         * @param {Array<string>} [documentType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentsDeliveryDocumentsGet: async (fromInvoiceDate?: string, toInvoiceDate?: string, suppliers?: Array<number>, companies?: Array<number>, documentType?: Array<string>, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/delivery-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromInvoiceDate !== undefined) {
                localVarQueryParameter['from_invoice_date'] = (fromInvoiceDate as any instanceof Date) ?
                    (fromInvoiceDate as any).toISOString() :
                    fromInvoiceDate;
            }

            if (toInvoiceDate !== undefined) {
                localVarQueryParameter['to_invoice_date'] = (toInvoiceDate as any instanceof Date) ?
                    (toInvoiceDate as any).toISOString() :
                    toInvoiceDate;
            }

            if (suppliers) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }

            if (documentType) {
                localVarQueryParameter['document_type'] = documentType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {boolean} [hidePaid] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryInvoicesReportsDeliveryInvoiceGet: async (fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/delivery-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (suppliers !== undefined) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (hidePaid !== undefined) {
                localVarQueryParameter['hide_paid'] = hidePaid;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {number} deliveryId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryItemsDeliveriesDeliveryIdItemsGet: async (deliveryId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryId' is not null or undefined
            assertParamExists('readDeliveryItemsDeliveriesDeliveryIdItemsGet', 'deliveryId', deliveryId)
            const localVarPath = `/deliveries/{delivery_id}/items`
                .replace(`{${"delivery_id"}}`, encodeURIComponent(String(deliveryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [supplierId] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [itemCategories] 
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryItemsReportsDeliveryItemsGet: async (fromDeliveryDate?: string, toDeliveryDate?: string, supplierId?: number, companies?: Array<number>, warehouses?: Array<number>, itemCategories?: Array<number>, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/delivery-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDeliveryDate !== undefined) {
                localVarQueryParameter['from_delivery_date'] = (fromDeliveryDate as any instanceof Date) ?
                    (fromDeliveryDate as any).toISOString() :
                    fromDeliveryDate;
            }

            if (toDeliveryDate !== undefined) {
                localVarQueryParameter['to_delivery_date'] = (toDeliveryDate as any instanceof Date) ?
                    (toDeliveryDate as any).toISOString() :
                    toDeliveryDate;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplier_id'] = supplierId;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (itemCategories) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Remaining Amount
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRemainingAmountDeliveriesDeliveryIdRemainingAmountGet: async (deliveryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryId' is not null or undefined
            assertParamExists('readDeliveryRemainingAmountDeliveriesDeliveryIdRemainingAmountGet', 'deliveryId', deliveryId)
            const localVarPath = `/deliveries/{delivery_id}/remaining-amount`
                .replace(`{${"delivery_id"}}`, encodeURIComponent(String(deliveryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Request By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRequestByIdDeliveryRequestsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readDeliveryRequestByIdDeliveryRequestsIdGet', 'id', id)
            const localVarPath = `/delivery-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Request Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRequestItemsDeliveryRequestsIdItemsGet: async (id: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readDeliveryRequestItemsDeliveryRequestsIdItemsGet', 'id', id)
            const localVarPath = `/delivery-requests/{id}/items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Request Stock Receiving Process
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRequestStockReceivingProcessDeliveryRequestsIdProcessGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readDeliveryRequestStockReceivingProcessDeliveryRequestsIdProcessGet', 'id', id)
            const localVarPath = `/delivery-requests/{id}/process`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Requests
         * @param {Array<number>} [id] 
         * @param {string} [fromCreated] 
         * @param {string} [toCreated] 
         * @param {string} [fromDelivery] 
         * @param {string} [toDelivery] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [warehouses] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRequestsDeliveryRequestsGet: async (id?: Array<number>, fromCreated?: string, toCreated?: string, fromDelivery?: string, toDelivery?: string, suppliers?: Array<number>, warehouses?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/delivery-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (fromCreated !== undefined) {
                localVarQueryParameter['from_created'] = (fromCreated as any instanceof Date) ?
                    (fromCreated as any).toISOString() :
                    fromCreated;
            }

            if (toCreated !== undefined) {
                localVarQueryParameter['to_created'] = (toCreated as any instanceof Date) ?
                    (toCreated as any).toISOString() :
                    toCreated;
            }

            if (fromDelivery !== undefined) {
                localVarQueryParameter['from_delivery'] = (fromDelivery as any instanceof Date) ?
                    (fromDelivery as any).toISOString().substr(0,10) :
                    fromDelivery;
            }

            if (toDelivery !== undefined) {
                localVarQueryParameter['to_delivery'] = (toDelivery as any instanceof Date) ?
                    (toDelivery as any).toISOString().substr(0,10) :
                    toDelivery;
            }

            if (suppliers) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Depleted Amounts With Prices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDepletedAmountsWithPricesReportsDepletedAmountsWithPricesGet: async (fromDate?: string, toDate?: string, receivers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/depleted-amounts-with-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (receivers !== undefined) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Group By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroupByIdGroupsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readGroupByIdGroupsIdGet', 'id', id)
            const localVarPath = `/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Groups
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroupsGroupsGet: async (id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceByIdInvoicesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readInvoiceByIdInvoicesIdGet', 'id', id)
            const localVarPath = `/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceDocumentInvoicesInvoiceIdDocumentGet: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('readInvoiceDocumentInvoicesInvoiceIdDocumentGet', 'invoiceId', invoiceId)
            const localVarPath = `/invoices/{invoice_id}/document`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice Orders
         * @param {number} invoiceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceOrdersInvoicesInvoiceIdOrdersGet: async (invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('readInvoiceOrdersInvoicesInvoiceIdOrdersGet', 'invoiceId', invoiceId)
            const localVarPath = `/invoices/{invoice_id}/orders`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice Transactions
         * @param {number} invoiceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceTransactionsInvoicesInvoiceIdTransactionsGet: async (invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('readInvoiceTransactionsInvoicesInvoiceIdTransactionsGet', 'invoiceId', invoiceId)
            const localVarPath = `/invoices/{invoice_id}/transactions`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoices
         * @param {Array<number>} [id] 
         * @param {string} [fromCreatedAt] 
         * @param {string} [toCreatedAt] 
         * @param {Array<number>} [legalEntities] 
         * @param {Array<number>} [companies] 
         * @param {boolean} [hideCancelled] 
         * @param {boolean} [hidePaidInFull] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoicesInvoicesGet: async (id?: Array<number>, fromCreatedAt?: string, toCreatedAt?: string, legalEntities?: Array<number>, companies?: Array<number>, hideCancelled?: boolean, hidePaidInFull?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (fromCreatedAt !== undefined) {
                localVarQueryParameter['from_created_at'] = (fromCreatedAt as any instanceof Date) ?
                    (fromCreatedAt as any).toISOString() :
                    fromCreatedAt;
            }

            if (toCreatedAt !== undefined) {
                localVarQueryParameter['to_created_at'] = (toCreatedAt as any instanceof Date) ?
                    (toCreatedAt as any).toISOString() :
                    toCreatedAt;
            }

            if (legalEntities) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }

            if (hideCancelled !== undefined) {
                localVarQueryParameter['hide_cancelled'] = hideCancelled;
            }

            if (hidePaidInFull !== undefined) {
                localVarQueryParameter['hide_paid_in_full'] = hidePaidInFull;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Item By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemByIdItemsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readItemByIdItemsIdGet', 'id', id)
            const localVarPath = `/items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Item Categories Paginated
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [leafOnly] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemCategoriesPaginatedItemCategoriesGet: async (id?: Array<number>, query?: string, leafOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/item-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (leafOnly !== undefined) {
                localVarQueryParameter['leaf_only'] = leafOnly;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Item Category By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemCategoryByIdItemCategoriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readItemCategoryByIdItemCategoriesIdGet', 'id', id)
            const localVarPath = `/item-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Item Delivery Subscription By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemDeliverySubscriptionByIdItemDeliverySubscriptionsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readItemDeliverySubscriptionByIdItemDeliverySubscriptionsIdGet', 'id', id)
            const localVarPath = `/item-delivery-subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Items By Partner
         * @param {number} partnerId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsByPartnerPartnersPartnerIdItemsGet: async (partnerId: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('readItemsByPartnerPartnersPartnerIdItemsGet', 'partnerId', partnerId)
            const localVarPath = `/partners/{partner_id}/items`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Items
         * @param {Array<number>} [id] 
         * @param {Array<string>} [query] 
         * @param {Array<number>} [categories] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [manufacturers] 
         * @param {number} [partner] 
         * @param {boolean} [showInactive] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsItemsGet: async (id?: Array<number>, query?: Array<string>, categories?: Array<number>, suppliers?: Array<number>, manufacturers?: Array<number>, partner?: number, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (query) {
                localVarQueryParameter['query'] = query;
            }

            if (categories) {
                localVarQueryParameter['categories'] = categories;
            }

            if (suppliers) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (manufacturers) {
                localVarQueryParameter['manufacturers'] = manufacturers;
            }

            if (partner !== undefined) {
                localVarQueryParameter['partner'] = partner;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Last Delivery Country
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLastDeliveryCountryItemsIdLastDeliveryCountryGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readLastDeliveryCountryItemsIdLastDeliveryCountryGet', 'id', id)
            const localVarPath = `/items/{id}/last-delivery-country`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Last Delivery Price
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLastDeliveryPriceItemsIdLastDeliveryPriceGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readLastDeliveryPriceItemsIdLastDeliveryPriceGet', 'id', id)
            const localVarPath = `/items/{id}/last-delivery-price`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Legal Entities By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLegalEntitiesByCompanyIdCompaniesCompanyIdLegalEntitiesGet: async (companyId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('readLegalEntitiesByCompanyIdCompaniesCompanyIdLegalEntitiesGet', 'companyId', companyId)
            const localVarPath = `/companies/{company_id}/legal-entities`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Legal Entities
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [company] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLegalEntitiesLegalEntitiesGet: async (id?: Array<number>, showInactive?: boolean, query?: string, company?: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/legal-entities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Legal Entity By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLegalEntityByIdLegalEntitiesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readLegalEntityByIdLegalEntitiesIdGet', 'id', id)
            const localVarPath = `/legal-entities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Linked Item Associations For Item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLinkedItemAssociationsForItemItemsItemIdLinkedItemAssociationGet: async (itemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('readLinkedItemAssociationsForItemItemsItemIdLinkedItemAssociationGet', 'itemId', itemId)
            const localVarPath = `/items/{item_id}/linked-item-association`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read List
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListPermissionsGet: async (page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Manifacturer By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManifacturerByIdManufacturersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readManifacturerByIdManufacturersIdGet', 'id', id)
            const localVarPath = `/manufacturers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Manufacturer Export By Id
         * @param {number} manufacturerExportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturerExportByIdManufacturerExportsManufacturerExportIdGet: async (manufacturerExportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerExportId' is not null or undefined
            assertParamExists('readManufacturerExportByIdManufacturerExportsManufacturerExportIdGet', 'manufacturerExportId', manufacturerExportId)
            const localVarPath = `/manufacturer-exports/{manufacturer_export_id}`
                .replace(`{${"manufacturer_export_id"}}`, encodeURIComponent(String(manufacturerExportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Manufacturer Exports
         * @param {Array<number>} [manufacturers] 
         * @param {string} [fromCreatedDate] 
         * @param {string} [toCreatedDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturerExportsManufacturerExportsGet: async (manufacturers?: Array<number>, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/manufacturer-exports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (manufacturers) {
                localVarQueryParameter['manufacturers'] = manufacturers;
            }

            if (fromCreatedDate !== undefined) {
                localVarQueryParameter['from_created_date'] = (fromCreatedDate as any instanceof Date) ?
                    (fromCreatedDate as any).toISOString() :
                    fromCreatedDate;
            }

            if (toCreatedDate !== undefined) {
                localVarQueryParameter['to_created_date'] = (toCreatedDate as any instanceof Date) ?
                    (toCreatedDate as any).toISOString() :
                    toCreatedDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Manufacturer Exports
         * @param {number} manufacturerId 
         * @param {string} [fromCreatedDate] 
         * @param {string} [toCreatedDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturerExportsManufacturersManufacturerIdExportsGet: async (manufacturerId: number, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('readManufacturerExportsManufacturersManufacturerIdExportsGet', 'manufacturerId', manufacturerId)
            const localVarPath = `/manufacturers/{manufacturer_id}/exports`
                .replace(`{${"manufacturer_id"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromCreatedDate !== undefined) {
                localVarQueryParameter['from_created_date'] = (fromCreatedDate as any instanceof Date) ?
                    (fromCreatedDate as any).toISOString() :
                    fromCreatedDate;
            }

            if (toCreatedDate !== undefined) {
                localVarQueryParameter['to_created_date'] = (toCreatedDate as any instanceof Date) ?
                    (toCreatedDate as any).toISOString() :
                    toCreatedDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Manufacturer Items
         * @param {number} manufacturerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturerItemsManufacturersManufacturerIdItemsGet: async (manufacturerId: number, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('readManufacturerItemsManufacturersManufacturerIdItemsGet', 'manufacturerId', manufacturerId)
            const localVarPath = `/manufacturers/{manufacturer_id}/items`
                .replace(`{${"manufacturer_id"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Manufacturers
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturersManufacturersGet: async (id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemOrdersOrderIdItemsIdGet: async (orderId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderItemOrdersOrderIdItemsIdGet', 'orderId', orderId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readOrderItemOrdersOrderIdItemsIdGet', 'id', id)
            const localVarPath = `/orders/{order_id}/items/{id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemOrdersOrderIdItemsIdGet_3: async (orderId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderItemOrdersOrderIdItemsIdGet_3', 'orderId', orderId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readOrderItemOrdersOrderIdItemsIdGet_3', 'id', id)
            const localVarPath = `/orders/{order_id}/items/{id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Items
         * @param {number} orderId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemsOrdersOrderIdItemsGet: async (orderId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderItemsOrdersOrderIdItemsGet', 'orderId', orderId)
            const localVarPath = `/orders/{order_id}/items`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Items
         * @param {number} orderId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemsOrdersOrderIdItemsGet_4: async (orderId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderItemsOrdersOrderIdItemsGet_4', 'orderId', orderId)
            const localVarPath = `/orders/{order_id}/items`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderOrdersOrderIdGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderOrdersOrderIdGet', 'orderId', orderId)
            const localVarPath = `/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Status Logs
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderStatusLogsOrdersOrderIdStatusLogsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderStatusLogsOrdersOrderIdStatusLogsGet', 'orderId', orderId)
            const localVarPath = `/orders/{order_id}/status-logs`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderingPolicyLegalEntitiesIdOrderingPolicyGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readOrderingPolicyLegalEntitiesIdOrderingPolicyGet', 'id', id)
            const localVarPath = `/legal-entities/{id}/ordering-policy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderingPolicyPartnersIdOrderingPolicyGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readOrderingPolicyPartnersIdOrderingPolicyGet', 'id', id)
            const localVarPath = `/partners/{id}/ordering-policy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Orders Balance
         * @param {string} [statusKeys] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersBalanceReportsOrdersBalanceGet: async (statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/orders-balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (statusKeys !== undefined) {
                localVarQueryParameter['status_keys'] = statusKeys;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (noInvoiceOnly !== undefined) {
                localVarQueryParameter['no_invoice_only'] = noInvoiceOnly;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Partner Bonus Quantities
         * @param {Array<number>} [items] 
         * @param {Array<number>} [partners] 
         * @param {string} [fromStartDate] 
         * @param {string} [toStartDate] 
         * @param {string} [fromEndDate] 
         * @param {string} [toEndDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerBonusQuantitiesPromotionsBonusQuantityGet: async (items?: Array<number>, partners?: Array<number>, fromStartDate?: string, toStartDate?: string, fromEndDate?: string, toEndDate?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions/bonus-quantity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (items) {
                localVarQueryParameter['items'] = items;
            }

            if (partners) {
                localVarQueryParameter['partners'] = partners;
            }

            if (fromStartDate !== undefined) {
                localVarQueryParameter['from_start_date'] = (fromStartDate as any instanceof Date) ?
                    (fromStartDate as any).toISOString() :
                    fromStartDate;
            }

            if (toStartDate !== undefined) {
                localVarQueryParameter['to_start_date'] = (toStartDate as any instanceof Date) ?
                    (toStartDate as any).toISOString() :
                    toStartDate;
            }

            if (fromEndDate !== undefined) {
                localVarQueryParameter['from_end_date'] = (fromEndDate as any instanceof Date) ?
                    (fromEndDate as any).toISOString() :
                    fromEndDate;
            }

            if (toEndDate !== undefined) {
                localVarQueryParameter['to_end_date'] = (toEndDate as any instanceof Date) ?
                    (toEndDate as any).toISOString() :
                    toEndDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Partner By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerByIdPartnersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPartnerByIdPartnersIdGet', 'id', id)
            const localVarPath = `/partners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Partner Item Configurations
         * @param {number} itemId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerItemConfigurationsItemsItemIdPartnerConfigurationsGet: async (itemId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('readPartnerItemConfigurationsItemsItemIdPartnerConfigurationsGet', 'itemId', itemId)
            const localVarPath = `/items/{item_id}/partner-configurations`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Partner Item Configurations
         * @param {number} partnerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerItemConfigurationsPartnersPartnerIdItemConfigurationGet: async (partnerId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('readPartnerItemConfigurationsPartnersPartnerIdItemConfigurationGet', 'partnerId', partnerId)
            const localVarPath = `/partners/{partner_id}/item-configuration`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnersPartnerIdItemCategoryAssociationGet: async (partnerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('readPartnersPartnerIdItemCategoryAssociationGet', 'partnerId', partnerId)
            const localVarPath = `/partners/{partner_id}/item-category-association`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnersPartnerIdItemCategoryAssociationGet_5: async (partnerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('readPartnersPartnerIdItemCategoryAssociationGet_5', 'partnerId', partnerId)
            const localVarPath = `/partners/{partner_id}/item-category-association`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Partners
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnersPartnersGet: async (id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Payment Option By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPaymentOptionByIdPaymentOptionsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPaymentOptionByIdPaymentOptionsIdGet', 'id', id)
            const localVarPath = `/payment-options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Payment Options By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPaymentOptionsByCompanyIdCompaniesCompanyIdPaymentOptionsGet: async (companyId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('readPaymentOptionsByCompanyIdCompaniesCompanyIdPaymentOptionsGet', 'companyId', companyId)
            const localVarPath = `/companies/{company_id}/payment-options`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Payment Options
         * @param {Array<number>} [id] 
         * @param {number} [company] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPaymentOptionsPaymentOptionsGet: async (id?: Array<number>, company?: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment-options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Portal User By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPortalUserByIdPortalUsersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPortalUserByIdPortalUsersIdGet', 'id', id)
            const localVarPath = `/portal-users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Portal Users
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPortalUsersPortalUsersGet: async (id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portal-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policies By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPoliciesByCompanyIdCompaniesCompanyIdPricingPoliciesGet: async (companyId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('readPricingPoliciesByCompanyIdCompaniesCompanyIdPricingPoliciesGet', 'companyId', companyId)
            const localVarPath = `/companies/{company_id}/pricing-policies`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policies
         * @param {Array<number>} [id] 
         * @param {Array<number>} [companies] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPoliciesPricingPoliciesGet: async (id?: Array<number>, companies?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pricing-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyByIdPricingPoliciesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPricingPolicyByIdPricingPoliciesIdGet', 'id', id)
            const localVarPath = `/pricing-policies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoriesPricingPoliciesIdItemCategoriesGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPricingPolicyItemCategoriesPricingPoliciesIdItemCategoriesGet', 'id', id)
            const localVarPath = `/pricing-policies/{id}/item-categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Category Multipliers
         * @param {string} itemCategories 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoryMultipliersReportsPricingPolicyItemCategoryMultipliersGet: async (itemCategories: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemCategories' is not null or undefined
            assertParamExists('readPricingPolicyItemCategoryMultipliersReportsPricingPolicyItemCategoryMultipliersGet', 'itemCategories', itemCategories)
            const localVarPath = `/reports/pricing-policy-item-category-multipliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Exceptions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemExceptionsPricingPoliciesIdItemExceptionsGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPricingPolicyItemExceptionsPricingPoliciesIdItemExceptionsGet', 'id', id)
            const localVarPath = `/pricing-policies/{id}/item-exceptions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Prices
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemPricesReportsPricingPolicyItemPricesGet: async (items: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'items' is not null or undefined
            assertParamExists('readPricingPolicyItemPricesReportsPricingPolicyItemPricesGet', 'items', items)
            const localVarPath = `/reports/pricing-policy-item-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Print Document Queue
         * @param {string} [state] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPrintDocumentQueuePrintDocumentQueueGet: async (state?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/print-document-queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Promotion By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPromotionByIdPromotionsBonusQuantityIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPromotionByIdPromotionsBonusQuantityIdGet', 'id', id)
            const localVarPath = `/promotions/bonus-quantity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Role By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRoleByIdRolesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readRoleByIdRolesIdGet', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Roles
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRolesRolesGet: async (id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Sales
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [itemCategories] 
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSalesReportsSalesGet: async (fromDeliveryDate?: string, toDeliveryDate?: string, receivers?: Array<number>, legalEntities?: Array<number>, companies?: Array<number>, warehouses?: Array<number>, itemCategories?: Array<number>, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/sales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDeliveryDate !== undefined) {
                localVarQueryParameter['from_delivery_date'] = (fromDeliveryDate as any instanceof Date) ?
                    (fromDeliveryDate as any).toISOString() :
                    fromDeliveryDate;
            }

            if (toDeliveryDate !== undefined) {
                localVarQueryParameter['to_delivery_date'] = (toDeliveryDate as any instanceof Date) ?
                    (toDeliveryDate as any).toISOString() :
                    toDeliveryDate;
            }

            if (receivers) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (legalEntities) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (itemCategories) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Shipping Route By Id
         * @param {number} shippingRouteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShippingRouteByIdShippingRoutesShippingRouteIdGet: async (shippingRouteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shippingRouteId' is not null or undefined
            assertParamExists('readShippingRouteByIdShippingRoutesShippingRouteIdGet', 'shippingRouteId', shippingRouteId)
            const localVarPath = `/shipping-routes/{shipping_route_id}`
                .replace(`{${"shipping_route_id"}}`, encodeURIComponent(String(shippingRouteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Shipping Route Orders
         * @param {number} shippingRouteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShippingRouteOrdersShippingRoutesShippingRouteIdOrdersGet: async (shippingRouteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shippingRouteId' is not null or undefined
            assertParamExists('readShippingRouteOrdersShippingRoutesShippingRouteIdOrdersGet', 'shippingRouteId', shippingRouteId)
            const localVarPath = `/shipping-routes/{shipping_route_id}/orders`
                .replace(`{${"shipping_route_id"}}`, encodeURIComponent(String(shippingRouteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Shipping Routes
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [users] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShippingRoutesShippingRoutesGet: async (fromDate?: string, toDate?: string, users?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shipping-routes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Shopping Cart By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShoppingCartByIdShoppingCartsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readShoppingCartByIdShoppingCartsIdGet', 'id', id)
            const localVarPath = `/shopping-carts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Shopping Cart Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShoppingCartItemsShoppingCartsIdItemsGet: async (id: number, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readShoppingCartItemsShoppingCartsIdItemsGet', 'id', id)
            const localVarPath = `/shopping-carts/{id}/items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Shopping Carts
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShoppingCartsShoppingCartsGet: async (id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopping-carts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Similar Item Associations For Item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSimilarItemAssociationsForItemItemsItemIdSimilarItemAssociationGet: async (itemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('readSimilarItemAssociationsForItemItemsItemIdSimilarItemAssociationGet', 'itemId', itemId)
            const localVarPath = `/items/{item_id}/similar-item-association`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stock By Deliveries
         * @param {number} [companyId] 
         * @param {number} [warehouseId] 
         * @param {number} [itemId] 
         * @param {number} [manufacturerId] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [itemCategories] 
         * @param {boolean} [hideDepleted] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockByDeliveriesReportsStockByDeliveriesGet: async (companyId?: number, warehouseId?: number, itemId?: number, manufacturerId?: number, suppliers?: Array<number>, itemCategories?: Array<number>, hideDepleted?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/stock-by-deliveries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['company_id'] = companyId;
            }

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturer_id'] = manufacturerId;
            }

            if (suppliers) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (itemCategories) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (hideDepleted !== undefined) {
                localVarQueryParameter['hide_depleted'] = hideDepleted;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stock Receiving Process By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockReceivingProcessByIdStockReceivingProcessesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStockReceivingProcessByIdStockReceivingProcessesIdGet', 'id', id)
            const localVarPath = `/stock-receiving-processes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stock Receiving Process Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockReceivingProcessItemsStockReceivingProcessesIdItemsGet: async (id: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStockReceivingProcessItemsStockReceivingProcessesIdItemsGet', 'id', id)
            const localVarPath = `/stock-receiving-processes/{id}/items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stock Receiving Processes
         * @param {Array<number>} [id] 
         * @param {string} [fromCreated] 
         * @param {string} [toCreated] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockReceivingProcessesStockReceivingProcessesGet: async (id?: Array<number>, fromCreated?: string, toCreated?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stock-receiving-processes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (fromCreated !== undefined) {
                localVarQueryParameter['from_created'] = (fromCreated as any instanceof Date) ?
                    (fromCreated as any).toISOString() :
                    fromCreated;
            }

            if (toCreated !== undefined) {
                localVarQueryParameter['to_created'] = (toCreated as any instanceof Date) ?
                    (toCreated as any).toISOString() :
                    toCreated;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stock Taking Report Items
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockTakingReportItemsStockTakingReportItemsGet: async (itemId?: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stock-taking-report-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stock Taking Report
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockTakingReportStockTakingReportsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStockTakingReportStockTakingReportsIdGet', 'id', id)
            const localVarPath = `/stock-taking-reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stock Taking Reports
         * @param {string} [fromExecutionDate] 
         * @param {string} [toExecutionDate] 
         * @param {number} [userId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockTakingReportsStockTakingReportsGet: async (fromExecutionDate?: string, toExecutionDate?: string, userId?: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stock-taking-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromExecutionDate !== undefined) {
                localVarQueryParameter['from_execution_date'] = fromExecutionDate;
            }

            if (toExecutionDate !== undefined) {
                localVarQueryParameter['to_execution_date'] = toExecutionDate;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stocktaking By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStocktakingByIdStocktakingsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStocktakingByIdStocktakingsIdGet', 'id', id)
            const localVarPath = `/stocktakings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stocktaking Tasks
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStocktakingTasksStocktakingsIdTasksGet: async (id: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStocktakingTasksStocktakingsIdTasksGet', 'id', id)
            const localVarPath = `/stocktakings/{id}/tasks`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stocktakings
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [warehouses] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStocktakingsStocktakingsGet: async (fromDate?: string, toDate?: string, warehouses?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocktakings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Supplier By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierByIdSuppliersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSupplierByIdSuppliersIdGet', 'id', id)
            const localVarPath = `/suppliers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Supplier Price Differences
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierPriceDifferencesSuppliersIdPriceDifferencesGet: async (id: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSupplierPriceDifferencesSuppliersIdPriceDifferencesGet', 'id', id)
            const localVarPath = `/suppliers/{id}/price-differences`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Supplier Quantity Differences
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierQuantityDifferencesSuppliersIdQuantityDifferencesGet: async (id: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSupplierQuantityDifferencesSuppliersIdQuantityDifferencesGet', 'id', id)
            const localVarPath = `/suppliers/{id}/quantity-differences`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Supplier Reserve Subscription By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierReserveSubscriptionByIdSupplierReserveSubscriptionsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSupplierReserveSubscriptionByIdSupplierReserveSubscriptionsIdGet', 'id', id)
            const localVarPath = `/supplier-reserve-subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Supplier Reserve Subscriptions
         * @param {Array<number>} [id] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<string>} [weekday] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierReserveSubscriptionsSupplierReserveSubscriptionsGet: async (id?: Array<number>, suppliers?: Array<number>, weekday?: Array<string>, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/supplier-reserve-subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (suppliers) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (weekday) {
                localVarQueryParameter['weekday'] = weekday;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Suppliers
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [showInactive] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSuppliersSuppliersGet: async (id?: Array<number>, query?: string, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/suppliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Transactions
         * @param {Array<number>} [id] 
         * @param {number} [invoiceId] 
         * @param {number} [deliveryInvoiceId] 
         * @param {Array<number>} [treasuries] 
         * @param {string} [fromCreatedDate] 
         * @param {string} [toCreatedDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTransactionsTransactionsGet: async (id?: Array<number>, invoiceId?: number, deliveryInvoiceId?: number, treasuries?: Array<number>, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoice_id'] = invoiceId;
            }

            if (deliveryInvoiceId !== undefined) {
                localVarQueryParameter['delivery_invoice_id'] = deliveryInvoiceId;
            }

            if (treasuries) {
                localVarQueryParameter['treasuries'] = treasuries;
            }

            if (fromCreatedDate !== undefined) {
                localVarQueryParameter['from_created_date'] = (fromCreatedDate as any instanceof Date) ?
                    (fromCreatedDate as any).toISOString() :
                    fromCreatedDate;
            }

            if (toCreatedDate !== undefined) {
                localVarQueryParameter['to_created_date'] = (toCreatedDate as any instanceof Date) ?
                    (toCreatedDate as any).toISOString() :
                    toCreatedDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Treasuries
         * @param {Array<number>} [id] 
         * @param {number} [companyId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuriesTreasuriesGet: async (id?: Array<number>, companyId?: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/treasuries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['company_id'] = companyId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Treasury Bank Account Amount
         * @param {number} treasuryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuryBankAccountAmountTreasuriesTreasuryIdBankAccountAmountGet: async (treasuryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treasuryId' is not null or undefined
            assertParamExists('readTreasuryBankAccountAmountTreasuriesTreasuryIdBankAccountAmountGet', 'treasuryId', treasuryId)
            const localVarPath = `/treasuries/{treasury_id}/bank-account-amount`
                .replace(`{${"treasury_id"}}`, encodeURIComponent(String(treasuryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Treasury By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuryByIdTreasuriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readTreasuryByIdTreasuriesIdGet', 'id', id)
            const localVarPath = `/treasuries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Treasury Cash Amount
         * @param {number} treasuryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuryCashAmountTreasuriesTreasuryIdCashAmountGet: async (treasuryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treasuryId' is not null or undefined
            assertParamExists('readTreasuryCashAmountTreasuriesTreasuryIdCashAmountGet', 'treasuryId', treasuryId)
            const localVarPath = `/treasuries/{treasury_id}/cash-amount`
                .replace(`{${"treasury_id"}}`, encodeURIComponent(String(treasuryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Treasury Transactions
         * @param {number} treasuryId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuryTransactionsTreasuriesTreasuryIdTransactionsGet: async (treasuryId: number, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treasuryId' is not null or undefined
            assertParamExists('readTreasuryTransactionsTreasuriesTreasuryIdTransactionsGet', 'treasuryId', treasuryId)
            const localVarPath = `/treasuries/{treasury_id}/transactions`
                .replace(`{${"treasury_id"}}`, encodeURIComponent(String(treasuryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read User By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserByIdUsersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readUserByIdUsersIdGet', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Users By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersByCompanyIdCompaniesCompanyIdUsersGet: async (companyId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('readUsersByCompanyIdCompaniesCompanyIdUsersGet', 'companyId', companyId)
            const localVarPath = `/companies/{company_id}/users`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Users
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {number} [company] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersUsersGet: async (id?: Array<number>, showInactive?: boolean, company?: number, query?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Warehouse By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseByIdWarehousesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readWarehouseByIdWarehousesIdGet', 'id', id)
            const localVarPath = `/warehouses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Warehouse Movements
         * @param {Array<number>} [id] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [items] 
         * @param {string} [fromExpirationDate] 
         * @param {string} [toExpirationDate] 
         * @param {string} [batchNumber] 
         * @param {string} [movementType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseMovementsWarehouseMovementsGet: async (id?: Array<number>, warehouses?: Array<number>, items?: Array<number>, fromExpirationDate?: string, toExpirationDate?: string, batchNumber?: string, movementType?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/warehouse-movements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (items) {
                localVarQueryParameter['items'] = items;
            }

            if (fromExpirationDate !== undefined) {
                localVarQueryParameter['from_expiration_date'] = (fromExpirationDate as any instanceof Date) ?
                    (fromExpirationDate as any).toISOString().substr(0,10) :
                    fromExpirationDate;
            }

            if (toExpirationDate !== undefined) {
                localVarQueryParameter['to_expiration_date'] = (toExpirationDate as any instanceof Date) ?
                    (toExpirationDate as any).toISOString().substr(0,10) :
                    toExpirationDate;
            }

            if (batchNumber !== undefined) {
                localVarQueryParameter['batch_number'] = batchNumber;
            }

            if (movementType !== undefined) {
                localVarQueryParameter['movement_type'] = movementType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Warehouse Stock By Orders
         * @param {number} warehouseId 
         * @param {string} toDate 
         * @param {boolean} [hideOrdersWithoutDeliveryDate] 
         * @param {boolean} [hideAvailable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseStockByOrdersReportsStockByOrdersGet: async (warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('readWarehouseStockByOrdersReportsStockByOrdersGet', 'warehouseId', warehouseId)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('readWarehouseStockByOrdersReportsStockByOrdersGet', 'toDate', toDate)
            const localVarPath = `/reports/stock-by-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (hideOrdersWithoutDeliveryDate !== undefined) {
                localVarQueryParameter['hide_orders_without_delivery_date'] = hideOrdersWithoutDeliveryDate;
            }

            if (hideAvailable !== undefined) {
                localVarQueryParameter['hide_available'] = hideAvailable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Warehouses
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehousesWarehousesGet: async (id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/warehouses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderingPolicyLegalEntitiesIdOrderingPolicyPut: async (id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setOrderingPolicyLegalEntitiesIdOrderingPolicyPut', 'id', id)
            // verify required parameter 'setOrderingPolicyRequest' is not null or undefined
            assertParamExists('setOrderingPolicyLegalEntitiesIdOrderingPolicyPut', 'setOrderingPolicyRequest', setOrderingPolicyRequest)
            const localVarPath = `/legal-entities/{id}/ordering-policy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setOrderingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderingPolicyPartnersIdOrderingPolicyPut: async (id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setOrderingPolicyPartnersIdOrderingPolicyPut', 'id', id)
            // verify required parameter 'setOrderingPolicyRequest' is not null or undefined
            assertParamExists('setOrderingPolicyPartnersIdOrderingPolicyPut', 'setOrderingPolicyRequest', setOrderingPolicyRequest)
            const localVarPath = `/partners/{id}/ordering-policy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setOrderingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Partner Integration Settings
         * @param {number} partnerId 
         * @param {Request1} request1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost: async (partnerId: number, request1: Request1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost', 'partnerId', partnerId)
            // verify required parameter 'request1' is not null or undefined
            assertParamExists('setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost', 'request1', request1)
            const localVarPath = `/partners/{partner_id}/integration-settings`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost: async (deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestId' is not null or undefined
            assertParamExists('startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost', 'deliveryRequestId', deliveryRequestId)
            // verify required parameter 'startStockReceivingProcessRequest' is not null or undefined
            assertParamExists('startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost', 'startStockReceivingProcessRequest', startStockReceivingProcessRequest)
            const localVarPath = `/delivery-requests/{delivery_request_id}/process`
                .replace(`{${"delivery_request_id"}}`, encodeURIComponent(String(deliveryRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startStockReceivingProcessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test Barsy Connection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testBarsyConnectionPartnersIdTestBarsyConnectionPost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testBarsyConnectionPartnersIdTestBarsyConnectionPost', 'id', id)
            const localVarPath = `/partners/{id}/test-barsy-connection`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Client
         * @param {number} id 
         * @param {UpdateClientRequest} updateClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientClientsIdPatch: async (id: number, updateClientRequest: UpdateClientRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClientClientsIdPatch', 'id', id)
            // verify required parameter 'updateClientRequest' is not null or undefined
            assertParamExists('updateClientClientsIdPatch', 'updateClientRequest', updateClientRequest)
            const localVarPath = `/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateClientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Company Category
         * @param {number} id 
         * @param {SaveCompanyCategoryRequest} saveCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyCategoryCompanyCategoriesIdPut: async (id: number, saveCompanyCategoryRequest: SaveCompanyCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCompanyCategoryCompanyCategoriesIdPut', 'id', id)
            // verify required parameter 'saveCompanyCategoryRequest' is not null or undefined
            assertParamExists('updateCompanyCategoryCompanyCategoriesIdPut', 'saveCompanyCategoryRequest', saveCompanyCategoryRequest)
            const localVarPath = `/company-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveCompanyCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Company
         * @param {number} id 
         * @param {UpdateCompanyRequest} updateCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyCompaniesIdPut: async (id: number, updateCompanyRequest: UpdateCompanyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCompanyCompaniesIdPut', 'id', id)
            // verify required parameter 'updateCompanyRequest' is not null or undefined
            assertParamExists('updateCompanyCompaniesIdPut', 'updateCompanyRequest', updateCompanyRequest)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Delivery Document
         * @param {number} deliveryDocumentId 
         * @param {UpdateDeliveryDocumentRequest} updateDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut: async (deliveryDocumentId: number, updateDeliveryDocumentRequest: UpdateDeliveryDocumentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryDocumentId' is not null or undefined
            assertParamExists('updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut', 'deliveryDocumentId', deliveryDocumentId)
            // verify required parameter 'updateDeliveryDocumentRequest' is not null or undefined
            assertParamExists('updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut', 'updateDeliveryDocumentRequest', updateDeliveryDocumentRequest)
            const localVarPath = `/delivery-documents/{delivery_document_id}`
                .replace(`{${"delivery_document_id"}}`, encodeURIComponent(String(deliveryDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliveryDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Delivery Request
         * @param {number} id 
         * @param {UpdateDeliveryRequestRequest} updateDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryRequestDeliveryRequestsIdPut: async (id: number, updateDeliveryRequestRequest: UpdateDeliveryRequestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDeliveryRequestDeliveryRequestsIdPut', 'id', id)
            // verify required parameter 'updateDeliveryRequestRequest' is not null or undefined
            assertParamExists('updateDeliveryRequestDeliveryRequestsIdPut', 'updateDeliveryRequestRequest', updateDeliveryRequestRequest)
            const localVarPath = `/delivery-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliveryRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Group
         * @param {number} id 
         * @param {UpdateGroupRequest} updateGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupGroupsIdPut: async (id: number, updateGroupRequest: UpdateGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGroupGroupsIdPut', 'id', id)
            // verify required parameter 'updateGroupRequest' is not null or undefined
            assertParamExists('updateGroupGroupsIdPut', 'updateGroupRequest', updateGroupRequest)
            const localVarPath = `/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Item Category
         * @param {number} id 
         * @param {SaveItemCategoryRequest} saveItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemCategoryItemCategoriesIdPut: async (id: number, saveItemCategoryRequest: SaveItemCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateItemCategoryItemCategoriesIdPut', 'id', id)
            // verify required parameter 'saveItemCategoryRequest' is not null or undefined
            assertParamExists('updateItemCategoryItemCategoriesIdPut', 'saveItemCategoryRequest', saveItemCategoryRequest)
            const localVarPath = `/item-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveItemCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Item Delivery Subscription
         * @param {number} id 
         * @param {PatchItemDeliverySubscriptionRequest} patchItemDeliverySubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut: async (id: number, patchItemDeliverySubscriptionRequest: PatchItemDeliverySubscriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut', 'id', id)
            // verify required parameter 'patchItemDeliverySubscriptionRequest' is not null or undefined
            assertParamExists('updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut', 'patchItemDeliverySubscriptionRequest', patchItemDeliverySubscriptionRequest)
            const localVarPath = `/item-delivery-subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchItemDeliverySubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Item
         * @param {number} id 
         * @param {SaveItemRequest} saveItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemItemsIdPut: async (id: number, saveItemRequest: SaveItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateItemItemsIdPut', 'id', id)
            // verify required parameter 'saveItemRequest' is not null or undefined
            assertParamExists('updateItemItemsIdPut', 'saveItemRequest', saveItemRequest)
            const localVarPath = `/items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Legal Entity
         * @param {number} id 
         * @param {SaveLegalEntityRequest} saveLegalEntityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLegalEntityLegalEntitiesIdPut: async (id: number, saveLegalEntityRequest: SaveLegalEntityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLegalEntityLegalEntitiesIdPut', 'id', id)
            // verify required parameter 'saveLegalEntityRequest' is not null or undefined
            assertParamExists('updateLegalEntityLegalEntitiesIdPut', 'saveLegalEntityRequest', saveLegalEntityRequest)
            const localVarPath = `/legal-entities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveLegalEntityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Manufacturer
         * @param {number} id 
         * @param {SaveManufacturerRequest} saveManufacturerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManufacturerManufacturersIdPut: async (id: number, saveManufacturerRequest: SaveManufacturerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateManufacturerManufacturersIdPut', 'id', id)
            // verify required parameter 'saveManufacturerRequest' is not null or undefined
            assertParamExists('updateManufacturerManufacturersIdPut', 'saveManufacturerRequest', saveManufacturerRequest)
            const localVarPath = `/manufacturers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveManufacturerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderItemOrdersOrderIdItemsIdPut: async (orderId: number, id: number, saveOrderItemRequest: SaveOrderItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('updateOrderItemOrdersOrderIdItemsIdPut', 'orderId', orderId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrderItemOrdersOrderIdItemsIdPut', 'id', id)
            // verify required parameter 'saveOrderItemRequest' is not null or undefined
            assertParamExists('updateOrderItemOrdersOrderIdItemsIdPut', 'saveOrderItemRequest', saveOrderItemRequest)
            const localVarPath = `/orders/{order_id}/items/{id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveOrderItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderItemOrdersOrderIdItemsIdPut_6: async (orderId: number, id: number, saveOrderItemRequest: SaveOrderItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('updateOrderItemOrdersOrderIdItemsIdPut_6', 'orderId', orderId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrderItemOrdersOrderIdItemsIdPut_6', 'id', id)
            // verify required parameter 'saveOrderItemRequest' is not null or undefined
            assertParamExists('updateOrderItemOrdersOrderIdItemsIdPut_6', 'saveOrderItemRequest', saveOrderItemRequest)
            const localVarPath = `/orders/{order_id}/items/{id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveOrderItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Partner Bonus Quantity
         * @param {number} id 
         * @param {PatchPartnerBonusQuantityRequest} patchPartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerBonusQuantityPromotionsBonusQuantityIdPut: async (id: number, patchPartnerBonusQuantityRequest: PatchPartnerBonusQuantityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePartnerBonusQuantityPromotionsBonusQuantityIdPut', 'id', id)
            // verify required parameter 'patchPartnerBonusQuantityRequest' is not null or undefined
            assertParamExists('updatePartnerBonusQuantityPromotionsBonusQuantityIdPut', 'patchPartnerBonusQuantityRequest', patchPartnerBonusQuantityRequest)
            const localVarPath = `/promotions/bonus-quantity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchPartnerBonusQuantityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Partner Item Configuration
         * @param {number} id 
         * @param {SavePartnerItemConfigurationRequest} savePartnerItemConfigurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerItemConfigurationPartnersItemConfigurationIdPut: async (id: number, savePartnerItemConfigurationRequest: SavePartnerItemConfigurationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePartnerItemConfigurationPartnersItemConfigurationIdPut', 'id', id)
            // verify required parameter 'savePartnerItemConfigurationRequest' is not null or undefined
            assertParamExists('updatePartnerItemConfigurationPartnersItemConfigurationIdPut', 'savePartnerItemConfigurationRequest', savePartnerItemConfigurationRequest)
            const localVarPath = `/partners/item-configuration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savePartnerItemConfigurationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Partner
         * @param {number} id 
         * @param {SavePartnerRequest} savePartnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerPartnersIdPut: async (id: number, savePartnerRequest: SavePartnerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePartnerPartnersIdPut', 'id', id)
            // verify required parameter 'savePartnerRequest' is not null or undefined
            assertParamExists('updatePartnerPartnersIdPut', 'savePartnerRequest', savePartnerRequest)
            const localVarPath = `/partners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savePartnerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnersPartnerIdItemCategoryAssociationPost: async (partnerId: number, requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('updatePartnersPartnerIdItemCategoryAssociationPost', 'partnerId', partnerId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updatePartnersPartnerIdItemCategoryAssociationPost', 'requestBody', requestBody)
            const localVarPath = `/partners/{partner_id}/item-category-association`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnersPartnerIdItemCategoryAssociationPost_7: async (partnerId: number, requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('updatePartnersPartnerIdItemCategoryAssociationPost_7', 'partnerId', partnerId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updatePartnersPartnerIdItemCategoryAssociationPost_7', 'requestBody', requestBody)
            const localVarPath = `/partners/{partner_id}/item-category-association`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Payment Option
         * @param {number} id 
         * @param {SavePaymentOptionRequest} savePaymentOptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentOptionPaymentOptionsIdPut: async (id: number, savePaymentOptionRequest: SavePaymentOptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePaymentOptionPaymentOptionsIdPut', 'id', id)
            // verify required parameter 'savePaymentOptionRequest' is not null or undefined
            assertParamExists('updatePaymentOptionPaymentOptionsIdPut', 'savePaymentOptionRequest', savePaymentOptionRequest)
            const localVarPath = `/payment-options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savePaymentOptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {number} id 
         * @param {SavePricingPolicyRequest} savePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePricingPoliciesIdPut: async (id: number, savePricingPolicyRequest: SavePricingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePricingPoliciesIdPut', 'id', id)
            // verify required parameter 'savePricingPolicyRequest' is not null or undefined
            assertParamExists('updatePricingPoliciesIdPut', 'savePricingPolicyRequest', savePricingPolicyRequest)
            const localVarPath = `/pricing-policies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savePricingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Print Document Queue
         * @param {number} id 
         * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrintDocumentQueuePrintDocumentQueueIdPatch: async (id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePrintDocumentQueuePrintDocumentQueueIdPatch', 'id', id)
            // verify required parameter 'updatePrintDocumentQueueRequest' is not null or undefined
            assertParamExists('updatePrintDocumentQueuePrintDocumentQueueIdPatch', 'updatePrintDocumentQueueRequest', updatePrintDocumentQueueRequest)
            const localVarPath = `/print-document-queue/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePrintDocumentQueueRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Role
         * @param {number} id 
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleRolesIdPut: async (id: number, updateRoleRequest: UpdateRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRoleRolesIdPut', 'id', id)
            // verify required parameter 'updateRoleRequest' is not null or undefined
            assertParamExists('updateRoleRolesIdPut', 'updateRoleRequest', updateRoleRequest)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {Request} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch: async (deliveryRequestId: number, request: Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestId' is not null or undefined
            assertParamExists('updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch', 'deliveryRequestId', deliveryRequestId)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch', 'request', request)
            const localVarPath = `/delivery-requests/{delivery_request_id}/process`
                .replace(`{${"delivery_request_id"}}`, encodeURIComponent(String(deliveryRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Supplier Reserve Subscription
         * @param {number} id 
         * @param {PatchSupplierReserveSubscriptionRequest} patchSupplierReserveSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch: async (id: number, patchSupplierReserveSubscriptionRequest: PatchSupplierReserveSubscriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch', 'id', id)
            // verify required parameter 'patchSupplierReserveSubscriptionRequest' is not null or undefined
            assertParamExists('updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch', 'patchSupplierReserveSubscriptionRequest', patchSupplierReserveSubscriptionRequest)
            const localVarPath = `/supplier-reserve-subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchSupplierReserveSubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Supplier
         * @param {number} id 
         * @param {SaveSupplierRequest} saveSupplierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplierSuppliersIdPut: async (id: number, saveSupplierRequest: SaveSupplierRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSupplierSuppliersIdPut', 'id', id)
            // verify required parameter 'saveSupplierRequest' is not null or undefined
            assertParamExists('updateSupplierSuppliersIdPut', 'saveSupplierRequest', saveSupplierRequest)
            const localVarPath = `/suppliers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveSupplierRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Transaction
         * @param {number} id 
         * @param {PatchTransactionRequest} patchTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionTransactionsIdPut: async (id: number, patchTransactionRequest: PatchTransactionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTransactionTransactionsIdPut', 'id', id)
            // verify required parameter 'patchTransactionRequest' is not null or undefined
            assertParamExists('updateTransactionTransactionsIdPut', 'patchTransactionRequest', patchTransactionRequest)
            const localVarPath = `/transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Treasury
         * @param {number} id 
         * @param {PatchTreasuryRequest} patchTreasuryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTreasuryTreasuriesIdPut: async (id: number, patchTreasuryRequest: PatchTreasuryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTreasuryTreasuriesIdPut', 'id', id)
            // verify required parameter 'patchTreasuryRequest' is not null or undefined
            assertParamExists('updateTreasuryTreasuriesIdPut', 'patchTreasuryRequest', patchTreasuryRequest)
            const localVarPath = `/treasuries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchTreasuryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User
         * @param {number} id 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserUsersIdPut: async (id: number, updateUserRequest: UpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserUsersIdPut', 'id', id)
            // verify required parameter 'updateUserRequest' is not null or undefined
            assertParamExists('updateUserUsersIdPut', 'updateUserRequest', updateUserRequest)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Warehouse
         * @param {number} id 
         * @param {SaveWarehouseRequest} saveWarehouseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWarehouseWarehousesIdPut: async (id: number, saveWarehouseRequest: SaveWarehouseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWarehouseWarehousesIdPut', 'id', id)
            // verify required parameter 'saveWarehouseRequest' is not null or undefined
            assertParamExists('updateWarehouseWarehousesIdPut', 'saveWarehouseRequest', saveWarehouseRequest)
            const localVarPath = `/warehouses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveWarehouseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AMSApi - functional programming interface
 * @export
 */
export const AMSApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AMSApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Bulk Create Partner Bonus Quantity Promotions
         * @param {BulkCreatePartnerBonusQuantityRequest} bulkCreatePartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkCreatePartnerBonusQuantityPromotionsPromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest: BulkCreatePartnerBonusQuantityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerBonusQuantityResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreatePartnerBonusQuantityPromotionsPromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel Invoice By Invoice Id
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelInvoiceByInvoiceIdInvoicesInvoiceIdPatch(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelInvoiceByInvoiceIdInvoicesInvoiceIdPatch(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Copy Partner Item Configurations
         * @param {number} partnerId 
         * @param {number} sourcePartnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost(partnerId: number, sourcePartnerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePartnerItemConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost(partnerId, sourcePartnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {CreateClientRequest} createClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClientsPost(createClientRequest: CreateClientRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClientsPost(createClientRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompaniesPost(createCompanyRequest: CreateCompanyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompaniesPost(createCompanyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {SaveCompanyCategoryRequest} saveCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompanyCategoriesPost(saveCompanyCategoryRequest: SaveCompanyCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompanyCategoriesPost(saveCompanyCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Delivery
         * @param {CreateDeliveryRequest} createDeliveryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeliveryDeliveriesPost(createDeliveryRequest: CreateDeliveryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeliveryDeliveriesPost(createDeliveryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Delivery Document
         * @param {CreateDeliveryDocumentRequest} createDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeliveryDocumentDeliveryDocumentsPost(createDeliveryDocumentRequest: CreateDeliveryDocumentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeliveryDocumentDeliveryDocumentsPost(createDeliveryDocumentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Delivery Request
         * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
         * @param {boolean} [shouldSendEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeliveryRequestDeliveryRequestsPost(createDeliveryRequestRequest: CreateDeliveryRequestRequest, shouldSendEmail?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeliveryRequestDeliveryRequestsPost(createDeliveryRequestRequest, shouldSendEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Group
         * @param {CreateGroupRequest} createGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroupGroupsPost(createGroupRequest: CreateGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGroupGroupsPost(createGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {CreateInvoiceRequest} createInvoiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvoicesPost(createInvoiceRequest: CreateInvoiceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvoicesPost(createInvoiceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {SaveItemCategoryRequest} saveItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemCategoriesPost(saveItemCategoryRequest: SaveItemCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemCategoriesPost(saveItemCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Item Delivery Subscription
         * @param {CreateItemDeliverySubscriptionRequest} createItemDeliverySubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemDeliverySubscriptionItemDeliverySubscriptionsPost(createItemDeliverySubscriptionRequest: CreateItemDeliverySubscriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemDeliverySubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemDeliverySubscriptionItemDeliverySubscriptionsPost(createItemDeliverySubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {SaveItemRequest} saveItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsPost(saveItemRequest: SaveItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsPost(saveItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {SaveLegalEntityRequest} saveLegalEntityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLegalEntitiesPost(saveLegalEntityRequest: SaveLegalEntityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLegalEntitiesPost(saveLegalEntityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Linked Item Association
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId: number, linkedItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkedItemAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId, linkedItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Manufacturer
         * @param {SaveManufacturerRequest} saveManufacturerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManufacturerManufacturersPost(saveManufacturerRequest: SaveManufacturerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createManufacturerManufacturersPost(saveManufacturerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Order Item
         * @param {number} orderId 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrderItemOrdersOrderIdItemsPost(orderId: number, saveOrderItemRequest: SaveOrderItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderItemOrdersOrderIdItemsPost(orderId, saveOrderItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Order Item
         * @param {number} orderId 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrderItemOrdersOrderIdItemsPost_1(orderId: number, saveOrderItemRequest: SaveOrderItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderItemOrdersOrderIdItemsPost_1(orderId, saveOrderItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderRequest} createOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrderOrdersPost(createOrderRequest: CreateOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderOrdersPost(createOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Partner Item Configuration
         * @param {number} partnerId 
         * @param {SavePartnerItemConfigurationRequest} savePartnerItemConfigurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost(partnerId: number, savePartnerItemConfigurationRequest: SavePartnerItemConfigurationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerItemConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost(partnerId, savePartnerItemConfigurationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Partner
         * @param {SavePartnerRequest} savePartnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPartnerPartnersPost(savePartnerRequest: SavePartnerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPartnerPartnersPost(savePartnerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Payment Option
         * @param {SavePaymentOptionRequest} savePaymentOptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPaymentOptionPaymentOptionsPost(savePaymentOptionRequest: SavePaymentOptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPaymentOptionPaymentOptionsPost(savePaymentOptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Portal User
         * @param {CreatePortalUserRequest} createPortalUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPortalUserPortalUsersPost(createPortalUserRequest: CreatePortalUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortalUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPortalUserPortalUsersPost(createPortalUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Pricing Policy
         * @param {SavePricingPolicyRequest} savePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPricingPolicyPricingPoliciesPost(savePricingPolicyRequest: SavePricingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPricingPolicyPricingPoliciesPost(savePricingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Role
         * @param {CreateRoleRequest} createRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoleRolesPost(createRoleRequest: CreateRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoleRolesPost(createRoleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Similar Item Association
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId: number, similarItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimilarItemAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId, similarItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Stocktaking
         * @param {StocktakingRequest} stocktakingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStocktakingStocktakingsPost(stocktakingRequest: StocktakingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StocktakingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStocktakingStocktakingsPost(stocktakingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Supplier Reserve Subscription
         * @param {CreateSupplierReserveSubscriptionRequest} createSupplierReserveSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSupplierReserveSubscriptionSupplierReserveSubscriptionsPost(createSupplierReserveSubscriptionRequest: CreateSupplierReserveSubscriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierReserveSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSupplierReserveSubscriptionSupplierReserveSubscriptionsPost(createSupplierReserveSubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Supplier
         * @param {SaveSupplierRequest} saveSupplierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSupplierSuppliersPost(saveSupplierRequest: SaveSupplierRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSupplierSuppliersPost(saveSupplierRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Transaction
         * @param {CreateTransactionRequest} createTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTransactionTransactionsPost(createTransactionRequest: CreateTransactionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTransactionTransactionsPost(createTransactionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Treasury
         * @param {CreateTreasuryRequest} createTreasuryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTreasuryTreasuriesPost(createTreasuryRequest: CreateTreasuryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreasuryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTreasuryTreasuriesPost(createTreasuryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create User
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserUsersPost(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserUsersPost(createUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {SaveWarehouseRequest} saveWarehouseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWarehousesPost(saveWarehouseRequest: SaveWarehouseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWarehousesPost(saveWarehouseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Client
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClientClientsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClientClientsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Delivery
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeliveryDeliveriesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeliveryDeliveriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} deliveryDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeliveryDocumentsDeliveryDocumentIdDelete(deliveryDocumentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeliveryDocumentsDeliveryDocumentIdDelete(deliveryDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Item Delivery Subscription
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteItemDeliverySubscriptionItemDeliverySubscriptionsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemDeliverySubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteItemDeliverySubscriptionItemDeliverySubscriptionsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Linked Item Association
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId: number, linkedItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkedItemAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId, linkedItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrderItemOrdersOrderIdItemsIdDelete(orderId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrderItemOrdersOrderIdItemsIdDelete(orderId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrderItemOrdersOrderIdItemsIdDelete_2(orderId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrderItemOrdersOrderIdItemsIdDelete_2(orderId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Partner Bonus Quantity
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePartnerBonusQuantityPromotionsBonusQuantityIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerBonusQuantityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePartnerBonusQuantityPromotionsBonusQuantityIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Partner Item Configuration
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePartnerItemConfigurationPartnersItemConfigurationIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerItemConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePartnerItemConfigurationPartnersItemConfigurationIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Similar Item Association
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId: number, similarItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimilarItemAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId, similarItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Supplier Reserve Subscription
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSupplierReserveSubscriptionSupplierReserveSubscriptionsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierReserveSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSupplierReserveSubscriptionSupplierReserveSubscriptionsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Transaction
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTransactionTransactionsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTransactionTransactionsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get B2B Orders
         * @param {Array<number>} [id] 
         * @param {Array<string>} [status] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getB2bOrdersOrdersB2bOrdersGet(id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, legalEntities?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseOrderListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getB2bOrdersOrdersB2bOrdersGet(id, status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get B2C Orders
         * @param {Array<number>} [id] 
         * @param {Array<string>} [status] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getB2cOrdersOrdersB2cOrdersGet(id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, legalEntities?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseOrderListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getB2cOrdersOrdersB2cOrdersGet(id, status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Deliveries
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [warehouses] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeliveriesDeliveriesGet(suppliers?: Array<number>, warehouses?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseDeliveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeliveriesDeliveriesGet(suppliers, warehouses, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Delivery Request Expected Total
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeliveryRequestExpectedTotalDeliveryRequestsIdExpectedTotalGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeliveryRequestExpectedTotalDeliveryRequestsIdExpectedTotalGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Internal Transfers
         * @param {Array<number>} [id] 
         * @param {Array<string>} [status] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [receivers] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInternalTransfersOrdersInternalTransfersGet(id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseOrderListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInternalTransfersOrdersInternalTransfersGet(id, status, companies, warehouses, receivers, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Item Delivery Subscriptions
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [items] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemDeliverySubscriptionsItemDeliverySubscriptionsGet(warehouses?: Array<number>, items?: Array<number>, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseItemDeliverySubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemDeliverySubscriptionsItemDeliverySubscriptionsGet(warehouses, items, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Order Execution Percentage
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderExecutionPercentageOrdersOrderIdExecutionPercentageGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderExecutionPercentageOrdersOrderIdExecutionPercentageGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Orders For Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersForCollectionLegalEntitiesIdOrdersForCollectionAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersForCollectionLegalEntitiesIdOrdersForCollectionAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Orders For Distribution Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersForDistributionAmountLegalEntitiesIdOrdersForDistributionAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersForDistributionAmountLegalEntitiesIdOrdersForDistributionAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Overdue Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOverduePaymentsAmountLegalEntitiesIdOverduePaymentsAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOverduePaymentsAmountLegalEntitiesIdOverduePaymentsAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Pending Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingPaymentsAmountLegalEntitiesIdPendingPaymentsAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingPaymentsAmountLegalEntitiesIdPendingPaymentsAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCheckHealthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheckHealthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Load Info
         * @param {string} vatNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadInfoLegalEntitiesLoadInfoPost(vatNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadInfoLegalEntitiesLoadInfoPost(vatNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Order
         * @param {number} orderId 
         * @param {PatchOrderRequest} patchOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOrderOrdersOrderIdPatch(orderId: number, patchOrderRequest: PatchOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchOrderOrdersOrderIdPatch(orderId, patchOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readActOfAcceptanceActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActOfAcceptanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readActOfAcceptanceByIdActsOfAcceptanceActOfAcceptanceIdDocumentGet(actOfAcceptanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceByIdActsOfAcceptanceActOfAcceptanceIdDocumentGet(actOfAcceptanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readActOfAcceptanceItemsActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseActOfAcceptanceItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceItemsActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Active Order Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readActiveOrderItemsItemsIdActiveOrderItemsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseActiveOrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActiveOrderItemsItemsIdActiveOrderItemsGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {Array<number>} [id] 
         * @param {string} [fromCreatedAt] 
         * @param {string} [toCreatedAt] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [users] 
         * @param {Array<string>} [orderType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readActsOfAcceptanceActsOfAcceptanceGet(id?: Array<number>, fromCreatedAt?: string, toCreatedAt?: string, receivers?: Array<number>, legalEntities?: Array<number>, companies?: Array<number>, users?: Array<number>, orderType?: Array<string>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseActOfAcceptanceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActsOfAcceptanceActsOfAcceptanceGet(id, fromCreatedAt, toCreatedAt, receivers, legalEntities, companies, users, orderType, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Acts Of Acceptance By Manufacturer Export Id
         * @param {number} manufacturerExportId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readActsOfAcceptanceByManufacturerExportIdManufacturerExportsManufacturerExportIdActsOfAcceptanceGet(manufacturerExportId: number, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseActOfAcceptanceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActsOfAcceptanceByManufacturerExportIdManufacturerExportsManufacturerExportIdActsOfAcceptanceGet(manufacturerExportId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Aggregated Item Movements
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [partners] 
         * @param {Array<number>} [warehouses] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAggregatedItemMovementsReportsItemMovementsBalanceGet(fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseItemMovementBalanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAggregatedItemMovementsReportsItemMovementsBalanceGet(fromDate, toDate, partners, warehouses, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All Item Batches
         * @param {number} itemId 
         * @param {Array<number>} [warehouses] 
         * @param {boolean} [skipDepleted] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllItemBatchesItemsItemIdBatchesGet(itemId: number, warehouses?: Array<number>, skipDepleted?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseItemDeliveryBatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllItemBatchesItemsItemIdBatchesGet(itemId, warehouses, skipDepleted, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Availabilities
         * @param {Array<number>} [items] 
         * @param {Array<number>} [warehouses] 
         * @param {boolean} [realTime] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAvailabilitiesAvailabilitiesGet(items?: Array<number>, warehouses?: Array<number>, realTime?: boolean, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseAvailabilityListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAvailabilitiesAvailabilitiesGet(items, warehouses, realTime, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Batch Infos
         * @param {Array<number>} [id] 
         * @param {Array<number>} [items] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readBatchInfosBatchInfosGet(id?: Array<number>, items?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseBatchInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readBatchInfosBatchInfosGet(id, items, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Client By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readClientByIdClientsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readClientByIdClientsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Client Devices
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readClientDevicesClientsIdDevicesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseClientDeviceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readClientDevicesClientsIdDevicesGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Client Favorites
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readClientFavoritesClientsIdFavoritesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseFavoriteItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readClientFavoritesClientsIdFavoritesGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Client Shopping Carts
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readClientShoppingCartsClientsIdShoppingCartsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseClientShoppingCartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readClientShoppingCartsClientsIdShoppingCartsGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Clients
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {Array<number>} [partners] 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readClientsClientsGet(id?: Array<number>, showInactive?: boolean, partners?: Array<number>, name?: string, email?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readClientsClientsGet(id, showInactive, partners, name, email, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Collected Batch Items
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCollectedBatchItemsOrdersOrderIdCollectedBatchItemsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CollectedBatchItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCollectedBatchItemsOrdersOrderIdCollectedBatchItemsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Companies
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [showInactive] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCompaniesCompaniesGet(id?: Array<number>, query?: string, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseCompanyListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCompaniesCompaniesGet(id, query, showInactive, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Company By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCompanyByIdCompaniesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCompanyByIdCompaniesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Company Categories Paginated
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [leafOnly] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCompanyCategoriesPaginatedCompanyCategoriesGet(id?: Array<number>, query?: string, leafOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseCompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCompanyCategoriesPaginatedCompanyCategoriesGet(id, query, leafOnly, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Company Category By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCompanyCategoryByIdCompanyCategoriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCompanyCategoryByIdCompanyCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Credit Notes
         * @param {number} invoiceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCreditNotesInvoicesInvoiceIdCreditNotesGet(invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseInvoiceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCreditNotesInvoicesInvoiceIdCreditNotesGet(invoiceId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryBatchOrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryBatchOrdersOrderIdDocumentsDeliveryBatchGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryDeliveriesDeliveryIdGet(deliveryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryDeliveriesDeliveryIdGet(deliveryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Document Credit Note Items
         * @param {number} deliveryDocumentId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryDocumentCreditNoteItemsDeliveryDocumentsDeliveryDocumentIdCreditNoteItemsGet(deliveryDocumentId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseDeliveryDocumentCreditNoteItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryDocumentCreditNoteItemsDeliveryDocumentsDeliveryDocumentIdCreditNoteItemsGet(deliveryDocumentId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Document Remaining Amount
         * @param {number} deliveryDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryDocumentRemainingAmountDeliveryDocumentsDeliveryDocumentIdRemainingAmountGet(deliveryDocumentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryDocumentRemainingAmountDeliveryDocumentsDeliveryDocumentIdRemainingAmountGet(deliveryDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Documents
         * @param {number} deliveryId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryDocumentsDeliveriesDeliveryIdDocumentsGet(deliveryId: number, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseDeliveryDocumentListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryDocumentsDeliveriesDeliveryIdDocumentsGet(deliveryId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Documents
         * @param {string} [fromInvoiceDate] 
         * @param {string} [toInvoiceDate] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [companies] 
         * @param {Array<string>} [documentType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryDocumentsDeliveryDocumentsGet(fromInvoiceDate?: string, toInvoiceDate?: string, suppliers?: Array<number>, companies?: Array<number>, documentType?: Array<string>, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseDeliveryDocumentListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryDocumentsDeliveryDocumentsGet(fromInvoiceDate, toInvoiceDate, suppliers, companies, documentType, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {boolean} [hidePaid] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryInvoicesReportsDeliveryInvoiceGet(fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseReportDeliveryDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryInvoicesReportsDeliveryInvoiceGet(fromDate, toDate, suppliers, companies, hidePaid, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {number} deliveryId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryItemsDeliveriesDeliveryIdItemsGet(deliveryId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseDeliveryItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryItemsDeliveriesDeliveryIdItemsGet(deliveryId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [supplierId] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [itemCategories] 
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryItemsReportsDeliveryItemsGet(fromDeliveryDate?: string, toDeliveryDate?: string, supplierId?: number, companies?: Array<number>, warehouses?: Array<number>, itemCategories?: Array<number>, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseReportDeliveryItemExtendedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryItemsReportsDeliveryItemsGet(fromDeliveryDate, toDeliveryDate, supplierId, companies, warehouses, itemCategories, itemId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Remaining Amount
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryRemainingAmountDeliveriesDeliveryIdRemainingAmountGet(deliveryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryRemainingAmountDeliveriesDeliveryIdRemainingAmountGet(deliveryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Request By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryRequestByIdDeliveryRequestsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryRequestByIdDeliveryRequestsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Request Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryRequestItemsDeliveryRequestsIdItemsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseDeliveryRequestItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryRequestItemsDeliveryRequestsIdItemsGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Request Stock Receiving Process
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryRequestStockReceivingProcessDeliveryRequestsIdProcessGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockReceivingProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryRequestStockReceivingProcessDeliveryRequestsIdProcessGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Requests
         * @param {Array<number>} [id] 
         * @param {string} [fromCreated] 
         * @param {string} [toCreated] 
         * @param {string} [fromDelivery] 
         * @param {string} [toDelivery] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [warehouses] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryRequestsDeliveryRequestsGet(id?: Array<number>, fromCreated?: string, toCreated?: string, fromDelivery?: string, toDelivery?: string, suppliers?: Array<number>, warehouses?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseDeliveryRequestListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryRequestsDeliveryRequestsGet(id, fromCreated, toCreated, fromDelivery, toDelivery, suppliers, warehouses, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Depleted Amounts With Prices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDepletedAmountsWithPricesReportsDepletedAmountsWithPricesGet(fromDate?: string, toDate?: string, receivers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseReportDepletedAmountWithPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDepletedAmountsWithPricesReportsDepletedAmountsWithPricesGet(fromDate, toDate, receivers, companies, warehouses, itemCategories, itemId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Group By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readGroupByIdGroupsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readGroupByIdGroupsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Groups
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readGroupsGroupsGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readGroupsGroupsGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readInvoiceByIdInvoicesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceByIdInvoicesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readInvoiceDocumentInvoicesInvoiceIdDocumentGet(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceDocumentInvoicesInvoiceIdDocumentGet(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice Orders
         * @param {number} invoiceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readInvoiceOrdersInvoicesInvoiceIdOrdersGet(invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseOrderListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceOrdersInvoicesInvoiceIdOrdersGet(invoiceId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice Transactions
         * @param {number} invoiceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readInvoiceTransactionsInvoicesInvoiceIdTransactionsGet(invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseTransactionListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceTransactionsInvoicesInvoiceIdTransactionsGet(invoiceId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoices
         * @param {Array<number>} [id] 
         * @param {string} [fromCreatedAt] 
         * @param {string} [toCreatedAt] 
         * @param {Array<number>} [legalEntities] 
         * @param {Array<number>} [companies] 
         * @param {boolean} [hideCancelled] 
         * @param {boolean} [hidePaidInFull] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readInvoicesInvoicesGet(id?: Array<number>, fromCreatedAt?: string, toCreatedAt?: string, legalEntities?: Array<number>, companies?: Array<number>, hideCancelled?: boolean, hidePaidInFull?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseInvoiceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoicesInvoicesGet(id, fromCreatedAt, toCreatedAt, legalEntities, companies, hideCancelled, hidePaidInFull, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Item By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemByIdItemsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemByIdItemsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Item Categories Paginated
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [leafOnly] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemCategoriesPaginatedItemCategoriesGet(id?: Array<number>, query?: string, leafOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemCategoriesPaginatedItemCategoriesGet(id, query, leafOnly, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Item Category By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemCategoryByIdItemCategoriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemCategoryByIdItemCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Item Delivery Subscription By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemDeliverySubscriptionByIdItemDeliverySubscriptionsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemDeliverySubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemDeliverySubscriptionByIdItemDeliverySubscriptionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Items By Partner
         * @param {number} partnerId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsByPartnerPartnersPartnerIdItemsGet(partnerId: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsByPartnerPartnersPartnerIdItemsGet(partnerId, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Items
         * @param {Array<number>} [id] 
         * @param {Array<string>} [query] 
         * @param {Array<number>} [categories] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [manufacturers] 
         * @param {number} [partner] 
         * @param {boolean} [showInactive] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsItemsGet(id?: Array<number>, query?: Array<string>, categories?: Array<number>, suppliers?: Array<number>, manufacturers?: Array<number>, partner?: number, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseItemListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsItemsGet(id, query, categories, suppliers, manufacturers, partner, showInactive, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Last Delivery Country
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readLastDeliveryCountryItemsIdLastDeliveryCountryGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readLastDeliveryCountryItemsIdLastDeliveryCountryGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Last Delivery Price
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readLastDeliveryPriceItemsIdLastDeliveryPriceGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readLastDeliveryPriceItemsIdLastDeliveryPriceGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Legal Entities By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readLegalEntitiesByCompanyIdCompaniesCompanyIdLegalEntitiesGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseLegalEntityListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readLegalEntitiesByCompanyIdCompaniesCompanyIdLegalEntitiesGet(companyId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Legal Entities
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [company] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readLegalEntitiesLegalEntitiesGet(id?: Array<number>, showInactive?: boolean, query?: string, company?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseLegalEntityListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readLegalEntitiesLegalEntitiesGet(id, showInactive, query, company, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Legal Entity By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readLegalEntityByIdLegalEntitiesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readLegalEntityByIdLegalEntitiesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Linked Item Associations For Item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readLinkedItemAssociationsForItemItemsItemIdLinkedItemAssociationGet(itemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LinkedItemAssociationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readLinkedItemAssociationsForItemItemsItemIdLinkedItemAssociationGet(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read List
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readListPermissionsGet(page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePermissionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readListPermissionsGet(page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Manifacturer By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readManifacturerByIdManufacturersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readManifacturerByIdManufacturersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Manufacturer Export By Id
         * @param {number} manufacturerExportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readManufacturerExportByIdManufacturerExportsManufacturerExportIdGet(manufacturerExportId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readManufacturerExportByIdManufacturerExportsManufacturerExportIdGet(manufacturerExportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Manufacturer Exports
         * @param {Array<number>} [manufacturers] 
         * @param {string} [fromCreatedDate] 
         * @param {string} [toCreatedDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readManufacturerExportsManufacturerExportsGet(manufacturers?: Array<number>, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseManufacturerExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readManufacturerExportsManufacturerExportsGet(manufacturers, fromCreatedDate, toCreatedDate, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Manufacturer Exports
         * @param {number} manufacturerId 
         * @param {string} [fromCreatedDate] 
         * @param {string} [toCreatedDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readManufacturerExportsManufacturersManufacturerIdExportsGet(manufacturerId: number, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseManufacturerExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readManufacturerExportsManufacturersManufacturerIdExportsGet(manufacturerId, fromCreatedDate, toCreatedDate, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Manufacturer Items
         * @param {number} manufacturerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readManufacturerItemsManufacturersManufacturerIdItemsGet(manufacturerId: number, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseItemListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readManufacturerItemsManufacturersManufacturerIdItemsGet(manufacturerId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Manufacturers
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readManufacturersManufacturersGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseManufacturerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readManufacturersManufacturersGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderItemOrdersOrderIdItemsIdGet(orderId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderItemOrdersOrderIdItemsIdGet(orderId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderItemOrdersOrderIdItemsIdGet_3(orderId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderItemOrdersOrderIdItemsIdGet_3(orderId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Items
         * @param {number} orderId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderItemsOrdersOrderIdItemsGet(orderId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseOrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderItemsOrdersOrderIdItemsGet(orderId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Items
         * @param {number} orderId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderItemsOrdersOrderIdItemsGet_4(orderId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseOrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderItemsOrdersOrderIdItemsGet_4(orderId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderOrdersOrderIdGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderOrdersOrderIdGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Status Logs
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderStatusLogsOrdersOrderIdStatusLogsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderStatusLogResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderStatusLogsOrdersOrderIdStatusLogsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderingPolicyLegalEntitiesIdOrderingPolicyGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderingPolicyLegalEntitiesIdOrderingPolicyGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderingPolicyPartnersIdOrderingPolicyGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderingPolicyPartnersIdOrderingPolicyGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Orders Balance
         * @param {string} [statusKeys] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrdersBalanceReportsOrdersBalanceGet(statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseReportOrderBalanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrdersBalanceReportsOrdersBalanceGet(statusKeys, companies, warehouses, fromDate, toDate, noInvoiceOnly, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Partner Bonus Quantities
         * @param {Array<number>} [items] 
         * @param {Array<number>} [partners] 
         * @param {string} [fromStartDate] 
         * @param {string} [toStartDate] 
         * @param {string} [fromEndDate] 
         * @param {string} [toEndDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPartnerBonusQuantitiesPromotionsBonusQuantityGet(items?: Array<number>, partners?: Array<number>, fromStartDate?: string, toStartDate?: string, fromEndDate?: string, toEndDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePartnerBonusQuantityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPartnerBonusQuantitiesPromotionsBonusQuantityGet(items, partners, fromStartDate, toStartDate, fromEndDate, toEndDate, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Partner By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPartnerByIdPartnersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPartnerByIdPartnersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Partner Item Configurations
         * @param {number} itemId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPartnerItemConfigurationsItemsItemIdPartnerConfigurationsGet(itemId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseItemPartnerConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPartnerItemConfigurationsItemsItemIdPartnerConfigurationsGet(itemId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Partner Item Configurations
         * @param {number} partnerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPartnerItemConfigurationsPartnersPartnerIdItemConfigurationGet(partnerId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePartnerItemConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPartnerItemConfigurationsPartnersPartnerIdItemConfigurationGet(partnerId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPartnersPartnerIdItemCategoryAssociationGet(partnerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPartnersPartnerIdItemCategoryAssociationGet(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPartnersPartnerIdItemCategoryAssociationGet_5(partnerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPartnersPartnerIdItemCategoryAssociationGet_5(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Partners
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPartnersPartnersGet(id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePartnerListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPartnersPartnersGet(id, showInactive, query, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Payment Option By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPaymentOptionByIdPaymentOptionsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPaymentOptionByIdPaymentOptionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Payment Options By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPaymentOptionsByCompanyIdCompaniesCompanyIdPaymentOptionsGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePaymentOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPaymentOptionsByCompanyIdCompaniesCompanyIdPaymentOptionsGet(companyId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Payment Options
         * @param {Array<number>} [id] 
         * @param {number} [company] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPaymentOptionsPaymentOptionsGet(id?: Array<number>, company?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePaymentOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPaymentOptionsPaymentOptionsGet(id, company, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Portal User By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPortalUserByIdPortalUsersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortalUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPortalUserByIdPortalUsersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Portal Users
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPortalUsersPortalUsersGet(id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePortalUserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPortalUsersPortalUsersGet(id, showInactive, query, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policies By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPoliciesByCompanyIdCompaniesCompanyIdPricingPoliciesGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePricingPolicySimpleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPoliciesByCompanyIdCompaniesCompanyIdPricingPoliciesGet(companyId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policies
         * @param {Array<number>} [id] 
         * @param {Array<number>} [companies] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPoliciesPricingPoliciesGet(id?: Array<number>, companies?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePricingPolicyListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPoliciesPricingPoliciesGet(id, companies, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPolicyByIdPricingPoliciesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyByIdPricingPoliciesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPolicyItemCategoriesPricingPoliciesIdItemCategoriesGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PricingPolicyItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemCategoriesPricingPoliciesIdItemCategoriesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Category Multipliers
         * @param {string} itemCategories 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPolicyItemCategoryMultipliersReportsPricingPolicyItemCategoryMultipliersGet(itemCategories: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportPricingPolicyItemCategoryMultipliersListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemCategoryMultipliersReportsPricingPolicyItemCategoryMultipliersGet(itemCategories, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Exceptions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPolicyItemExceptionsPricingPoliciesIdItemExceptionsGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PricingPolicyItemExceptionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemExceptionsPricingPoliciesIdItemExceptionsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Prices
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPolicyItemPricesReportsPricingPolicyItemPricesGet(items: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportPricingPolicyItemPricesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemPricesReportsPricingPolicyItemPricesGet(items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Print Document Queue
         * @param {string} [state] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPrintDocumentQueuePrintDocumentQueueGet(state?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePrintDocumentQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPrintDocumentQueuePrintDocumentQueueGet(state, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Promotion By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPromotionByIdPromotionsBonusQuantityIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerBonusQuantityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPromotionByIdPromotionsBonusQuantityIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Role By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readRoleByIdRolesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readRoleByIdRolesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Roles
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readRolesRolesGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseRoleListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readRolesRolesGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Sales
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [itemCategories] 
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSalesReportsSalesGet(fromDeliveryDate?: string, toDeliveryDate?: string, receivers?: Array<number>, legalEntities?: Array<number>, companies?: Array<number>, warehouses?: Array<number>, itemCategories?: Array<number>, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseReportSalesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSalesReportsSalesGet(fromDeliveryDate, toDeliveryDate, receivers, legalEntities, companies, warehouses, itemCategories, itemId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Shipping Route By Id
         * @param {number} shippingRouteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readShippingRouteByIdShippingRoutesShippingRouteIdGet(shippingRouteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShippingRouteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readShippingRouteByIdShippingRoutesShippingRouteIdGet(shippingRouteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Shipping Route Orders
         * @param {number} shippingRouteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readShippingRouteOrdersShippingRoutesShippingRouteIdOrdersGet(shippingRouteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseOrderListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readShippingRouteOrdersShippingRoutesShippingRouteIdOrdersGet(shippingRouteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Shipping Routes
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [users] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readShippingRoutesShippingRoutesGet(fromDate?: string, toDate?: string, users?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseShippingRouteListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readShippingRoutesShippingRoutesGet(fromDate, toDate, users, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Shopping Cart By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readShoppingCartByIdShoppingCartsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientShoppingCartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readShoppingCartByIdShoppingCartsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Shopping Cart Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readShoppingCartItemsShoppingCartsIdItemsGet(id: number, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseClientShoppingCartItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readShoppingCartItemsShoppingCartsIdItemsGet(id, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Shopping Carts
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readShoppingCartsShoppingCartsGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseClientShoppingCartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readShoppingCartsShoppingCartsGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Similar Item Associations For Item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSimilarItemAssociationsForItemItemsItemIdSimilarItemAssociationGet(itemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimilarItemAssociationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSimilarItemAssociationsForItemItemsItemIdSimilarItemAssociationGet(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stock By Deliveries
         * @param {number} [companyId] 
         * @param {number} [warehouseId] 
         * @param {number} [itemId] 
         * @param {number} [manufacturerId] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [itemCategories] 
         * @param {boolean} [hideDepleted] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStockByDeliveriesReportsStockByDeliveriesGet(companyId?: number, warehouseId?: number, itemId?: number, manufacturerId?: number, suppliers?: Array<number>, itemCategories?: Array<number>, hideDepleted?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseReportDeliveryItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockByDeliveriesReportsStockByDeliveriesGet(companyId, warehouseId, itemId, manufacturerId, suppliers, itemCategories, hideDepleted, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stock Receiving Process By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStockReceivingProcessByIdStockReceivingProcessesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockReceivingProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockReceivingProcessByIdStockReceivingProcessesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stock Receiving Process Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStockReceivingProcessItemsStockReceivingProcessesIdItemsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseStockReceivingProcessItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockReceivingProcessItemsStockReceivingProcessesIdItemsGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stock Receiving Processes
         * @param {Array<number>} [id] 
         * @param {string} [fromCreated] 
         * @param {string} [toCreated] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStockReceivingProcessesStockReceivingProcessesGet(id?: Array<number>, fromCreated?: string, toCreated?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseStockReceivingProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockReceivingProcessesStockReceivingProcessesGet(id, fromCreated, toCreated, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stock Taking Report Items
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStockTakingReportItemsStockTakingReportItemsGet(itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseStockTakingReportItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockTakingReportItemsStockTakingReportItemsGet(itemId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stock Taking Report
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStockTakingReportStockTakingReportsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockTakingReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockTakingReportStockTakingReportsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stock Taking Reports
         * @param {string} [fromExecutionDate] 
         * @param {string} [toExecutionDate] 
         * @param {number} [userId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStockTakingReportsStockTakingReportsGet(fromExecutionDate?: string, toExecutionDate?: string, userId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseStockTakingReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockTakingReportsStockTakingReportsGet(fromExecutionDate, toExecutionDate, userId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stocktaking By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStocktakingByIdStocktakingsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StocktakingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStocktakingByIdStocktakingsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stocktaking Tasks
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStocktakingTasksStocktakingsIdTasksGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseStockTakingTaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStocktakingTasksStocktakingsIdTasksGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stocktakings
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [warehouses] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStocktakingsStocktakingsGet(fromDate?: string, toDate?: string, warehouses?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseStocktakingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStocktakingsStocktakingsGet(fromDate, toDate, warehouses, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Supplier By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSupplierByIdSuppliersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSupplierByIdSuppliersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Supplier Price Differences
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSupplierPriceDifferencesSuppliersIdPriceDifferencesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseSupplierPriceDifferenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSupplierPriceDifferencesSuppliersIdPriceDifferencesGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Supplier Quantity Differences
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSupplierQuantityDifferencesSuppliersIdQuantityDifferencesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseSupplierQuantityDifferenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSupplierQuantityDifferencesSuppliersIdQuantityDifferencesGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Supplier Reserve Subscription By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSupplierReserveSubscriptionByIdSupplierReserveSubscriptionsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierReserveSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSupplierReserveSubscriptionByIdSupplierReserveSubscriptionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Supplier Reserve Subscriptions
         * @param {Array<number>} [id] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<string>} [weekday] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSupplierReserveSubscriptionsSupplierReserveSubscriptionsGet(id?: Array<number>, suppliers?: Array<number>, weekday?: Array<string>, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseSupplierReserveSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSupplierReserveSubscriptionsSupplierReserveSubscriptionsGet(id, suppliers, weekday, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Suppliers
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [showInactive] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSuppliersSuppliersGet(id?: Array<number>, query?: string, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseSupplierListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSuppliersSuppliersGet(id, query, showInactive, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Transactions
         * @param {Array<number>} [id] 
         * @param {number} [invoiceId] 
         * @param {number} [deliveryInvoiceId] 
         * @param {Array<number>} [treasuries] 
         * @param {string} [fromCreatedDate] 
         * @param {string} [toCreatedDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTransactionsTransactionsGet(id?: Array<number>, invoiceId?: number, deliveryInvoiceId?: number, treasuries?: Array<number>, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseTransactionListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTransactionsTransactionsGet(id, invoiceId, deliveryInvoiceId, treasuries, fromCreatedDate, toCreatedDate, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Treasuries
         * @param {Array<number>} [id] 
         * @param {number} [companyId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTreasuriesTreasuriesGet(id?: Array<number>, companyId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseTreasuryListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTreasuriesTreasuriesGet(id, companyId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Treasury Bank Account Amount
         * @param {number} treasuryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTreasuryBankAccountAmountTreasuriesTreasuryIdBankAccountAmountGet(treasuryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTreasuryBankAccountAmountTreasuriesTreasuryIdBankAccountAmountGet(treasuryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Treasury By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTreasuryByIdTreasuriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreasuryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTreasuryByIdTreasuriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Treasury Cash Amount
         * @param {number} treasuryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTreasuryCashAmountTreasuriesTreasuryIdCashAmountGet(treasuryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTreasuryCashAmountTreasuriesTreasuryIdCashAmountGet(treasuryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Treasury Transactions
         * @param {number} treasuryId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTreasuryTransactionsTreasuriesTreasuryIdTransactionsGet(treasuryId: number, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseTransactionListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTreasuryTransactionsTreasuriesTreasuryIdTransactionsGet(treasuryId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read User By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUserByIdUsersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUserByIdUsersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Users By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUsersByCompanyIdCompaniesCompanyIdUsersGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseUserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUsersByCompanyIdCompaniesCompanyIdUsersGet(companyId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Users
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {number} [company] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUsersUsersGet(id?: Array<number>, showInactive?: boolean, company?: number, query?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseUserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUsersUsersGet(id, showInactive, company, query, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Warehouse By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readWarehouseByIdWarehousesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readWarehouseByIdWarehousesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Warehouse Movements
         * @param {Array<number>} [id] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [items] 
         * @param {string} [fromExpirationDate] 
         * @param {string} [toExpirationDate] 
         * @param {string} [batchNumber] 
         * @param {string} [movementType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readWarehouseMovementsWarehouseMovementsGet(id?: Array<number>, warehouses?: Array<number>, items?: Array<number>, fromExpirationDate?: string, toExpirationDate?: string, batchNumber?: string, movementType?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseWarehouseMovementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readWarehouseMovementsWarehouseMovementsGet(id, warehouses, items, fromExpirationDate, toExpirationDate, batchNumber, movementType, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Warehouse Stock By Orders
         * @param {number} warehouseId 
         * @param {string} toDate 
         * @param {boolean} [hideOrdersWithoutDeliveryDate] 
         * @param {boolean} [hideAvailable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readWarehouseStockByOrdersReportsStockByOrdersGet(warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseReportDeliveryItemByOrdersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readWarehouseStockByOrdersReportsStockByOrdersGet(warehouseId, toDate, hideOrdersWithoutDeliveryDate, hideAvailable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Warehouses
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readWarehousesWarehousesGet(id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseWarehouseListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readWarehousesWarehousesGet(id, showInactive, query, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setOrderingPolicyLegalEntitiesIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setOrderingPolicyLegalEntitiesIdOrderingPolicyPut(id, setOrderingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setOrderingPolicyPartnersIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setOrderingPolicyPartnersIdOrderingPolicyPut(id, setOrderingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set Partner Integration Settings
         * @param {number} partnerId 
         * @param {Request1} request1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost(partnerId: number, request1: Request1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost(partnerId, request1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId, startStockReceivingProcessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Test Barsy Connection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testBarsyConnectionPartnersIdTestBarsyConnectionPost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestConnectionDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testBarsyConnectionPartnersIdTestBarsyConnectionPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Client
         * @param {number} id 
         * @param {UpdateClientRequest} updateClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientClientsIdPatch(id: number, updateClientRequest: UpdateClientRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientClientsIdPatch(id, updateClientRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Company Category
         * @param {number} id 
         * @param {SaveCompanyCategoryRequest} saveCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyCategoryCompanyCategoriesIdPut(id: number, saveCompanyCategoryRequest: SaveCompanyCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyCategoryCompanyCategoriesIdPut(id, saveCompanyCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Company
         * @param {number} id 
         * @param {UpdateCompanyRequest} updateCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyCompaniesIdPut(id: number, updateCompanyRequest: UpdateCompanyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyCompaniesIdPut(id, updateCompanyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Delivery Document
         * @param {number} deliveryDocumentId 
         * @param {UpdateDeliveryDocumentRequest} updateDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut(deliveryDocumentId: number, updateDeliveryDocumentRequest: UpdateDeliveryDocumentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut(deliveryDocumentId, updateDeliveryDocumentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Delivery Request
         * @param {number} id 
         * @param {UpdateDeliveryRequestRequest} updateDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeliveryRequestDeliveryRequestsIdPut(id: number, updateDeliveryRequestRequest: UpdateDeliveryRequestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeliveryRequestDeliveryRequestsIdPut(id, updateDeliveryRequestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Group
         * @param {number} id 
         * @param {UpdateGroupRequest} updateGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroupGroupsIdPut(id: number, updateGroupRequest: UpdateGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroupGroupsIdPut(id, updateGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Item Category
         * @param {number} id 
         * @param {SaveItemCategoryRequest} saveItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateItemCategoryItemCategoriesIdPut(id: number, saveItemCategoryRequest: SaveItemCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateItemCategoryItemCategoriesIdPut(id, saveItemCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Item Delivery Subscription
         * @param {number} id 
         * @param {PatchItemDeliverySubscriptionRequest} patchItemDeliverySubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut(id: number, patchItemDeliverySubscriptionRequest: PatchItemDeliverySubscriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemDeliverySubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut(id, patchItemDeliverySubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Item
         * @param {number} id 
         * @param {SaveItemRequest} saveItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateItemItemsIdPut(id: number, saveItemRequest: SaveItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateItemItemsIdPut(id, saveItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Legal Entity
         * @param {number} id 
         * @param {SaveLegalEntityRequest} saveLegalEntityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLegalEntityLegalEntitiesIdPut(id: number, saveLegalEntityRequest: SaveLegalEntityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLegalEntityLegalEntitiesIdPut(id, saveLegalEntityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Manufacturer
         * @param {number} id 
         * @param {SaveManufacturerRequest} saveManufacturerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManufacturerManufacturersIdPut(id: number, saveManufacturerRequest: SaveManufacturerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateManufacturerManufacturersIdPut(id, saveManufacturerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderItemOrdersOrderIdItemsIdPut(orderId: number, id: number, saveOrderItemRequest: SaveOrderItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderItemOrdersOrderIdItemsIdPut(orderId, id, saveOrderItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderItemOrdersOrderIdItemsIdPut_6(orderId: number, id: number, saveOrderItemRequest: SaveOrderItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderItemOrdersOrderIdItemsIdPut_6(orderId, id, saveOrderItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Partner Bonus Quantity
         * @param {number} id 
         * @param {PatchPartnerBonusQuantityRequest} patchPartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePartnerBonusQuantityPromotionsBonusQuantityIdPut(id: number, patchPartnerBonusQuantityRequest: PatchPartnerBonusQuantityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerBonusQuantityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartnerBonusQuantityPromotionsBonusQuantityIdPut(id, patchPartnerBonusQuantityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Partner Item Configuration
         * @param {number} id 
         * @param {SavePartnerItemConfigurationRequest} savePartnerItemConfigurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePartnerItemConfigurationPartnersItemConfigurationIdPut(id: number, savePartnerItemConfigurationRequest: SavePartnerItemConfigurationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerItemConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartnerItemConfigurationPartnersItemConfigurationIdPut(id, savePartnerItemConfigurationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Partner
         * @param {number} id 
         * @param {SavePartnerRequest} savePartnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePartnerPartnersIdPut(id: number, savePartnerRequest: SavePartnerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartnerPartnersIdPut(id, savePartnerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePartnersPartnerIdItemCategoryAssociationPost(partnerId: number, requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartnersPartnerIdItemCategoryAssociationPost(partnerId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePartnersPartnerIdItemCategoryAssociationPost_7(partnerId: number, requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartnersPartnerIdItemCategoryAssociationPost_7(partnerId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Payment Option
         * @param {number} id 
         * @param {SavePaymentOptionRequest} savePaymentOptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePaymentOptionPaymentOptionsIdPut(id: number, savePaymentOptionRequest: SavePaymentOptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePaymentOptionPaymentOptionsIdPut(id, savePaymentOptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {number} id 
         * @param {SavePricingPolicyRequest} savePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePricingPoliciesIdPut(id: number, savePricingPolicyRequest: SavePricingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePricingPoliciesIdPut(id, savePricingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Print Document Queue
         * @param {number} id 
         * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePrintDocumentQueuePrintDocumentQueueIdPatch(id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrintDocumentQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePrintDocumentQueuePrintDocumentQueueIdPatch(id, updatePrintDocumentQueueRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Role
         * @param {number} id 
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoleRolesIdPut(id: number, updateRoleRequest: UpdateRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoleRolesIdPut(id, updateRoleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {Request} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId: number, request: Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockReceivingProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Supplier Reserve Subscription
         * @param {number} id 
         * @param {PatchSupplierReserveSubscriptionRequest} patchSupplierReserveSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch(id: number, patchSupplierReserveSubscriptionRequest: PatchSupplierReserveSubscriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierReserveSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch(id, patchSupplierReserveSubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Supplier
         * @param {number} id 
         * @param {SaveSupplierRequest} saveSupplierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSupplierSuppliersIdPut(id: number, saveSupplierRequest: SaveSupplierRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSupplierSuppliersIdPut(id, saveSupplierRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Transaction
         * @param {number} id 
         * @param {PatchTransactionRequest} patchTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransactionTransactionsIdPut(id: number, patchTransactionRequest: PatchTransactionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransactionTransactionsIdPut(id, patchTransactionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Treasury
         * @param {number} id 
         * @param {PatchTreasuryRequest} patchTreasuryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTreasuryTreasuriesIdPut(id: number, patchTreasuryRequest: PatchTreasuryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreasuryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTreasuryTreasuriesIdPut(id, patchTreasuryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update User
         * @param {number} id 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserUsersIdPut(id: number, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserUsersIdPut(id, updateUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Warehouse
         * @param {number} id 
         * @param {SaveWarehouseRequest} saveWarehouseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWarehouseWarehousesIdPut(id: number, saveWarehouseRequest: SaveWarehouseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWarehouseWarehousesIdPut(id, saveWarehouseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AMSApi - factory interface
 * @export
 */
export const AMSApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AMSApiFp(configuration)
    return {
        /**
         * 
         * @summary Bulk Create Partner Bonus Quantity Promotions
         * @param {BulkCreatePartnerBonusQuantityRequest} bulkCreatePartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreatePartnerBonusQuantityPromotionsPromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest: BulkCreatePartnerBonusQuantityRequest, options?: any): AxiosPromise<Array<PartnerBonusQuantityResponse>> {
            return localVarFp.bulkCreatePartnerBonusQuantityPromotionsPromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel Invoice By Invoice Id
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInvoiceByInvoiceIdInvoicesInvoiceIdPatch(invoiceId: number, options?: any): AxiosPromise<InvoiceResponse> {
            return localVarFp.cancelInvoiceByInvoiceIdInvoicesInvoiceIdPatch(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Copy Partner Item Configurations
         * @param {number} partnerId 
         * @param {number} sourcePartnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost(partnerId: number, sourcePartnerId: number, options?: any): AxiosPromise<PaginatedListResponsePartnerItemConfigurationResponse> {
            return localVarFp.copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost(partnerId, sourcePartnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {CreateClientRequest} createClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientsPost(createClientRequest: CreateClientRequest, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.createClientsPost(createClientRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompaniesPost(createCompanyRequest: CreateCompanyRequest, options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.createCompaniesPost(createCompanyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {SaveCompanyCategoryRequest} saveCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyCategoriesPost(saveCompanyCategoryRequest: SaveCompanyCategoryRequest, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.createCompanyCategoriesPost(saveCompanyCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Delivery
         * @param {CreateDeliveryRequest} createDeliveryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryDeliveriesPost(createDeliveryRequest: CreateDeliveryRequest, options?: any): AxiosPromise<DeliveryResponse> {
            return localVarFp.createDeliveryDeliveriesPost(createDeliveryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Delivery Document
         * @param {CreateDeliveryDocumentRequest} createDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryDocumentDeliveryDocumentsPost(createDeliveryDocumentRequest: CreateDeliveryDocumentRequest, options?: any): AxiosPromise<DeliveryDocumentResponse> {
            return localVarFp.createDeliveryDocumentDeliveryDocumentsPost(createDeliveryDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Delivery Request
         * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
         * @param {boolean} [shouldSendEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryRequestDeliveryRequestsPost(createDeliveryRequestRequest: CreateDeliveryRequestRequest, shouldSendEmail?: boolean, options?: any): AxiosPromise<DeliveryRequestResponse> {
            return localVarFp.createDeliveryRequestDeliveryRequestsPost(createDeliveryRequestRequest, shouldSendEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Group
         * @param {CreateGroupRequest} createGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroupGroupsPost(createGroupRequest: CreateGroupRequest, options?: any): AxiosPromise<GroupResponse> {
            return localVarFp.createGroupGroupsPost(createGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {CreateInvoiceRequest} createInvoiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoicesPost(createInvoiceRequest: CreateInvoiceRequest, options?: any): AxiosPromise<InvoiceResponse> {
            return localVarFp.createInvoicesPost(createInvoiceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {SaveItemCategoryRequest} saveItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemCategoriesPost(saveItemCategoryRequest: SaveItemCategoryRequest, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.createItemCategoriesPost(saveItemCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Item Delivery Subscription
         * @param {CreateItemDeliverySubscriptionRequest} createItemDeliverySubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemDeliverySubscriptionItemDeliverySubscriptionsPost(createItemDeliverySubscriptionRequest: CreateItemDeliverySubscriptionRequest, options?: any): AxiosPromise<ItemDeliverySubscriptionResponse> {
            return localVarFp.createItemDeliverySubscriptionItemDeliverySubscriptionsPost(createItemDeliverySubscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {SaveItemRequest} saveItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsPost(saveItemRequest: SaveItemRequest, options?: any): AxiosPromise<ItemResponse> {
            return localVarFp.createItemsPost(saveItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {SaveLegalEntityRequest} saveLegalEntityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalEntitiesPost(saveLegalEntityRequest: SaveLegalEntityRequest, options?: any): AxiosPromise<LegalEntityResponse> {
            return localVarFp.createLegalEntitiesPost(saveLegalEntityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Linked Item Association
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId: number, linkedItemId: number, options?: any): AxiosPromise<LinkedItemAssociationResponse> {
            return localVarFp.createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId, linkedItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Manufacturer
         * @param {SaveManufacturerRequest} saveManufacturerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManufacturerManufacturersPost(saveManufacturerRequest: SaveManufacturerRequest, options?: any): AxiosPromise<ManufacturerResponse> {
            return localVarFp.createManufacturerManufacturersPost(saveManufacturerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Order Item
         * @param {number} orderId 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderItemOrdersOrderIdItemsPost(orderId: number, saveOrderItemRequest: SaveOrderItemRequest, options?: any): AxiosPromise<OrderItemResponse> {
            return localVarFp.createOrderItemOrdersOrderIdItemsPost(orderId, saveOrderItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Order Item
         * @param {number} orderId 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderItemOrdersOrderIdItemsPost_1(orderId: number, saveOrderItemRequest: SaveOrderItemRequest, options?: any): AxiosPromise<OrderItemResponse> {
            return localVarFp.createOrderItemOrdersOrderIdItemsPost_1(orderId, saveOrderItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderRequest} createOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderOrdersPost(createOrderRequest: CreateOrderRequest, options?: any): AxiosPromise<OrderResponse> {
            return localVarFp.createOrderOrdersPost(createOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Partner Item Configuration
         * @param {number} partnerId 
         * @param {SavePartnerItemConfigurationRequest} savePartnerItemConfigurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost(partnerId: number, savePartnerItemConfigurationRequest: SavePartnerItemConfigurationRequest, options?: any): AxiosPromise<PartnerItemConfigurationResponse> {
            return localVarFp.createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost(partnerId, savePartnerItemConfigurationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Partner
         * @param {SavePartnerRequest} savePartnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartnerPartnersPost(savePartnerRequest: SavePartnerRequest, options?: any): AxiosPromise<PartnerResponse> {
            return localVarFp.createPartnerPartnersPost(savePartnerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Payment Option
         * @param {SavePaymentOptionRequest} savePaymentOptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentOptionPaymentOptionsPost(savePaymentOptionRequest: SavePaymentOptionRequest, options?: any): AxiosPromise<PaymentOptionResponse> {
            return localVarFp.createPaymentOptionPaymentOptionsPost(savePaymentOptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Portal User
         * @param {CreatePortalUserRequest} createPortalUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPortalUserPortalUsersPost(createPortalUserRequest: CreatePortalUserRequest, options?: any): AxiosPromise<PortalUserResponse> {
            return localVarFp.createPortalUserPortalUsersPost(createPortalUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Pricing Policy
         * @param {SavePricingPolicyRequest} savePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPricingPolicyPricingPoliciesPost(savePricingPolicyRequest: SavePricingPolicyRequest, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.createPricingPolicyPricingPoliciesPost(savePricingPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Role
         * @param {CreateRoleRequest} createRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleRolesPost(createRoleRequest: CreateRoleRequest, options?: any): AxiosPromise<RoleResponse> {
            return localVarFp.createRoleRolesPost(createRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Similar Item Association
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId: number, similarItemId: number, options?: any): AxiosPromise<SimilarItemAssociationResponse> {
            return localVarFp.createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId, similarItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Stocktaking
         * @param {StocktakingRequest} stocktakingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStocktakingStocktakingsPost(stocktakingRequest: StocktakingRequest, options?: any): AxiosPromise<StocktakingResponse> {
            return localVarFp.createStocktakingStocktakingsPost(stocktakingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Supplier Reserve Subscription
         * @param {CreateSupplierReserveSubscriptionRequest} createSupplierReserveSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupplierReserveSubscriptionSupplierReserveSubscriptionsPost(createSupplierReserveSubscriptionRequest: CreateSupplierReserveSubscriptionRequest, options?: any): AxiosPromise<SupplierReserveSubscriptionResponse> {
            return localVarFp.createSupplierReserveSubscriptionSupplierReserveSubscriptionsPost(createSupplierReserveSubscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Supplier
         * @param {SaveSupplierRequest} saveSupplierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupplierSuppliersPost(saveSupplierRequest: SaveSupplierRequest, options?: any): AxiosPromise<SupplierResponse> {
            return localVarFp.createSupplierSuppliersPost(saveSupplierRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Transaction
         * @param {CreateTransactionRequest} createTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransactionTransactionsPost(createTransactionRequest: CreateTransactionRequest, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.createTransactionTransactionsPost(createTransactionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Treasury
         * @param {CreateTreasuryRequest} createTreasuryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTreasuryTreasuriesPost(createTreasuryRequest: CreateTreasuryRequest, options?: any): AxiosPromise<TreasuryResponse> {
            return localVarFp.createTreasuryTreasuriesPost(createTreasuryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create User
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserUsersPost(createUserRequest: CreateUserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.createUserUsersPost(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {SaveWarehouseRequest} saveWarehouseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWarehousesPost(saveWarehouseRequest: SaveWarehouseRequest, options?: any): AxiosPromise<WarehouseResponse> {
            return localVarFp.createWarehousesPost(saveWarehouseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Client
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClientClientsIdDelete(id: number, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.deleteClientClientsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Delivery
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeliveryDeliveriesIdDelete(id: number, options?: any): AxiosPromise<DeliveryResponse> {
            return localVarFp.deleteDeliveryDeliveriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} deliveryDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeliveryDocumentsDeliveryDocumentIdDelete(deliveryDocumentId: number, options?: any): AxiosPromise<DeliveryDocumentResponse> {
            return localVarFp.deleteDeliveryDocumentsDeliveryDocumentIdDelete(deliveryDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Item Delivery Subscription
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItemDeliverySubscriptionItemDeliverySubscriptionsIdDelete(id: number, options?: any): AxiosPromise<ItemDeliverySubscriptionResponse> {
            return localVarFp.deleteItemDeliverySubscriptionItemDeliverySubscriptionsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Linked Item Association
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId: number, linkedItemId: number, options?: any): AxiosPromise<LinkedItemAssociationResponse> {
            return localVarFp.deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId, linkedItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderItemOrdersOrderIdItemsIdDelete(orderId: number, id: number, options?: any): AxiosPromise<OrderItemResponse> {
            return localVarFp.deleteOrderItemOrdersOrderIdItemsIdDelete(orderId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderItemOrdersOrderIdItemsIdDelete_2(orderId: number, id: number, options?: any): AxiosPromise<OrderItemResponse> {
            return localVarFp.deleteOrderItemOrdersOrderIdItemsIdDelete_2(orderId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Partner Bonus Quantity
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerBonusQuantityPromotionsBonusQuantityIdDelete(id: number, options?: any): AxiosPromise<PartnerBonusQuantityResponse> {
            return localVarFp.deletePartnerBonusQuantityPromotionsBonusQuantityIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Partner Item Configuration
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerItemConfigurationPartnersItemConfigurationIdDelete(id: number, options?: any): AxiosPromise<PartnerItemConfigurationResponse> {
            return localVarFp.deletePartnerItemConfigurationPartnersItemConfigurationIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Similar Item Association
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId: number, similarItemId: number, options?: any): AxiosPromise<SimilarItemAssociationResponse> {
            return localVarFp.deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId, similarItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Supplier Reserve Subscription
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupplierReserveSubscriptionSupplierReserveSubscriptionsIdDelete(id: number, options?: any): AxiosPromise<SupplierReserveSubscriptionResponse> {
            return localVarFp.deleteSupplierReserveSubscriptionSupplierReserveSubscriptionsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Transaction
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransactionTransactionsIdDelete(id: number, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.deleteTransactionTransactionsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get B2B Orders
         * @param {Array<number>} [id] 
         * @param {Array<string>} [status] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getB2bOrdersOrdersB2bOrdersGet(id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, legalEntities?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseOrderListResponse> {
            return localVarFp.getB2bOrdersOrdersB2bOrdersGet(id, status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get B2C Orders
         * @param {Array<number>} [id] 
         * @param {Array<string>} [status] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getB2cOrdersOrdersB2cOrdersGet(id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, legalEntities?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseOrderListResponse> {
            return localVarFp.getB2cOrdersOrdersB2cOrdersGet(id, status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Deliveries
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [warehouses] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveriesDeliveriesGet(suppliers?: Array<number>, warehouses?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseDeliveryResponse> {
            return localVarFp.getDeliveriesDeliveriesGet(suppliers, warehouses, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Delivery Request Expected Total
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryRequestExpectedTotalDeliveryRequestsIdExpectedTotalGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getDeliveryRequestExpectedTotalDeliveryRequestsIdExpectedTotalGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Internal Transfers
         * @param {Array<number>} [id] 
         * @param {Array<string>} [status] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [receivers] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalTransfersOrdersInternalTransfersGet(id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseOrderListResponse> {
            return localVarFp.getInternalTransfersOrdersInternalTransfersGet(id, status, companies, warehouses, receivers, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Item Delivery Subscriptions
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [items] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemDeliverySubscriptionsItemDeliverySubscriptionsGet(warehouses?: Array<number>, items?: Array<number>, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseItemDeliverySubscriptionResponse> {
            return localVarFp.getItemDeliverySubscriptionsItemDeliverySubscriptionsGet(warehouses, items, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeMeGet(options?: any): AxiosPromise<UserAccessResponse> {
            return localVarFp.getMeMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Order Execution Percentage
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderExecutionPercentageOrdersOrderIdExecutionPercentageGet(orderId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getOrderExecutionPercentageOrdersOrderIdExecutionPercentageGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Orders For Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForCollectionLegalEntitiesIdOrdersForCollectionAmountGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getOrdersForCollectionLegalEntitiesIdOrdersForCollectionAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Orders For Distribution Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForDistributionAmountLegalEntitiesIdOrdersForDistributionAmountGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getOrdersForDistributionAmountLegalEntitiesIdOrdersForDistributionAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Overdue Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverduePaymentsAmountLegalEntitiesIdOverduePaymentsAmountGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getOverduePaymentsAmountLegalEntitiesIdOverduePaymentsAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Pending Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingPaymentsAmountLegalEntitiesIdPendingPaymentsAmountGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getPendingPaymentsAmountLegalEntitiesIdPendingPaymentsAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckHealthGet(options?: any): AxiosPromise<any> {
            return localVarFp.healthCheckHealthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Load Info
         * @param {string} vatNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadInfoLegalEntitiesLoadInfoPost(vatNumber: string, options?: any): AxiosPromise<CompanyInfo> {
            return localVarFp.loadInfoLegalEntitiesLoadInfoPost(vatNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Order
         * @param {number} orderId 
         * @param {PatchOrderRequest} patchOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrderOrdersOrderIdPatch(orderId: number, patchOrderRequest: PatchOrderRequest, options?: any): AxiosPromise<OrderResponse> {
            return localVarFp.patchOrderOrdersOrderIdPatch(orderId, patchOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActOfAcceptanceActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: any): AxiosPromise<ActOfAcceptanceResponse> {
            return localVarFp.readActOfAcceptanceActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActOfAcceptanceByIdActsOfAcceptanceActOfAcceptanceIdDocumentGet(actOfAcceptanceId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readActOfAcceptanceByIdActsOfAcceptanceActOfAcceptanceIdDocumentGet(actOfAcceptanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActOfAcceptanceItemsActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseActOfAcceptanceItemResponse> {
            return localVarFp.readActOfAcceptanceItemsActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Active Order Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActiveOrderItemsItemsIdActiveOrderItemsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseActiveOrderItemResponse> {
            return localVarFp.readActiveOrderItemsItemsIdActiveOrderItemsGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {Array<number>} [id] 
         * @param {string} [fromCreatedAt] 
         * @param {string} [toCreatedAt] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [users] 
         * @param {Array<string>} [orderType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActsOfAcceptanceActsOfAcceptanceGet(id?: Array<number>, fromCreatedAt?: string, toCreatedAt?: string, receivers?: Array<number>, legalEntities?: Array<number>, companies?: Array<number>, users?: Array<number>, orderType?: Array<string>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseActOfAcceptanceListResponse> {
            return localVarFp.readActsOfAcceptanceActsOfAcceptanceGet(id, fromCreatedAt, toCreatedAt, receivers, legalEntities, companies, users, orderType, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Acts Of Acceptance By Manufacturer Export Id
         * @param {number} manufacturerExportId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActsOfAcceptanceByManufacturerExportIdManufacturerExportsManufacturerExportIdActsOfAcceptanceGet(manufacturerExportId: number, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseActOfAcceptanceListResponse> {
            return localVarFp.readActsOfAcceptanceByManufacturerExportIdManufacturerExportsManufacturerExportIdActsOfAcceptanceGet(manufacturerExportId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Aggregated Item Movements
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [partners] 
         * @param {Array<number>} [warehouses] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAggregatedItemMovementsReportsItemMovementsBalanceGet(fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseItemMovementBalanceResponse> {
            return localVarFp.readAggregatedItemMovementsReportsItemMovementsBalanceGet(fromDate, toDate, partners, warehouses, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All Item Batches
         * @param {number} itemId 
         * @param {Array<number>} [warehouses] 
         * @param {boolean} [skipDepleted] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllItemBatchesItemsItemIdBatchesGet(itemId: number, warehouses?: Array<number>, skipDepleted?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseItemDeliveryBatchResponse> {
            return localVarFp.readAllItemBatchesItemsItemIdBatchesGet(itemId, warehouses, skipDepleted, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Availabilities
         * @param {Array<number>} [items] 
         * @param {Array<number>} [warehouses] 
         * @param {boolean} [realTime] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAvailabilitiesAvailabilitiesGet(items?: Array<number>, warehouses?: Array<number>, realTime?: boolean, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseAvailabilityListResponse> {
            return localVarFp.readAvailabilitiesAvailabilitiesGet(items, warehouses, realTime, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Batch Infos
         * @param {Array<number>} [id] 
         * @param {Array<number>} [items] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBatchInfosBatchInfosGet(id?: Array<number>, items?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseBatchInfoResponse> {
            return localVarFp.readBatchInfosBatchInfosGet(id, items, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Client By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientByIdClientsIdGet(id: number, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.readClientByIdClientsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Client Devices
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientDevicesClientsIdDevicesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseClientDeviceResponse> {
            return localVarFp.readClientDevicesClientsIdDevicesGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Client Favorites
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientFavoritesClientsIdFavoritesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseFavoriteItemResponse> {
            return localVarFp.readClientFavoritesClientsIdFavoritesGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Client Shopping Carts
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientShoppingCartsClientsIdShoppingCartsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseClientShoppingCartResponse> {
            return localVarFp.readClientShoppingCartsClientsIdShoppingCartsGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Clients
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {Array<number>} [partners] 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientsClientsGet(id?: Array<number>, showInactive?: boolean, partners?: Array<number>, name?: string, email?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseClientResponse> {
            return localVarFp.readClientsClientsGet(id, showInactive, partners, name, email, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Collected Batch Items
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCollectedBatchItemsOrdersOrderIdCollectedBatchItemsGet(orderId: number, options?: any): AxiosPromise<Array<CollectedBatchItemResponse>> {
            return localVarFp.readCollectedBatchItemsOrdersOrderIdCollectedBatchItemsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Companies
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [showInactive] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompaniesCompaniesGet(id?: Array<number>, query?: string, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseCompanyListResponse> {
            return localVarFp.readCompaniesCompaniesGet(id, query, showInactive, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Company By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanyByIdCompaniesIdGet(id: number, options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.readCompanyByIdCompaniesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Company Categories Paginated
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [leafOnly] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanyCategoriesPaginatedCompanyCategoriesGet(id?: Array<number>, query?: string, leafOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseCompanyCategoryResponse> {
            return localVarFp.readCompanyCategoriesPaginatedCompanyCategoriesGet(id, query, leafOnly, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Company Category By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanyCategoryByIdCompanyCategoriesIdGet(id: number, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.readCompanyCategoryByIdCompanyCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Credit Notes
         * @param {number} invoiceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCreditNotesInvoicesInvoiceIdCreditNotesGet(invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseInvoiceListResponse> {
            return localVarFp.readCreditNotesInvoicesInvoiceIdCreditNotesGet(invoiceId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryBatchOrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readDeliveryBatchOrdersOrderIdDocumentsDeliveryBatchGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDeliveriesDeliveryIdGet(deliveryId: number, options?: any): AxiosPromise<DeliveryResponse> {
            return localVarFp.readDeliveryDeliveriesDeliveryIdGet(deliveryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Document Credit Note Items
         * @param {number} deliveryDocumentId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentCreditNoteItemsDeliveryDocumentsDeliveryDocumentIdCreditNoteItemsGet(deliveryDocumentId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseDeliveryDocumentCreditNoteItemResponse> {
            return localVarFp.readDeliveryDocumentCreditNoteItemsDeliveryDocumentsDeliveryDocumentIdCreditNoteItemsGet(deliveryDocumentId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Document Remaining Amount
         * @param {number} deliveryDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentRemainingAmountDeliveryDocumentsDeliveryDocumentIdRemainingAmountGet(deliveryDocumentId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readDeliveryDocumentRemainingAmountDeliveryDocumentsDeliveryDocumentIdRemainingAmountGet(deliveryDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Documents
         * @param {number} deliveryId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentsDeliveriesDeliveryIdDocumentsGet(deliveryId: number, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseDeliveryDocumentListResponse> {
            return localVarFp.readDeliveryDocumentsDeliveriesDeliveryIdDocumentsGet(deliveryId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Documents
         * @param {string} [fromInvoiceDate] 
         * @param {string} [toInvoiceDate] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [companies] 
         * @param {Array<string>} [documentType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentsDeliveryDocumentsGet(fromInvoiceDate?: string, toInvoiceDate?: string, suppliers?: Array<number>, companies?: Array<number>, documentType?: Array<string>, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseDeliveryDocumentListResponse> {
            return localVarFp.readDeliveryDocumentsDeliveryDocumentsGet(fromInvoiceDate, toInvoiceDate, suppliers, companies, documentType, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {boolean} [hidePaid] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryInvoicesReportsDeliveryInvoiceGet(fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseReportDeliveryDocumentResponse> {
            return localVarFp.readDeliveryInvoicesReportsDeliveryInvoiceGet(fromDate, toDate, suppliers, companies, hidePaid, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {number} deliveryId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryItemsDeliveriesDeliveryIdItemsGet(deliveryId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseDeliveryItemResponse> {
            return localVarFp.readDeliveryItemsDeliveriesDeliveryIdItemsGet(deliveryId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [supplierId] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [itemCategories] 
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryItemsReportsDeliveryItemsGet(fromDeliveryDate?: string, toDeliveryDate?: string, supplierId?: number, companies?: Array<number>, warehouses?: Array<number>, itemCategories?: Array<number>, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseReportDeliveryItemExtendedResponse> {
            return localVarFp.readDeliveryItemsReportsDeliveryItemsGet(fromDeliveryDate, toDeliveryDate, supplierId, companies, warehouses, itemCategories, itemId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Remaining Amount
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRemainingAmountDeliveriesDeliveryIdRemainingAmountGet(deliveryId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readDeliveryRemainingAmountDeliveriesDeliveryIdRemainingAmountGet(deliveryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Request By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRequestByIdDeliveryRequestsIdGet(id: number, options?: any): AxiosPromise<DeliveryRequestResponse> {
            return localVarFp.readDeliveryRequestByIdDeliveryRequestsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Request Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRequestItemsDeliveryRequestsIdItemsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseDeliveryRequestItemResponse> {
            return localVarFp.readDeliveryRequestItemsDeliveryRequestsIdItemsGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Request Stock Receiving Process
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRequestStockReceivingProcessDeliveryRequestsIdProcessGet(id: number, options?: any): AxiosPromise<StockReceivingProcessResponse> {
            return localVarFp.readDeliveryRequestStockReceivingProcessDeliveryRequestsIdProcessGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Requests
         * @param {Array<number>} [id] 
         * @param {string} [fromCreated] 
         * @param {string} [toCreated] 
         * @param {string} [fromDelivery] 
         * @param {string} [toDelivery] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [warehouses] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRequestsDeliveryRequestsGet(id?: Array<number>, fromCreated?: string, toCreated?: string, fromDelivery?: string, toDelivery?: string, suppliers?: Array<number>, warehouses?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseDeliveryRequestListResponse> {
            return localVarFp.readDeliveryRequestsDeliveryRequestsGet(id, fromCreated, toCreated, fromDelivery, toDelivery, suppliers, warehouses, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Depleted Amounts With Prices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDepletedAmountsWithPricesReportsDepletedAmountsWithPricesGet(fromDate?: string, toDate?: string, receivers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseReportDepletedAmountWithPricesResponse> {
            return localVarFp.readDepletedAmountsWithPricesReportsDepletedAmountsWithPricesGet(fromDate, toDate, receivers, companies, warehouses, itemCategories, itemId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Group By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroupByIdGroupsIdGet(id: number, options?: any): AxiosPromise<GroupResponse> {
            return localVarFp.readGroupByIdGroupsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Groups
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroupsGroupsGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseGroupResponse> {
            return localVarFp.readGroupsGroupsGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceByIdInvoicesIdGet(id: number, options?: any): AxiosPromise<InvoiceResponse> {
            return localVarFp.readInvoiceByIdInvoicesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceDocumentInvoicesInvoiceIdDocumentGet(invoiceId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readInvoiceDocumentInvoicesInvoiceIdDocumentGet(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice Orders
         * @param {number} invoiceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceOrdersInvoicesInvoiceIdOrdersGet(invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseOrderListResponse> {
            return localVarFp.readInvoiceOrdersInvoicesInvoiceIdOrdersGet(invoiceId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice Transactions
         * @param {number} invoiceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceTransactionsInvoicesInvoiceIdTransactionsGet(invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseTransactionListResponse> {
            return localVarFp.readInvoiceTransactionsInvoicesInvoiceIdTransactionsGet(invoiceId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoices
         * @param {Array<number>} [id] 
         * @param {string} [fromCreatedAt] 
         * @param {string} [toCreatedAt] 
         * @param {Array<number>} [legalEntities] 
         * @param {Array<number>} [companies] 
         * @param {boolean} [hideCancelled] 
         * @param {boolean} [hidePaidInFull] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoicesInvoicesGet(id?: Array<number>, fromCreatedAt?: string, toCreatedAt?: string, legalEntities?: Array<number>, companies?: Array<number>, hideCancelled?: boolean, hidePaidInFull?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseInvoiceListResponse> {
            return localVarFp.readInvoicesInvoicesGet(id, fromCreatedAt, toCreatedAt, legalEntities, companies, hideCancelled, hidePaidInFull, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Item By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemByIdItemsIdGet(id: number, options?: any): AxiosPromise<ItemResponse> {
            return localVarFp.readItemByIdItemsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Item Categories Paginated
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [leafOnly] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemCategoriesPaginatedItemCategoriesGet(id?: Array<number>, query?: string, leafOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseItemCategoryResponse> {
            return localVarFp.readItemCategoriesPaginatedItemCategoriesGet(id, query, leafOnly, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Item Category By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemCategoryByIdItemCategoriesIdGet(id: number, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.readItemCategoryByIdItemCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Item Delivery Subscription By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemDeliverySubscriptionByIdItemDeliverySubscriptionsIdGet(id: number, options?: any): AxiosPromise<ItemDeliverySubscriptionResponse> {
            return localVarFp.readItemDeliverySubscriptionByIdItemDeliverySubscriptionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Items By Partner
         * @param {number} partnerId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsByPartnerPartnersPartnerIdItemsGet(partnerId: number, search?: string, options?: any): AxiosPromise<Array<PartnerItemResponse>> {
            return localVarFp.readItemsByPartnerPartnersPartnerIdItemsGet(partnerId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Items
         * @param {Array<number>} [id] 
         * @param {Array<string>} [query] 
         * @param {Array<number>} [categories] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [manufacturers] 
         * @param {number} [partner] 
         * @param {boolean} [showInactive] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsItemsGet(id?: Array<number>, query?: Array<string>, categories?: Array<number>, suppliers?: Array<number>, manufacturers?: Array<number>, partner?: number, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseItemListResponse> {
            return localVarFp.readItemsItemsGet(id, query, categories, suppliers, manufacturers, partner, showInactive, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Last Delivery Country
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLastDeliveryCountryItemsIdLastDeliveryCountryGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.readLastDeliveryCountryItemsIdLastDeliveryCountryGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Last Delivery Price
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLastDeliveryPriceItemsIdLastDeliveryPriceGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.readLastDeliveryPriceItemsIdLastDeliveryPriceGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Legal Entities By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLegalEntitiesByCompanyIdCompaniesCompanyIdLegalEntitiesGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseLegalEntityListResponse> {
            return localVarFp.readLegalEntitiesByCompanyIdCompaniesCompanyIdLegalEntitiesGet(companyId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Legal Entities
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [company] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLegalEntitiesLegalEntitiesGet(id?: Array<number>, showInactive?: boolean, query?: string, company?: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseLegalEntityListResponse> {
            return localVarFp.readLegalEntitiesLegalEntitiesGet(id, showInactive, query, company, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Legal Entity By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLegalEntityByIdLegalEntitiesIdGet(id: number, options?: any): AxiosPromise<LegalEntityResponse> {
            return localVarFp.readLegalEntityByIdLegalEntitiesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Linked Item Associations For Item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLinkedItemAssociationsForItemItemsItemIdLinkedItemAssociationGet(itemId: number, options?: any): AxiosPromise<Array<LinkedItemAssociationResponse>> {
            return localVarFp.readLinkedItemAssociationsForItemItemsItemIdLinkedItemAssociationGet(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read List
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListPermissionsGet(page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponsePermissionResponse> {
            return localVarFp.readListPermissionsGet(page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Manifacturer By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManifacturerByIdManufacturersIdGet(id: number, options?: any): AxiosPromise<ManufacturerResponse> {
            return localVarFp.readManifacturerByIdManufacturersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Manufacturer Export By Id
         * @param {number} manufacturerExportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturerExportByIdManufacturerExportsManufacturerExportIdGet(manufacturerExportId: number, options?: any): AxiosPromise<ManufacturerExportResponse> {
            return localVarFp.readManufacturerExportByIdManufacturerExportsManufacturerExportIdGet(manufacturerExportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Manufacturer Exports
         * @param {Array<number>} [manufacturers] 
         * @param {string} [fromCreatedDate] 
         * @param {string} [toCreatedDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturerExportsManufacturerExportsGet(manufacturers?: Array<number>, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseManufacturerExportResponse> {
            return localVarFp.readManufacturerExportsManufacturerExportsGet(manufacturers, fromCreatedDate, toCreatedDate, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Manufacturer Exports
         * @param {number} manufacturerId 
         * @param {string} [fromCreatedDate] 
         * @param {string} [toCreatedDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturerExportsManufacturersManufacturerIdExportsGet(manufacturerId: number, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseManufacturerExportResponse> {
            return localVarFp.readManufacturerExportsManufacturersManufacturerIdExportsGet(manufacturerId, fromCreatedDate, toCreatedDate, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Manufacturer Items
         * @param {number} manufacturerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturerItemsManufacturersManufacturerIdItemsGet(manufacturerId: number, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseItemListResponse> {
            return localVarFp.readManufacturerItemsManufacturersManufacturerIdItemsGet(manufacturerId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Manufacturers
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturersManufacturersGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseManufacturerResponse> {
            return localVarFp.readManufacturersManufacturersGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemOrdersOrderIdItemsIdGet(orderId: number, id: number, options?: any): AxiosPromise<OrderItemResponse> {
            return localVarFp.readOrderItemOrdersOrderIdItemsIdGet(orderId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemOrdersOrderIdItemsIdGet_3(orderId: number, id: number, options?: any): AxiosPromise<OrderItemResponse> {
            return localVarFp.readOrderItemOrdersOrderIdItemsIdGet_3(orderId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Items
         * @param {number} orderId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemsOrdersOrderIdItemsGet(orderId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseOrderItemResponse> {
            return localVarFp.readOrderItemsOrdersOrderIdItemsGet(orderId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Items
         * @param {number} orderId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemsOrdersOrderIdItemsGet_4(orderId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseOrderItemResponse> {
            return localVarFp.readOrderItemsOrdersOrderIdItemsGet_4(orderId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderOrdersOrderIdGet(orderId: number, options?: any): AxiosPromise<OrderResponse> {
            return localVarFp.readOrderOrdersOrderIdGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Status Logs
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderStatusLogsOrdersOrderIdStatusLogsGet(orderId: number, options?: any): AxiosPromise<Array<OrderStatusLogResponse>> {
            return localVarFp.readOrderStatusLogsOrdersOrderIdStatusLogsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderingPolicyLegalEntitiesIdOrderingPolicyGet(id: number, options?: any): AxiosPromise<OrderingPolicyResponse> {
            return localVarFp.readOrderingPolicyLegalEntitiesIdOrderingPolicyGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderingPolicyPartnersIdOrderingPolicyGet(id: number, options?: any): AxiosPromise<OrderingPolicyResponse> {
            return localVarFp.readOrderingPolicyPartnersIdOrderingPolicyGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Orders Balance
         * @param {string} [statusKeys] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersBalanceReportsOrdersBalanceGet(statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseReportOrderBalanceResponse> {
            return localVarFp.readOrdersBalanceReportsOrdersBalanceGet(statusKeys, companies, warehouses, fromDate, toDate, noInvoiceOnly, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Partner Bonus Quantities
         * @param {Array<number>} [items] 
         * @param {Array<number>} [partners] 
         * @param {string} [fromStartDate] 
         * @param {string} [toStartDate] 
         * @param {string} [fromEndDate] 
         * @param {string} [toEndDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerBonusQuantitiesPromotionsBonusQuantityGet(items?: Array<number>, partners?: Array<number>, fromStartDate?: string, toStartDate?: string, fromEndDate?: string, toEndDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponsePartnerBonusQuantityResponse> {
            return localVarFp.readPartnerBonusQuantitiesPromotionsBonusQuantityGet(items, partners, fromStartDate, toStartDate, fromEndDate, toEndDate, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Partner By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerByIdPartnersIdGet(id: number, options?: any): AxiosPromise<PartnerResponse> {
            return localVarFp.readPartnerByIdPartnersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Partner Item Configurations
         * @param {number} itemId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerItemConfigurationsItemsItemIdPartnerConfigurationsGet(itemId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseItemPartnerConfigurationResponse> {
            return localVarFp.readPartnerItemConfigurationsItemsItemIdPartnerConfigurationsGet(itemId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Partner Item Configurations
         * @param {number} partnerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerItemConfigurationsPartnersPartnerIdItemConfigurationGet(partnerId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponsePartnerItemConfigurationResponse> {
            return localVarFp.readPartnerItemConfigurationsPartnersPartnerIdItemConfigurationGet(partnerId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnersPartnerIdItemCategoryAssociationGet(partnerId: number, options?: any): AxiosPromise<Array<PartnerItemCategoryResponse>> {
            return localVarFp.readPartnersPartnerIdItemCategoryAssociationGet(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnersPartnerIdItemCategoryAssociationGet_5(partnerId: number, options?: any): AxiosPromise<Array<PartnerItemCategoryResponse>> {
            return localVarFp.readPartnersPartnerIdItemCategoryAssociationGet_5(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Partners
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnersPartnersGet(id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponsePartnerListResponse> {
            return localVarFp.readPartnersPartnersGet(id, showInactive, query, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Payment Option By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPaymentOptionByIdPaymentOptionsIdGet(id: number, options?: any): AxiosPromise<PaymentOptionResponse> {
            return localVarFp.readPaymentOptionByIdPaymentOptionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Payment Options By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPaymentOptionsByCompanyIdCompaniesCompanyIdPaymentOptionsGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponsePaymentOptionResponse> {
            return localVarFp.readPaymentOptionsByCompanyIdCompaniesCompanyIdPaymentOptionsGet(companyId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Payment Options
         * @param {Array<number>} [id] 
         * @param {number} [company] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPaymentOptionsPaymentOptionsGet(id?: Array<number>, company?: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponsePaymentOptionResponse> {
            return localVarFp.readPaymentOptionsPaymentOptionsGet(id, company, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Portal User By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPortalUserByIdPortalUsersIdGet(id: number, options?: any): AxiosPromise<PortalUserResponse> {
            return localVarFp.readPortalUserByIdPortalUsersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Portal Users
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPortalUsersPortalUsersGet(id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponsePortalUserListResponse> {
            return localVarFp.readPortalUsersPortalUsersGet(id, showInactive, query, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policies By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPoliciesByCompanyIdCompaniesCompanyIdPricingPoliciesGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponsePricingPolicySimpleResponse> {
            return localVarFp.readPricingPoliciesByCompanyIdCompaniesCompanyIdPricingPoliciesGet(companyId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policies
         * @param {Array<number>} [id] 
         * @param {Array<number>} [companies] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPoliciesPricingPoliciesGet(id?: Array<number>, companies?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponsePricingPolicyListResponse> {
            return localVarFp.readPricingPoliciesPricingPoliciesGet(id, companies, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyByIdPricingPoliciesIdGet(id: number, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.readPricingPolicyByIdPricingPoliciesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoriesPricingPoliciesIdItemCategoriesGet(id: number, options?: any): AxiosPromise<Array<PricingPolicyItemCategoryResponse>> {
            return localVarFp.readPricingPolicyItemCategoriesPricingPoliciesIdItemCategoriesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Category Multipliers
         * @param {string} itemCategories 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoryMultipliersReportsPricingPolicyItemCategoryMultipliersGet(itemCategories: string, options?: any): AxiosPromise<ReportPricingPolicyItemCategoryMultipliersListResponse> {
            return localVarFp.readPricingPolicyItemCategoryMultipliersReportsPricingPolicyItemCategoryMultipliersGet(itemCategories, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Exceptions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemExceptionsPricingPoliciesIdItemExceptionsGet(id: number, options?: any): AxiosPromise<Array<PricingPolicyItemExceptionResponse>> {
            return localVarFp.readPricingPolicyItemExceptionsPricingPoliciesIdItemExceptionsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Prices
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemPricesReportsPricingPolicyItemPricesGet(items: string, options?: any): AxiosPromise<ReportPricingPolicyItemPricesListResponse> {
            return localVarFp.readPricingPolicyItemPricesReportsPricingPolicyItemPricesGet(items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Print Document Queue
         * @param {string} [state] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPrintDocumentQueuePrintDocumentQueueGet(state?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponsePrintDocumentQueueResponse> {
            return localVarFp.readPrintDocumentQueuePrintDocumentQueueGet(state, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Promotion By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPromotionByIdPromotionsBonusQuantityIdGet(id: number, options?: any): AxiosPromise<PartnerBonusQuantityResponse> {
            return localVarFp.readPromotionByIdPromotionsBonusQuantityIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Role By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRoleByIdRolesIdGet(id: number, options?: any): AxiosPromise<RoleResponse> {
            return localVarFp.readRoleByIdRolesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Roles
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRolesRolesGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseRoleListResponse> {
            return localVarFp.readRolesRolesGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Sales
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [itemCategories] 
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSalesReportsSalesGet(fromDeliveryDate?: string, toDeliveryDate?: string, receivers?: Array<number>, legalEntities?: Array<number>, companies?: Array<number>, warehouses?: Array<number>, itemCategories?: Array<number>, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseReportSalesResponse> {
            return localVarFp.readSalesReportsSalesGet(fromDeliveryDate, toDeliveryDate, receivers, legalEntities, companies, warehouses, itemCategories, itemId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Shipping Route By Id
         * @param {number} shippingRouteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShippingRouteByIdShippingRoutesShippingRouteIdGet(shippingRouteId: number, options?: any): AxiosPromise<ShippingRouteResponse> {
            return localVarFp.readShippingRouteByIdShippingRoutesShippingRouteIdGet(shippingRouteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Shipping Route Orders
         * @param {number} shippingRouteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShippingRouteOrdersShippingRoutesShippingRouteIdOrdersGet(shippingRouteId: number, options?: any): AxiosPromise<PaginatedListResponseOrderListResponse> {
            return localVarFp.readShippingRouteOrdersShippingRoutesShippingRouteIdOrdersGet(shippingRouteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Shipping Routes
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [users] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShippingRoutesShippingRoutesGet(fromDate?: string, toDate?: string, users?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseShippingRouteListResponse> {
            return localVarFp.readShippingRoutesShippingRoutesGet(fromDate, toDate, users, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Shopping Cart By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShoppingCartByIdShoppingCartsIdGet(id: number, options?: any): AxiosPromise<ClientShoppingCartResponse> {
            return localVarFp.readShoppingCartByIdShoppingCartsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Shopping Cart Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShoppingCartItemsShoppingCartsIdItemsGet(id: number, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseClientShoppingCartItemResponse> {
            return localVarFp.readShoppingCartItemsShoppingCartsIdItemsGet(id, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Shopping Carts
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShoppingCartsShoppingCartsGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseClientShoppingCartResponse> {
            return localVarFp.readShoppingCartsShoppingCartsGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Similar Item Associations For Item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSimilarItemAssociationsForItemItemsItemIdSimilarItemAssociationGet(itemId: number, options?: any): AxiosPromise<Array<SimilarItemAssociationResponse>> {
            return localVarFp.readSimilarItemAssociationsForItemItemsItemIdSimilarItemAssociationGet(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stock By Deliveries
         * @param {number} [companyId] 
         * @param {number} [warehouseId] 
         * @param {number} [itemId] 
         * @param {number} [manufacturerId] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [itemCategories] 
         * @param {boolean} [hideDepleted] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockByDeliveriesReportsStockByDeliveriesGet(companyId?: number, warehouseId?: number, itemId?: number, manufacturerId?: number, suppliers?: Array<number>, itemCategories?: Array<number>, hideDepleted?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseReportDeliveryItemResponse> {
            return localVarFp.readStockByDeliveriesReportsStockByDeliveriesGet(companyId, warehouseId, itemId, manufacturerId, suppliers, itemCategories, hideDepleted, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stock Receiving Process By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockReceivingProcessByIdStockReceivingProcessesIdGet(id: number, options?: any): AxiosPromise<StockReceivingProcessResponse> {
            return localVarFp.readStockReceivingProcessByIdStockReceivingProcessesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stock Receiving Process Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockReceivingProcessItemsStockReceivingProcessesIdItemsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseStockReceivingProcessItemResponse> {
            return localVarFp.readStockReceivingProcessItemsStockReceivingProcessesIdItemsGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stock Receiving Processes
         * @param {Array<number>} [id] 
         * @param {string} [fromCreated] 
         * @param {string} [toCreated] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockReceivingProcessesStockReceivingProcessesGet(id?: Array<number>, fromCreated?: string, toCreated?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseStockReceivingProcessResponse> {
            return localVarFp.readStockReceivingProcessesStockReceivingProcessesGet(id, fromCreated, toCreated, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stock Taking Report Items
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockTakingReportItemsStockTakingReportItemsGet(itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseStockTakingReportItemResponse> {
            return localVarFp.readStockTakingReportItemsStockTakingReportItemsGet(itemId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stock Taking Report
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockTakingReportStockTakingReportsIdGet(id: number, options?: any): AxiosPromise<StockTakingReportResponse> {
            return localVarFp.readStockTakingReportStockTakingReportsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stock Taking Reports
         * @param {string} [fromExecutionDate] 
         * @param {string} [toExecutionDate] 
         * @param {number} [userId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockTakingReportsStockTakingReportsGet(fromExecutionDate?: string, toExecutionDate?: string, userId?: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseStockTakingReportResponse> {
            return localVarFp.readStockTakingReportsStockTakingReportsGet(fromExecutionDate, toExecutionDate, userId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stocktaking By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStocktakingByIdStocktakingsIdGet(id: number, options?: any): AxiosPromise<StocktakingResponse> {
            return localVarFp.readStocktakingByIdStocktakingsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stocktaking Tasks
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStocktakingTasksStocktakingsIdTasksGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseStockTakingTaskResponse> {
            return localVarFp.readStocktakingTasksStocktakingsIdTasksGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stocktakings
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [warehouses] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStocktakingsStocktakingsGet(fromDate?: string, toDate?: string, warehouses?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseStocktakingResponse> {
            return localVarFp.readStocktakingsStocktakingsGet(fromDate, toDate, warehouses, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Supplier By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierByIdSuppliersIdGet(id: number, options?: any): AxiosPromise<SupplierResponse> {
            return localVarFp.readSupplierByIdSuppliersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Supplier Price Differences
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierPriceDifferencesSuppliersIdPriceDifferencesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseSupplierPriceDifferenceResponse> {
            return localVarFp.readSupplierPriceDifferencesSuppliersIdPriceDifferencesGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Supplier Quantity Differences
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierQuantityDifferencesSuppliersIdQuantityDifferencesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseSupplierQuantityDifferenceResponse> {
            return localVarFp.readSupplierQuantityDifferencesSuppliersIdQuantityDifferencesGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Supplier Reserve Subscription By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierReserveSubscriptionByIdSupplierReserveSubscriptionsIdGet(id: number, options?: any): AxiosPromise<SupplierReserveSubscriptionResponse> {
            return localVarFp.readSupplierReserveSubscriptionByIdSupplierReserveSubscriptionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Supplier Reserve Subscriptions
         * @param {Array<number>} [id] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<string>} [weekday] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierReserveSubscriptionsSupplierReserveSubscriptionsGet(id?: Array<number>, suppliers?: Array<number>, weekday?: Array<string>, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseSupplierReserveSubscriptionResponse> {
            return localVarFp.readSupplierReserveSubscriptionsSupplierReserveSubscriptionsGet(id, suppliers, weekday, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Suppliers
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [showInactive] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSuppliersSuppliersGet(id?: Array<number>, query?: string, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseSupplierListResponse> {
            return localVarFp.readSuppliersSuppliersGet(id, query, showInactive, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Transactions
         * @param {Array<number>} [id] 
         * @param {number} [invoiceId] 
         * @param {number} [deliveryInvoiceId] 
         * @param {Array<number>} [treasuries] 
         * @param {string} [fromCreatedDate] 
         * @param {string} [toCreatedDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTransactionsTransactionsGet(id?: Array<number>, invoiceId?: number, deliveryInvoiceId?: number, treasuries?: Array<number>, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseTransactionListResponse> {
            return localVarFp.readTransactionsTransactionsGet(id, invoiceId, deliveryInvoiceId, treasuries, fromCreatedDate, toCreatedDate, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Treasuries
         * @param {Array<number>} [id] 
         * @param {number} [companyId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuriesTreasuriesGet(id?: Array<number>, companyId?: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseTreasuryListResponse> {
            return localVarFp.readTreasuriesTreasuriesGet(id, companyId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Treasury Bank Account Amount
         * @param {number} treasuryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuryBankAccountAmountTreasuriesTreasuryIdBankAccountAmountGet(treasuryId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readTreasuryBankAccountAmountTreasuriesTreasuryIdBankAccountAmountGet(treasuryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Treasury By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuryByIdTreasuriesIdGet(id: number, options?: any): AxiosPromise<TreasuryResponse> {
            return localVarFp.readTreasuryByIdTreasuriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Treasury Cash Amount
         * @param {number} treasuryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuryCashAmountTreasuriesTreasuryIdCashAmountGet(treasuryId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readTreasuryCashAmountTreasuriesTreasuryIdCashAmountGet(treasuryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Treasury Transactions
         * @param {number} treasuryId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuryTransactionsTreasuriesTreasuryIdTransactionsGet(treasuryId: number, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseTransactionListResponse> {
            return localVarFp.readTreasuryTransactionsTreasuriesTreasuryIdTransactionsGet(treasuryId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read User By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserByIdUsersIdGet(id: number, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.readUserByIdUsersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Users By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersByCompanyIdCompaniesCompanyIdUsersGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseUserListResponse> {
            return localVarFp.readUsersByCompanyIdCompaniesCompanyIdUsersGet(companyId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Users
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {number} [company] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersUsersGet(id?: Array<number>, showInactive?: boolean, company?: number, query?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseUserListResponse> {
            return localVarFp.readUsersUsersGet(id, showInactive, company, query, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Warehouse By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseByIdWarehousesIdGet(id: number, options?: any): AxiosPromise<WarehouseResponse> {
            return localVarFp.readWarehouseByIdWarehousesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Warehouse Movements
         * @param {Array<number>} [id] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [items] 
         * @param {string} [fromExpirationDate] 
         * @param {string} [toExpirationDate] 
         * @param {string} [batchNumber] 
         * @param {string} [movementType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseMovementsWarehouseMovementsGet(id?: Array<number>, warehouses?: Array<number>, items?: Array<number>, fromExpirationDate?: string, toExpirationDate?: string, batchNumber?: string, movementType?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseWarehouseMovementResponse> {
            return localVarFp.readWarehouseMovementsWarehouseMovementsGet(id, warehouses, items, fromExpirationDate, toExpirationDate, batchNumber, movementType, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Warehouse Stock By Orders
         * @param {number} warehouseId 
         * @param {string} toDate 
         * @param {boolean} [hideOrdersWithoutDeliveryDate] 
         * @param {boolean} [hideAvailable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseStockByOrdersReportsStockByOrdersGet(warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options?: any): AxiosPromise<PaginatedListResponseReportDeliveryItemByOrdersResponse> {
            return localVarFp.readWarehouseStockByOrdersReportsStockByOrdersGet(warehouseId, toDate, hideOrdersWithoutDeliveryDate, hideAvailable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Warehouses
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehousesWarehousesGet(id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseWarehouseListResponse> {
            return localVarFp.readWarehousesWarehousesGet(id, showInactive, query, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderingPolicyLegalEntitiesIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: any): AxiosPromise<OrderingPolicyResponse> {
            return localVarFp.setOrderingPolicyLegalEntitiesIdOrderingPolicyPut(id, setOrderingPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderingPolicyPartnersIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: any): AxiosPromise<OrderingPolicyResponse> {
            return localVarFp.setOrderingPolicyPartnersIdOrderingPolicyPut(id, setOrderingPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Partner Integration Settings
         * @param {number} partnerId 
         * @param {Request1} request1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost(partnerId: number, request1: Request1, options?: any): AxiosPromise<void> {
            return localVarFp.setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost(partnerId, request1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options?: any): AxiosPromise<void> {
            return localVarFp.startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId, startStockReceivingProcessRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test Barsy Connection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testBarsyConnectionPartnersIdTestBarsyConnectionPost(id: number, options?: any): AxiosPromise<TestConnectionDetailsResponse> {
            return localVarFp.testBarsyConnectionPartnersIdTestBarsyConnectionPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Client
         * @param {number} id 
         * @param {UpdateClientRequest} updateClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientClientsIdPatch(id: number, updateClientRequest: UpdateClientRequest, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.updateClientClientsIdPatch(id, updateClientRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Company Category
         * @param {number} id 
         * @param {SaveCompanyCategoryRequest} saveCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyCategoryCompanyCategoriesIdPut(id: number, saveCompanyCategoryRequest: SaveCompanyCategoryRequest, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.updateCompanyCategoryCompanyCategoriesIdPut(id, saveCompanyCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Company
         * @param {number} id 
         * @param {UpdateCompanyRequest} updateCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyCompaniesIdPut(id: number, updateCompanyRequest: UpdateCompanyRequest, options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.updateCompanyCompaniesIdPut(id, updateCompanyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Delivery Document
         * @param {number} deliveryDocumentId 
         * @param {UpdateDeliveryDocumentRequest} updateDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut(deliveryDocumentId: number, updateDeliveryDocumentRequest: UpdateDeliveryDocumentRequest, options?: any): AxiosPromise<DeliveryDocumentResponse> {
            return localVarFp.updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut(deliveryDocumentId, updateDeliveryDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Delivery Request
         * @param {number} id 
         * @param {UpdateDeliveryRequestRequest} updateDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryRequestDeliveryRequestsIdPut(id: number, updateDeliveryRequestRequest: UpdateDeliveryRequestRequest, options?: any): AxiosPromise<DeliveryRequestResponse> {
            return localVarFp.updateDeliveryRequestDeliveryRequestsIdPut(id, updateDeliveryRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Group
         * @param {number} id 
         * @param {UpdateGroupRequest} updateGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupGroupsIdPut(id: number, updateGroupRequest: UpdateGroupRequest, options?: any): AxiosPromise<GroupResponse> {
            return localVarFp.updateGroupGroupsIdPut(id, updateGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Item Category
         * @param {number} id 
         * @param {SaveItemCategoryRequest} saveItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemCategoryItemCategoriesIdPut(id: number, saveItemCategoryRequest: SaveItemCategoryRequest, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.updateItemCategoryItemCategoriesIdPut(id, saveItemCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Item Delivery Subscription
         * @param {number} id 
         * @param {PatchItemDeliverySubscriptionRequest} patchItemDeliverySubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut(id: number, patchItemDeliverySubscriptionRequest: PatchItemDeliverySubscriptionRequest, options?: any): AxiosPromise<ItemDeliverySubscriptionResponse> {
            return localVarFp.updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut(id, patchItemDeliverySubscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Item
         * @param {number} id 
         * @param {SaveItemRequest} saveItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemItemsIdPut(id: number, saveItemRequest: SaveItemRequest, options?: any): AxiosPromise<ItemResponse> {
            return localVarFp.updateItemItemsIdPut(id, saveItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Legal Entity
         * @param {number} id 
         * @param {SaveLegalEntityRequest} saveLegalEntityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLegalEntityLegalEntitiesIdPut(id: number, saveLegalEntityRequest: SaveLegalEntityRequest, options?: any): AxiosPromise<LegalEntityResponse> {
            return localVarFp.updateLegalEntityLegalEntitiesIdPut(id, saveLegalEntityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Manufacturer
         * @param {number} id 
         * @param {SaveManufacturerRequest} saveManufacturerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManufacturerManufacturersIdPut(id: number, saveManufacturerRequest: SaveManufacturerRequest, options?: any): AxiosPromise<ManufacturerResponse> {
            return localVarFp.updateManufacturerManufacturersIdPut(id, saveManufacturerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderItemOrdersOrderIdItemsIdPut(orderId: number, id: number, saveOrderItemRequest: SaveOrderItemRequest, options?: any): AxiosPromise<OrderItemResponse> {
            return localVarFp.updateOrderItemOrdersOrderIdItemsIdPut(orderId, id, saveOrderItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderItemOrdersOrderIdItemsIdPut_6(orderId: number, id: number, saveOrderItemRequest: SaveOrderItemRequest, options?: any): AxiosPromise<OrderItemResponse> {
            return localVarFp.updateOrderItemOrdersOrderIdItemsIdPut_6(orderId, id, saveOrderItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Partner Bonus Quantity
         * @param {number} id 
         * @param {PatchPartnerBonusQuantityRequest} patchPartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerBonusQuantityPromotionsBonusQuantityIdPut(id: number, patchPartnerBonusQuantityRequest: PatchPartnerBonusQuantityRequest, options?: any): AxiosPromise<PartnerBonusQuantityResponse> {
            return localVarFp.updatePartnerBonusQuantityPromotionsBonusQuantityIdPut(id, patchPartnerBonusQuantityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Partner Item Configuration
         * @param {number} id 
         * @param {SavePartnerItemConfigurationRequest} savePartnerItemConfigurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerItemConfigurationPartnersItemConfigurationIdPut(id: number, savePartnerItemConfigurationRequest: SavePartnerItemConfigurationRequest, options?: any): AxiosPromise<PartnerItemConfigurationResponse> {
            return localVarFp.updatePartnerItemConfigurationPartnersItemConfigurationIdPut(id, savePartnerItemConfigurationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Partner
         * @param {number} id 
         * @param {SavePartnerRequest} savePartnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerPartnersIdPut(id: number, savePartnerRequest: SavePartnerRequest, options?: any): AxiosPromise<PartnerResponse> {
            return localVarFp.updatePartnerPartnersIdPut(id, savePartnerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnersPartnerIdItemCategoryAssociationPost(partnerId: number, requestBody: Array<number>, options?: any): AxiosPromise<Array<PartnerItemCategoryResponse>> {
            return localVarFp.updatePartnersPartnerIdItemCategoryAssociationPost(partnerId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnersPartnerIdItemCategoryAssociationPost_7(partnerId: number, requestBody: Array<number>, options?: any): AxiosPromise<Array<PartnerItemCategoryResponse>> {
            return localVarFp.updatePartnersPartnerIdItemCategoryAssociationPost_7(partnerId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Payment Option
         * @param {number} id 
         * @param {SavePaymentOptionRequest} savePaymentOptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentOptionPaymentOptionsIdPut(id: number, savePaymentOptionRequest: SavePaymentOptionRequest, options?: any): AxiosPromise<PaymentOptionResponse> {
            return localVarFp.updatePaymentOptionPaymentOptionsIdPut(id, savePaymentOptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {number} id 
         * @param {SavePricingPolicyRequest} savePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePricingPoliciesIdPut(id: number, savePricingPolicyRequest: SavePricingPolicyRequest, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.updatePricingPoliciesIdPut(id, savePricingPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Print Document Queue
         * @param {number} id 
         * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrintDocumentQueuePrintDocumentQueueIdPatch(id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options?: any): AxiosPromise<PrintDocumentQueueResponse> {
            return localVarFp.updatePrintDocumentQueuePrintDocumentQueueIdPatch(id, updatePrintDocumentQueueRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Role
         * @param {number} id 
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleRolesIdPut(id: number, updateRoleRequest: UpdateRoleRequest, options?: any): AxiosPromise<RoleResponse> {
            return localVarFp.updateRoleRolesIdPut(id, updateRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {Request} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId: number, request: Request, options?: any): AxiosPromise<StockReceivingProcessResponse> {
            return localVarFp.updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Supplier Reserve Subscription
         * @param {number} id 
         * @param {PatchSupplierReserveSubscriptionRequest} patchSupplierReserveSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch(id: number, patchSupplierReserveSubscriptionRequest: PatchSupplierReserveSubscriptionRequest, options?: any): AxiosPromise<SupplierReserveSubscriptionResponse> {
            return localVarFp.updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch(id, patchSupplierReserveSubscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Supplier
         * @param {number} id 
         * @param {SaveSupplierRequest} saveSupplierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplierSuppliersIdPut(id: number, saveSupplierRequest: SaveSupplierRequest, options?: any): AxiosPromise<SupplierResponse> {
            return localVarFp.updateSupplierSuppliersIdPut(id, saveSupplierRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Transaction
         * @param {number} id 
         * @param {PatchTransactionRequest} patchTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionTransactionsIdPut(id: number, patchTransactionRequest: PatchTransactionRequest, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.updateTransactionTransactionsIdPut(id, patchTransactionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Treasury
         * @param {number} id 
         * @param {PatchTreasuryRequest} patchTreasuryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTreasuryTreasuriesIdPut(id: number, patchTreasuryRequest: PatchTreasuryRequest, options?: any): AxiosPromise<TreasuryResponse> {
            return localVarFp.updateTreasuryTreasuriesIdPut(id, patchTreasuryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User
         * @param {number} id 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserUsersIdPut(id: number, updateUserRequest: UpdateUserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.updateUserUsersIdPut(id, updateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Warehouse
         * @param {number} id 
         * @param {SaveWarehouseRequest} saveWarehouseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWarehouseWarehousesIdPut(id: number, saveWarehouseRequest: SaveWarehouseRequest, options?: any): AxiosPromise<WarehouseResponse> {
            return localVarFp.updateWarehouseWarehousesIdPut(id, saveWarehouseRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AMSApi - object-oriented interface
 * @export
 * @class AMSApi
 * @extends {BaseAPI}
 */
export class AMSApi extends BaseAPI {
    /**
     * 
     * @summary Bulk Create Partner Bonus Quantity Promotions
     * @param {BulkCreatePartnerBonusQuantityRequest} bulkCreatePartnerBonusQuantityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public bulkCreatePartnerBonusQuantityPromotionsPromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest: BulkCreatePartnerBonusQuantityRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).bulkCreatePartnerBonusQuantityPromotionsPromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel Invoice By Invoice Id
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public cancelInvoiceByInvoiceIdInvoicesInvoiceIdPatch(invoiceId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).cancelInvoiceByInvoiceIdInvoicesInvoiceIdPatch(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Copy Partner Item Configurations
     * @param {number} partnerId 
     * @param {number} sourcePartnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost(partnerId: number, sourcePartnerId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost(partnerId, sourcePartnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {CreateClientRequest} createClientRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createClientsPost(createClientRequest: CreateClientRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createClientsPost(createClientRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {CreateCompanyRequest} createCompanyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createCompaniesPost(createCompanyRequest: CreateCompanyRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createCompaniesPost(createCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {SaveCompanyCategoryRequest} saveCompanyCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createCompanyCategoriesPost(saveCompanyCategoryRequest: SaveCompanyCategoryRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createCompanyCategoriesPost(saveCompanyCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Delivery
     * @param {CreateDeliveryRequest} createDeliveryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createDeliveryDeliveriesPost(createDeliveryRequest: CreateDeliveryRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createDeliveryDeliveriesPost(createDeliveryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Delivery Document
     * @param {CreateDeliveryDocumentRequest} createDeliveryDocumentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createDeliveryDocumentDeliveryDocumentsPost(createDeliveryDocumentRequest: CreateDeliveryDocumentRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createDeliveryDocumentDeliveryDocumentsPost(createDeliveryDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Delivery Request
     * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
     * @param {boolean} [shouldSendEmail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createDeliveryRequestDeliveryRequestsPost(createDeliveryRequestRequest: CreateDeliveryRequestRequest, shouldSendEmail?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createDeliveryRequestDeliveryRequestsPost(createDeliveryRequestRequest, shouldSendEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Group
     * @param {CreateGroupRequest} createGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createGroupGroupsPost(createGroupRequest: CreateGroupRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createGroupGroupsPost(createGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {CreateInvoiceRequest} createInvoiceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createInvoicesPost(createInvoiceRequest: CreateInvoiceRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createInvoicesPost(createInvoiceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {SaveItemCategoryRequest} saveItemCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createItemCategoriesPost(saveItemCategoryRequest: SaveItemCategoryRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createItemCategoriesPost(saveItemCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Item Delivery Subscription
     * @param {CreateItemDeliverySubscriptionRequest} createItemDeliverySubscriptionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createItemDeliverySubscriptionItemDeliverySubscriptionsPost(createItemDeliverySubscriptionRequest: CreateItemDeliverySubscriptionRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createItemDeliverySubscriptionItemDeliverySubscriptionsPost(createItemDeliverySubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {SaveItemRequest} saveItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createItemsPost(saveItemRequest: SaveItemRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createItemsPost(saveItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {SaveLegalEntityRequest} saveLegalEntityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createLegalEntitiesPost(saveLegalEntityRequest: SaveLegalEntityRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createLegalEntitiesPost(saveLegalEntityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Linked Item Association
     * @param {number} itemId 
     * @param {number} linkedItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId: number, linkedItemId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId, linkedItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Manufacturer
     * @param {SaveManufacturerRequest} saveManufacturerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createManufacturerManufacturersPost(saveManufacturerRequest: SaveManufacturerRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createManufacturerManufacturersPost(saveManufacturerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Order Item
     * @param {number} orderId 
     * @param {SaveOrderItemRequest} saveOrderItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createOrderItemOrdersOrderIdItemsPost(orderId: number, saveOrderItemRequest: SaveOrderItemRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createOrderItemOrdersOrderIdItemsPost(orderId, saveOrderItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Order Item
     * @param {number} orderId 
     * @param {SaveOrderItemRequest} saveOrderItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createOrderItemOrdersOrderIdItemsPost_1(orderId: number, saveOrderItemRequest: SaveOrderItemRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createOrderItemOrdersOrderIdItemsPost_1(orderId, saveOrderItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Order
     * @param {CreateOrderRequest} createOrderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createOrderOrdersPost(createOrderRequest: CreateOrderRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createOrderOrdersPost(createOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Partner Item Configuration
     * @param {number} partnerId 
     * @param {SavePartnerItemConfigurationRequest} savePartnerItemConfigurationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost(partnerId: number, savePartnerItemConfigurationRequest: SavePartnerItemConfigurationRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost(partnerId, savePartnerItemConfigurationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Partner
     * @param {SavePartnerRequest} savePartnerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createPartnerPartnersPost(savePartnerRequest: SavePartnerRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createPartnerPartnersPost(savePartnerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Payment Option
     * @param {SavePaymentOptionRequest} savePaymentOptionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createPaymentOptionPaymentOptionsPost(savePaymentOptionRequest: SavePaymentOptionRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createPaymentOptionPaymentOptionsPost(savePaymentOptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Portal User
     * @param {CreatePortalUserRequest} createPortalUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createPortalUserPortalUsersPost(createPortalUserRequest: CreatePortalUserRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createPortalUserPortalUsersPost(createPortalUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Pricing Policy
     * @param {SavePricingPolicyRequest} savePricingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createPricingPolicyPricingPoliciesPost(savePricingPolicyRequest: SavePricingPolicyRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createPricingPolicyPricingPoliciesPost(savePricingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Role
     * @param {CreateRoleRequest} createRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createRoleRolesPost(createRoleRequest: CreateRoleRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createRoleRolesPost(createRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Similar Item Association
     * @param {number} itemId 
     * @param {number} similarItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId: number, similarItemId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId, similarItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Stocktaking
     * @param {StocktakingRequest} stocktakingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createStocktakingStocktakingsPost(stocktakingRequest: StocktakingRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createStocktakingStocktakingsPost(stocktakingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Supplier Reserve Subscription
     * @param {CreateSupplierReserveSubscriptionRequest} createSupplierReserveSubscriptionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createSupplierReserveSubscriptionSupplierReserveSubscriptionsPost(createSupplierReserveSubscriptionRequest: CreateSupplierReserveSubscriptionRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createSupplierReserveSubscriptionSupplierReserveSubscriptionsPost(createSupplierReserveSubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Supplier
     * @param {SaveSupplierRequest} saveSupplierRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createSupplierSuppliersPost(saveSupplierRequest: SaveSupplierRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createSupplierSuppliersPost(saveSupplierRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Transaction
     * @param {CreateTransactionRequest} createTransactionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createTransactionTransactionsPost(createTransactionRequest: CreateTransactionRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createTransactionTransactionsPost(createTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Treasury
     * @param {CreateTreasuryRequest} createTreasuryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createTreasuryTreasuriesPost(createTreasuryRequest: CreateTreasuryRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createTreasuryTreasuriesPost(createTreasuryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create User
     * @param {CreateUserRequest} createUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createUserUsersPost(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createUserUsersPost(createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {SaveWarehouseRequest} saveWarehouseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createWarehousesPost(saveWarehouseRequest: SaveWarehouseRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createWarehousesPost(saveWarehouseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Client
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteClientClientsIdDelete(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteClientClientsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Delivery
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteDeliveryDeliveriesIdDelete(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteDeliveryDeliveriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} deliveryDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteDeliveryDocumentsDeliveryDocumentIdDelete(deliveryDocumentId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteDeliveryDocumentsDeliveryDocumentIdDelete(deliveryDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Item Delivery Subscription
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteItemDeliverySubscriptionItemDeliverySubscriptionsIdDelete(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteItemDeliverySubscriptionItemDeliverySubscriptionsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Linked Item Association
     * @param {number} itemId 
     * @param {number} linkedItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId: number, linkedItemId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId, linkedItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Order Item
     * @param {number} orderId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteOrderItemOrdersOrderIdItemsIdDelete(orderId: number, id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteOrderItemOrdersOrderIdItemsIdDelete(orderId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Order Item
     * @param {number} orderId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteOrderItemOrdersOrderIdItemsIdDelete_2(orderId: number, id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteOrderItemOrdersOrderIdItemsIdDelete_2(orderId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Partner Bonus Quantity
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deletePartnerBonusQuantityPromotionsBonusQuantityIdDelete(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deletePartnerBonusQuantityPromotionsBonusQuantityIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Partner Item Configuration
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deletePartnerItemConfigurationPartnersItemConfigurationIdDelete(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deletePartnerItemConfigurationPartnersItemConfigurationIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Similar Item Association
     * @param {number} itemId 
     * @param {number} similarItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId: number, similarItemId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId, similarItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Supplier Reserve Subscription
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteSupplierReserveSubscriptionSupplierReserveSubscriptionsIdDelete(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteSupplierReserveSubscriptionSupplierReserveSubscriptionsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Transaction
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteTransactionTransactionsIdDelete(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteTransactionTransactionsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get B2B Orders
     * @param {Array<number>} [id] 
     * @param {Array<string>} [status] 
     * @param {Array<number>} [companies] 
     * @param {Array<number>} [warehouses] 
     * @param {Array<number>} [receivers] 
     * @param {Array<number>} [legalEntities] 
     * @param {string} [fromDeliveryDate] 
     * @param {string} [toDeliveryDate] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getB2bOrdersOrdersB2bOrdersGet(id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, legalEntities?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getB2bOrdersOrdersB2bOrdersGet(id, status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get B2C Orders
     * @param {Array<number>} [id] 
     * @param {Array<string>} [status] 
     * @param {Array<number>} [companies] 
     * @param {Array<number>} [warehouses] 
     * @param {Array<number>} [receivers] 
     * @param {Array<number>} [legalEntities] 
     * @param {string} [fromDeliveryDate] 
     * @param {string} [toDeliveryDate] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getB2cOrdersOrdersB2cOrdersGet(id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, legalEntities?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getB2cOrdersOrdersB2cOrdersGet(id, status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Deliveries
     * @param {Array<number>} [suppliers] 
     * @param {Array<number>} [warehouses] 
     * @param {string} [fromDeliveryDate] 
     * @param {string} [toDeliveryDate] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getDeliveriesDeliveriesGet(suppliers?: Array<number>, warehouses?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getDeliveriesDeliveriesGet(suppliers, warehouses, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Delivery Request Expected Total
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getDeliveryRequestExpectedTotalDeliveryRequestsIdExpectedTotalGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getDeliveryRequestExpectedTotalDeliveryRequestsIdExpectedTotalGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Internal Transfers
     * @param {Array<number>} [id] 
     * @param {Array<string>} [status] 
     * @param {Array<number>} [companies] 
     * @param {Array<number>} [warehouses] 
     * @param {Array<number>} [receivers] 
     * @param {string} [fromDeliveryDate] 
     * @param {string} [toDeliveryDate] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getInternalTransfersOrdersInternalTransfersGet(id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getInternalTransfersOrdersInternalTransfersGet(id, status, companies, warehouses, receivers, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Item Delivery Subscriptions
     * @param {Array<number>} [warehouses] 
     * @param {Array<number>} [items] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getItemDeliverySubscriptionsItemDeliverySubscriptionsGet(warehouses?: Array<number>, items?: Array<number>, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getItemDeliverySubscriptionsItemDeliverySubscriptionsGet(warehouses, items, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getMeMeGet(options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getMeMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Order Execution Percentage
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getOrderExecutionPercentageOrdersOrderIdExecutionPercentageGet(orderId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getOrderExecutionPercentageOrdersOrderIdExecutionPercentageGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Orders For Collection
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getOrdersForCollectionLegalEntitiesIdOrdersForCollectionAmountGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getOrdersForCollectionLegalEntitiesIdOrdersForCollectionAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Orders For Distribution Amount
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getOrdersForDistributionAmountLegalEntitiesIdOrdersForDistributionAmountGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getOrdersForDistributionAmountLegalEntitiesIdOrdersForDistributionAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Overdue Payments Amount
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getOverduePaymentsAmountLegalEntitiesIdOverduePaymentsAmountGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getOverduePaymentsAmountLegalEntitiesIdOverduePaymentsAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Pending Payments Amount
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getPendingPaymentsAmountLegalEntitiesIdPendingPaymentsAmountGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getPendingPaymentsAmountLegalEntitiesIdPendingPaymentsAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public healthCheckHealthGet(options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).healthCheckHealthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Load Info
     * @param {string} vatNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public loadInfoLegalEntitiesLoadInfoPost(vatNumber: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).loadInfoLegalEntitiesLoadInfoPost(vatNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Order
     * @param {number} orderId 
     * @param {PatchOrderRequest} patchOrderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public patchOrderOrdersOrderIdPatch(orderId: number, patchOrderRequest: PatchOrderRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).patchOrderOrdersOrderIdPatch(orderId, patchOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance
     * @param {number} actOfAcceptanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readActOfAcceptanceActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readActOfAcceptanceActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance By Id
     * @param {number} actOfAcceptanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readActOfAcceptanceByIdActsOfAcceptanceActOfAcceptanceIdDocumentGet(actOfAcceptanceId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readActOfAcceptanceByIdActsOfAcceptanceActOfAcceptanceIdDocumentGet(actOfAcceptanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance Items
     * @param {number} actOfAcceptanceId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readActOfAcceptanceItemsActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readActOfAcceptanceItemsActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Active Order Items
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readActiveOrderItemsItemsIdActiveOrderItemsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readActiveOrderItemsItemsIdActiveOrderItemsGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Acts Of Acceptance
     * @param {Array<number>} [id] 
     * @param {string} [fromCreatedAt] 
     * @param {string} [toCreatedAt] 
     * @param {Array<number>} [receivers] 
     * @param {Array<number>} [legalEntities] 
     * @param {Array<number>} [companies] 
     * @param {Array<number>} [users] 
     * @param {Array<string>} [orderType] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readActsOfAcceptanceActsOfAcceptanceGet(id?: Array<number>, fromCreatedAt?: string, toCreatedAt?: string, receivers?: Array<number>, legalEntities?: Array<number>, companies?: Array<number>, users?: Array<number>, orderType?: Array<string>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readActsOfAcceptanceActsOfAcceptanceGet(id, fromCreatedAt, toCreatedAt, receivers, legalEntities, companies, users, orderType, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Acts Of Acceptance By Manufacturer Export Id
     * @param {number} manufacturerExportId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readActsOfAcceptanceByManufacturerExportIdManufacturerExportsManufacturerExportIdActsOfAcceptanceGet(manufacturerExportId: number, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readActsOfAcceptanceByManufacturerExportIdManufacturerExportsManufacturerExportIdActsOfAcceptanceGet(manufacturerExportId, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Aggregated Item Movements
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {Array<number>} [partners] 
     * @param {Array<number>} [warehouses] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readAggregatedItemMovementsReportsItemMovementsBalanceGet(fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readAggregatedItemMovementsReportsItemMovementsBalanceGet(fromDate, toDate, partners, warehouses, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All Item Batches
     * @param {number} itemId 
     * @param {Array<number>} [warehouses] 
     * @param {boolean} [skipDepleted] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readAllItemBatchesItemsItemIdBatchesGet(itemId: number, warehouses?: Array<number>, skipDepleted?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readAllItemBatchesItemsItemIdBatchesGet(itemId, warehouses, skipDepleted, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Availabilities
     * @param {Array<number>} [items] 
     * @param {Array<number>} [warehouses] 
     * @param {boolean} [realTime] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readAvailabilitiesAvailabilitiesGet(items?: Array<number>, warehouses?: Array<number>, realTime?: boolean, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readAvailabilitiesAvailabilitiesGet(items, warehouses, realTime, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Batch Infos
     * @param {Array<number>} [id] 
     * @param {Array<number>} [items] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readBatchInfosBatchInfosGet(id?: Array<number>, items?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readBatchInfosBatchInfosGet(id, items, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Client By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readClientByIdClientsIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readClientByIdClientsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Client Devices
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readClientDevicesClientsIdDevicesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readClientDevicesClientsIdDevicesGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Client Favorites
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readClientFavoritesClientsIdFavoritesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readClientFavoritesClientsIdFavoritesGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Client Shopping Carts
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readClientShoppingCartsClientsIdShoppingCartsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readClientShoppingCartsClientsIdShoppingCartsGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Clients
     * @param {Array<number>} [id] 
     * @param {boolean} [showInactive] 
     * @param {Array<number>} [partners] 
     * @param {string} [name] 
     * @param {string} [email] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readClientsClientsGet(id?: Array<number>, showInactive?: boolean, partners?: Array<number>, name?: string, email?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readClientsClientsGet(id, showInactive, partners, name, email, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Collected Batch Items
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readCollectedBatchItemsOrdersOrderIdCollectedBatchItemsGet(orderId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readCollectedBatchItemsOrdersOrderIdCollectedBatchItemsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Companies
     * @param {Array<number>} [id] 
     * @param {string} [query] 
     * @param {boolean} [showInactive] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readCompaniesCompaniesGet(id?: Array<number>, query?: string, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readCompaniesCompaniesGet(id, query, showInactive, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Company By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readCompanyByIdCompaniesIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readCompanyByIdCompaniesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Company Categories Paginated
     * @param {Array<number>} [id] 
     * @param {string} [query] 
     * @param {boolean} [leafOnly] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readCompanyCategoriesPaginatedCompanyCategoriesGet(id?: Array<number>, query?: string, leafOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readCompanyCategoriesPaginatedCompanyCategoriesGet(id, query, leafOnly, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Company Category By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readCompanyCategoryByIdCompanyCategoriesIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readCompanyCategoryByIdCompanyCategoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Credit Notes
     * @param {number} invoiceId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readCreditNotesInvoicesInvoiceIdCreditNotesGet(invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readCreditNotesInvoicesInvoiceIdCreditNotesGet(invoiceId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Batch
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryBatchOrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryBatchOrdersOrderIdDocumentsDeliveryBatchGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery
     * @param {number} deliveryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryDeliveriesDeliveryIdGet(deliveryId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryDeliveriesDeliveryIdGet(deliveryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Document Credit Note Items
     * @param {number} deliveryDocumentId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryDocumentCreditNoteItemsDeliveryDocumentsDeliveryDocumentIdCreditNoteItemsGet(deliveryDocumentId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryDocumentCreditNoteItemsDeliveryDocumentsDeliveryDocumentIdCreditNoteItemsGet(deliveryDocumentId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Document Remaining Amount
     * @param {number} deliveryDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryDocumentRemainingAmountDeliveryDocumentsDeliveryDocumentIdRemainingAmountGet(deliveryDocumentId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryDocumentRemainingAmountDeliveryDocumentsDeliveryDocumentIdRemainingAmountGet(deliveryDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Documents
     * @param {number} deliveryId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryDocumentsDeliveriesDeliveryIdDocumentsGet(deliveryId: number, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryDocumentsDeliveriesDeliveryIdDocumentsGet(deliveryId, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Documents
     * @param {string} [fromInvoiceDate] 
     * @param {string} [toInvoiceDate] 
     * @param {Array<number>} [suppliers] 
     * @param {Array<number>} [companies] 
     * @param {Array<string>} [documentType] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryDocumentsDeliveryDocumentsGet(fromInvoiceDate?: string, toInvoiceDate?: string, suppliers?: Array<number>, companies?: Array<number>, documentType?: Array<string>, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryDocumentsDeliveryDocumentsGet(fromInvoiceDate, toInvoiceDate, suppliers, companies, documentType, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Invoices
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [suppliers] 
     * @param {string} [companies] 
     * @param {boolean} [hidePaid] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryInvoicesReportsDeliveryInvoiceGet(fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryInvoicesReportsDeliveryInvoiceGet(fromDate, toDate, suppliers, companies, hidePaid, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Items
     * @param {number} deliveryId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryItemsDeliveriesDeliveryIdItemsGet(deliveryId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryItemsDeliveriesDeliveryIdItemsGet(deliveryId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Items
     * @param {string} [fromDeliveryDate] 
     * @param {string} [toDeliveryDate] 
     * @param {number} [supplierId] 
     * @param {Array<number>} [companies] 
     * @param {Array<number>} [warehouses] 
     * @param {Array<number>} [itemCategories] 
     * @param {number} [itemId] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryItemsReportsDeliveryItemsGet(fromDeliveryDate?: string, toDeliveryDate?: string, supplierId?: number, companies?: Array<number>, warehouses?: Array<number>, itemCategories?: Array<number>, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryItemsReportsDeliveryItemsGet(fromDeliveryDate, toDeliveryDate, supplierId, companies, warehouses, itemCategories, itemId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Remaining Amount
     * @param {number} deliveryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryRemainingAmountDeliveriesDeliveryIdRemainingAmountGet(deliveryId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryRemainingAmountDeliveriesDeliveryIdRemainingAmountGet(deliveryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Request By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryRequestByIdDeliveryRequestsIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryRequestByIdDeliveryRequestsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Request Items
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryRequestItemsDeliveryRequestsIdItemsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryRequestItemsDeliveryRequestsIdItemsGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Request Stock Receiving Process
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryRequestStockReceivingProcessDeliveryRequestsIdProcessGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryRequestStockReceivingProcessDeliveryRequestsIdProcessGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Requests
     * @param {Array<number>} [id] 
     * @param {string} [fromCreated] 
     * @param {string} [toCreated] 
     * @param {string} [fromDelivery] 
     * @param {string} [toDelivery] 
     * @param {Array<number>} [suppliers] 
     * @param {Array<number>} [warehouses] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryRequestsDeliveryRequestsGet(id?: Array<number>, fromCreated?: string, toCreated?: string, fromDelivery?: string, toDelivery?: string, suppliers?: Array<number>, warehouses?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryRequestsDeliveryRequestsGet(id, fromCreated, toCreated, fromDelivery, toDelivery, suppliers, warehouses, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Depleted Amounts With Prices
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [receivers] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [itemCategories] 
     * @param {number} [itemId] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDepletedAmountsWithPricesReportsDepletedAmountsWithPricesGet(fromDate?: string, toDate?: string, receivers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDepletedAmountsWithPricesReportsDepletedAmountsWithPricesGet(fromDate, toDate, receivers, companies, warehouses, itemCategories, itemId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Group By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readGroupByIdGroupsIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readGroupByIdGroupsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Groups
     * @param {Array<number>} [id] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readGroupsGroupsGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readGroupsGroupsGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readInvoiceByIdInvoicesIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readInvoiceByIdInvoicesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice Document
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readInvoiceDocumentInvoicesInvoiceIdDocumentGet(invoiceId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readInvoiceDocumentInvoicesInvoiceIdDocumentGet(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice Orders
     * @param {number} invoiceId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readInvoiceOrdersInvoicesInvoiceIdOrdersGet(invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readInvoiceOrdersInvoicesInvoiceIdOrdersGet(invoiceId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice Transactions
     * @param {number} invoiceId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readInvoiceTransactionsInvoicesInvoiceIdTransactionsGet(invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readInvoiceTransactionsInvoicesInvoiceIdTransactionsGet(invoiceId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoices
     * @param {Array<number>} [id] 
     * @param {string} [fromCreatedAt] 
     * @param {string} [toCreatedAt] 
     * @param {Array<number>} [legalEntities] 
     * @param {Array<number>} [companies] 
     * @param {boolean} [hideCancelled] 
     * @param {boolean} [hidePaidInFull] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readInvoicesInvoicesGet(id?: Array<number>, fromCreatedAt?: string, toCreatedAt?: string, legalEntities?: Array<number>, companies?: Array<number>, hideCancelled?: boolean, hidePaidInFull?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readInvoicesInvoicesGet(id, fromCreatedAt, toCreatedAt, legalEntities, companies, hideCancelled, hidePaidInFull, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Item By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readItemByIdItemsIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readItemByIdItemsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Item Categories Paginated
     * @param {Array<number>} [id] 
     * @param {string} [query] 
     * @param {boolean} [leafOnly] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readItemCategoriesPaginatedItemCategoriesGet(id?: Array<number>, query?: string, leafOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readItemCategoriesPaginatedItemCategoriesGet(id, query, leafOnly, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Item Category By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readItemCategoryByIdItemCategoriesIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readItemCategoryByIdItemCategoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Item Delivery Subscription By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readItemDeliverySubscriptionByIdItemDeliverySubscriptionsIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readItemDeliverySubscriptionByIdItemDeliverySubscriptionsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Items By Partner
     * @param {number} partnerId 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readItemsByPartnerPartnersPartnerIdItemsGet(partnerId: number, search?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readItemsByPartnerPartnersPartnerIdItemsGet(partnerId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Items
     * @param {Array<number>} [id] 
     * @param {Array<string>} [query] 
     * @param {Array<number>} [categories] 
     * @param {Array<number>} [suppliers] 
     * @param {Array<number>} [manufacturers] 
     * @param {number} [partner] 
     * @param {boolean} [showInactive] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readItemsItemsGet(id?: Array<number>, query?: Array<string>, categories?: Array<number>, suppliers?: Array<number>, manufacturers?: Array<number>, partner?: number, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readItemsItemsGet(id, query, categories, suppliers, manufacturers, partner, showInactive, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Last Delivery Country
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readLastDeliveryCountryItemsIdLastDeliveryCountryGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readLastDeliveryCountryItemsIdLastDeliveryCountryGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Last Delivery Price
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readLastDeliveryPriceItemsIdLastDeliveryPriceGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readLastDeliveryPriceItemsIdLastDeliveryPriceGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Legal Entities By Company Id
     * @param {number} companyId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readLegalEntitiesByCompanyIdCompaniesCompanyIdLegalEntitiesGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readLegalEntitiesByCompanyIdCompaniesCompanyIdLegalEntitiesGet(companyId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Legal Entities
     * @param {Array<number>} [id] 
     * @param {boolean} [showInactive] 
     * @param {string} [query] 
     * @param {number} [company] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readLegalEntitiesLegalEntitiesGet(id?: Array<number>, showInactive?: boolean, query?: string, company?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readLegalEntitiesLegalEntitiesGet(id, showInactive, query, company, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Legal Entity By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readLegalEntityByIdLegalEntitiesIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readLegalEntityByIdLegalEntitiesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Linked Item Associations For Item
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readLinkedItemAssociationsForItemItemsItemIdLinkedItemAssociationGet(itemId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readLinkedItemAssociationsForItemItemsItemIdLinkedItemAssociationGet(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read List
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readListPermissionsGet(page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readListPermissionsGet(page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Manifacturer By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readManifacturerByIdManufacturersIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readManifacturerByIdManufacturersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Manufacturer Export By Id
     * @param {number} manufacturerExportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readManufacturerExportByIdManufacturerExportsManufacturerExportIdGet(manufacturerExportId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readManufacturerExportByIdManufacturerExportsManufacturerExportIdGet(manufacturerExportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Manufacturer Exports
     * @param {Array<number>} [manufacturers] 
     * @param {string} [fromCreatedDate] 
     * @param {string} [toCreatedDate] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readManufacturerExportsManufacturerExportsGet(manufacturers?: Array<number>, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readManufacturerExportsManufacturerExportsGet(manufacturers, fromCreatedDate, toCreatedDate, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Manufacturer Exports
     * @param {number} manufacturerId 
     * @param {string} [fromCreatedDate] 
     * @param {string} [toCreatedDate] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readManufacturerExportsManufacturersManufacturerIdExportsGet(manufacturerId: number, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readManufacturerExportsManufacturersManufacturerIdExportsGet(manufacturerId, fromCreatedDate, toCreatedDate, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Manufacturer Items
     * @param {number} manufacturerId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readManufacturerItemsManufacturersManufacturerIdItemsGet(manufacturerId: number, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readManufacturerItemsManufacturersManufacturerIdItemsGet(manufacturerId, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Manufacturers
     * @param {Array<number>} [id] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readManufacturersManufacturersGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readManufacturersManufacturersGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Item
     * @param {number} orderId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderItemOrdersOrderIdItemsIdGet(orderId: number, id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderItemOrdersOrderIdItemsIdGet(orderId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Item
     * @param {number} orderId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderItemOrdersOrderIdItemsIdGet_3(orderId: number, id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderItemOrdersOrderIdItemsIdGet_3(orderId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Items
     * @param {number} orderId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderItemsOrdersOrderIdItemsGet(orderId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderItemsOrdersOrderIdItemsGet(orderId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Items
     * @param {number} orderId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderItemsOrdersOrderIdItemsGet_4(orderId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderItemsOrdersOrderIdItemsGet_4(orderId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderOrdersOrderIdGet(orderId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderOrdersOrderIdGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Status Logs
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderStatusLogsOrdersOrderIdStatusLogsGet(orderId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderStatusLogsOrdersOrderIdStatusLogsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Ordering Policy
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderingPolicyLegalEntitiesIdOrderingPolicyGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderingPolicyLegalEntitiesIdOrderingPolicyGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Ordering Policy
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderingPolicyPartnersIdOrderingPolicyGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderingPolicyPartnersIdOrderingPolicyGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Orders Balance
     * @param {string} [statusKeys] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {boolean} [noInvoiceOnly] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrdersBalanceReportsOrdersBalanceGet(statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrdersBalanceReportsOrdersBalanceGet(statusKeys, companies, warehouses, fromDate, toDate, noInvoiceOnly, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Partner Bonus Quantities
     * @param {Array<number>} [items] 
     * @param {Array<number>} [partners] 
     * @param {string} [fromStartDate] 
     * @param {string} [toStartDate] 
     * @param {string} [fromEndDate] 
     * @param {string} [toEndDate] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPartnerBonusQuantitiesPromotionsBonusQuantityGet(items?: Array<number>, partners?: Array<number>, fromStartDate?: string, toStartDate?: string, fromEndDate?: string, toEndDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPartnerBonusQuantitiesPromotionsBonusQuantityGet(items, partners, fromStartDate, toStartDate, fromEndDate, toEndDate, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Partner By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPartnerByIdPartnersIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPartnerByIdPartnersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Partner Item Configurations
     * @param {number} itemId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPartnerItemConfigurationsItemsItemIdPartnerConfigurationsGet(itemId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPartnerItemConfigurationsItemsItemIdPartnerConfigurationsGet(itemId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Partner Item Configurations
     * @param {number} partnerId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPartnerItemConfigurationsPartnersPartnerIdItemConfigurationGet(partnerId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPartnerItemConfigurationsPartnersPartnerIdItemConfigurationGet(partnerId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} partnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPartnersPartnerIdItemCategoryAssociationGet(partnerId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPartnersPartnerIdItemCategoryAssociationGet(partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} partnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPartnersPartnerIdItemCategoryAssociationGet_5(partnerId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPartnersPartnerIdItemCategoryAssociationGet_5(partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Partners
     * @param {Array<number>} [id] 
     * @param {boolean} [showInactive] 
     * @param {string} [query] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPartnersPartnersGet(id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPartnersPartnersGet(id, showInactive, query, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Payment Option By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPaymentOptionByIdPaymentOptionsIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPaymentOptionByIdPaymentOptionsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Payment Options By Company Id
     * @param {number} companyId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPaymentOptionsByCompanyIdCompaniesCompanyIdPaymentOptionsGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPaymentOptionsByCompanyIdCompaniesCompanyIdPaymentOptionsGet(companyId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Payment Options
     * @param {Array<number>} [id] 
     * @param {number} [company] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPaymentOptionsPaymentOptionsGet(id?: Array<number>, company?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPaymentOptionsPaymentOptionsGet(id, company, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Portal User By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPortalUserByIdPortalUsersIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPortalUserByIdPortalUsersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Portal Users
     * @param {Array<number>} [id] 
     * @param {boolean} [showInactive] 
     * @param {string} [query] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPortalUsersPortalUsersGet(id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPortalUsersPortalUsersGet(id, showInactive, query, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policies By Company Id
     * @param {number} companyId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPricingPoliciesByCompanyIdCompaniesCompanyIdPricingPoliciesGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPricingPoliciesByCompanyIdCompaniesCompanyIdPricingPoliciesGet(companyId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policies
     * @param {Array<number>} [id] 
     * @param {Array<number>} [companies] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPricingPoliciesPricingPoliciesGet(id?: Array<number>, companies?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPricingPoliciesPricingPoliciesGet(id, companies, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPricingPolicyByIdPricingPoliciesIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPricingPolicyByIdPricingPoliciesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Categories
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPricingPolicyItemCategoriesPricingPoliciesIdItemCategoriesGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPricingPolicyItemCategoriesPricingPoliciesIdItemCategoriesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Category Multipliers
     * @param {string} itemCategories 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPricingPolicyItemCategoryMultipliersReportsPricingPolicyItemCategoryMultipliersGet(itemCategories: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPricingPolicyItemCategoryMultipliersReportsPricingPolicyItemCategoryMultipliersGet(itemCategories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Exceptions
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPricingPolicyItemExceptionsPricingPoliciesIdItemExceptionsGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPricingPolicyItemExceptionsPricingPoliciesIdItemExceptionsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Prices
     * @param {string} items 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPricingPolicyItemPricesReportsPricingPolicyItemPricesGet(items: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPricingPolicyItemPricesReportsPricingPolicyItemPricesGet(items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Print Document Queue
     * @param {string} [state] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPrintDocumentQueuePrintDocumentQueueGet(state?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPrintDocumentQueuePrintDocumentQueueGet(state, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Promotion By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPromotionByIdPromotionsBonusQuantityIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPromotionByIdPromotionsBonusQuantityIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Role By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readRoleByIdRolesIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readRoleByIdRolesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Roles
     * @param {Array<number>} [id] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readRolesRolesGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readRolesRolesGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Sales
     * @param {string} [fromDeliveryDate] 
     * @param {string} [toDeliveryDate] 
     * @param {Array<number>} [receivers] 
     * @param {Array<number>} [legalEntities] 
     * @param {Array<number>} [companies] 
     * @param {Array<number>} [warehouses] 
     * @param {Array<number>} [itemCategories] 
     * @param {number} [itemId] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readSalesReportsSalesGet(fromDeliveryDate?: string, toDeliveryDate?: string, receivers?: Array<number>, legalEntities?: Array<number>, companies?: Array<number>, warehouses?: Array<number>, itemCategories?: Array<number>, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readSalesReportsSalesGet(fromDeliveryDate, toDeliveryDate, receivers, legalEntities, companies, warehouses, itemCategories, itemId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Shipping Route By Id
     * @param {number} shippingRouteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readShippingRouteByIdShippingRoutesShippingRouteIdGet(shippingRouteId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readShippingRouteByIdShippingRoutesShippingRouteIdGet(shippingRouteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Shipping Route Orders
     * @param {number} shippingRouteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readShippingRouteOrdersShippingRoutesShippingRouteIdOrdersGet(shippingRouteId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readShippingRouteOrdersShippingRoutesShippingRouteIdOrdersGet(shippingRouteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Shipping Routes
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {Array<number>} [users] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readShippingRoutesShippingRoutesGet(fromDate?: string, toDate?: string, users?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readShippingRoutesShippingRoutesGet(fromDate, toDate, users, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Shopping Cart By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readShoppingCartByIdShoppingCartsIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readShoppingCartByIdShoppingCartsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Shopping Cart Items
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readShoppingCartItemsShoppingCartsIdItemsGet(id: number, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readShoppingCartItemsShoppingCartsIdItemsGet(id, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Shopping Carts
     * @param {Array<number>} [id] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readShoppingCartsShoppingCartsGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readShoppingCartsShoppingCartsGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Similar Item Associations For Item
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readSimilarItemAssociationsForItemItemsItemIdSimilarItemAssociationGet(itemId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readSimilarItemAssociationsForItemItemsItemIdSimilarItemAssociationGet(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stock By Deliveries
     * @param {number} [companyId] 
     * @param {number} [warehouseId] 
     * @param {number} [itemId] 
     * @param {number} [manufacturerId] 
     * @param {Array<number>} [suppliers] 
     * @param {Array<number>} [itemCategories] 
     * @param {boolean} [hideDepleted] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStockByDeliveriesReportsStockByDeliveriesGet(companyId?: number, warehouseId?: number, itemId?: number, manufacturerId?: number, suppliers?: Array<number>, itemCategories?: Array<number>, hideDepleted?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStockByDeliveriesReportsStockByDeliveriesGet(companyId, warehouseId, itemId, manufacturerId, suppliers, itemCategories, hideDepleted, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stock Receiving Process By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStockReceivingProcessByIdStockReceivingProcessesIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStockReceivingProcessByIdStockReceivingProcessesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stock Receiving Process Items
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStockReceivingProcessItemsStockReceivingProcessesIdItemsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStockReceivingProcessItemsStockReceivingProcessesIdItemsGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stock Receiving Processes
     * @param {Array<number>} [id] 
     * @param {string} [fromCreated] 
     * @param {string} [toCreated] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStockReceivingProcessesStockReceivingProcessesGet(id?: Array<number>, fromCreated?: string, toCreated?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStockReceivingProcessesStockReceivingProcessesGet(id, fromCreated, toCreated, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stock Taking Report Items
     * @param {number} [itemId] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStockTakingReportItemsStockTakingReportItemsGet(itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStockTakingReportItemsStockTakingReportItemsGet(itemId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stock Taking Report
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStockTakingReportStockTakingReportsIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStockTakingReportStockTakingReportsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stock Taking Reports
     * @param {string} [fromExecutionDate] 
     * @param {string} [toExecutionDate] 
     * @param {number} [userId] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStockTakingReportsStockTakingReportsGet(fromExecutionDate?: string, toExecutionDate?: string, userId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStockTakingReportsStockTakingReportsGet(fromExecutionDate, toExecutionDate, userId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stocktaking By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStocktakingByIdStocktakingsIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStocktakingByIdStocktakingsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stocktaking Tasks
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStocktakingTasksStocktakingsIdTasksGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStocktakingTasksStocktakingsIdTasksGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stocktakings
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [warehouses] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStocktakingsStocktakingsGet(fromDate?: string, toDate?: string, warehouses?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStocktakingsStocktakingsGet(fromDate, toDate, warehouses, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Supplier By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readSupplierByIdSuppliersIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readSupplierByIdSuppliersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Supplier Price Differences
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readSupplierPriceDifferencesSuppliersIdPriceDifferencesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readSupplierPriceDifferencesSuppliersIdPriceDifferencesGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Supplier Quantity Differences
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readSupplierQuantityDifferencesSuppliersIdQuantityDifferencesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readSupplierQuantityDifferencesSuppliersIdQuantityDifferencesGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Supplier Reserve Subscription By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readSupplierReserveSubscriptionByIdSupplierReserveSubscriptionsIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readSupplierReserveSubscriptionByIdSupplierReserveSubscriptionsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Supplier Reserve Subscriptions
     * @param {Array<number>} [id] 
     * @param {Array<number>} [suppliers] 
     * @param {Array<string>} [weekday] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readSupplierReserveSubscriptionsSupplierReserveSubscriptionsGet(id?: Array<number>, suppliers?: Array<number>, weekday?: Array<string>, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readSupplierReserveSubscriptionsSupplierReserveSubscriptionsGet(id, suppliers, weekday, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Suppliers
     * @param {Array<number>} [id] 
     * @param {string} [query] 
     * @param {boolean} [showInactive] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readSuppliersSuppliersGet(id?: Array<number>, query?: string, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readSuppliersSuppliersGet(id, query, showInactive, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Transactions
     * @param {Array<number>} [id] 
     * @param {number} [invoiceId] 
     * @param {number} [deliveryInvoiceId] 
     * @param {Array<number>} [treasuries] 
     * @param {string} [fromCreatedDate] 
     * @param {string} [toCreatedDate] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readTransactionsTransactionsGet(id?: Array<number>, invoiceId?: number, deliveryInvoiceId?: number, treasuries?: Array<number>, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readTransactionsTransactionsGet(id, invoiceId, deliveryInvoiceId, treasuries, fromCreatedDate, toCreatedDate, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Treasuries
     * @param {Array<number>} [id] 
     * @param {number} [companyId] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readTreasuriesTreasuriesGet(id?: Array<number>, companyId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readTreasuriesTreasuriesGet(id, companyId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Treasury Bank Account Amount
     * @param {number} treasuryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readTreasuryBankAccountAmountTreasuriesTreasuryIdBankAccountAmountGet(treasuryId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readTreasuryBankAccountAmountTreasuriesTreasuryIdBankAccountAmountGet(treasuryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Treasury By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readTreasuryByIdTreasuriesIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readTreasuryByIdTreasuriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Treasury Cash Amount
     * @param {number} treasuryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readTreasuryCashAmountTreasuriesTreasuryIdCashAmountGet(treasuryId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readTreasuryCashAmountTreasuriesTreasuryIdCashAmountGet(treasuryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Treasury Transactions
     * @param {number} treasuryId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readTreasuryTransactionsTreasuriesTreasuryIdTransactionsGet(treasuryId: number, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readTreasuryTransactionsTreasuriesTreasuryIdTransactionsGet(treasuryId, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read User By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readUserByIdUsersIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readUserByIdUsersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Users By Company Id
     * @param {number} companyId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readUsersByCompanyIdCompaniesCompanyIdUsersGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readUsersByCompanyIdCompaniesCompanyIdUsersGet(companyId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Users
     * @param {Array<number>} [id] 
     * @param {boolean} [showInactive] 
     * @param {number} [company] 
     * @param {string} [query] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readUsersUsersGet(id?: Array<number>, showInactive?: boolean, company?: number, query?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readUsersUsersGet(id, showInactive, company, query, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Warehouse By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readWarehouseByIdWarehousesIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readWarehouseByIdWarehousesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Warehouse Movements
     * @param {Array<number>} [id] 
     * @param {Array<number>} [warehouses] 
     * @param {Array<number>} [items] 
     * @param {string} [fromExpirationDate] 
     * @param {string} [toExpirationDate] 
     * @param {string} [batchNumber] 
     * @param {string} [movementType] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readWarehouseMovementsWarehouseMovementsGet(id?: Array<number>, warehouses?: Array<number>, items?: Array<number>, fromExpirationDate?: string, toExpirationDate?: string, batchNumber?: string, movementType?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readWarehouseMovementsWarehouseMovementsGet(id, warehouses, items, fromExpirationDate, toExpirationDate, batchNumber, movementType, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Warehouse Stock By Orders
     * @param {number} warehouseId 
     * @param {string} toDate 
     * @param {boolean} [hideOrdersWithoutDeliveryDate] 
     * @param {boolean} [hideAvailable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readWarehouseStockByOrdersReportsStockByOrdersGet(warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readWarehouseStockByOrdersReportsStockByOrdersGet(warehouseId, toDate, hideOrdersWithoutDeliveryDate, hideAvailable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Warehouses
     * @param {Array<number>} [id] 
     * @param {boolean} [showInactive] 
     * @param {string} [query] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readWarehousesWarehousesGet(id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readWarehousesWarehousesGet(id, showInactive, query, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Ordering Policy
     * @param {number} id 
     * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public setOrderingPolicyLegalEntitiesIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).setOrderingPolicyLegalEntitiesIdOrderingPolicyPut(id, setOrderingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Ordering Policy
     * @param {number} id 
     * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public setOrderingPolicyPartnersIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).setOrderingPolicyPartnersIdOrderingPolicyPut(id, setOrderingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Partner Integration Settings
     * @param {number} partnerId 
     * @param {Request1} request1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost(partnerId: number, request1: Request1, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost(partnerId, request1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start Stock Receiving Process
     * @param {number} deliveryRequestId 
     * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId, startStockReceivingProcessRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test Barsy Connection
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public testBarsyConnectionPartnersIdTestBarsyConnectionPost(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).testBarsyConnectionPartnersIdTestBarsyConnectionPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Client
     * @param {number} id 
     * @param {UpdateClientRequest} updateClientRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateClientClientsIdPatch(id: number, updateClientRequest: UpdateClientRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateClientClientsIdPatch(id, updateClientRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Company Category
     * @param {number} id 
     * @param {SaveCompanyCategoryRequest} saveCompanyCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateCompanyCategoryCompanyCategoriesIdPut(id: number, saveCompanyCategoryRequest: SaveCompanyCategoryRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateCompanyCategoryCompanyCategoriesIdPut(id, saveCompanyCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Company
     * @param {number} id 
     * @param {UpdateCompanyRequest} updateCompanyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateCompanyCompaniesIdPut(id: number, updateCompanyRequest: UpdateCompanyRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateCompanyCompaniesIdPut(id, updateCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Delivery Document
     * @param {number} deliveryDocumentId 
     * @param {UpdateDeliveryDocumentRequest} updateDeliveryDocumentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut(deliveryDocumentId: number, updateDeliveryDocumentRequest: UpdateDeliveryDocumentRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut(deliveryDocumentId, updateDeliveryDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Delivery Request
     * @param {number} id 
     * @param {UpdateDeliveryRequestRequest} updateDeliveryRequestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateDeliveryRequestDeliveryRequestsIdPut(id: number, updateDeliveryRequestRequest: UpdateDeliveryRequestRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateDeliveryRequestDeliveryRequestsIdPut(id, updateDeliveryRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Group
     * @param {number} id 
     * @param {UpdateGroupRequest} updateGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateGroupGroupsIdPut(id: number, updateGroupRequest: UpdateGroupRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateGroupGroupsIdPut(id, updateGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Item Category
     * @param {number} id 
     * @param {SaveItemCategoryRequest} saveItemCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateItemCategoryItemCategoriesIdPut(id: number, saveItemCategoryRequest: SaveItemCategoryRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateItemCategoryItemCategoriesIdPut(id, saveItemCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Item Delivery Subscription
     * @param {number} id 
     * @param {PatchItemDeliverySubscriptionRequest} patchItemDeliverySubscriptionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut(id: number, patchItemDeliverySubscriptionRequest: PatchItemDeliverySubscriptionRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut(id, patchItemDeliverySubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Item
     * @param {number} id 
     * @param {SaveItemRequest} saveItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateItemItemsIdPut(id: number, saveItemRequest: SaveItemRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateItemItemsIdPut(id, saveItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Legal Entity
     * @param {number} id 
     * @param {SaveLegalEntityRequest} saveLegalEntityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateLegalEntityLegalEntitiesIdPut(id: number, saveLegalEntityRequest: SaveLegalEntityRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateLegalEntityLegalEntitiesIdPut(id, saveLegalEntityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Manufacturer
     * @param {number} id 
     * @param {SaveManufacturerRequest} saveManufacturerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateManufacturerManufacturersIdPut(id: number, saveManufacturerRequest: SaveManufacturerRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateManufacturerManufacturersIdPut(id, saveManufacturerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Order Item
     * @param {number} orderId 
     * @param {number} id 
     * @param {SaveOrderItemRequest} saveOrderItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateOrderItemOrdersOrderIdItemsIdPut(orderId: number, id: number, saveOrderItemRequest: SaveOrderItemRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateOrderItemOrdersOrderIdItemsIdPut(orderId, id, saveOrderItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Order Item
     * @param {number} orderId 
     * @param {number} id 
     * @param {SaveOrderItemRequest} saveOrderItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateOrderItemOrdersOrderIdItemsIdPut_6(orderId: number, id: number, saveOrderItemRequest: SaveOrderItemRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateOrderItemOrdersOrderIdItemsIdPut_6(orderId, id, saveOrderItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Partner Bonus Quantity
     * @param {number} id 
     * @param {PatchPartnerBonusQuantityRequest} patchPartnerBonusQuantityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updatePartnerBonusQuantityPromotionsBonusQuantityIdPut(id: number, patchPartnerBonusQuantityRequest: PatchPartnerBonusQuantityRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updatePartnerBonusQuantityPromotionsBonusQuantityIdPut(id, patchPartnerBonusQuantityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Partner Item Configuration
     * @param {number} id 
     * @param {SavePartnerItemConfigurationRequest} savePartnerItemConfigurationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updatePartnerItemConfigurationPartnersItemConfigurationIdPut(id: number, savePartnerItemConfigurationRequest: SavePartnerItemConfigurationRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updatePartnerItemConfigurationPartnersItemConfigurationIdPut(id, savePartnerItemConfigurationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Partner
     * @param {number} id 
     * @param {SavePartnerRequest} savePartnerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updatePartnerPartnersIdPut(id: number, savePartnerRequest: SavePartnerRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updatePartnerPartnersIdPut(id, savePartnerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {number} partnerId 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updatePartnersPartnerIdItemCategoryAssociationPost(partnerId: number, requestBody: Array<number>, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updatePartnersPartnerIdItemCategoryAssociationPost(partnerId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {number} partnerId 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updatePartnersPartnerIdItemCategoryAssociationPost_7(partnerId: number, requestBody: Array<number>, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updatePartnersPartnerIdItemCategoryAssociationPost_7(partnerId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Payment Option
     * @param {number} id 
     * @param {SavePaymentOptionRequest} savePaymentOptionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updatePaymentOptionPaymentOptionsIdPut(id: number, savePaymentOptionRequest: SavePaymentOptionRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updatePaymentOptionPaymentOptionsIdPut(id, savePaymentOptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {number} id 
     * @param {SavePricingPolicyRequest} savePricingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updatePricingPoliciesIdPut(id: number, savePricingPolicyRequest: SavePricingPolicyRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updatePricingPoliciesIdPut(id, savePricingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Print Document Queue
     * @param {number} id 
     * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updatePrintDocumentQueuePrintDocumentQueueIdPatch(id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updatePrintDocumentQueuePrintDocumentQueueIdPatch(id, updatePrintDocumentQueueRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Role
     * @param {number} id 
     * @param {UpdateRoleRequest} updateRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateRoleRolesIdPut(id: number, updateRoleRequest: UpdateRoleRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateRoleRolesIdPut(id, updateRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Stock Receiving Process
     * @param {number} deliveryRequestId 
     * @param {Request} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId: number, request: Request, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Supplier Reserve Subscription
     * @param {number} id 
     * @param {PatchSupplierReserveSubscriptionRequest} patchSupplierReserveSubscriptionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch(id: number, patchSupplierReserveSubscriptionRequest: PatchSupplierReserveSubscriptionRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch(id, patchSupplierReserveSubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Supplier
     * @param {number} id 
     * @param {SaveSupplierRequest} saveSupplierRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateSupplierSuppliersIdPut(id: number, saveSupplierRequest: SaveSupplierRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateSupplierSuppliersIdPut(id, saveSupplierRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Transaction
     * @param {number} id 
     * @param {PatchTransactionRequest} patchTransactionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateTransactionTransactionsIdPut(id: number, patchTransactionRequest: PatchTransactionRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateTransactionTransactionsIdPut(id, patchTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Treasury
     * @param {number} id 
     * @param {PatchTreasuryRequest} patchTreasuryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateTreasuryTreasuriesIdPut(id: number, patchTreasuryRequest: PatchTreasuryRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateTreasuryTreasuriesIdPut(id, patchTreasuryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User
     * @param {number} id 
     * @param {UpdateUserRequest} updateUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateUserUsersIdPut(id: number, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateUserUsersIdPut(id, updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Warehouse
     * @param {number} id 
     * @param {SaveWarehouseRequest} saveWarehouseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateWarehouseWarehousesIdPut(id: number, saveWarehouseRequest: SaveWarehouseRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateWarehouseWarehousesIdPut(id, saveWarehouseRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccessManagementApi - axios parameter creator
 * @export
 */
export const AccessManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read List
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListPermissionsGet: async (page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessManagementApi - functional programming interface
 * @export
 */
export const AccessManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read List
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readListPermissionsGet(page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePermissionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readListPermissionsGet(page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccessManagementApi - factory interface
 * @export
 */
export const AccessManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessManagementApiFp(configuration)
    return {
        /**
         * 
         * @summary Read List
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readListPermissionsGet(page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponsePermissionResponse> {
            return localVarFp.readListPermissionsGet(page, size, sort, order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccessManagementApi - object-oriented interface
 * @export
 * @class AccessManagementApi
 * @extends {BaseAPI}
 */
export class AccessManagementApi extends BaseAPI {
    /**
     * 
     * @summary Read List
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessManagementApi
     */
    public readListPermissionsGet(page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return AccessManagementApiFp(this.configuration).readListPermissionsGet(page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActsOfAcceptanceApi - axios parameter creator
 * @export
 */
export const ActsOfAcceptanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActOfAcceptanceActsOfAcceptanceActOfAcceptanceIdGet: async (actOfAcceptanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actOfAcceptanceId' is not null or undefined
            assertParamExists('readActOfAcceptanceActsOfAcceptanceActOfAcceptanceIdGet', 'actOfAcceptanceId', actOfAcceptanceId)
            const localVarPath = `/acts-of-acceptance/{act_of_acceptance_id}`
                .replace(`{${"act_of_acceptance_id"}}`, encodeURIComponent(String(actOfAcceptanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActOfAcceptanceByIdActsOfAcceptanceActOfAcceptanceIdDocumentGet: async (actOfAcceptanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actOfAcceptanceId' is not null or undefined
            assertParamExists('readActOfAcceptanceByIdActsOfAcceptanceActOfAcceptanceIdDocumentGet', 'actOfAcceptanceId', actOfAcceptanceId)
            const localVarPath = `/acts-of-acceptance/{act_of_acceptance_id}/document`
                .replace(`{${"act_of_acceptance_id"}}`, encodeURIComponent(String(actOfAcceptanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActOfAcceptanceItemsActsOfAcceptanceActOfAcceptanceIdItemsGet: async (actOfAcceptanceId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actOfAcceptanceId' is not null or undefined
            assertParamExists('readActOfAcceptanceItemsActsOfAcceptanceActOfAcceptanceIdItemsGet', 'actOfAcceptanceId', actOfAcceptanceId)
            const localVarPath = `/acts-of-acceptance/{act_of_acceptance_id}/items`
                .replace(`{${"act_of_acceptance_id"}}`, encodeURIComponent(String(actOfAcceptanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {Array<number>} [id] 
         * @param {string} [fromCreatedAt] 
         * @param {string} [toCreatedAt] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [users] 
         * @param {Array<string>} [orderType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActsOfAcceptanceActsOfAcceptanceGet: async (id?: Array<number>, fromCreatedAt?: string, toCreatedAt?: string, receivers?: Array<number>, legalEntities?: Array<number>, companies?: Array<number>, users?: Array<number>, orderType?: Array<string>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/acts-of-acceptance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (fromCreatedAt !== undefined) {
                localVarQueryParameter['from_created_at'] = (fromCreatedAt as any instanceof Date) ?
                    (fromCreatedAt as any).toISOString() :
                    fromCreatedAt;
            }

            if (toCreatedAt !== undefined) {
                localVarQueryParameter['to_created_at'] = (toCreatedAt as any instanceof Date) ?
                    (toCreatedAt as any).toISOString() :
                    toCreatedAt;
            }

            if (receivers) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (legalEntities) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (orderType) {
                localVarQueryParameter['order_type'] = orderType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActsOfAcceptanceApi - functional programming interface
 * @export
 */
export const ActsOfAcceptanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActsOfAcceptanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readActOfAcceptanceActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActOfAcceptanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readActOfAcceptanceByIdActsOfAcceptanceActOfAcceptanceIdDocumentGet(actOfAcceptanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceByIdActsOfAcceptanceActOfAcceptanceIdDocumentGet(actOfAcceptanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readActOfAcceptanceItemsActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseActOfAcceptanceItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActOfAcceptanceItemsActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {Array<number>} [id] 
         * @param {string} [fromCreatedAt] 
         * @param {string} [toCreatedAt] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [users] 
         * @param {Array<string>} [orderType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readActsOfAcceptanceActsOfAcceptanceGet(id?: Array<number>, fromCreatedAt?: string, toCreatedAt?: string, receivers?: Array<number>, legalEntities?: Array<number>, companies?: Array<number>, users?: Array<number>, orderType?: Array<string>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseActOfAcceptanceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActsOfAcceptanceActsOfAcceptanceGet(id, fromCreatedAt, toCreatedAt, receivers, legalEntities, companies, users, orderType, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActsOfAcceptanceApi - factory interface
 * @export
 */
export const ActsOfAcceptanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActsOfAcceptanceApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Act Of Acceptance
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActOfAcceptanceActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: any): AxiosPromise<ActOfAcceptanceResponse> {
            return localVarFp.readActOfAcceptanceActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance By Id
         * @param {number} actOfAcceptanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActOfAcceptanceByIdActsOfAcceptanceActOfAcceptanceIdDocumentGet(actOfAcceptanceId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readActOfAcceptanceByIdActsOfAcceptanceActOfAcceptanceIdDocumentGet(actOfAcceptanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Act Of Acceptance Items
         * @param {number} actOfAcceptanceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActOfAcceptanceItemsActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseActOfAcceptanceItemResponse> {
            return localVarFp.readActOfAcceptanceItemsActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Acts Of Acceptance
         * @param {Array<number>} [id] 
         * @param {string} [fromCreatedAt] 
         * @param {string} [toCreatedAt] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [users] 
         * @param {Array<string>} [orderType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActsOfAcceptanceActsOfAcceptanceGet(id?: Array<number>, fromCreatedAt?: string, toCreatedAt?: string, receivers?: Array<number>, legalEntities?: Array<number>, companies?: Array<number>, users?: Array<number>, orderType?: Array<string>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseActOfAcceptanceListResponse> {
            return localVarFp.readActsOfAcceptanceActsOfAcceptanceGet(id, fromCreatedAt, toCreatedAt, receivers, legalEntities, companies, users, orderType, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActsOfAcceptanceApi - object-oriented interface
 * @export
 * @class ActsOfAcceptanceApi
 * @extends {BaseAPI}
 */
export class ActsOfAcceptanceApi extends BaseAPI {
    /**
     * 
     * @summary Read Act Of Acceptance
     * @param {number} actOfAcceptanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActsOfAcceptanceApi
     */
    public readActOfAcceptanceActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId: number, options?: AxiosRequestConfig) {
        return ActsOfAcceptanceApiFp(this.configuration).readActOfAcceptanceActsOfAcceptanceActOfAcceptanceIdGet(actOfAcceptanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance By Id
     * @param {number} actOfAcceptanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActsOfAcceptanceApi
     */
    public readActOfAcceptanceByIdActsOfAcceptanceActOfAcceptanceIdDocumentGet(actOfAcceptanceId: number, options?: AxiosRequestConfig) {
        return ActsOfAcceptanceApiFp(this.configuration).readActOfAcceptanceByIdActsOfAcceptanceActOfAcceptanceIdDocumentGet(actOfAcceptanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Act Of Acceptance Items
     * @param {number} actOfAcceptanceId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActsOfAcceptanceApi
     */
    public readActOfAcceptanceItemsActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ActsOfAcceptanceApiFp(this.configuration).readActOfAcceptanceItemsActsOfAcceptanceActOfAcceptanceIdItemsGet(actOfAcceptanceId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Acts Of Acceptance
     * @param {Array<number>} [id] 
     * @param {string} [fromCreatedAt] 
     * @param {string} [toCreatedAt] 
     * @param {Array<number>} [receivers] 
     * @param {Array<number>} [legalEntities] 
     * @param {Array<number>} [companies] 
     * @param {Array<number>} [users] 
     * @param {Array<string>} [orderType] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActsOfAcceptanceApi
     */
    public readActsOfAcceptanceActsOfAcceptanceGet(id?: Array<number>, fromCreatedAt?: string, toCreatedAt?: string, receivers?: Array<number>, legalEntities?: Array<number>, companies?: Array<number>, users?: Array<number>, orderType?: Array<string>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ActsOfAcceptanceApiFp(this.configuration).readActsOfAcceptanceActsOfAcceptanceGet(id, fromCreatedAt, toCreatedAt, receivers, legalEntities, companies, users, orderType, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AvailabilitiesApi - axios parameter creator
 * @export
 */
export const AvailabilitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Availabilities
         * @param {Array<number>} [items] 
         * @param {Array<number>} [warehouses] 
         * @param {boolean} [realTime] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAvailabilitiesAvailabilitiesGet: async (items?: Array<number>, warehouses?: Array<number>, realTime?: boolean, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/availabilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (items) {
                localVarQueryParameter['items'] = items;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (realTime !== undefined) {
                localVarQueryParameter['real_time'] = realTime;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AvailabilitiesApi - functional programming interface
 * @export
 */
export const AvailabilitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AvailabilitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Availabilities
         * @param {Array<number>} [items] 
         * @param {Array<number>} [warehouses] 
         * @param {boolean} [realTime] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAvailabilitiesAvailabilitiesGet(items?: Array<number>, warehouses?: Array<number>, realTime?: boolean, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseAvailabilityListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAvailabilitiesAvailabilitiesGet(items, warehouses, realTime, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AvailabilitiesApi - factory interface
 * @export
 */
export const AvailabilitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AvailabilitiesApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Availabilities
         * @param {Array<number>} [items] 
         * @param {Array<number>} [warehouses] 
         * @param {boolean} [realTime] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAvailabilitiesAvailabilitiesGet(items?: Array<number>, warehouses?: Array<number>, realTime?: boolean, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseAvailabilityListResponse> {
            return localVarFp.readAvailabilitiesAvailabilitiesGet(items, warehouses, realTime, page, size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AvailabilitiesApi - object-oriented interface
 * @export
 * @class AvailabilitiesApi
 * @extends {BaseAPI}
 */
export class AvailabilitiesApi extends BaseAPI {
    /**
     * 
     * @summary Read Availabilities
     * @param {Array<number>} [items] 
     * @param {Array<number>} [warehouses] 
     * @param {boolean} [realTime] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvailabilitiesApi
     */
    public readAvailabilitiesAvailabilitiesGet(items?: Array<number>, warehouses?: Array<number>, realTime?: boolean, page?: number, size?: number, options?: AxiosRequestConfig) {
        return AvailabilitiesApiFp(this.configuration).readAvailabilitiesAvailabilitiesGet(items, warehouses, realTime, page, size, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BatchInfosApi - axios parameter creator
 * @export
 */
export const BatchInfosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Batch Infos
         * @param {Array<number>} [id] 
         * @param {Array<number>} [items] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBatchInfosBatchInfosGet: async (id?: Array<number>, items?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/batch-infos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (items) {
                localVarQueryParameter['items'] = items;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BatchInfosApi - functional programming interface
 * @export
 */
export const BatchInfosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BatchInfosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Batch Infos
         * @param {Array<number>} [id] 
         * @param {Array<number>} [items] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readBatchInfosBatchInfosGet(id?: Array<number>, items?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseBatchInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readBatchInfosBatchInfosGet(id, items, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BatchInfosApi - factory interface
 * @export
 */
export const BatchInfosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BatchInfosApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Batch Infos
         * @param {Array<number>} [id] 
         * @param {Array<number>} [items] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readBatchInfosBatchInfosGet(id?: Array<number>, items?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseBatchInfoResponse> {
            return localVarFp.readBatchInfosBatchInfosGet(id, items, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BatchInfosApi - object-oriented interface
 * @export
 * @class BatchInfosApi
 * @extends {BaseAPI}
 */
export class BatchInfosApi extends BaseAPI {
    /**
     * 
     * @summary Read Batch Infos
     * @param {Array<number>} [id] 
     * @param {Array<number>} [items] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchInfosApi
     */
    public readBatchInfosBatchInfosGet(id?: Array<number>, items?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return BatchInfosApiFp(this.configuration).readBatchInfosBatchInfosGet(id, items, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientsApi - axios parameter creator
 * @export
 */
export const ClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create
         * @param {CreateClientRequest} createClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientsPost: async (createClientRequest: CreateClientRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createClientRequest' is not null or undefined
            assertParamExists('createClientsPost', 'createClientRequest', createClientRequest)
            const localVarPath = `/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Client
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClientClientsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteClientClientsIdDelete', 'id', id)
            const localVarPath = `/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Client By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientByIdClientsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readClientByIdClientsIdGet', 'id', id)
            const localVarPath = `/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Client Devices
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientDevicesClientsIdDevicesGet: async (id: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readClientDevicesClientsIdDevicesGet', 'id', id)
            const localVarPath = `/clients/{id}/devices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Client Favorites
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientFavoritesClientsIdFavoritesGet: async (id: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readClientFavoritesClientsIdFavoritesGet', 'id', id)
            const localVarPath = `/clients/{id}/favorites`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Client Shopping Carts
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientShoppingCartsClientsIdShoppingCartsGet: async (id: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readClientShoppingCartsClientsIdShoppingCartsGet', 'id', id)
            const localVarPath = `/clients/{id}/shopping-carts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Clients
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {Array<number>} [partners] 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientsClientsGet: async (id?: Array<number>, showInactive?: boolean, partners?: Array<number>, name?: string, email?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (partners) {
                localVarQueryParameter['partners'] = partners;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Client
         * @param {number} id 
         * @param {UpdateClientRequest} updateClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientClientsIdPatch: async (id: number, updateClientRequest: UpdateClientRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClientClientsIdPatch', 'id', id)
            // verify required parameter 'updateClientRequest' is not null or undefined
            assertParamExists('updateClientClientsIdPatch', 'updateClientRequest', updateClientRequest)
            const localVarPath = `/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateClientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreateClientRequest} createClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClientsPost(createClientRequest: CreateClientRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClientsPost(createClientRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Client
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClientClientsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClientClientsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Client By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readClientByIdClientsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readClientByIdClientsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Client Devices
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readClientDevicesClientsIdDevicesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseClientDeviceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readClientDevicesClientsIdDevicesGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Client Favorites
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readClientFavoritesClientsIdFavoritesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseFavoriteItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readClientFavoritesClientsIdFavoritesGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Client Shopping Carts
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readClientShoppingCartsClientsIdShoppingCartsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseClientShoppingCartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readClientShoppingCartsClientsIdShoppingCartsGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Clients
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {Array<number>} [partners] 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readClientsClientsGet(id?: Array<number>, showInactive?: boolean, partners?: Array<number>, name?: string, email?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readClientsClientsGet(id, showInactive, partners, name, email, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Client
         * @param {number} id 
         * @param {UpdateClientRequest} updateClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientClientsIdPatch(id: number, updateClientRequest: UpdateClientRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientClientsIdPatch(id, updateClientRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreateClientRequest} createClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientsPost(createClientRequest: CreateClientRequest, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.createClientsPost(createClientRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Client
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClientClientsIdDelete(id: number, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.deleteClientClientsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Client By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientByIdClientsIdGet(id: number, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.readClientByIdClientsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Client Devices
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientDevicesClientsIdDevicesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseClientDeviceResponse> {
            return localVarFp.readClientDevicesClientsIdDevicesGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Client Favorites
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientFavoritesClientsIdFavoritesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseFavoriteItemResponse> {
            return localVarFp.readClientFavoritesClientsIdFavoritesGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Client Shopping Carts
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientShoppingCartsClientsIdShoppingCartsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseClientShoppingCartResponse> {
            return localVarFp.readClientShoppingCartsClientsIdShoppingCartsGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Clients
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {Array<number>} [partners] 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientsClientsGet(id?: Array<number>, showInactive?: boolean, partners?: Array<number>, name?: string, email?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseClientResponse> {
            return localVarFp.readClientsClientsGet(id, showInactive, partners, name, email, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Client
         * @param {number} id 
         * @param {UpdateClientRequest} updateClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientClientsIdPatch(id: number, updateClientRequest: UpdateClientRequest, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.updateClientClientsIdPatch(id, updateClientRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
    /**
     * 
     * @summary Create
     * @param {CreateClientRequest} createClientRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public createClientsPost(createClientRequest: CreateClientRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).createClientsPost(createClientRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Client
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public deleteClientClientsIdDelete(id: number, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).deleteClientClientsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Client By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public readClientByIdClientsIdGet(id: number, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).readClientByIdClientsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Client Devices
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public readClientDevicesClientsIdDevicesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).readClientDevicesClientsIdDevicesGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Client Favorites
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public readClientFavoritesClientsIdFavoritesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).readClientFavoritesClientsIdFavoritesGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Client Shopping Carts
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public readClientShoppingCartsClientsIdShoppingCartsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).readClientShoppingCartsClientsIdShoppingCartsGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Clients
     * @param {Array<number>} [id] 
     * @param {boolean} [showInactive] 
     * @param {Array<number>} [partners] 
     * @param {string} [name] 
     * @param {string} [email] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public readClientsClientsGet(id?: Array<number>, showInactive?: boolean, partners?: Array<number>, name?: string, email?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).readClientsClientsGet(id, showInactive, partners, name, email, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Client
     * @param {number} id 
     * @param {UpdateClientRequest} updateClientRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public updateClientClientsIdPatch(id: number, updateClientRequest: UpdateClientRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).updateClientClientsIdPatch(id, updateClientRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommonApi - axios parameter creator
 * @export
 */
export const CommonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckHealthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonApi - functional programming interface
 * @export
 */
export const CommonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCheckHealthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheckHealthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommonApi - factory interface
 * @export
 */
export const CommonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeMeGet(options?: any): AxiosPromise<UserAccessResponse> {
            return localVarFp.getMeMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckHealthGet(options?: any): AxiosPromise<any> {
            return localVarFp.healthCheckHealthGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
export class CommonApi extends BaseAPI {
    /**
     * 
     * @summary Get Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public getMeMeGet(options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).getMeMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public healthCheckHealthGet(options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).healthCheckHealthGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompaniesPost: async (createCompanyRequest: CreateCompanyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyRequest' is not null or undefined
            assertParamExists('createCompaniesPost', 'createCompanyRequest', createCompanyRequest)
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {SaveCompanyCategoryRequest} saveCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyCategoriesPost: async (saveCompanyCategoryRequest: SaveCompanyCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveCompanyCategoryRequest' is not null or undefined
            assertParamExists('createCompanyCategoriesPost', 'saveCompanyCategoryRequest', saveCompanyCategoryRequest)
            const localVarPath = `/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveCompanyCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Companies
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [showInactive] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompaniesCompaniesGet: async (id?: Array<number>, query?: string, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Company By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanyByIdCompaniesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readCompanyByIdCompaniesIdGet', 'id', id)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Company Categories Paginated
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [leafOnly] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanyCategoriesPaginatedCompanyCategoriesGet: async (id?: Array<number>, query?: string, leafOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (leafOnly !== undefined) {
                localVarQueryParameter['leaf_only'] = leafOnly;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Company Category By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanyCategoryByIdCompanyCategoriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readCompanyCategoryByIdCompanyCategoriesIdGet', 'id', id)
            const localVarPath = `/company-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Legal Entities By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLegalEntitiesByCompanyIdCompaniesCompanyIdLegalEntitiesGet: async (companyId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('readLegalEntitiesByCompanyIdCompaniesCompanyIdLegalEntitiesGet', 'companyId', companyId)
            const localVarPath = `/companies/{company_id}/legal-entities`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Payment Options By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPaymentOptionsByCompanyIdCompaniesCompanyIdPaymentOptionsGet: async (companyId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('readPaymentOptionsByCompanyIdCompaniesCompanyIdPaymentOptionsGet', 'companyId', companyId)
            const localVarPath = `/companies/{company_id}/payment-options`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policies By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPoliciesByCompanyIdCompaniesCompanyIdPricingPoliciesGet: async (companyId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('readPricingPoliciesByCompanyIdCompaniesCompanyIdPricingPoliciesGet', 'companyId', companyId)
            const localVarPath = `/companies/{company_id}/pricing-policies`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Users By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersByCompanyIdCompaniesCompanyIdUsersGet: async (companyId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('readUsersByCompanyIdCompaniesCompanyIdUsersGet', 'companyId', companyId)
            const localVarPath = `/companies/{company_id}/users`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Company Category
         * @param {number} id 
         * @param {SaveCompanyCategoryRequest} saveCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyCategoryCompanyCategoriesIdPut: async (id: number, saveCompanyCategoryRequest: SaveCompanyCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCompanyCategoryCompanyCategoriesIdPut', 'id', id)
            // verify required parameter 'saveCompanyCategoryRequest' is not null or undefined
            assertParamExists('updateCompanyCategoryCompanyCategoriesIdPut', 'saveCompanyCategoryRequest', saveCompanyCategoryRequest)
            const localVarPath = `/company-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveCompanyCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Company
         * @param {number} id 
         * @param {UpdateCompanyRequest} updateCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyCompaniesIdPut: async (id: number, updateCompanyRequest: UpdateCompanyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCompanyCompaniesIdPut', 'id', id)
            // verify required parameter 'updateCompanyRequest' is not null or undefined
            assertParamExists('updateCompanyCompaniesIdPut', 'updateCompanyRequest', updateCompanyRequest)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompaniesPost(createCompanyRequest: CreateCompanyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompaniesPost(createCompanyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {SaveCompanyCategoryRequest} saveCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompanyCategoriesPost(saveCompanyCategoryRequest: SaveCompanyCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompanyCategoriesPost(saveCompanyCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Companies
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [showInactive] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCompaniesCompaniesGet(id?: Array<number>, query?: string, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseCompanyListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCompaniesCompaniesGet(id, query, showInactive, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Company By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCompanyByIdCompaniesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCompanyByIdCompaniesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Company Categories Paginated
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [leafOnly] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCompanyCategoriesPaginatedCompanyCategoriesGet(id?: Array<number>, query?: string, leafOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseCompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCompanyCategoriesPaginatedCompanyCategoriesGet(id, query, leafOnly, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Company Category By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCompanyCategoryByIdCompanyCategoriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCompanyCategoryByIdCompanyCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Legal Entities By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readLegalEntitiesByCompanyIdCompaniesCompanyIdLegalEntitiesGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseLegalEntityListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readLegalEntitiesByCompanyIdCompaniesCompanyIdLegalEntitiesGet(companyId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Payment Options By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPaymentOptionsByCompanyIdCompaniesCompanyIdPaymentOptionsGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePaymentOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPaymentOptionsByCompanyIdCompaniesCompanyIdPaymentOptionsGet(companyId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policies By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPoliciesByCompanyIdCompaniesCompanyIdPricingPoliciesGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePricingPolicySimpleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPoliciesByCompanyIdCompaniesCompanyIdPricingPoliciesGet(companyId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Users By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUsersByCompanyIdCompaniesCompanyIdUsersGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseUserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUsersByCompanyIdCompaniesCompanyIdUsersGet(companyId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Company Category
         * @param {number} id 
         * @param {SaveCompanyCategoryRequest} saveCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyCategoryCompanyCategoriesIdPut(id: number, saveCompanyCategoryRequest: SaveCompanyCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyCategoryCompanyCategoriesIdPut(id, saveCompanyCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Company
         * @param {number} id 
         * @param {UpdateCompanyRequest} updateCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyCompaniesIdPut(id: number, updateCompanyRequest: UpdateCompanyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyCompaniesIdPut(id, updateCompanyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompaniesPost(createCompanyRequest: CreateCompanyRequest, options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.createCompaniesPost(createCompanyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {SaveCompanyCategoryRequest} saveCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyCategoriesPost(saveCompanyCategoryRequest: SaveCompanyCategoryRequest, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.createCompanyCategoriesPost(saveCompanyCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Companies
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [showInactive] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompaniesCompaniesGet(id?: Array<number>, query?: string, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseCompanyListResponse> {
            return localVarFp.readCompaniesCompaniesGet(id, query, showInactive, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Company By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanyByIdCompaniesIdGet(id: number, options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.readCompanyByIdCompaniesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Company Categories Paginated
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [leafOnly] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanyCategoriesPaginatedCompanyCategoriesGet(id?: Array<number>, query?: string, leafOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseCompanyCategoryResponse> {
            return localVarFp.readCompanyCategoriesPaginatedCompanyCategoriesGet(id, query, leafOnly, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Company Category By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompanyCategoryByIdCompanyCategoriesIdGet(id: number, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.readCompanyCategoryByIdCompanyCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Legal Entities By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLegalEntitiesByCompanyIdCompaniesCompanyIdLegalEntitiesGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseLegalEntityListResponse> {
            return localVarFp.readLegalEntitiesByCompanyIdCompaniesCompanyIdLegalEntitiesGet(companyId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Payment Options By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPaymentOptionsByCompanyIdCompaniesCompanyIdPaymentOptionsGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponsePaymentOptionResponse> {
            return localVarFp.readPaymentOptionsByCompanyIdCompaniesCompanyIdPaymentOptionsGet(companyId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policies By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPoliciesByCompanyIdCompaniesCompanyIdPricingPoliciesGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponsePricingPolicySimpleResponse> {
            return localVarFp.readPricingPoliciesByCompanyIdCompaniesCompanyIdPricingPoliciesGet(companyId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Users By Company Id
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersByCompanyIdCompaniesCompanyIdUsersGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseUserListResponse> {
            return localVarFp.readUsersByCompanyIdCompaniesCompanyIdUsersGet(companyId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Company Category
         * @param {number} id 
         * @param {SaveCompanyCategoryRequest} saveCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyCategoryCompanyCategoriesIdPut(id: number, saveCompanyCategoryRequest: SaveCompanyCategoryRequest, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.updateCompanyCategoryCompanyCategoriesIdPut(id, saveCompanyCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Company
         * @param {number} id 
         * @param {UpdateCompanyRequest} updateCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyCompaniesIdPut(id: number, updateCompanyRequest: UpdateCompanyRequest, options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.updateCompanyCompaniesIdPut(id, updateCompanyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
    /**
     * 
     * @summary Create
     * @param {CreateCompanyRequest} createCompanyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public createCompaniesPost(createCompanyRequest: CreateCompanyRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).createCompaniesPost(createCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {SaveCompanyCategoryRequest} saveCompanyCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public createCompanyCategoriesPost(saveCompanyCategoryRequest: SaveCompanyCategoryRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).createCompanyCategoriesPost(saveCompanyCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Companies
     * @param {Array<number>} [id] 
     * @param {string} [query] 
     * @param {boolean} [showInactive] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public readCompaniesCompaniesGet(id?: Array<number>, query?: string, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).readCompaniesCompaniesGet(id, query, showInactive, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Company By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public readCompanyByIdCompaniesIdGet(id: number, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).readCompanyByIdCompaniesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Company Categories Paginated
     * @param {Array<number>} [id] 
     * @param {string} [query] 
     * @param {boolean} [leafOnly] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public readCompanyCategoriesPaginatedCompanyCategoriesGet(id?: Array<number>, query?: string, leafOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).readCompanyCategoriesPaginatedCompanyCategoriesGet(id, query, leafOnly, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Company Category By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public readCompanyCategoryByIdCompanyCategoriesIdGet(id: number, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).readCompanyCategoryByIdCompanyCategoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Legal Entities By Company Id
     * @param {number} companyId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public readLegalEntitiesByCompanyIdCompaniesCompanyIdLegalEntitiesGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).readLegalEntitiesByCompanyIdCompaniesCompanyIdLegalEntitiesGet(companyId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Payment Options By Company Id
     * @param {number} companyId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public readPaymentOptionsByCompanyIdCompaniesCompanyIdPaymentOptionsGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).readPaymentOptionsByCompanyIdCompaniesCompanyIdPaymentOptionsGet(companyId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policies By Company Id
     * @param {number} companyId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public readPricingPoliciesByCompanyIdCompaniesCompanyIdPricingPoliciesGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).readPricingPoliciesByCompanyIdCompaniesCompanyIdPricingPoliciesGet(companyId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Users By Company Id
     * @param {number} companyId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public readUsersByCompanyIdCompaniesCompanyIdUsersGet(companyId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).readUsersByCompanyIdCompaniesCompanyIdUsersGet(companyId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Company Category
     * @param {number} id 
     * @param {SaveCompanyCategoryRequest} saveCompanyCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public updateCompanyCategoryCompanyCategoriesIdPut(id: number, saveCompanyCategoryRequest: SaveCompanyCategoryRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).updateCompanyCategoryCompanyCategoriesIdPut(id, saveCompanyCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Company
     * @param {number} id 
     * @param {UpdateCompanyRequest} updateCompanyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public updateCompanyCompaniesIdPut(id: number, updateCompanyRequest: UpdateCompanyRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).updateCompanyCompaniesIdPut(id, updateCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginTokenPost: async (username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('loginTokenPost', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('loginTokenPost', 'password', password)
            const localVarPath = `/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginTokenPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginTokenPost(username, password, grantType, scope, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginTokenPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: any): AxiosPromise<any> {
            return localVarFp.loginTokenPost(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Login
     * @param {string} username 
     * @param {string} password 
     * @param {string} [grantType] 
     * @param {string} [scope] 
     * @param {string} [clientId] 
     * @param {string} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loginTokenPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).loginTokenPost(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeliveriesApi - axios parameter creator
 * @export
 */
export const DeliveriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Delivery
         * @param {CreateDeliveryRequest} createDeliveryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryDeliveriesPost: async (createDeliveryRequest: CreateDeliveryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeliveryRequest' is not null or undefined
            assertParamExists('createDeliveryDeliveriesPost', 'createDeliveryRequest', createDeliveryRequest)
            const localVarPath = `/deliveries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeliveryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Delivery
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeliveryDeliveriesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDeliveryDeliveriesIdDelete', 'id', id)
            const localVarPath = `/deliveries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Deliveries
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [warehouses] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveriesDeliveriesGet: async (suppliers?: Array<number>, warehouses?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deliveries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (suppliers) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (fromDeliveryDate !== undefined) {
                localVarQueryParameter['from_delivery_date'] = (fromDeliveryDate as any instanceof Date) ?
                    (fromDeliveryDate as any).toISOString() :
                    fromDeliveryDate;
            }

            if (toDeliveryDate !== undefined) {
                localVarQueryParameter['to_delivery_date'] = (toDeliveryDate as any instanceof Date) ?
                    (toDeliveryDate as any).toISOString() :
                    toDeliveryDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDeliveriesDeliveryIdGet: async (deliveryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryId' is not null or undefined
            assertParamExists('readDeliveryDeliveriesDeliveryIdGet', 'deliveryId', deliveryId)
            const localVarPath = `/deliveries/{delivery_id}`
                .replace(`{${"delivery_id"}}`, encodeURIComponent(String(deliveryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Documents
         * @param {number} deliveryId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentsDeliveriesDeliveryIdDocumentsGet: async (deliveryId: number, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryId' is not null or undefined
            assertParamExists('readDeliveryDocumentsDeliveriesDeliveryIdDocumentsGet', 'deliveryId', deliveryId)
            const localVarPath = `/deliveries/{delivery_id}/documents`
                .replace(`{${"delivery_id"}}`, encodeURIComponent(String(deliveryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {number} deliveryId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryItemsDeliveriesDeliveryIdItemsGet: async (deliveryId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryId' is not null or undefined
            assertParamExists('readDeliveryItemsDeliveriesDeliveryIdItemsGet', 'deliveryId', deliveryId)
            const localVarPath = `/deliveries/{delivery_id}/items`
                .replace(`{${"delivery_id"}}`, encodeURIComponent(String(deliveryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Remaining Amount
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRemainingAmountDeliveriesDeliveryIdRemainingAmountGet: async (deliveryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryId' is not null or undefined
            assertParamExists('readDeliveryRemainingAmountDeliveriesDeliveryIdRemainingAmountGet', 'deliveryId', deliveryId)
            const localVarPath = `/deliveries/{delivery_id}/remaining-amount`
                .replace(`{${"delivery_id"}}`, encodeURIComponent(String(deliveryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveriesApi - functional programming interface
 * @export
 */
export const DeliveriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Delivery
         * @param {CreateDeliveryRequest} createDeliveryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeliveryDeliveriesPost(createDeliveryRequest: CreateDeliveryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeliveryDeliveriesPost(createDeliveryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Delivery
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeliveryDeliveriesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeliveryDeliveriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Deliveries
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [warehouses] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeliveriesDeliveriesGet(suppliers?: Array<number>, warehouses?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseDeliveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeliveriesDeliveriesGet(suppliers, warehouses, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryDeliveriesDeliveryIdGet(deliveryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryDeliveriesDeliveryIdGet(deliveryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Documents
         * @param {number} deliveryId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryDocumentsDeliveriesDeliveryIdDocumentsGet(deliveryId: number, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseDeliveryDocumentListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryDocumentsDeliveriesDeliveryIdDocumentsGet(deliveryId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {number} deliveryId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryItemsDeliveriesDeliveryIdItemsGet(deliveryId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseDeliveryItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryItemsDeliveriesDeliveryIdItemsGet(deliveryId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Remaining Amount
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryRemainingAmountDeliveriesDeliveryIdRemainingAmountGet(deliveryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryRemainingAmountDeliveriesDeliveryIdRemainingAmountGet(deliveryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeliveriesApi - factory interface
 * @export
 */
export const DeliveriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Delivery
         * @param {CreateDeliveryRequest} createDeliveryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryDeliveriesPost(createDeliveryRequest: CreateDeliveryRequest, options?: any): AxiosPromise<DeliveryResponse> {
            return localVarFp.createDeliveryDeliveriesPost(createDeliveryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Delivery
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeliveryDeliveriesIdDelete(id: number, options?: any): AxiosPromise<DeliveryResponse> {
            return localVarFp.deleteDeliveryDeliveriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Deliveries
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [warehouses] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveriesDeliveriesGet(suppliers?: Array<number>, warehouses?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseDeliveryResponse> {
            return localVarFp.getDeliveriesDeliveriesGet(suppliers, warehouses, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDeliveriesDeliveryIdGet(deliveryId: number, options?: any): AxiosPromise<DeliveryResponse> {
            return localVarFp.readDeliveryDeliveriesDeliveryIdGet(deliveryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Documents
         * @param {number} deliveryId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentsDeliveriesDeliveryIdDocumentsGet(deliveryId: number, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseDeliveryDocumentListResponse> {
            return localVarFp.readDeliveryDocumentsDeliveriesDeliveryIdDocumentsGet(deliveryId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {number} deliveryId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryItemsDeliveriesDeliveryIdItemsGet(deliveryId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseDeliveryItemResponse> {
            return localVarFp.readDeliveryItemsDeliveriesDeliveryIdItemsGet(deliveryId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Remaining Amount
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRemainingAmountDeliveriesDeliveryIdRemainingAmountGet(deliveryId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readDeliveryRemainingAmountDeliveriesDeliveryIdRemainingAmountGet(deliveryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliveriesApi - object-oriented interface
 * @export
 * @class DeliveriesApi
 * @extends {BaseAPI}
 */
export class DeliveriesApi extends BaseAPI {
    /**
     * 
     * @summary Create Delivery
     * @param {CreateDeliveryRequest} createDeliveryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public createDeliveryDeliveriesPost(createDeliveryRequest: CreateDeliveryRequest, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).createDeliveryDeliveriesPost(createDeliveryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Delivery
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public deleteDeliveryDeliveriesIdDelete(id: number, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).deleteDeliveryDeliveriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Deliveries
     * @param {Array<number>} [suppliers] 
     * @param {Array<number>} [warehouses] 
     * @param {string} [fromDeliveryDate] 
     * @param {string} [toDeliveryDate] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public getDeliveriesDeliveriesGet(suppliers?: Array<number>, warehouses?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).getDeliveriesDeliveriesGet(suppliers, warehouses, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery
     * @param {number} deliveryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public readDeliveryDeliveriesDeliveryIdGet(deliveryId: number, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).readDeliveryDeliveriesDeliveryIdGet(deliveryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Documents
     * @param {number} deliveryId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public readDeliveryDocumentsDeliveriesDeliveryIdDocumentsGet(deliveryId: number, page?: number, size?: number, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).readDeliveryDocumentsDeliveriesDeliveryIdDocumentsGet(deliveryId, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Items
     * @param {number} deliveryId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public readDeliveryItemsDeliveriesDeliveryIdItemsGet(deliveryId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).readDeliveryItemsDeliveriesDeliveryIdItemsGet(deliveryId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Remaining Amount
     * @param {number} deliveryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesApi
     */
    public readDeliveryRemainingAmountDeliveriesDeliveryIdRemainingAmountGet(deliveryId: number, options?: AxiosRequestConfig) {
        return DeliveriesApiFp(this.configuration).readDeliveryRemainingAmountDeliveriesDeliveryIdRemainingAmountGet(deliveryId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeliveryDocumentsApi - axios parameter creator
 * @export
 */
export const DeliveryDocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Delivery Document
         * @param {CreateDeliveryDocumentRequest} createDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryDocumentDeliveryDocumentsPost: async (createDeliveryDocumentRequest: CreateDeliveryDocumentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeliveryDocumentRequest' is not null or undefined
            assertParamExists('createDeliveryDocumentDeliveryDocumentsPost', 'createDeliveryDocumentRequest', createDeliveryDocumentRequest)
            const localVarPath = `/delivery-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeliveryDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} deliveryDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeliveryDocumentsDeliveryDocumentIdDelete: async (deliveryDocumentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryDocumentId' is not null or undefined
            assertParamExists('deleteDeliveryDocumentsDeliveryDocumentIdDelete', 'deliveryDocumentId', deliveryDocumentId)
            const localVarPath = `/delivery-documents/{delivery_document_id}`
                .replace(`{${"delivery_document_id"}}`, encodeURIComponent(String(deliveryDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Document Credit Note Items
         * @param {number} deliveryDocumentId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentCreditNoteItemsDeliveryDocumentsDeliveryDocumentIdCreditNoteItemsGet: async (deliveryDocumentId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryDocumentId' is not null or undefined
            assertParamExists('readDeliveryDocumentCreditNoteItemsDeliveryDocumentsDeliveryDocumentIdCreditNoteItemsGet', 'deliveryDocumentId', deliveryDocumentId)
            const localVarPath = `/delivery-documents/{delivery_document_id}/credit-note-items`
                .replace(`{${"delivery_document_id"}}`, encodeURIComponent(String(deliveryDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Document Remaining Amount
         * @param {number} deliveryDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentRemainingAmountDeliveryDocumentsDeliveryDocumentIdRemainingAmountGet: async (deliveryDocumentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryDocumentId' is not null or undefined
            assertParamExists('readDeliveryDocumentRemainingAmountDeliveryDocumentsDeliveryDocumentIdRemainingAmountGet', 'deliveryDocumentId', deliveryDocumentId)
            const localVarPath = `/delivery-documents/{delivery_document_id}/remaining-amount`
                .replace(`{${"delivery_document_id"}}`, encodeURIComponent(String(deliveryDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Documents
         * @param {string} [fromInvoiceDate] 
         * @param {string} [toInvoiceDate] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [companies] 
         * @param {Array<string>} [documentType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentsDeliveryDocumentsGet: async (fromInvoiceDate?: string, toInvoiceDate?: string, suppliers?: Array<number>, companies?: Array<number>, documentType?: Array<string>, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/delivery-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromInvoiceDate !== undefined) {
                localVarQueryParameter['from_invoice_date'] = (fromInvoiceDate as any instanceof Date) ?
                    (fromInvoiceDate as any).toISOString() :
                    fromInvoiceDate;
            }

            if (toInvoiceDate !== undefined) {
                localVarQueryParameter['to_invoice_date'] = (toInvoiceDate as any instanceof Date) ?
                    (toInvoiceDate as any).toISOString() :
                    toInvoiceDate;
            }

            if (suppliers) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }

            if (documentType) {
                localVarQueryParameter['document_type'] = documentType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Delivery Document
         * @param {number} deliveryDocumentId 
         * @param {UpdateDeliveryDocumentRequest} updateDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut: async (deliveryDocumentId: number, updateDeliveryDocumentRequest: UpdateDeliveryDocumentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryDocumentId' is not null or undefined
            assertParamExists('updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut', 'deliveryDocumentId', deliveryDocumentId)
            // verify required parameter 'updateDeliveryDocumentRequest' is not null or undefined
            assertParamExists('updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut', 'updateDeliveryDocumentRequest', updateDeliveryDocumentRequest)
            const localVarPath = `/delivery-documents/{delivery_document_id}`
                .replace(`{${"delivery_document_id"}}`, encodeURIComponent(String(deliveryDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliveryDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveryDocumentsApi - functional programming interface
 * @export
 */
export const DeliveryDocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveryDocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Delivery Document
         * @param {CreateDeliveryDocumentRequest} createDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeliveryDocumentDeliveryDocumentsPost(createDeliveryDocumentRequest: CreateDeliveryDocumentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeliveryDocumentDeliveryDocumentsPost(createDeliveryDocumentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} deliveryDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeliveryDocumentsDeliveryDocumentIdDelete(deliveryDocumentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeliveryDocumentsDeliveryDocumentIdDelete(deliveryDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Document Credit Note Items
         * @param {number} deliveryDocumentId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryDocumentCreditNoteItemsDeliveryDocumentsDeliveryDocumentIdCreditNoteItemsGet(deliveryDocumentId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseDeliveryDocumentCreditNoteItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryDocumentCreditNoteItemsDeliveryDocumentsDeliveryDocumentIdCreditNoteItemsGet(deliveryDocumentId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Document Remaining Amount
         * @param {number} deliveryDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryDocumentRemainingAmountDeliveryDocumentsDeliveryDocumentIdRemainingAmountGet(deliveryDocumentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryDocumentRemainingAmountDeliveryDocumentsDeliveryDocumentIdRemainingAmountGet(deliveryDocumentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Documents
         * @param {string} [fromInvoiceDate] 
         * @param {string} [toInvoiceDate] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [companies] 
         * @param {Array<string>} [documentType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryDocumentsDeliveryDocumentsGet(fromInvoiceDate?: string, toInvoiceDate?: string, suppliers?: Array<number>, companies?: Array<number>, documentType?: Array<string>, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseDeliveryDocumentListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryDocumentsDeliveryDocumentsGet(fromInvoiceDate, toInvoiceDate, suppliers, companies, documentType, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Delivery Document
         * @param {number} deliveryDocumentId 
         * @param {UpdateDeliveryDocumentRequest} updateDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut(deliveryDocumentId: number, updateDeliveryDocumentRequest: UpdateDeliveryDocumentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut(deliveryDocumentId, updateDeliveryDocumentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeliveryDocumentsApi - factory interface
 * @export
 */
export const DeliveryDocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveryDocumentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Delivery Document
         * @param {CreateDeliveryDocumentRequest} createDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryDocumentDeliveryDocumentsPost(createDeliveryDocumentRequest: CreateDeliveryDocumentRequest, options?: any): AxiosPromise<DeliveryDocumentResponse> {
            return localVarFp.createDeliveryDocumentDeliveryDocumentsPost(createDeliveryDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} deliveryDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeliveryDocumentsDeliveryDocumentIdDelete(deliveryDocumentId: number, options?: any): AxiosPromise<DeliveryDocumentResponse> {
            return localVarFp.deleteDeliveryDocumentsDeliveryDocumentIdDelete(deliveryDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Document Credit Note Items
         * @param {number} deliveryDocumentId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentCreditNoteItemsDeliveryDocumentsDeliveryDocumentIdCreditNoteItemsGet(deliveryDocumentId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseDeliveryDocumentCreditNoteItemResponse> {
            return localVarFp.readDeliveryDocumentCreditNoteItemsDeliveryDocumentsDeliveryDocumentIdCreditNoteItemsGet(deliveryDocumentId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Document Remaining Amount
         * @param {number} deliveryDocumentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentRemainingAmountDeliveryDocumentsDeliveryDocumentIdRemainingAmountGet(deliveryDocumentId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readDeliveryDocumentRemainingAmountDeliveryDocumentsDeliveryDocumentIdRemainingAmountGet(deliveryDocumentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Documents
         * @param {string} [fromInvoiceDate] 
         * @param {string} [toInvoiceDate] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [companies] 
         * @param {Array<string>} [documentType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryDocumentsDeliveryDocumentsGet(fromInvoiceDate?: string, toInvoiceDate?: string, suppliers?: Array<number>, companies?: Array<number>, documentType?: Array<string>, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseDeliveryDocumentListResponse> {
            return localVarFp.readDeliveryDocumentsDeliveryDocumentsGet(fromInvoiceDate, toInvoiceDate, suppliers, companies, documentType, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Delivery Document
         * @param {number} deliveryDocumentId 
         * @param {UpdateDeliveryDocumentRequest} updateDeliveryDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut(deliveryDocumentId: number, updateDeliveryDocumentRequest: UpdateDeliveryDocumentRequest, options?: any): AxiosPromise<DeliveryDocumentResponse> {
            return localVarFp.updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut(deliveryDocumentId, updateDeliveryDocumentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliveryDocumentsApi - object-oriented interface
 * @export
 * @class DeliveryDocumentsApi
 * @extends {BaseAPI}
 */
export class DeliveryDocumentsApi extends BaseAPI {
    /**
     * 
     * @summary Create Delivery Document
     * @param {CreateDeliveryDocumentRequest} createDeliveryDocumentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryDocumentsApi
     */
    public createDeliveryDocumentDeliveryDocumentsPost(createDeliveryDocumentRequest: CreateDeliveryDocumentRequest, options?: AxiosRequestConfig) {
        return DeliveryDocumentsApiFp(this.configuration).createDeliveryDocumentDeliveryDocumentsPost(createDeliveryDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} deliveryDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryDocumentsApi
     */
    public deleteDeliveryDocumentsDeliveryDocumentIdDelete(deliveryDocumentId: number, options?: AxiosRequestConfig) {
        return DeliveryDocumentsApiFp(this.configuration).deleteDeliveryDocumentsDeliveryDocumentIdDelete(deliveryDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Document Credit Note Items
     * @param {number} deliveryDocumentId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryDocumentsApi
     */
    public readDeliveryDocumentCreditNoteItemsDeliveryDocumentsDeliveryDocumentIdCreditNoteItemsGet(deliveryDocumentId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return DeliveryDocumentsApiFp(this.configuration).readDeliveryDocumentCreditNoteItemsDeliveryDocumentsDeliveryDocumentIdCreditNoteItemsGet(deliveryDocumentId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Document Remaining Amount
     * @param {number} deliveryDocumentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryDocumentsApi
     */
    public readDeliveryDocumentRemainingAmountDeliveryDocumentsDeliveryDocumentIdRemainingAmountGet(deliveryDocumentId: number, options?: AxiosRequestConfig) {
        return DeliveryDocumentsApiFp(this.configuration).readDeliveryDocumentRemainingAmountDeliveryDocumentsDeliveryDocumentIdRemainingAmountGet(deliveryDocumentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Documents
     * @param {string} [fromInvoiceDate] 
     * @param {string} [toInvoiceDate] 
     * @param {Array<number>} [suppliers] 
     * @param {Array<number>} [companies] 
     * @param {Array<string>} [documentType] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryDocumentsApi
     */
    public readDeliveryDocumentsDeliveryDocumentsGet(fromInvoiceDate?: string, toInvoiceDate?: string, suppliers?: Array<number>, companies?: Array<number>, documentType?: Array<string>, page?: number, size?: number, options?: AxiosRequestConfig) {
        return DeliveryDocumentsApiFp(this.configuration).readDeliveryDocumentsDeliveryDocumentsGet(fromInvoiceDate, toInvoiceDate, suppliers, companies, documentType, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Delivery Document
     * @param {number} deliveryDocumentId 
     * @param {UpdateDeliveryDocumentRequest} updateDeliveryDocumentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryDocumentsApi
     */
    public updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut(deliveryDocumentId: number, updateDeliveryDocumentRequest: UpdateDeliveryDocumentRequest, options?: AxiosRequestConfig) {
        return DeliveryDocumentsApiFp(this.configuration).updateDeliveryDocumentDeliveryDocumentsDeliveryDocumentIdPut(deliveryDocumentId, updateDeliveryDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeliveryRequestApi - axios parameter creator
 * @export
 */
export const DeliveryRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Delivery Request
         * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
         * @param {boolean} [shouldSendEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryRequestDeliveryRequestsPost: async (createDeliveryRequestRequest: CreateDeliveryRequestRequest, shouldSendEmail?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeliveryRequestRequest' is not null or undefined
            assertParamExists('createDeliveryRequestDeliveryRequestsPost', 'createDeliveryRequestRequest', createDeliveryRequestRequest)
            const localVarPath = `/delivery-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (shouldSendEmail !== undefined) {
                localVarQueryParameter['should_send_email'] = shouldSendEmail;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeliveryRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Delivery Request Expected Total
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryRequestExpectedTotalDeliveryRequestsIdExpectedTotalGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeliveryRequestExpectedTotalDeliveryRequestsIdExpectedTotalGet', 'id', id)
            const localVarPath = `/delivery-requests/{id}/expected-total`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Request By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRequestByIdDeliveryRequestsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readDeliveryRequestByIdDeliveryRequestsIdGet', 'id', id)
            const localVarPath = `/delivery-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Request Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRequestItemsDeliveryRequestsIdItemsGet: async (id: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readDeliveryRequestItemsDeliveryRequestsIdItemsGet', 'id', id)
            const localVarPath = `/delivery-requests/{id}/items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Request Stock Receiving Process
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRequestStockReceivingProcessDeliveryRequestsIdProcessGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readDeliveryRequestStockReceivingProcessDeliveryRequestsIdProcessGet', 'id', id)
            const localVarPath = `/delivery-requests/{id}/process`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Requests
         * @param {Array<number>} [id] 
         * @param {string} [fromCreated] 
         * @param {string} [toCreated] 
         * @param {string} [fromDelivery] 
         * @param {string} [toDelivery] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [warehouses] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRequestsDeliveryRequestsGet: async (id?: Array<number>, fromCreated?: string, toCreated?: string, fromDelivery?: string, toDelivery?: string, suppliers?: Array<number>, warehouses?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/delivery-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (fromCreated !== undefined) {
                localVarQueryParameter['from_created'] = (fromCreated as any instanceof Date) ?
                    (fromCreated as any).toISOString() :
                    fromCreated;
            }

            if (toCreated !== undefined) {
                localVarQueryParameter['to_created'] = (toCreated as any instanceof Date) ?
                    (toCreated as any).toISOString() :
                    toCreated;
            }

            if (fromDelivery !== undefined) {
                localVarQueryParameter['from_delivery'] = (fromDelivery as any instanceof Date) ?
                    (fromDelivery as any).toISOString().substr(0,10) :
                    fromDelivery;
            }

            if (toDelivery !== undefined) {
                localVarQueryParameter['to_delivery'] = (toDelivery as any instanceof Date) ?
                    (toDelivery as any).toISOString().substr(0,10) :
                    toDelivery;
            }

            if (suppliers) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost: async (deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestId' is not null or undefined
            assertParamExists('startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost', 'deliveryRequestId', deliveryRequestId)
            // verify required parameter 'startStockReceivingProcessRequest' is not null or undefined
            assertParamExists('startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost', 'startStockReceivingProcessRequest', startStockReceivingProcessRequest)
            const localVarPath = `/delivery-requests/{delivery_request_id}/process`
                .replace(`{${"delivery_request_id"}}`, encodeURIComponent(String(deliveryRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startStockReceivingProcessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Delivery Request
         * @param {number} id 
         * @param {UpdateDeliveryRequestRequest} updateDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryRequestDeliveryRequestsIdPut: async (id: number, updateDeliveryRequestRequest: UpdateDeliveryRequestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDeliveryRequestDeliveryRequestsIdPut', 'id', id)
            // verify required parameter 'updateDeliveryRequestRequest' is not null or undefined
            assertParamExists('updateDeliveryRequestDeliveryRequestsIdPut', 'updateDeliveryRequestRequest', updateDeliveryRequestRequest)
            const localVarPath = `/delivery-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliveryRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {Request} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch: async (deliveryRequestId: number, request: Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryRequestId' is not null or undefined
            assertParamExists('updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch', 'deliveryRequestId', deliveryRequestId)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch', 'request', request)
            const localVarPath = `/delivery-requests/{delivery_request_id}/process`
                .replace(`{${"delivery_request_id"}}`, encodeURIComponent(String(deliveryRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveryRequestApi - functional programming interface
 * @export
 */
export const DeliveryRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveryRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Delivery Request
         * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
         * @param {boolean} [shouldSendEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeliveryRequestDeliveryRequestsPost(createDeliveryRequestRequest: CreateDeliveryRequestRequest, shouldSendEmail?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeliveryRequestDeliveryRequestsPost(createDeliveryRequestRequest, shouldSendEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Delivery Request Expected Total
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeliveryRequestExpectedTotalDeliveryRequestsIdExpectedTotalGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeliveryRequestExpectedTotalDeliveryRequestsIdExpectedTotalGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Request By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryRequestByIdDeliveryRequestsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryRequestByIdDeliveryRequestsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Request Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryRequestItemsDeliveryRequestsIdItemsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseDeliveryRequestItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryRequestItemsDeliveryRequestsIdItemsGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Request Stock Receiving Process
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryRequestStockReceivingProcessDeliveryRequestsIdProcessGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockReceivingProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryRequestStockReceivingProcessDeliveryRequestsIdProcessGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Requests
         * @param {Array<number>} [id] 
         * @param {string} [fromCreated] 
         * @param {string} [toCreated] 
         * @param {string} [fromDelivery] 
         * @param {string} [toDelivery] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [warehouses] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryRequestsDeliveryRequestsGet(id?: Array<number>, fromCreated?: string, toCreated?: string, fromDelivery?: string, toDelivery?: string, suppliers?: Array<number>, warehouses?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseDeliveryRequestListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryRequestsDeliveryRequestsGet(id, fromCreated, toCreated, fromDelivery, toDelivery, suppliers, warehouses, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId, startStockReceivingProcessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Delivery Request
         * @param {number} id 
         * @param {UpdateDeliveryRequestRequest} updateDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeliveryRequestDeliveryRequestsIdPut(id: number, updateDeliveryRequestRequest: UpdateDeliveryRequestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeliveryRequestDeliveryRequestsIdPut(id, updateDeliveryRequestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {Request} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId: number, request: Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockReceivingProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeliveryRequestApi - factory interface
 * @export
 */
export const DeliveryRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveryRequestApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Delivery Request
         * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
         * @param {boolean} [shouldSendEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryRequestDeliveryRequestsPost(createDeliveryRequestRequest: CreateDeliveryRequestRequest, shouldSendEmail?: boolean, options?: any): AxiosPromise<DeliveryRequestResponse> {
            return localVarFp.createDeliveryRequestDeliveryRequestsPost(createDeliveryRequestRequest, shouldSendEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Delivery Request Expected Total
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryRequestExpectedTotalDeliveryRequestsIdExpectedTotalGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getDeliveryRequestExpectedTotalDeliveryRequestsIdExpectedTotalGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Request By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRequestByIdDeliveryRequestsIdGet(id: number, options?: any): AxiosPromise<DeliveryRequestResponse> {
            return localVarFp.readDeliveryRequestByIdDeliveryRequestsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Request Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRequestItemsDeliveryRequestsIdItemsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseDeliveryRequestItemResponse> {
            return localVarFp.readDeliveryRequestItemsDeliveryRequestsIdItemsGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Request Stock Receiving Process
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRequestStockReceivingProcessDeliveryRequestsIdProcessGet(id: number, options?: any): AxiosPromise<StockReceivingProcessResponse> {
            return localVarFp.readDeliveryRequestStockReceivingProcessDeliveryRequestsIdProcessGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Requests
         * @param {Array<number>} [id] 
         * @param {string} [fromCreated] 
         * @param {string} [toCreated] 
         * @param {string} [fromDelivery] 
         * @param {string} [toDelivery] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [warehouses] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryRequestsDeliveryRequestsGet(id?: Array<number>, fromCreated?: string, toCreated?: string, fromDelivery?: string, toDelivery?: string, suppliers?: Array<number>, warehouses?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseDeliveryRequestListResponse> {
            return localVarFp.readDeliveryRequestsDeliveryRequestsGet(id, fromCreated, toCreated, fromDelivery, toDelivery, suppliers, warehouses, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options?: any): AxiosPromise<void> {
            return localVarFp.startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId, startStockReceivingProcessRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Delivery Request
         * @param {number} id 
         * @param {UpdateDeliveryRequestRequest} updateDeliveryRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryRequestDeliveryRequestsIdPut(id: number, updateDeliveryRequestRequest: UpdateDeliveryRequestRequest, options?: any): AxiosPromise<DeliveryRequestResponse> {
            return localVarFp.updateDeliveryRequestDeliveryRequestsIdPut(id, updateDeliveryRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Stock Receiving Process
         * @param {number} deliveryRequestId 
         * @param {Request} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId: number, request: Request, options?: any): AxiosPromise<StockReceivingProcessResponse> {
            return localVarFp.updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliveryRequestApi - object-oriented interface
 * @export
 * @class DeliveryRequestApi
 * @extends {BaseAPI}
 */
export class DeliveryRequestApi extends BaseAPI {
    /**
     * 
     * @summary Create Delivery Request
     * @param {CreateDeliveryRequestRequest} createDeliveryRequestRequest 
     * @param {boolean} [shouldSendEmail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryRequestApi
     */
    public createDeliveryRequestDeliveryRequestsPost(createDeliveryRequestRequest: CreateDeliveryRequestRequest, shouldSendEmail?: boolean, options?: AxiosRequestConfig) {
        return DeliveryRequestApiFp(this.configuration).createDeliveryRequestDeliveryRequestsPost(createDeliveryRequestRequest, shouldSendEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Delivery Request Expected Total
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryRequestApi
     */
    public getDeliveryRequestExpectedTotalDeliveryRequestsIdExpectedTotalGet(id: number, options?: AxiosRequestConfig) {
        return DeliveryRequestApiFp(this.configuration).getDeliveryRequestExpectedTotalDeliveryRequestsIdExpectedTotalGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Request By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryRequestApi
     */
    public readDeliveryRequestByIdDeliveryRequestsIdGet(id: number, options?: AxiosRequestConfig) {
        return DeliveryRequestApiFp(this.configuration).readDeliveryRequestByIdDeliveryRequestsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Request Items
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryRequestApi
     */
    public readDeliveryRequestItemsDeliveryRequestsIdItemsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return DeliveryRequestApiFp(this.configuration).readDeliveryRequestItemsDeliveryRequestsIdItemsGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Request Stock Receiving Process
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryRequestApi
     */
    public readDeliveryRequestStockReceivingProcessDeliveryRequestsIdProcessGet(id: number, options?: AxiosRequestConfig) {
        return DeliveryRequestApiFp(this.configuration).readDeliveryRequestStockReceivingProcessDeliveryRequestsIdProcessGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Requests
     * @param {Array<number>} [id] 
     * @param {string} [fromCreated] 
     * @param {string} [toCreated] 
     * @param {string} [fromDelivery] 
     * @param {string} [toDelivery] 
     * @param {Array<number>} [suppliers] 
     * @param {Array<number>} [warehouses] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryRequestApi
     */
    public readDeliveryRequestsDeliveryRequestsGet(id?: Array<number>, fromCreated?: string, toCreated?: string, fromDelivery?: string, toDelivery?: string, suppliers?: Array<number>, warehouses?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return DeliveryRequestApiFp(this.configuration).readDeliveryRequestsDeliveryRequestsGet(id, fromCreated, toCreated, fromDelivery, toDelivery, suppliers, warehouses, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start Stock Receiving Process
     * @param {number} deliveryRequestId 
     * @param {StartStockReceivingProcessRequest} startStockReceivingProcessRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryRequestApi
     */
    public startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId: number, startStockReceivingProcessRequest: StartStockReceivingProcessRequest, options?: AxiosRequestConfig) {
        return DeliveryRequestApiFp(this.configuration).startStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPost(deliveryRequestId, startStockReceivingProcessRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Delivery Request
     * @param {number} id 
     * @param {UpdateDeliveryRequestRequest} updateDeliveryRequestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryRequestApi
     */
    public updateDeliveryRequestDeliveryRequestsIdPut(id: number, updateDeliveryRequestRequest: UpdateDeliveryRequestRequest, options?: AxiosRequestConfig) {
        return DeliveryRequestApiFp(this.configuration).updateDeliveryRequestDeliveryRequestsIdPut(id, updateDeliveryRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Stock Receiving Process
     * @param {number} deliveryRequestId 
     * @param {Request} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryRequestApi
     */
    public updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId: number, request: Request, options?: AxiosRequestConfig) {
        return DeliveryRequestApiFp(this.configuration).updateStockReceivingProcessDeliveryRequestsDeliveryRequestIdProcessPatch(deliveryRequestId, request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FinanceApi - axios parameter creator
 * @export
 */
export const FinanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Treasury
         * @param {CreateTreasuryRequest} createTreasuryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTreasuryTreasuriesPost: async (createTreasuryRequest: CreateTreasuryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTreasuryRequest' is not null or undefined
            assertParamExists('createTreasuryTreasuriesPost', 'createTreasuryRequest', createTreasuryRequest)
            const localVarPath = `/treasuries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTreasuryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Treasuries
         * @param {Array<number>} [id] 
         * @param {number} [companyId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuriesTreasuriesGet: async (id?: Array<number>, companyId?: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/treasuries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['company_id'] = companyId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Treasury Bank Account Amount
         * @param {number} treasuryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuryBankAccountAmountTreasuriesTreasuryIdBankAccountAmountGet: async (treasuryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treasuryId' is not null or undefined
            assertParamExists('readTreasuryBankAccountAmountTreasuriesTreasuryIdBankAccountAmountGet', 'treasuryId', treasuryId)
            const localVarPath = `/treasuries/{treasury_id}/bank-account-amount`
                .replace(`{${"treasury_id"}}`, encodeURIComponent(String(treasuryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Treasury By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuryByIdTreasuriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readTreasuryByIdTreasuriesIdGet', 'id', id)
            const localVarPath = `/treasuries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Treasury Cash Amount
         * @param {number} treasuryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuryCashAmountTreasuriesTreasuryIdCashAmountGet: async (treasuryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treasuryId' is not null or undefined
            assertParamExists('readTreasuryCashAmountTreasuriesTreasuryIdCashAmountGet', 'treasuryId', treasuryId)
            const localVarPath = `/treasuries/{treasury_id}/cash-amount`
                .replace(`{${"treasury_id"}}`, encodeURIComponent(String(treasuryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Treasury Transactions
         * @param {number} treasuryId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuryTransactionsTreasuriesTreasuryIdTransactionsGet: async (treasuryId: number, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treasuryId' is not null or undefined
            assertParamExists('readTreasuryTransactionsTreasuriesTreasuryIdTransactionsGet', 'treasuryId', treasuryId)
            const localVarPath = `/treasuries/{treasury_id}/transactions`
                .replace(`{${"treasury_id"}}`, encodeURIComponent(String(treasuryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Treasury
         * @param {number} id 
         * @param {PatchTreasuryRequest} patchTreasuryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTreasuryTreasuriesIdPut: async (id: number, patchTreasuryRequest: PatchTreasuryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTreasuryTreasuriesIdPut', 'id', id)
            // verify required parameter 'patchTreasuryRequest' is not null or undefined
            assertParamExists('updateTreasuryTreasuriesIdPut', 'patchTreasuryRequest', patchTreasuryRequest)
            const localVarPath = `/treasuries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchTreasuryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinanceApi - functional programming interface
 * @export
 */
export const FinanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FinanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Treasury
         * @param {CreateTreasuryRequest} createTreasuryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTreasuryTreasuriesPost(createTreasuryRequest: CreateTreasuryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreasuryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTreasuryTreasuriesPost(createTreasuryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Treasuries
         * @param {Array<number>} [id] 
         * @param {number} [companyId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTreasuriesTreasuriesGet(id?: Array<number>, companyId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseTreasuryListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTreasuriesTreasuriesGet(id, companyId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Treasury Bank Account Amount
         * @param {number} treasuryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTreasuryBankAccountAmountTreasuriesTreasuryIdBankAccountAmountGet(treasuryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTreasuryBankAccountAmountTreasuriesTreasuryIdBankAccountAmountGet(treasuryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Treasury By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTreasuryByIdTreasuriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreasuryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTreasuryByIdTreasuriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Treasury Cash Amount
         * @param {number} treasuryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTreasuryCashAmountTreasuriesTreasuryIdCashAmountGet(treasuryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTreasuryCashAmountTreasuriesTreasuryIdCashAmountGet(treasuryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Treasury Transactions
         * @param {number} treasuryId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTreasuryTransactionsTreasuriesTreasuryIdTransactionsGet(treasuryId: number, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseTransactionListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTreasuryTransactionsTreasuriesTreasuryIdTransactionsGet(treasuryId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Treasury
         * @param {number} id 
         * @param {PatchTreasuryRequest} patchTreasuryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTreasuryTreasuriesIdPut(id: number, patchTreasuryRequest: PatchTreasuryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreasuryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTreasuryTreasuriesIdPut(id, patchTreasuryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FinanceApi - factory interface
 * @export
 */
export const FinanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FinanceApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Treasury
         * @param {CreateTreasuryRequest} createTreasuryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTreasuryTreasuriesPost(createTreasuryRequest: CreateTreasuryRequest, options?: any): AxiosPromise<TreasuryResponse> {
            return localVarFp.createTreasuryTreasuriesPost(createTreasuryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Treasuries
         * @param {Array<number>} [id] 
         * @param {number} [companyId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuriesTreasuriesGet(id?: Array<number>, companyId?: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseTreasuryListResponse> {
            return localVarFp.readTreasuriesTreasuriesGet(id, companyId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Treasury Bank Account Amount
         * @param {number} treasuryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuryBankAccountAmountTreasuriesTreasuryIdBankAccountAmountGet(treasuryId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readTreasuryBankAccountAmountTreasuriesTreasuryIdBankAccountAmountGet(treasuryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Treasury By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuryByIdTreasuriesIdGet(id: number, options?: any): AxiosPromise<TreasuryResponse> {
            return localVarFp.readTreasuryByIdTreasuriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Treasury Cash Amount
         * @param {number} treasuryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuryCashAmountTreasuriesTreasuryIdCashAmountGet(treasuryId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readTreasuryCashAmountTreasuriesTreasuryIdCashAmountGet(treasuryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Treasury Transactions
         * @param {number} treasuryId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTreasuryTransactionsTreasuriesTreasuryIdTransactionsGet(treasuryId: number, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseTransactionListResponse> {
            return localVarFp.readTreasuryTransactionsTreasuriesTreasuryIdTransactionsGet(treasuryId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Treasury
         * @param {number} id 
         * @param {PatchTreasuryRequest} patchTreasuryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTreasuryTreasuriesIdPut(id: number, patchTreasuryRequest: PatchTreasuryRequest, options?: any): AxiosPromise<TreasuryResponse> {
            return localVarFp.updateTreasuryTreasuriesIdPut(id, patchTreasuryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FinanceApi - object-oriented interface
 * @export
 * @class FinanceApi
 * @extends {BaseAPI}
 */
export class FinanceApi extends BaseAPI {
    /**
     * 
     * @summary Create Treasury
     * @param {CreateTreasuryRequest} createTreasuryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApi
     */
    public createTreasuryTreasuriesPost(createTreasuryRequest: CreateTreasuryRequest, options?: AxiosRequestConfig) {
        return FinanceApiFp(this.configuration).createTreasuryTreasuriesPost(createTreasuryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Treasuries
     * @param {Array<number>} [id] 
     * @param {number} [companyId] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApi
     */
    public readTreasuriesTreasuriesGet(id?: Array<number>, companyId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return FinanceApiFp(this.configuration).readTreasuriesTreasuriesGet(id, companyId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Treasury Bank Account Amount
     * @param {number} treasuryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApi
     */
    public readTreasuryBankAccountAmountTreasuriesTreasuryIdBankAccountAmountGet(treasuryId: number, options?: AxiosRequestConfig) {
        return FinanceApiFp(this.configuration).readTreasuryBankAccountAmountTreasuriesTreasuryIdBankAccountAmountGet(treasuryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Treasury By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApi
     */
    public readTreasuryByIdTreasuriesIdGet(id: number, options?: AxiosRequestConfig) {
        return FinanceApiFp(this.configuration).readTreasuryByIdTreasuriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Treasury Cash Amount
     * @param {number} treasuryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApi
     */
    public readTreasuryCashAmountTreasuriesTreasuryIdCashAmountGet(treasuryId: number, options?: AxiosRequestConfig) {
        return FinanceApiFp(this.configuration).readTreasuryCashAmountTreasuriesTreasuryIdCashAmountGet(treasuryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Treasury Transactions
     * @param {number} treasuryId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApi
     */
    public readTreasuryTransactionsTreasuriesTreasuryIdTransactionsGet(treasuryId: number, page?: number, size?: number, options?: AxiosRequestConfig) {
        return FinanceApiFp(this.configuration).readTreasuryTransactionsTreasuriesTreasuryIdTransactionsGet(treasuryId, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Treasury
     * @param {number} id 
     * @param {PatchTreasuryRequest} patchTreasuryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApi
     */
    public updateTreasuryTreasuriesIdPut(id: number, patchTreasuryRequest: PatchTreasuryRequest, options?: AxiosRequestConfig) {
        return FinanceApiFp(this.configuration).updateTreasuryTreasuriesIdPut(id, patchTreasuryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupsApi - axios parameter creator
 * @export
 */
export const GroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Group
         * @param {CreateGroupRequest} createGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroupGroupsPost: async (createGroupRequest: CreateGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createGroupRequest' is not null or undefined
            assertParamExists('createGroupGroupsPost', 'createGroupRequest', createGroupRequest)
            const localVarPath = `/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Group By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroupByIdGroupsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readGroupByIdGroupsIdGet', 'id', id)
            const localVarPath = `/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Groups
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroupsGroupsGet: async (id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Group
         * @param {number} id 
         * @param {UpdateGroupRequest} updateGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupGroupsIdPut: async (id: number, updateGroupRequest: UpdateGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGroupGroupsIdPut', 'id', id)
            // verify required parameter 'updateGroupRequest' is not null or undefined
            assertParamExists('updateGroupGroupsIdPut', 'updateGroupRequest', updateGroupRequest)
            const localVarPath = `/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupsApi - functional programming interface
 * @export
 */
export const GroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Group
         * @param {CreateGroupRequest} createGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroupGroupsPost(createGroupRequest: CreateGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGroupGroupsPost(createGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Group By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readGroupByIdGroupsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readGroupByIdGroupsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Groups
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readGroupsGroupsGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readGroupsGroupsGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Group
         * @param {number} id 
         * @param {UpdateGroupRequest} updateGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroupGroupsIdPut(id: number, updateGroupRequest: UpdateGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroupGroupsIdPut(id, updateGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupsApi - factory interface
 * @export
 */
export const GroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Group
         * @param {CreateGroupRequest} createGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroupGroupsPost(createGroupRequest: CreateGroupRequest, options?: any): AxiosPromise<GroupResponse> {
            return localVarFp.createGroupGroupsPost(createGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Group By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroupByIdGroupsIdGet(id: number, options?: any): AxiosPromise<GroupResponse> {
            return localVarFp.readGroupByIdGroupsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Groups
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readGroupsGroupsGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseGroupResponse> {
            return localVarFp.readGroupsGroupsGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Group
         * @param {number} id 
         * @param {UpdateGroupRequest} updateGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupGroupsIdPut(id: number, updateGroupRequest: UpdateGroupRequest, options?: any): AxiosPromise<GroupResponse> {
            return localVarFp.updateGroupGroupsIdPut(id, updateGroupRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupsApi - object-oriented interface
 * @export
 * @class GroupsApi
 * @extends {BaseAPI}
 */
export class GroupsApi extends BaseAPI {
    /**
     * 
     * @summary Create Group
     * @param {CreateGroupRequest} createGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public createGroupGroupsPost(createGroupRequest: CreateGroupRequest, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).createGroupGroupsPost(createGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Group By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public readGroupByIdGroupsIdGet(id: number, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).readGroupByIdGroupsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Groups
     * @param {Array<number>} [id] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public readGroupsGroupsGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).readGroupsGroupsGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Group
     * @param {number} id 
     * @param {UpdateGroupRequest} updateGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public updateGroupGroupsIdPut(id: number, updateGroupRequest: UpdateGroupRequest, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).updateGroupGroupsIdPut(id, updateGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoicesApi - axios parameter creator
 * @export
 */
export const InvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel Invoice By Invoice Id
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInvoiceByInvoiceIdInvoicesInvoiceIdPatch: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('cancelInvoiceByInvoiceIdInvoicesInvoiceIdPatch', 'invoiceId', invoiceId)
            const localVarPath = `/invoices/{invoice_id}`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {CreateInvoiceRequest} createInvoiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoicesPost: async (createInvoiceRequest: CreateInvoiceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInvoiceRequest' is not null or undefined
            assertParamExists('createInvoicesPost', 'createInvoiceRequest', createInvoiceRequest)
            const localVarPath = `/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInvoiceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Credit Notes
         * @param {number} invoiceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCreditNotesInvoicesInvoiceIdCreditNotesGet: async (invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('readCreditNotesInvoicesInvoiceIdCreditNotesGet', 'invoiceId', invoiceId)
            const localVarPath = `/invoices/{invoice_id}/credit-notes`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceByIdInvoicesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readInvoiceByIdInvoicesIdGet', 'id', id)
            const localVarPath = `/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceDocumentInvoicesInvoiceIdDocumentGet: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('readInvoiceDocumentInvoicesInvoiceIdDocumentGet', 'invoiceId', invoiceId)
            const localVarPath = `/invoices/{invoice_id}/document`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice Orders
         * @param {number} invoiceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceOrdersInvoicesInvoiceIdOrdersGet: async (invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('readInvoiceOrdersInvoicesInvoiceIdOrdersGet', 'invoiceId', invoiceId)
            const localVarPath = `/invoices/{invoice_id}/orders`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoice Transactions
         * @param {number} invoiceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceTransactionsInvoicesInvoiceIdTransactionsGet: async (invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('readInvoiceTransactionsInvoicesInvoiceIdTransactionsGet', 'invoiceId', invoiceId)
            const localVarPath = `/invoices/{invoice_id}/transactions`
                .replace(`{${"invoice_id"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Invoices
         * @param {Array<number>} [id] 
         * @param {string} [fromCreatedAt] 
         * @param {string} [toCreatedAt] 
         * @param {Array<number>} [legalEntities] 
         * @param {Array<number>} [companies] 
         * @param {boolean} [hideCancelled] 
         * @param {boolean} [hidePaidInFull] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoicesInvoicesGet: async (id?: Array<number>, fromCreatedAt?: string, toCreatedAt?: string, legalEntities?: Array<number>, companies?: Array<number>, hideCancelled?: boolean, hidePaidInFull?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (fromCreatedAt !== undefined) {
                localVarQueryParameter['from_created_at'] = (fromCreatedAt as any instanceof Date) ?
                    (fromCreatedAt as any).toISOString() :
                    fromCreatedAt;
            }

            if (toCreatedAt !== undefined) {
                localVarQueryParameter['to_created_at'] = (toCreatedAt as any instanceof Date) ?
                    (toCreatedAt as any).toISOString() :
                    toCreatedAt;
            }

            if (legalEntities) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }

            if (hideCancelled !== undefined) {
                localVarQueryParameter['hide_cancelled'] = hideCancelled;
            }

            if (hidePaidInFull !== undefined) {
                localVarQueryParameter['hide_paid_in_full'] = hidePaidInFull;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoicesApi - functional programming interface
 * @export
 */
export const InvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel Invoice By Invoice Id
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelInvoiceByInvoiceIdInvoicesInvoiceIdPatch(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelInvoiceByInvoiceIdInvoicesInvoiceIdPatch(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {CreateInvoiceRequest} createInvoiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvoicesPost(createInvoiceRequest: CreateInvoiceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvoicesPost(createInvoiceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Credit Notes
         * @param {number} invoiceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCreditNotesInvoicesInvoiceIdCreditNotesGet(invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseInvoiceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCreditNotesInvoicesInvoiceIdCreditNotesGet(invoiceId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readInvoiceByIdInvoicesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceByIdInvoicesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readInvoiceDocumentInvoicesInvoiceIdDocumentGet(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceDocumentInvoicesInvoiceIdDocumentGet(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice Orders
         * @param {number} invoiceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readInvoiceOrdersInvoicesInvoiceIdOrdersGet(invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseOrderListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceOrdersInvoicesInvoiceIdOrdersGet(invoiceId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoice Transactions
         * @param {number} invoiceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readInvoiceTransactionsInvoicesInvoiceIdTransactionsGet(invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseTransactionListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoiceTransactionsInvoicesInvoiceIdTransactionsGet(invoiceId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Invoices
         * @param {Array<number>} [id] 
         * @param {string} [fromCreatedAt] 
         * @param {string} [toCreatedAt] 
         * @param {Array<number>} [legalEntities] 
         * @param {Array<number>} [companies] 
         * @param {boolean} [hideCancelled] 
         * @param {boolean} [hidePaidInFull] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readInvoicesInvoicesGet(id?: Array<number>, fromCreatedAt?: string, toCreatedAt?: string, legalEntities?: Array<number>, companies?: Array<number>, hideCancelled?: boolean, hidePaidInFull?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseInvoiceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInvoicesInvoicesGet(id, fromCreatedAt, toCreatedAt, legalEntities, companies, hideCancelled, hidePaidInFull, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoicesApi - factory interface
 * @export
 */
export const InvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel Invoice By Invoice Id
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInvoiceByInvoiceIdInvoicesInvoiceIdPatch(invoiceId: number, options?: any): AxiosPromise<InvoiceResponse> {
            return localVarFp.cancelInvoiceByInvoiceIdInvoicesInvoiceIdPatch(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {CreateInvoiceRequest} createInvoiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoicesPost(createInvoiceRequest: CreateInvoiceRequest, options?: any): AxiosPromise<InvoiceResponse> {
            return localVarFp.createInvoicesPost(createInvoiceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Credit Notes
         * @param {number} invoiceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCreditNotesInvoicesInvoiceIdCreditNotesGet(invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseInvoiceListResponse> {
            return localVarFp.readCreditNotesInvoicesInvoiceIdCreditNotesGet(invoiceId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceByIdInvoicesIdGet(id: number, options?: any): AxiosPromise<InvoiceResponse> {
            return localVarFp.readInvoiceByIdInvoicesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice Document
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceDocumentInvoicesInvoiceIdDocumentGet(invoiceId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readInvoiceDocumentInvoicesInvoiceIdDocumentGet(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice Orders
         * @param {number} invoiceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceOrdersInvoicesInvoiceIdOrdersGet(invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseOrderListResponse> {
            return localVarFp.readInvoiceOrdersInvoicesInvoiceIdOrdersGet(invoiceId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoice Transactions
         * @param {number} invoiceId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoiceTransactionsInvoicesInvoiceIdTransactionsGet(invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseTransactionListResponse> {
            return localVarFp.readInvoiceTransactionsInvoicesInvoiceIdTransactionsGet(invoiceId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Invoices
         * @param {Array<number>} [id] 
         * @param {string} [fromCreatedAt] 
         * @param {string} [toCreatedAt] 
         * @param {Array<number>} [legalEntities] 
         * @param {Array<number>} [companies] 
         * @param {boolean} [hideCancelled] 
         * @param {boolean} [hidePaidInFull] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInvoicesInvoicesGet(id?: Array<number>, fromCreatedAt?: string, toCreatedAt?: string, legalEntities?: Array<number>, companies?: Array<number>, hideCancelled?: boolean, hidePaidInFull?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseInvoiceListResponse> {
            return localVarFp.readInvoicesInvoicesGet(id, fromCreatedAt, toCreatedAt, legalEntities, companies, hideCancelled, hidePaidInFull, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoicesApi - object-oriented interface
 * @export
 * @class InvoicesApi
 * @extends {BaseAPI}
 */
export class InvoicesApi extends BaseAPI {
    /**
     * 
     * @summary Cancel Invoice By Invoice Id
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public cancelInvoiceByInvoiceIdInvoicesInvoiceIdPatch(invoiceId: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).cancelInvoiceByInvoiceIdInvoicesInvoiceIdPatch(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {CreateInvoiceRequest} createInvoiceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public createInvoicesPost(createInvoiceRequest: CreateInvoiceRequest, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).createInvoicesPost(createInvoiceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Credit Notes
     * @param {number} invoiceId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public readCreditNotesInvoicesInvoiceIdCreditNotesGet(invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).readCreditNotesInvoicesInvoiceIdCreditNotesGet(invoiceId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public readInvoiceByIdInvoicesIdGet(id: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).readInvoiceByIdInvoicesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice Document
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public readInvoiceDocumentInvoicesInvoiceIdDocumentGet(invoiceId: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).readInvoiceDocumentInvoicesInvoiceIdDocumentGet(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice Orders
     * @param {number} invoiceId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public readInvoiceOrdersInvoicesInvoiceIdOrdersGet(invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).readInvoiceOrdersInvoicesInvoiceIdOrdersGet(invoiceId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoice Transactions
     * @param {number} invoiceId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public readInvoiceTransactionsInvoicesInvoiceIdTransactionsGet(invoiceId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).readInvoiceTransactionsInvoicesInvoiceIdTransactionsGet(invoiceId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Invoices
     * @param {Array<number>} [id] 
     * @param {string} [fromCreatedAt] 
     * @param {string} [toCreatedAt] 
     * @param {Array<number>} [legalEntities] 
     * @param {Array<number>} [companies] 
     * @param {boolean} [hideCancelled] 
     * @param {boolean} [hidePaidInFull] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public readInvoicesInvoicesGet(id?: Array<number>, fromCreatedAt?: string, toCreatedAt?: string, legalEntities?: Array<number>, companies?: Array<number>, hideCancelled?: boolean, hidePaidInFull?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).readInvoicesInvoicesGet(id, fromCreatedAt, toCreatedAt, legalEntities, companies, hideCancelled, hidePaidInFull, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemDeliverySubscriptionsApi - axios parameter creator
 * @export
 */
export const ItemDeliverySubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Item Delivery Subscription
         * @param {CreateItemDeliverySubscriptionRequest} createItemDeliverySubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemDeliverySubscriptionItemDeliverySubscriptionsPost: async (createItemDeliverySubscriptionRequest: CreateItemDeliverySubscriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createItemDeliverySubscriptionRequest' is not null or undefined
            assertParamExists('createItemDeliverySubscriptionItemDeliverySubscriptionsPost', 'createItemDeliverySubscriptionRequest', createItemDeliverySubscriptionRequest)
            const localVarPath = `/item-delivery-subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createItemDeliverySubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Item Delivery Subscription
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItemDeliverySubscriptionItemDeliverySubscriptionsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteItemDeliverySubscriptionItemDeliverySubscriptionsIdDelete', 'id', id)
            const localVarPath = `/item-delivery-subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Item Delivery Subscriptions
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [items] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemDeliverySubscriptionsItemDeliverySubscriptionsGet: async (warehouses?: Array<number>, items?: Array<number>, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/item-delivery-subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (items) {
                localVarQueryParameter['items'] = items;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Item Delivery Subscription By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemDeliverySubscriptionByIdItemDeliverySubscriptionsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readItemDeliverySubscriptionByIdItemDeliverySubscriptionsIdGet', 'id', id)
            const localVarPath = `/item-delivery-subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Item Delivery Subscription
         * @param {number} id 
         * @param {PatchItemDeliverySubscriptionRequest} patchItemDeliverySubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut: async (id: number, patchItemDeliverySubscriptionRequest: PatchItemDeliverySubscriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut', 'id', id)
            // verify required parameter 'patchItemDeliverySubscriptionRequest' is not null or undefined
            assertParamExists('updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut', 'patchItemDeliverySubscriptionRequest', patchItemDeliverySubscriptionRequest)
            const localVarPath = `/item-delivery-subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchItemDeliverySubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemDeliverySubscriptionsApi - functional programming interface
 * @export
 */
export const ItemDeliverySubscriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemDeliverySubscriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Item Delivery Subscription
         * @param {CreateItemDeliverySubscriptionRequest} createItemDeliverySubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemDeliverySubscriptionItemDeliverySubscriptionsPost(createItemDeliverySubscriptionRequest: CreateItemDeliverySubscriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemDeliverySubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemDeliverySubscriptionItemDeliverySubscriptionsPost(createItemDeliverySubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Item Delivery Subscription
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteItemDeliverySubscriptionItemDeliverySubscriptionsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemDeliverySubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteItemDeliverySubscriptionItemDeliverySubscriptionsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Item Delivery Subscriptions
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [items] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemDeliverySubscriptionsItemDeliverySubscriptionsGet(warehouses?: Array<number>, items?: Array<number>, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseItemDeliverySubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemDeliverySubscriptionsItemDeliverySubscriptionsGet(warehouses, items, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Item Delivery Subscription By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemDeliverySubscriptionByIdItemDeliverySubscriptionsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemDeliverySubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemDeliverySubscriptionByIdItemDeliverySubscriptionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Item Delivery Subscription
         * @param {number} id 
         * @param {PatchItemDeliverySubscriptionRequest} patchItemDeliverySubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut(id: number, patchItemDeliverySubscriptionRequest: PatchItemDeliverySubscriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemDeliverySubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut(id, patchItemDeliverySubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemDeliverySubscriptionsApi - factory interface
 * @export
 */
export const ItemDeliverySubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemDeliverySubscriptionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Item Delivery Subscription
         * @param {CreateItemDeliverySubscriptionRequest} createItemDeliverySubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemDeliverySubscriptionItemDeliverySubscriptionsPost(createItemDeliverySubscriptionRequest: CreateItemDeliverySubscriptionRequest, options?: any): AxiosPromise<ItemDeliverySubscriptionResponse> {
            return localVarFp.createItemDeliverySubscriptionItemDeliverySubscriptionsPost(createItemDeliverySubscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Item Delivery Subscription
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItemDeliverySubscriptionItemDeliverySubscriptionsIdDelete(id: number, options?: any): AxiosPromise<ItemDeliverySubscriptionResponse> {
            return localVarFp.deleteItemDeliverySubscriptionItemDeliverySubscriptionsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Item Delivery Subscriptions
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [items] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemDeliverySubscriptionsItemDeliverySubscriptionsGet(warehouses?: Array<number>, items?: Array<number>, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseItemDeliverySubscriptionResponse> {
            return localVarFp.getItemDeliverySubscriptionsItemDeliverySubscriptionsGet(warehouses, items, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Item Delivery Subscription By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemDeliverySubscriptionByIdItemDeliverySubscriptionsIdGet(id: number, options?: any): AxiosPromise<ItemDeliverySubscriptionResponse> {
            return localVarFp.readItemDeliverySubscriptionByIdItemDeliverySubscriptionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Item Delivery Subscription
         * @param {number} id 
         * @param {PatchItemDeliverySubscriptionRequest} patchItemDeliverySubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut(id: number, patchItemDeliverySubscriptionRequest: PatchItemDeliverySubscriptionRequest, options?: any): AxiosPromise<ItemDeliverySubscriptionResponse> {
            return localVarFp.updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut(id, patchItemDeliverySubscriptionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemDeliverySubscriptionsApi - object-oriented interface
 * @export
 * @class ItemDeliverySubscriptionsApi
 * @extends {BaseAPI}
 */
export class ItemDeliverySubscriptionsApi extends BaseAPI {
    /**
     * 
     * @summary Create Item Delivery Subscription
     * @param {CreateItemDeliverySubscriptionRequest} createItemDeliverySubscriptionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemDeliverySubscriptionsApi
     */
    public createItemDeliverySubscriptionItemDeliverySubscriptionsPost(createItemDeliverySubscriptionRequest: CreateItemDeliverySubscriptionRequest, options?: AxiosRequestConfig) {
        return ItemDeliverySubscriptionsApiFp(this.configuration).createItemDeliverySubscriptionItemDeliverySubscriptionsPost(createItemDeliverySubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Item Delivery Subscription
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemDeliverySubscriptionsApi
     */
    public deleteItemDeliverySubscriptionItemDeliverySubscriptionsIdDelete(id: number, options?: AxiosRequestConfig) {
        return ItemDeliverySubscriptionsApiFp(this.configuration).deleteItemDeliverySubscriptionItemDeliverySubscriptionsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Item Delivery Subscriptions
     * @param {Array<number>} [warehouses] 
     * @param {Array<number>} [items] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemDeliverySubscriptionsApi
     */
    public getItemDeliverySubscriptionsItemDeliverySubscriptionsGet(warehouses?: Array<number>, items?: Array<number>, page?: number, size?: number, options?: AxiosRequestConfig) {
        return ItemDeliverySubscriptionsApiFp(this.configuration).getItemDeliverySubscriptionsItemDeliverySubscriptionsGet(warehouses, items, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Item Delivery Subscription By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemDeliverySubscriptionsApi
     */
    public readItemDeliverySubscriptionByIdItemDeliverySubscriptionsIdGet(id: number, options?: AxiosRequestConfig) {
        return ItemDeliverySubscriptionsApiFp(this.configuration).readItemDeliverySubscriptionByIdItemDeliverySubscriptionsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Item Delivery Subscription
     * @param {number} id 
     * @param {PatchItemDeliverySubscriptionRequest} patchItemDeliverySubscriptionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemDeliverySubscriptionsApi
     */
    public updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut(id: number, patchItemDeliverySubscriptionRequest: PatchItemDeliverySubscriptionRequest, options?: AxiosRequestConfig) {
        return ItemDeliverySubscriptionsApiFp(this.configuration).updateItemDeliverySubscriptionItemDeliverySubscriptionsIdPut(id, patchItemDeliverySubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsApi - axios parameter creator
 * @export
 */
export const ItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create
         * @param {SaveItemCategoryRequest} saveItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemCategoriesPost: async (saveItemCategoryRequest: SaveItemCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveItemCategoryRequest' is not null or undefined
            assertParamExists('createItemCategoriesPost', 'saveItemCategoryRequest', saveItemCategoryRequest)
            const localVarPath = `/item-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveItemCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {SaveItemRequest} saveItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsPost: async (saveItemRequest: SaveItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveItemRequest' is not null or undefined
            assertParamExists('createItemsPost', 'saveItemRequest', saveItemRequest)
            const localVarPath = `/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Linked Item Association
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost: async (itemId: number, linkedItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost', 'itemId', itemId)
            // verify required parameter 'linkedItemId' is not null or undefined
            assertParamExists('createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost', 'linkedItemId', linkedItemId)
            const localVarPath = `/items/{item_id}/linked-item-association/{linked_item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"linked_item_id"}}`, encodeURIComponent(String(linkedItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Similar Item Association
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost: async (itemId: number, similarItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost', 'itemId', itemId)
            // verify required parameter 'similarItemId' is not null or undefined
            assertParamExists('createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost', 'similarItemId', similarItemId)
            const localVarPath = `/items/{item_id}/similar-item-association/{similar_item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"similar_item_id"}}`, encodeURIComponent(String(similarItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Linked Item Association
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete: async (itemId: number, linkedItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete', 'itemId', itemId)
            // verify required parameter 'linkedItemId' is not null or undefined
            assertParamExists('deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete', 'linkedItemId', linkedItemId)
            const localVarPath = `/items/{item_id}/linked-item-association/{linked_item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"linked_item_id"}}`, encodeURIComponent(String(linkedItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Similar Item Association
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete: async (itemId: number, similarItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete', 'itemId', itemId)
            // verify required parameter 'similarItemId' is not null or undefined
            assertParamExists('deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete', 'similarItemId', similarItemId)
            const localVarPath = `/items/{item_id}/similar-item-association/{similar_item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"similar_item_id"}}`, encodeURIComponent(String(similarItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Active Order Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActiveOrderItemsItemsIdActiveOrderItemsGet: async (id: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readActiveOrderItemsItemsIdActiveOrderItemsGet', 'id', id)
            const localVarPath = `/items/{id}/active-order-items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All Item Batches
         * @param {number} itemId 
         * @param {Array<number>} [warehouses] 
         * @param {boolean} [skipDepleted] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllItemBatchesItemsItemIdBatchesGet: async (itemId: number, warehouses?: Array<number>, skipDepleted?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('readAllItemBatchesItemsItemIdBatchesGet', 'itemId', itemId)
            const localVarPath = `/items/{item_id}/batches`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (skipDepleted !== undefined) {
                localVarQueryParameter['skip_depleted'] = skipDepleted;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Item By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemByIdItemsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readItemByIdItemsIdGet', 'id', id)
            const localVarPath = `/items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Item Categories Paginated
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [leafOnly] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemCategoriesPaginatedItemCategoriesGet: async (id?: Array<number>, query?: string, leafOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/item-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (leafOnly !== undefined) {
                localVarQueryParameter['leaf_only'] = leafOnly;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Item Category By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemCategoryByIdItemCategoriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readItemCategoryByIdItemCategoriesIdGet', 'id', id)
            const localVarPath = `/item-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Items
         * @param {Array<number>} [id] 
         * @param {Array<string>} [query] 
         * @param {Array<number>} [categories] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [manufacturers] 
         * @param {number} [partner] 
         * @param {boolean} [showInactive] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsItemsGet: async (id?: Array<number>, query?: Array<string>, categories?: Array<number>, suppliers?: Array<number>, manufacturers?: Array<number>, partner?: number, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (query) {
                localVarQueryParameter['query'] = query;
            }

            if (categories) {
                localVarQueryParameter['categories'] = categories;
            }

            if (suppliers) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (manufacturers) {
                localVarQueryParameter['manufacturers'] = manufacturers;
            }

            if (partner !== undefined) {
                localVarQueryParameter['partner'] = partner;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Last Delivery Country
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLastDeliveryCountryItemsIdLastDeliveryCountryGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readLastDeliveryCountryItemsIdLastDeliveryCountryGet', 'id', id)
            const localVarPath = `/items/{id}/last-delivery-country`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Last Delivery Price
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLastDeliveryPriceItemsIdLastDeliveryPriceGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readLastDeliveryPriceItemsIdLastDeliveryPriceGet', 'id', id)
            const localVarPath = `/items/{id}/last-delivery-price`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Linked Item Associations For Item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLinkedItemAssociationsForItemItemsItemIdLinkedItemAssociationGet: async (itemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('readLinkedItemAssociationsForItemItemsItemIdLinkedItemAssociationGet', 'itemId', itemId)
            const localVarPath = `/items/{item_id}/linked-item-association`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Partner Item Configurations
         * @param {number} itemId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerItemConfigurationsItemsItemIdPartnerConfigurationsGet: async (itemId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('readPartnerItemConfigurationsItemsItemIdPartnerConfigurationsGet', 'itemId', itemId)
            const localVarPath = `/items/{item_id}/partner-configurations`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Similar Item Associations For Item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSimilarItemAssociationsForItemItemsItemIdSimilarItemAssociationGet: async (itemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('readSimilarItemAssociationsForItemItemsItemIdSimilarItemAssociationGet', 'itemId', itemId)
            const localVarPath = `/items/{item_id}/similar-item-association`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Item Category
         * @param {number} id 
         * @param {SaveItemCategoryRequest} saveItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemCategoryItemCategoriesIdPut: async (id: number, saveItemCategoryRequest: SaveItemCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateItemCategoryItemCategoriesIdPut', 'id', id)
            // verify required parameter 'saveItemCategoryRequest' is not null or undefined
            assertParamExists('updateItemCategoryItemCategoriesIdPut', 'saveItemCategoryRequest', saveItemCategoryRequest)
            const localVarPath = `/item-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveItemCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Item
         * @param {number} id 
         * @param {SaveItemRequest} saveItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemItemsIdPut: async (id: number, saveItemRequest: SaveItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateItemItemsIdPut', 'id', id)
            // verify required parameter 'saveItemRequest' is not null or undefined
            assertParamExists('updateItemItemsIdPut', 'saveItemRequest', saveItemRequest)
            const localVarPath = `/items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsApi - functional programming interface
 * @export
 */
export const ItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {SaveItemCategoryRequest} saveItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemCategoriesPost(saveItemCategoryRequest: SaveItemCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemCategoriesPost(saveItemCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {SaveItemRequest} saveItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItemsPost(saveItemRequest: SaveItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItemsPost(saveItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Linked Item Association
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId: number, linkedItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkedItemAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId, linkedItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Similar Item Association
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId: number, similarItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimilarItemAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId, similarItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Linked Item Association
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId: number, linkedItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkedItemAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId, linkedItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Similar Item Association
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId: number, similarItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimilarItemAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId, similarItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Active Order Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readActiveOrderItemsItemsIdActiveOrderItemsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseActiveOrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActiveOrderItemsItemsIdActiveOrderItemsGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All Item Batches
         * @param {number} itemId 
         * @param {Array<number>} [warehouses] 
         * @param {boolean} [skipDepleted] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllItemBatchesItemsItemIdBatchesGet(itemId: number, warehouses?: Array<number>, skipDepleted?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseItemDeliveryBatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllItemBatchesItemsItemIdBatchesGet(itemId, warehouses, skipDepleted, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Item By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemByIdItemsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemByIdItemsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Item Categories Paginated
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [leafOnly] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemCategoriesPaginatedItemCategoriesGet(id?: Array<number>, query?: string, leafOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemCategoriesPaginatedItemCategoriesGet(id, query, leafOnly, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Item Category By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemCategoryByIdItemCategoriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemCategoryByIdItemCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Items
         * @param {Array<number>} [id] 
         * @param {Array<string>} [query] 
         * @param {Array<number>} [categories] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [manufacturers] 
         * @param {number} [partner] 
         * @param {boolean} [showInactive] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsItemsGet(id?: Array<number>, query?: Array<string>, categories?: Array<number>, suppliers?: Array<number>, manufacturers?: Array<number>, partner?: number, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseItemListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsItemsGet(id, query, categories, suppliers, manufacturers, partner, showInactive, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Last Delivery Country
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readLastDeliveryCountryItemsIdLastDeliveryCountryGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readLastDeliveryCountryItemsIdLastDeliveryCountryGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Last Delivery Price
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readLastDeliveryPriceItemsIdLastDeliveryPriceGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readLastDeliveryPriceItemsIdLastDeliveryPriceGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Linked Item Associations For Item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readLinkedItemAssociationsForItemItemsItemIdLinkedItemAssociationGet(itemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LinkedItemAssociationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readLinkedItemAssociationsForItemItemsItemIdLinkedItemAssociationGet(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Partner Item Configurations
         * @param {number} itemId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPartnerItemConfigurationsItemsItemIdPartnerConfigurationsGet(itemId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseItemPartnerConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPartnerItemConfigurationsItemsItemIdPartnerConfigurationsGet(itemId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Similar Item Associations For Item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSimilarItemAssociationsForItemItemsItemIdSimilarItemAssociationGet(itemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimilarItemAssociationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSimilarItemAssociationsForItemItemsItemIdSimilarItemAssociationGet(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Item Category
         * @param {number} id 
         * @param {SaveItemCategoryRequest} saveItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateItemCategoryItemCategoriesIdPut(id: number, saveItemCategoryRequest: SaveItemCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateItemCategoryItemCategoriesIdPut(id, saveItemCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Item
         * @param {number} id 
         * @param {SaveItemRequest} saveItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateItemItemsIdPut(id: number, saveItemRequest: SaveItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateItemItemsIdPut(id, saveItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsApi - factory interface
 * @export
 */
export const ItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {SaveItemCategoryRequest} saveItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemCategoriesPost(saveItemCategoryRequest: SaveItemCategoryRequest, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.createItemCategoriesPost(saveItemCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {SaveItemRequest} saveItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItemsPost(saveItemRequest: SaveItemRequest, options?: any): AxiosPromise<ItemResponse> {
            return localVarFp.createItemsPost(saveItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Linked Item Association
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId: number, linkedItemId: number, options?: any): AxiosPromise<LinkedItemAssociationResponse> {
            return localVarFp.createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId, linkedItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Similar Item Association
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId: number, similarItemId: number, options?: any): AxiosPromise<SimilarItemAssociationResponse> {
            return localVarFp.createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId, similarItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Linked Item Association
         * @param {number} itemId 
         * @param {number} linkedItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId: number, linkedItemId: number, options?: any): AxiosPromise<LinkedItemAssociationResponse> {
            return localVarFp.deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId, linkedItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Similar Item Association
         * @param {number} itemId 
         * @param {number} similarItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId: number, similarItemId: number, options?: any): AxiosPromise<SimilarItemAssociationResponse> {
            return localVarFp.deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId, similarItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Active Order Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActiveOrderItemsItemsIdActiveOrderItemsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseActiveOrderItemResponse> {
            return localVarFp.readActiveOrderItemsItemsIdActiveOrderItemsGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All Item Batches
         * @param {number} itemId 
         * @param {Array<number>} [warehouses] 
         * @param {boolean} [skipDepleted] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllItemBatchesItemsItemIdBatchesGet(itemId: number, warehouses?: Array<number>, skipDepleted?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseItemDeliveryBatchResponse> {
            return localVarFp.readAllItemBatchesItemsItemIdBatchesGet(itemId, warehouses, skipDepleted, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Item By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemByIdItemsIdGet(id: number, options?: any): AxiosPromise<ItemResponse> {
            return localVarFp.readItemByIdItemsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Item Categories Paginated
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [leafOnly] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemCategoriesPaginatedItemCategoriesGet(id?: Array<number>, query?: string, leafOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseItemCategoryResponse> {
            return localVarFp.readItemCategoriesPaginatedItemCategoriesGet(id, query, leafOnly, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Item Category By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemCategoryByIdItemCategoriesIdGet(id: number, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.readItemCategoryByIdItemCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Items
         * @param {Array<number>} [id] 
         * @param {Array<string>} [query] 
         * @param {Array<number>} [categories] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [manufacturers] 
         * @param {number} [partner] 
         * @param {boolean} [showInactive] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsItemsGet(id?: Array<number>, query?: Array<string>, categories?: Array<number>, suppliers?: Array<number>, manufacturers?: Array<number>, partner?: number, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseItemListResponse> {
            return localVarFp.readItemsItemsGet(id, query, categories, suppliers, manufacturers, partner, showInactive, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Last Delivery Country
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLastDeliveryCountryItemsIdLastDeliveryCountryGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.readLastDeliveryCountryItemsIdLastDeliveryCountryGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Last Delivery Price
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLastDeliveryPriceItemsIdLastDeliveryPriceGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.readLastDeliveryPriceItemsIdLastDeliveryPriceGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Linked Item Associations For Item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLinkedItemAssociationsForItemItemsItemIdLinkedItemAssociationGet(itemId: number, options?: any): AxiosPromise<Array<LinkedItemAssociationResponse>> {
            return localVarFp.readLinkedItemAssociationsForItemItemsItemIdLinkedItemAssociationGet(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Partner Item Configurations
         * @param {number} itemId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerItemConfigurationsItemsItemIdPartnerConfigurationsGet(itemId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseItemPartnerConfigurationResponse> {
            return localVarFp.readPartnerItemConfigurationsItemsItemIdPartnerConfigurationsGet(itemId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Similar Item Associations For Item
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSimilarItemAssociationsForItemItemsItemIdSimilarItemAssociationGet(itemId: number, options?: any): AxiosPromise<Array<SimilarItemAssociationResponse>> {
            return localVarFp.readSimilarItemAssociationsForItemItemsItemIdSimilarItemAssociationGet(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Item Category
         * @param {number} id 
         * @param {SaveItemCategoryRequest} saveItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemCategoryItemCategoriesIdPut(id: number, saveItemCategoryRequest: SaveItemCategoryRequest, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.updateItemCategoryItemCategoriesIdPut(id, saveItemCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Item
         * @param {number} id 
         * @param {SaveItemRequest} saveItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemItemsIdPut(id: number, saveItemRequest: SaveItemRequest, options?: any): AxiosPromise<ItemResponse> {
            return localVarFp.updateItemItemsIdPut(id, saveItemRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsApi - object-oriented interface
 * @export
 * @class ItemsApi
 * @extends {BaseAPI}
 */
export class ItemsApi extends BaseAPI {
    /**
     * 
     * @summary Create
     * @param {SaveItemCategoryRequest} saveItemCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItemCategoriesPost(saveItemCategoryRequest: SaveItemCategoryRequest, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).createItemCategoriesPost(saveItemCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {SaveItemRequest} saveItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItemsPost(saveItemRequest: SaveItemRequest, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).createItemsPost(saveItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Linked Item Association
     * @param {number} itemId 
     * @param {number} linkedItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId: number, linkedItemId: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).createLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdPost(itemId, linkedItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Similar Item Association
     * @param {number} itemId 
     * @param {number} similarItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId: number, similarItemId: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).createSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdPost(itemId, similarItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Linked Item Association
     * @param {number} itemId 
     * @param {number} linkedItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId: number, linkedItemId: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).deleteLinkedItemAssociationItemsItemIdLinkedItemAssociationLinkedItemIdDelete(itemId, linkedItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Similar Item Association
     * @param {number} itemId 
     * @param {number} similarItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId: number, similarItemId: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).deleteSimilarItemAssociationItemsItemIdSimilarItemAssociationSimilarItemIdDelete(itemId, similarItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Active Order Items
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readActiveOrderItemsItemsIdActiveOrderItemsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readActiveOrderItemsItemsIdActiveOrderItemsGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All Item Batches
     * @param {number} itemId 
     * @param {Array<number>} [warehouses] 
     * @param {boolean} [skipDepleted] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readAllItemBatchesItemsItemIdBatchesGet(itemId: number, warehouses?: Array<number>, skipDepleted?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readAllItemBatchesItemsItemIdBatchesGet(itemId, warehouses, skipDepleted, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Item By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemByIdItemsIdGet(id: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readItemByIdItemsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Item Categories Paginated
     * @param {Array<number>} [id] 
     * @param {string} [query] 
     * @param {boolean} [leafOnly] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemCategoriesPaginatedItemCategoriesGet(id?: Array<number>, query?: string, leafOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readItemCategoriesPaginatedItemCategoriesGet(id, query, leafOnly, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Item Category By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemCategoryByIdItemCategoriesIdGet(id: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readItemCategoryByIdItemCategoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Items
     * @param {Array<number>} [id] 
     * @param {Array<string>} [query] 
     * @param {Array<number>} [categories] 
     * @param {Array<number>} [suppliers] 
     * @param {Array<number>} [manufacturers] 
     * @param {number} [partner] 
     * @param {boolean} [showInactive] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readItemsItemsGet(id?: Array<number>, query?: Array<string>, categories?: Array<number>, suppliers?: Array<number>, manufacturers?: Array<number>, partner?: number, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readItemsItemsGet(id, query, categories, suppliers, manufacturers, partner, showInactive, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Last Delivery Country
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readLastDeliveryCountryItemsIdLastDeliveryCountryGet(id: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readLastDeliveryCountryItemsIdLastDeliveryCountryGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Last Delivery Price
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readLastDeliveryPriceItemsIdLastDeliveryPriceGet(id: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readLastDeliveryPriceItemsIdLastDeliveryPriceGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Linked Item Associations For Item
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readLinkedItemAssociationsForItemItemsItemIdLinkedItemAssociationGet(itemId: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readLinkedItemAssociationsForItemItemsItemIdLinkedItemAssociationGet(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Partner Item Configurations
     * @param {number} itemId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readPartnerItemConfigurationsItemsItemIdPartnerConfigurationsGet(itemId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readPartnerItemConfigurationsItemsItemIdPartnerConfigurationsGet(itemId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Similar Item Associations For Item
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readSimilarItemAssociationsForItemItemsItemIdSimilarItemAssociationGet(itemId: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readSimilarItemAssociationsForItemItemsItemIdSimilarItemAssociationGet(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Item Category
     * @param {number} id 
     * @param {SaveItemCategoryRequest} saveItemCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateItemCategoryItemCategoriesIdPut(id: number, saveItemCategoryRequest: SaveItemCategoryRequest, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).updateItemCategoryItemCategoriesIdPut(id, saveItemCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Item
     * @param {number} id 
     * @param {SaveItemRequest} saveItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateItemItemsIdPut(id: number, saveItemRequest: SaveItemRequest, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).updateItemItemsIdPut(id, saveItemRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LegalEntitiesApi - axios parameter creator
 * @export
 */
export const LegalEntitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create
         * @param {SaveLegalEntityRequest} saveLegalEntityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalEntitiesPost: async (saveLegalEntityRequest: SaveLegalEntityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveLegalEntityRequest' is not null or undefined
            assertParamExists('createLegalEntitiesPost', 'saveLegalEntityRequest', saveLegalEntityRequest)
            const localVarPath = `/legal-entities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveLegalEntityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Orders For Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForCollectionLegalEntitiesIdOrdersForCollectionAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrdersForCollectionLegalEntitiesIdOrdersForCollectionAmountGet', 'id', id)
            const localVarPath = `/legal-entities/{id}/orders-for-collection/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Orders For Distribution Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForDistributionAmountLegalEntitiesIdOrdersForDistributionAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrdersForDistributionAmountLegalEntitiesIdOrdersForDistributionAmountGet', 'id', id)
            const localVarPath = `/legal-entities/{id}/orders-for-distribution/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Overdue Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverduePaymentsAmountLegalEntitiesIdOverduePaymentsAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOverduePaymentsAmountLegalEntitiesIdOverduePaymentsAmountGet', 'id', id)
            const localVarPath = `/legal-entities/{id}/overdue-payments/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Pending Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingPaymentsAmountLegalEntitiesIdPendingPaymentsAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPendingPaymentsAmountLegalEntitiesIdPendingPaymentsAmountGet', 'id', id)
            const localVarPath = `/legal-entities/{id}/pending-payments/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Load Info
         * @param {string} vatNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadInfoLegalEntitiesLoadInfoPost: async (vatNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vatNumber' is not null or undefined
            assertParamExists('loadInfoLegalEntitiesLoadInfoPost', 'vatNumber', vatNumber)
            const localVarPath = `/legal-entities/load-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (vatNumber !== undefined) {
                localVarQueryParameter['vat_number'] = vatNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Legal Entities
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [company] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLegalEntitiesLegalEntitiesGet: async (id?: Array<number>, showInactive?: boolean, query?: string, company?: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/legal-entities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Legal Entity By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLegalEntityByIdLegalEntitiesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readLegalEntityByIdLegalEntitiesIdGet', 'id', id)
            const localVarPath = `/legal-entities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderingPolicyLegalEntitiesIdOrderingPolicyGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readOrderingPolicyLegalEntitiesIdOrderingPolicyGet', 'id', id)
            const localVarPath = `/legal-entities/{id}/ordering-policy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderingPolicyLegalEntitiesIdOrderingPolicyPut: async (id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setOrderingPolicyLegalEntitiesIdOrderingPolicyPut', 'id', id)
            // verify required parameter 'setOrderingPolicyRequest' is not null or undefined
            assertParamExists('setOrderingPolicyLegalEntitiesIdOrderingPolicyPut', 'setOrderingPolicyRequest', setOrderingPolicyRequest)
            const localVarPath = `/legal-entities/{id}/ordering-policy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setOrderingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Legal Entity
         * @param {number} id 
         * @param {SaveLegalEntityRequest} saveLegalEntityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLegalEntityLegalEntitiesIdPut: async (id: number, saveLegalEntityRequest: SaveLegalEntityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLegalEntityLegalEntitiesIdPut', 'id', id)
            // verify required parameter 'saveLegalEntityRequest' is not null or undefined
            assertParamExists('updateLegalEntityLegalEntitiesIdPut', 'saveLegalEntityRequest', saveLegalEntityRequest)
            const localVarPath = `/legal-entities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveLegalEntityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LegalEntitiesApi - functional programming interface
 * @export
 */
export const LegalEntitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LegalEntitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {SaveLegalEntityRequest} saveLegalEntityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLegalEntitiesPost(saveLegalEntityRequest: SaveLegalEntityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLegalEntitiesPost(saveLegalEntityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Orders For Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersForCollectionLegalEntitiesIdOrdersForCollectionAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersForCollectionLegalEntitiesIdOrdersForCollectionAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Orders For Distribution Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersForDistributionAmountLegalEntitiesIdOrdersForDistributionAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersForDistributionAmountLegalEntitiesIdOrdersForDistributionAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Overdue Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOverduePaymentsAmountLegalEntitiesIdOverduePaymentsAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOverduePaymentsAmountLegalEntitiesIdOverduePaymentsAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Pending Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingPaymentsAmountLegalEntitiesIdPendingPaymentsAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingPaymentsAmountLegalEntitiesIdPendingPaymentsAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Load Info
         * @param {string} vatNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadInfoLegalEntitiesLoadInfoPost(vatNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadInfoLegalEntitiesLoadInfoPost(vatNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Legal Entities
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [company] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readLegalEntitiesLegalEntitiesGet(id?: Array<number>, showInactive?: boolean, query?: string, company?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseLegalEntityListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readLegalEntitiesLegalEntitiesGet(id, showInactive, query, company, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Legal Entity By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readLegalEntityByIdLegalEntitiesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readLegalEntityByIdLegalEntitiesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderingPolicyLegalEntitiesIdOrderingPolicyGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderingPolicyLegalEntitiesIdOrderingPolicyGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setOrderingPolicyLegalEntitiesIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setOrderingPolicyLegalEntitiesIdOrderingPolicyPut(id, setOrderingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Legal Entity
         * @param {number} id 
         * @param {SaveLegalEntityRequest} saveLegalEntityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLegalEntityLegalEntitiesIdPut(id: number, saveLegalEntityRequest: SaveLegalEntityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLegalEntityLegalEntitiesIdPut(id, saveLegalEntityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LegalEntitiesApi - factory interface
 * @export
 */
export const LegalEntitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LegalEntitiesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {SaveLegalEntityRequest} saveLegalEntityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalEntitiesPost(saveLegalEntityRequest: SaveLegalEntityRequest, options?: any): AxiosPromise<LegalEntityResponse> {
            return localVarFp.createLegalEntitiesPost(saveLegalEntityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Orders For Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForCollectionLegalEntitiesIdOrdersForCollectionAmountGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getOrdersForCollectionLegalEntitiesIdOrdersForCollectionAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Orders For Distribution Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersForDistributionAmountLegalEntitiesIdOrdersForDistributionAmountGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getOrdersForDistributionAmountLegalEntitiesIdOrdersForDistributionAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Overdue Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverduePaymentsAmountLegalEntitiesIdOverduePaymentsAmountGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getOverduePaymentsAmountLegalEntitiesIdOverduePaymentsAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Pending Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingPaymentsAmountLegalEntitiesIdPendingPaymentsAmountGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getPendingPaymentsAmountLegalEntitiesIdPendingPaymentsAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Load Info
         * @param {string} vatNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadInfoLegalEntitiesLoadInfoPost(vatNumber: string, options?: any): AxiosPromise<CompanyInfo> {
            return localVarFp.loadInfoLegalEntitiesLoadInfoPost(vatNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Legal Entities
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [company] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLegalEntitiesLegalEntitiesGet(id?: Array<number>, showInactive?: boolean, query?: string, company?: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseLegalEntityListResponse> {
            return localVarFp.readLegalEntitiesLegalEntitiesGet(id, showInactive, query, company, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Legal Entity By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLegalEntityByIdLegalEntitiesIdGet(id: number, options?: any): AxiosPromise<LegalEntityResponse> {
            return localVarFp.readLegalEntityByIdLegalEntitiesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderingPolicyLegalEntitiesIdOrderingPolicyGet(id: number, options?: any): AxiosPromise<OrderingPolicyResponse> {
            return localVarFp.readOrderingPolicyLegalEntitiesIdOrderingPolicyGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderingPolicyLegalEntitiesIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: any): AxiosPromise<OrderingPolicyResponse> {
            return localVarFp.setOrderingPolicyLegalEntitiesIdOrderingPolicyPut(id, setOrderingPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Legal Entity
         * @param {number} id 
         * @param {SaveLegalEntityRequest} saveLegalEntityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLegalEntityLegalEntitiesIdPut(id: number, saveLegalEntityRequest: SaveLegalEntityRequest, options?: any): AxiosPromise<LegalEntityResponse> {
            return localVarFp.updateLegalEntityLegalEntitiesIdPut(id, saveLegalEntityRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LegalEntitiesApi - object-oriented interface
 * @export
 * @class LegalEntitiesApi
 * @extends {BaseAPI}
 */
export class LegalEntitiesApi extends BaseAPI {
    /**
     * 
     * @summary Create
     * @param {SaveLegalEntityRequest} saveLegalEntityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntitiesApi
     */
    public createLegalEntitiesPost(saveLegalEntityRequest: SaveLegalEntityRequest, options?: AxiosRequestConfig) {
        return LegalEntitiesApiFp(this.configuration).createLegalEntitiesPost(saveLegalEntityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Orders For Collection
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntitiesApi
     */
    public getOrdersForCollectionLegalEntitiesIdOrdersForCollectionAmountGet(id: number, options?: AxiosRequestConfig) {
        return LegalEntitiesApiFp(this.configuration).getOrdersForCollectionLegalEntitiesIdOrdersForCollectionAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Orders For Distribution Amount
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntitiesApi
     */
    public getOrdersForDistributionAmountLegalEntitiesIdOrdersForDistributionAmountGet(id: number, options?: AxiosRequestConfig) {
        return LegalEntitiesApiFp(this.configuration).getOrdersForDistributionAmountLegalEntitiesIdOrdersForDistributionAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Overdue Payments Amount
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntitiesApi
     */
    public getOverduePaymentsAmountLegalEntitiesIdOverduePaymentsAmountGet(id: number, options?: AxiosRequestConfig) {
        return LegalEntitiesApiFp(this.configuration).getOverduePaymentsAmountLegalEntitiesIdOverduePaymentsAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Pending Payments Amount
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntitiesApi
     */
    public getPendingPaymentsAmountLegalEntitiesIdPendingPaymentsAmountGet(id: number, options?: AxiosRequestConfig) {
        return LegalEntitiesApiFp(this.configuration).getPendingPaymentsAmountLegalEntitiesIdPendingPaymentsAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Load Info
     * @param {string} vatNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntitiesApi
     */
    public loadInfoLegalEntitiesLoadInfoPost(vatNumber: string, options?: AxiosRequestConfig) {
        return LegalEntitiesApiFp(this.configuration).loadInfoLegalEntitiesLoadInfoPost(vatNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Legal Entities
     * @param {Array<number>} [id] 
     * @param {boolean} [showInactive] 
     * @param {string} [query] 
     * @param {number} [company] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntitiesApi
     */
    public readLegalEntitiesLegalEntitiesGet(id?: Array<number>, showInactive?: boolean, query?: string, company?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return LegalEntitiesApiFp(this.configuration).readLegalEntitiesLegalEntitiesGet(id, showInactive, query, company, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Legal Entity By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntitiesApi
     */
    public readLegalEntityByIdLegalEntitiesIdGet(id: number, options?: AxiosRequestConfig) {
        return LegalEntitiesApiFp(this.configuration).readLegalEntityByIdLegalEntitiesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Ordering Policy
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntitiesApi
     */
    public readOrderingPolicyLegalEntitiesIdOrderingPolicyGet(id: number, options?: AxiosRequestConfig) {
        return LegalEntitiesApiFp(this.configuration).readOrderingPolicyLegalEntitiesIdOrderingPolicyGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Ordering Policy
     * @param {number} id 
     * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntitiesApi
     */
    public setOrderingPolicyLegalEntitiesIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: AxiosRequestConfig) {
        return LegalEntitiesApiFp(this.configuration).setOrderingPolicyLegalEntitiesIdOrderingPolicyPut(id, setOrderingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Legal Entity
     * @param {number} id 
     * @param {SaveLegalEntityRequest} saveLegalEntityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntitiesApi
     */
    public updateLegalEntityLegalEntitiesIdPut(id: number, saveLegalEntityRequest: SaveLegalEntityRequest, options?: AxiosRequestConfig) {
        return LegalEntitiesApiFp(this.configuration).updateLegalEntityLegalEntitiesIdPut(id, saveLegalEntityRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManufacturersApi - axios parameter creator
 * @export
 */
export const ManufacturersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Manufacturer
         * @param {SaveManufacturerRequest} saveManufacturerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManufacturerManufacturersPost: async (saveManufacturerRequest: SaveManufacturerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveManufacturerRequest' is not null or undefined
            assertParamExists('createManufacturerManufacturersPost', 'saveManufacturerRequest', saveManufacturerRequest)
            const localVarPath = `/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveManufacturerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Acts Of Acceptance By Manufacturer Export Id
         * @param {number} manufacturerExportId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActsOfAcceptanceByManufacturerExportIdManufacturerExportsManufacturerExportIdActsOfAcceptanceGet: async (manufacturerExportId: number, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerExportId' is not null or undefined
            assertParamExists('readActsOfAcceptanceByManufacturerExportIdManufacturerExportsManufacturerExportIdActsOfAcceptanceGet', 'manufacturerExportId', manufacturerExportId)
            const localVarPath = `/manufacturer-exports/{manufacturer_export_id}/acts-of-acceptance`
                .replace(`{${"manufacturer_export_id"}}`, encodeURIComponent(String(manufacturerExportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Manifacturer By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManifacturerByIdManufacturersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readManifacturerByIdManufacturersIdGet', 'id', id)
            const localVarPath = `/manufacturers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Manufacturer Export By Id
         * @param {number} manufacturerExportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturerExportByIdManufacturerExportsManufacturerExportIdGet: async (manufacturerExportId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerExportId' is not null or undefined
            assertParamExists('readManufacturerExportByIdManufacturerExportsManufacturerExportIdGet', 'manufacturerExportId', manufacturerExportId)
            const localVarPath = `/manufacturer-exports/{manufacturer_export_id}`
                .replace(`{${"manufacturer_export_id"}}`, encodeURIComponent(String(manufacturerExportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Manufacturer Exports
         * @param {Array<number>} [manufacturers] 
         * @param {string} [fromCreatedDate] 
         * @param {string} [toCreatedDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturerExportsManufacturerExportsGet: async (manufacturers?: Array<number>, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/manufacturer-exports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (manufacturers) {
                localVarQueryParameter['manufacturers'] = manufacturers;
            }

            if (fromCreatedDate !== undefined) {
                localVarQueryParameter['from_created_date'] = (fromCreatedDate as any instanceof Date) ?
                    (fromCreatedDate as any).toISOString() :
                    fromCreatedDate;
            }

            if (toCreatedDate !== undefined) {
                localVarQueryParameter['to_created_date'] = (toCreatedDate as any instanceof Date) ?
                    (toCreatedDate as any).toISOString() :
                    toCreatedDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Manufacturer Exports
         * @param {number} manufacturerId 
         * @param {string} [fromCreatedDate] 
         * @param {string} [toCreatedDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturerExportsManufacturersManufacturerIdExportsGet: async (manufacturerId: number, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('readManufacturerExportsManufacturersManufacturerIdExportsGet', 'manufacturerId', manufacturerId)
            const localVarPath = `/manufacturers/{manufacturer_id}/exports`
                .replace(`{${"manufacturer_id"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromCreatedDate !== undefined) {
                localVarQueryParameter['from_created_date'] = (fromCreatedDate as any instanceof Date) ?
                    (fromCreatedDate as any).toISOString() :
                    fromCreatedDate;
            }

            if (toCreatedDate !== undefined) {
                localVarQueryParameter['to_created_date'] = (toCreatedDate as any instanceof Date) ?
                    (toCreatedDate as any).toISOString() :
                    toCreatedDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Manufacturer Items
         * @param {number} manufacturerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturerItemsManufacturersManufacturerIdItemsGet: async (manufacturerId: number, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('readManufacturerItemsManufacturersManufacturerIdItemsGet', 'manufacturerId', manufacturerId)
            const localVarPath = `/manufacturers/{manufacturer_id}/items`
                .replace(`{${"manufacturer_id"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Manufacturers
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturersManufacturersGet: async (id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Manufacturer
         * @param {number} id 
         * @param {SaveManufacturerRequest} saveManufacturerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManufacturerManufacturersIdPut: async (id: number, saveManufacturerRequest: SaveManufacturerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateManufacturerManufacturersIdPut', 'id', id)
            // verify required parameter 'saveManufacturerRequest' is not null or undefined
            assertParamExists('updateManufacturerManufacturersIdPut', 'saveManufacturerRequest', saveManufacturerRequest)
            const localVarPath = `/manufacturers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveManufacturerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManufacturersApi - functional programming interface
 * @export
 */
export const ManufacturersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManufacturersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Manufacturer
         * @param {SaveManufacturerRequest} saveManufacturerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManufacturerManufacturersPost(saveManufacturerRequest: SaveManufacturerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createManufacturerManufacturersPost(saveManufacturerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Acts Of Acceptance By Manufacturer Export Id
         * @param {number} manufacturerExportId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readActsOfAcceptanceByManufacturerExportIdManufacturerExportsManufacturerExportIdActsOfAcceptanceGet(manufacturerExportId: number, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseActOfAcceptanceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readActsOfAcceptanceByManufacturerExportIdManufacturerExportsManufacturerExportIdActsOfAcceptanceGet(manufacturerExportId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Manifacturer By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readManifacturerByIdManufacturersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readManifacturerByIdManufacturersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Manufacturer Export By Id
         * @param {number} manufacturerExportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readManufacturerExportByIdManufacturerExportsManufacturerExportIdGet(manufacturerExportId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readManufacturerExportByIdManufacturerExportsManufacturerExportIdGet(manufacturerExportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Manufacturer Exports
         * @param {Array<number>} [manufacturers] 
         * @param {string} [fromCreatedDate] 
         * @param {string} [toCreatedDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readManufacturerExportsManufacturerExportsGet(manufacturers?: Array<number>, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseManufacturerExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readManufacturerExportsManufacturerExportsGet(manufacturers, fromCreatedDate, toCreatedDate, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Manufacturer Exports
         * @param {number} manufacturerId 
         * @param {string} [fromCreatedDate] 
         * @param {string} [toCreatedDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readManufacturerExportsManufacturersManufacturerIdExportsGet(manufacturerId: number, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseManufacturerExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readManufacturerExportsManufacturersManufacturerIdExportsGet(manufacturerId, fromCreatedDate, toCreatedDate, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Manufacturer Items
         * @param {number} manufacturerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readManufacturerItemsManufacturersManufacturerIdItemsGet(manufacturerId: number, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseItemListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readManufacturerItemsManufacturersManufacturerIdItemsGet(manufacturerId, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Manufacturers
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readManufacturersManufacturersGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseManufacturerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readManufacturersManufacturersGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Manufacturer
         * @param {number} id 
         * @param {SaveManufacturerRequest} saveManufacturerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManufacturerManufacturersIdPut(id: number, saveManufacturerRequest: SaveManufacturerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateManufacturerManufacturersIdPut(id, saveManufacturerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManufacturersApi - factory interface
 * @export
 */
export const ManufacturersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManufacturersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Manufacturer
         * @param {SaveManufacturerRequest} saveManufacturerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManufacturerManufacturersPost(saveManufacturerRequest: SaveManufacturerRequest, options?: any): AxiosPromise<ManufacturerResponse> {
            return localVarFp.createManufacturerManufacturersPost(saveManufacturerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Acts Of Acceptance By Manufacturer Export Id
         * @param {number} manufacturerExportId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readActsOfAcceptanceByManufacturerExportIdManufacturerExportsManufacturerExportIdActsOfAcceptanceGet(manufacturerExportId: number, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseActOfAcceptanceListResponse> {
            return localVarFp.readActsOfAcceptanceByManufacturerExportIdManufacturerExportsManufacturerExportIdActsOfAcceptanceGet(manufacturerExportId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Manifacturer By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManifacturerByIdManufacturersIdGet(id: number, options?: any): AxiosPromise<ManufacturerResponse> {
            return localVarFp.readManifacturerByIdManufacturersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Manufacturer Export By Id
         * @param {number} manufacturerExportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturerExportByIdManufacturerExportsManufacturerExportIdGet(manufacturerExportId: number, options?: any): AxiosPromise<ManufacturerExportResponse> {
            return localVarFp.readManufacturerExportByIdManufacturerExportsManufacturerExportIdGet(manufacturerExportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Manufacturer Exports
         * @param {Array<number>} [manufacturers] 
         * @param {string} [fromCreatedDate] 
         * @param {string} [toCreatedDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturerExportsManufacturerExportsGet(manufacturers?: Array<number>, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseManufacturerExportResponse> {
            return localVarFp.readManufacturerExportsManufacturerExportsGet(manufacturers, fromCreatedDate, toCreatedDate, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Manufacturer Exports
         * @param {number} manufacturerId 
         * @param {string} [fromCreatedDate] 
         * @param {string} [toCreatedDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturerExportsManufacturersManufacturerIdExportsGet(manufacturerId: number, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseManufacturerExportResponse> {
            return localVarFp.readManufacturerExportsManufacturersManufacturerIdExportsGet(manufacturerId, fromCreatedDate, toCreatedDate, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Manufacturer Items
         * @param {number} manufacturerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturerItemsManufacturersManufacturerIdItemsGet(manufacturerId: number, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseItemListResponse> {
            return localVarFp.readManufacturerItemsManufacturersManufacturerIdItemsGet(manufacturerId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Manufacturers
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManufacturersManufacturersGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseManufacturerResponse> {
            return localVarFp.readManufacturersManufacturersGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Manufacturer
         * @param {number} id 
         * @param {SaveManufacturerRequest} saveManufacturerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManufacturerManufacturersIdPut(id: number, saveManufacturerRequest: SaveManufacturerRequest, options?: any): AxiosPromise<ManufacturerResponse> {
            return localVarFp.updateManufacturerManufacturersIdPut(id, saveManufacturerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManufacturersApi - object-oriented interface
 * @export
 * @class ManufacturersApi
 * @extends {BaseAPI}
 */
export class ManufacturersApi extends BaseAPI {
    /**
     * 
     * @summary Create Manufacturer
     * @param {SaveManufacturerRequest} saveManufacturerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public createManufacturerManufacturersPost(saveManufacturerRequest: SaveManufacturerRequest, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).createManufacturerManufacturersPost(saveManufacturerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Acts Of Acceptance By Manufacturer Export Id
     * @param {number} manufacturerExportId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public readActsOfAcceptanceByManufacturerExportIdManufacturerExportsManufacturerExportIdActsOfAcceptanceGet(manufacturerExportId: number, page?: number, size?: number, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).readActsOfAcceptanceByManufacturerExportIdManufacturerExportsManufacturerExportIdActsOfAcceptanceGet(manufacturerExportId, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Manifacturer By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public readManifacturerByIdManufacturersIdGet(id: number, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).readManifacturerByIdManufacturersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Manufacturer Export By Id
     * @param {number} manufacturerExportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public readManufacturerExportByIdManufacturerExportsManufacturerExportIdGet(manufacturerExportId: number, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).readManufacturerExportByIdManufacturerExportsManufacturerExportIdGet(manufacturerExportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Manufacturer Exports
     * @param {Array<number>} [manufacturers] 
     * @param {string} [fromCreatedDate] 
     * @param {string} [toCreatedDate] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public readManufacturerExportsManufacturerExportsGet(manufacturers?: Array<number>, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).readManufacturerExportsManufacturerExportsGet(manufacturers, fromCreatedDate, toCreatedDate, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Manufacturer Exports
     * @param {number} manufacturerId 
     * @param {string} [fromCreatedDate] 
     * @param {string} [toCreatedDate] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public readManufacturerExportsManufacturersManufacturerIdExportsGet(manufacturerId: number, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).readManufacturerExportsManufacturersManufacturerIdExportsGet(manufacturerId, fromCreatedDate, toCreatedDate, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Manufacturer Items
     * @param {number} manufacturerId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public readManufacturerItemsManufacturersManufacturerIdItemsGet(manufacturerId: number, page?: number, size?: number, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).readManufacturerItemsManufacturersManufacturerIdItemsGet(manufacturerId, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Manufacturers
     * @param {Array<number>} [id] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public readManufacturersManufacturersGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).readManufacturersManufacturersGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Manufacturer
     * @param {number} id 
     * @param {SaveManufacturerRequest} saveManufacturerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public updateManufacturerManufacturersIdPut(id: number, saveManufacturerRequest: SaveManufacturerRequest, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).updateManufacturerManufacturersIdPut(id, saveManufacturerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Order Item
         * @param {number} orderId 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderItemOrdersOrderIdItemsPost: async (orderId: number, saveOrderItemRequest: SaveOrderItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('createOrderItemOrdersOrderIdItemsPost', 'orderId', orderId)
            // verify required parameter 'saveOrderItemRequest' is not null or undefined
            assertParamExists('createOrderItemOrdersOrderIdItemsPost', 'saveOrderItemRequest', saveOrderItemRequest)
            const localVarPath = `/orders/{order_id}/items`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveOrderItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Order Item
         * @param {number} orderId 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderItemOrdersOrderIdItemsPost_1: async (orderId: number, saveOrderItemRequest: SaveOrderItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('createOrderItemOrdersOrderIdItemsPost_1', 'orderId', orderId)
            // verify required parameter 'saveOrderItemRequest' is not null or undefined
            assertParamExists('createOrderItemOrdersOrderIdItemsPost_1', 'saveOrderItemRequest', saveOrderItemRequest)
            const localVarPath = `/orders/{order_id}/items`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveOrderItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderRequest} createOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderOrdersPost: async (createOrderRequest: CreateOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderRequest' is not null or undefined
            assertParamExists('createOrderOrdersPost', 'createOrderRequest', createOrderRequest)
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderItemOrdersOrderIdItemsIdDelete: async (orderId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('deleteOrderItemOrdersOrderIdItemsIdDelete', 'orderId', orderId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOrderItemOrdersOrderIdItemsIdDelete', 'id', id)
            const localVarPath = `/orders/{order_id}/items/{id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderItemOrdersOrderIdItemsIdDelete_2: async (orderId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('deleteOrderItemOrdersOrderIdItemsIdDelete_2', 'orderId', orderId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOrderItemOrdersOrderIdItemsIdDelete_2', 'id', id)
            const localVarPath = `/orders/{order_id}/items/{id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get B2B Orders
         * @param {Array<number>} [id] 
         * @param {Array<string>} [status] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getB2bOrdersOrdersB2bOrdersGet: async (id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, legalEntities?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/b2b-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (receivers) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (legalEntities) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (fromDeliveryDate !== undefined) {
                localVarQueryParameter['from_delivery_date'] = (fromDeliveryDate as any instanceof Date) ?
                    (fromDeliveryDate as any).toISOString() :
                    fromDeliveryDate;
            }

            if (toDeliveryDate !== undefined) {
                localVarQueryParameter['to_delivery_date'] = (toDeliveryDate as any instanceof Date) ?
                    (toDeliveryDate as any).toISOString() :
                    toDeliveryDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get B2C Orders
         * @param {Array<number>} [id] 
         * @param {Array<string>} [status] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getB2cOrdersOrdersB2cOrdersGet: async (id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, legalEntities?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/b2c-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (receivers) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (legalEntities) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (fromDeliveryDate !== undefined) {
                localVarQueryParameter['from_delivery_date'] = (fromDeliveryDate as any instanceof Date) ?
                    (fromDeliveryDate as any).toISOString() :
                    fromDeliveryDate;
            }

            if (toDeliveryDate !== undefined) {
                localVarQueryParameter['to_delivery_date'] = (toDeliveryDate as any instanceof Date) ?
                    (toDeliveryDate as any).toISOString() :
                    toDeliveryDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Internal Transfers
         * @param {Array<number>} [id] 
         * @param {Array<string>} [status] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [receivers] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalTransfersOrdersInternalTransfersGet: async (id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/internal-transfers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (receivers) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (fromDeliveryDate !== undefined) {
                localVarQueryParameter['from_delivery_date'] = (fromDeliveryDate as any instanceof Date) ?
                    (fromDeliveryDate as any).toISOString() :
                    fromDeliveryDate;
            }

            if (toDeliveryDate !== undefined) {
                localVarQueryParameter['to_delivery_date'] = (toDeliveryDate as any instanceof Date) ?
                    (toDeliveryDate as any).toISOString() :
                    toDeliveryDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Order Execution Percentage
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderExecutionPercentageOrdersOrderIdExecutionPercentageGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getOrderExecutionPercentageOrdersOrderIdExecutionPercentageGet', 'orderId', orderId)
            const localVarPath = `/orders/{order_id}/execution-percentage`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Order
         * @param {number} orderId 
         * @param {PatchOrderRequest} patchOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrderOrdersOrderIdPatch: async (orderId: number, patchOrderRequest: PatchOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('patchOrderOrdersOrderIdPatch', 'orderId', orderId)
            // verify required parameter 'patchOrderRequest' is not null or undefined
            assertParamExists('patchOrderOrdersOrderIdPatch', 'patchOrderRequest', patchOrderRequest)
            const localVarPath = `/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Collected Batch Items
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCollectedBatchItemsOrdersOrderIdCollectedBatchItemsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readCollectedBatchItemsOrdersOrderIdCollectedBatchItemsGet', 'orderId', orderId)
            const localVarPath = `/orders/{order_id}/collected-batch-items`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryBatchOrdersOrderIdDocumentsDeliveryBatchGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readDeliveryBatchOrdersOrderIdDocumentsDeliveryBatchGet', 'orderId', orderId)
            const localVarPath = `/orders/{order_id}/documents/delivery-batch`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemOrdersOrderIdItemsIdGet: async (orderId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderItemOrdersOrderIdItemsIdGet', 'orderId', orderId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readOrderItemOrdersOrderIdItemsIdGet', 'id', id)
            const localVarPath = `/orders/{order_id}/items/{id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemOrdersOrderIdItemsIdGet_3: async (orderId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderItemOrdersOrderIdItemsIdGet_3', 'orderId', orderId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readOrderItemOrdersOrderIdItemsIdGet_3', 'id', id)
            const localVarPath = `/orders/{order_id}/items/{id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Items
         * @param {number} orderId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemsOrdersOrderIdItemsGet: async (orderId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderItemsOrdersOrderIdItemsGet', 'orderId', orderId)
            const localVarPath = `/orders/{order_id}/items`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Items
         * @param {number} orderId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemsOrdersOrderIdItemsGet_4: async (orderId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderItemsOrdersOrderIdItemsGet_4', 'orderId', orderId)
            const localVarPath = `/orders/{order_id}/items`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderOrdersOrderIdGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderOrdersOrderIdGet', 'orderId', orderId)
            const localVarPath = `/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Status Logs
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderStatusLogsOrdersOrderIdStatusLogsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderStatusLogsOrdersOrderIdStatusLogsGet', 'orderId', orderId)
            const localVarPath = `/orders/{order_id}/status-logs`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderItemOrdersOrderIdItemsIdPut: async (orderId: number, id: number, saveOrderItemRequest: SaveOrderItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('updateOrderItemOrdersOrderIdItemsIdPut', 'orderId', orderId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrderItemOrdersOrderIdItemsIdPut', 'id', id)
            // verify required parameter 'saveOrderItemRequest' is not null or undefined
            assertParamExists('updateOrderItemOrdersOrderIdItemsIdPut', 'saveOrderItemRequest', saveOrderItemRequest)
            const localVarPath = `/orders/{order_id}/items/{id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveOrderItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderItemOrdersOrderIdItemsIdPut_5: async (orderId: number, id: number, saveOrderItemRequest: SaveOrderItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('updateOrderItemOrdersOrderIdItemsIdPut_5', 'orderId', orderId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrderItemOrdersOrderIdItemsIdPut_5', 'id', id)
            // verify required parameter 'saveOrderItemRequest' is not null or undefined
            assertParamExists('updateOrderItemOrdersOrderIdItemsIdPut_5', 'saveOrderItemRequest', saveOrderItemRequest)
            const localVarPath = `/orders/{order_id}/items/{id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveOrderItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Order Item
         * @param {number} orderId 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrderItemOrdersOrderIdItemsPost(orderId: number, saveOrderItemRequest: SaveOrderItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderItemOrdersOrderIdItemsPost(orderId, saveOrderItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Order Item
         * @param {number} orderId 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrderItemOrdersOrderIdItemsPost_1(orderId: number, saveOrderItemRequest: SaveOrderItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderItemOrdersOrderIdItemsPost_1(orderId, saveOrderItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderRequest} createOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrderOrdersPost(createOrderRequest: CreateOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderOrdersPost(createOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrderItemOrdersOrderIdItemsIdDelete(orderId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrderItemOrdersOrderIdItemsIdDelete(orderId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrderItemOrdersOrderIdItemsIdDelete_2(orderId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrderItemOrdersOrderIdItemsIdDelete_2(orderId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get B2B Orders
         * @param {Array<number>} [id] 
         * @param {Array<string>} [status] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getB2bOrdersOrdersB2bOrdersGet(id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, legalEntities?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseOrderListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getB2bOrdersOrdersB2bOrdersGet(id, status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get B2C Orders
         * @param {Array<number>} [id] 
         * @param {Array<string>} [status] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getB2cOrdersOrdersB2cOrdersGet(id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, legalEntities?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseOrderListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getB2cOrdersOrdersB2cOrdersGet(id, status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Internal Transfers
         * @param {Array<number>} [id] 
         * @param {Array<string>} [status] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [receivers] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInternalTransfersOrdersInternalTransfersGet(id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseOrderListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInternalTransfersOrdersInternalTransfersGet(id, status, companies, warehouses, receivers, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Order Execution Percentage
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderExecutionPercentageOrdersOrderIdExecutionPercentageGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderExecutionPercentageOrdersOrderIdExecutionPercentageGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Order
         * @param {number} orderId 
         * @param {PatchOrderRequest} patchOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOrderOrdersOrderIdPatch(orderId: number, patchOrderRequest: PatchOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchOrderOrdersOrderIdPatch(orderId, patchOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Collected Batch Items
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCollectedBatchItemsOrdersOrderIdCollectedBatchItemsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CollectedBatchItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCollectedBatchItemsOrdersOrderIdCollectedBatchItemsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryBatchOrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryBatchOrdersOrderIdDocumentsDeliveryBatchGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderItemOrdersOrderIdItemsIdGet(orderId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderItemOrdersOrderIdItemsIdGet(orderId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderItemOrdersOrderIdItemsIdGet_3(orderId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderItemOrdersOrderIdItemsIdGet_3(orderId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Items
         * @param {number} orderId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderItemsOrdersOrderIdItemsGet(orderId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseOrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderItemsOrdersOrderIdItemsGet(orderId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Items
         * @param {number} orderId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderItemsOrdersOrderIdItemsGet_4(orderId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseOrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderItemsOrdersOrderIdItemsGet_4(orderId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderOrdersOrderIdGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderOrdersOrderIdGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Status Logs
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderStatusLogsOrdersOrderIdStatusLogsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderStatusLogResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderStatusLogsOrdersOrderIdStatusLogsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderItemOrdersOrderIdItemsIdPut(orderId: number, id: number, saveOrderItemRequest: SaveOrderItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderItemOrdersOrderIdItemsIdPut(orderId, id, saveOrderItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderItemOrdersOrderIdItemsIdPut_5(orderId: number, id: number, saveOrderItemRequest: SaveOrderItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderItemOrdersOrderIdItemsIdPut_5(orderId, id, saveOrderItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Order Item
         * @param {number} orderId 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderItemOrdersOrderIdItemsPost(orderId: number, saveOrderItemRequest: SaveOrderItemRequest, options?: any): AxiosPromise<OrderItemResponse> {
            return localVarFp.createOrderItemOrdersOrderIdItemsPost(orderId, saveOrderItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Order Item
         * @param {number} orderId 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderItemOrdersOrderIdItemsPost_1(orderId: number, saveOrderItemRequest: SaveOrderItemRequest, options?: any): AxiosPromise<OrderItemResponse> {
            return localVarFp.createOrderItemOrdersOrderIdItemsPost_1(orderId, saveOrderItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderRequest} createOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderOrdersPost(createOrderRequest: CreateOrderRequest, options?: any): AxiosPromise<OrderResponse> {
            return localVarFp.createOrderOrdersPost(createOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderItemOrdersOrderIdItemsIdDelete(orderId: number, id: number, options?: any): AxiosPromise<OrderItemResponse> {
            return localVarFp.deleteOrderItemOrdersOrderIdItemsIdDelete(orderId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderItemOrdersOrderIdItemsIdDelete_2(orderId: number, id: number, options?: any): AxiosPromise<OrderItemResponse> {
            return localVarFp.deleteOrderItemOrdersOrderIdItemsIdDelete_2(orderId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get B2B Orders
         * @param {Array<number>} [id] 
         * @param {Array<string>} [status] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getB2bOrdersOrdersB2bOrdersGet(id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, legalEntities?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseOrderListResponse> {
            return localVarFp.getB2bOrdersOrdersB2bOrdersGet(id, status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get B2C Orders
         * @param {Array<number>} [id] 
         * @param {Array<string>} [status] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getB2cOrdersOrdersB2cOrdersGet(id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, legalEntities?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseOrderListResponse> {
            return localVarFp.getB2cOrdersOrdersB2cOrdersGet(id, status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Internal Transfers
         * @param {Array<number>} [id] 
         * @param {Array<string>} [status] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [receivers] 
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalTransfersOrdersInternalTransfersGet(id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseOrderListResponse> {
            return localVarFp.getInternalTransfersOrdersInternalTransfersGet(id, status, companies, warehouses, receivers, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Order Execution Percentage
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderExecutionPercentageOrdersOrderIdExecutionPercentageGet(orderId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getOrderExecutionPercentageOrdersOrderIdExecutionPercentageGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Order
         * @param {number} orderId 
         * @param {PatchOrderRequest} patchOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrderOrdersOrderIdPatch(orderId: number, patchOrderRequest: PatchOrderRequest, options?: any): AxiosPromise<OrderResponse> {
            return localVarFp.patchOrderOrdersOrderIdPatch(orderId, patchOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Collected Batch Items
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCollectedBatchItemsOrdersOrderIdCollectedBatchItemsGet(orderId: number, options?: any): AxiosPromise<Array<CollectedBatchItemResponse>> {
            return localVarFp.readCollectedBatchItemsOrdersOrderIdCollectedBatchItemsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryBatchOrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readDeliveryBatchOrdersOrderIdDocumentsDeliveryBatchGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemOrdersOrderIdItemsIdGet(orderId: number, id: number, options?: any): AxiosPromise<OrderItemResponse> {
            return localVarFp.readOrderItemOrdersOrderIdItemsIdGet(orderId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemOrdersOrderIdItemsIdGet_3(orderId: number, id: number, options?: any): AxiosPromise<OrderItemResponse> {
            return localVarFp.readOrderItemOrdersOrderIdItemsIdGet_3(orderId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Items
         * @param {number} orderId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemsOrdersOrderIdItemsGet(orderId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseOrderItemResponse> {
            return localVarFp.readOrderItemsOrdersOrderIdItemsGet(orderId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Items
         * @param {number} orderId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderItemsOrdersOrderIdItemsGet_4(orderId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseOrderItemResponse> {
            return localVarFp.readOrderItemsOrdersOrderIdItemsGet_4(orderId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderOrdersOrderIdGet(orderId: number, options?: any): AxiosPromise<OrderResponse> {
            return localVarFp.readOrderOrdersOrderIdGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Status Logs
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderStatusLogsOrdersOrderIdStatusLogsGet(orderId: number, options?: any): AxiosPromise<Array<OrderStatusLogResponse>> {
            return localVarFp.readOrderStatusLogsOrdersOrderIdStatusLogsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderItemOrdersOrderIdItemsIdPut(orderId: number, id: number, saveOrderItemRequest: SaveOrderItemRequest, options?: any): AxiosPromise<OrderItemResponse> {
            return localVarFp.updateOrderItemOrdersOrderIdItemsIdPut(orderId, id, saveOrderItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Order Item
         * @param {number} orderId 
         * @param {number} id 
         * @param {SaveOrderItemRequest} saveOrderItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderItemOrdersOrderIdItemsIdPut_5(orderId: number, id: number, saveOrderItemRequest: SaveOrderItemRequest, options?: any): AxiosPromise<OrderItemResponse> {
            return localVarFp.updateOrderItemOrdersOrderIdItemsIdPut_5(orderId, id, saveOrderItemRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * 
     * @summary Create Order Item
     * @param {number} orderId 
     * @param {SaveOrderItemRequest} saveOrderItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public createOrderItemOrdersOrderIdItemsPost(orderId: number, saveOrderItemRequest: SaveOrderItemRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).createOrderItemOrdersOrderIdItemsPost(orderId, saveOrderItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Order Item
     * @param {number} orderId 
     * @param {SaveOrderItemRequest} saveOrderItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public createOrderItemOrdersOrderIdItemsPost_1(orderId: number, saveOrderItemRequest: SaveOrderItemRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).createOrderItemOrdersOrderIdItemsPost_1(orderId, saveOrderItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Order
     * @param {CreateOrderRequest} createOrderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public createOrderOrdersPost(createOrderRequest: CreateOrderRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).createOrderOrdersPost(createOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Order Item
     * @param {number} orderId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public deleteOrderItemOrdersOrderIdItemsIdDelete(orderId: number, id: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).deleteOrderItemOrdersOrderIdItemsIdDelete(orderId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Order Item
     * @param {number} orderId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public deleteOrderItemOrdersOrderIdItemsIdDelete_2(orderId: number, id: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).deleteOrderItemOrdersOrderIdItemsIdDelete_2(orderId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get B2B Orders
     * @param {Array<number>} [id] 
     * @param {Array<string>} [status] 
     * @param {Array<number>} [companies] 
     * @param {Array<number>} [warehouses] 
     * @param {Array<number>} [receivers] 
     * @param {Array<number>} [legalEntities] 
     * @param {string} [fromDeliveryDate] 
     * @param {string} [toDeliveryDate] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getB2bOrdersOrdersB2bOrdersGet(id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, legalEntities?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getB2bOrdersOrdersB2bOrdersGet(id, status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get B2C Orders
     * @param {Array<number>} [id] 
     * @param {Array<string>} [status] 
     * @param {Array<number>} [companies] 
     * @param {Array<number>} [warehouses] 
     * @param {Array<number>} [receivers] 
     * @param {Array<number>} [legalEntities] 
     * @param {string} [fromDeliveryDate] 
     * @param {string} [toDeliveryDate] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getB2cOrdersOrdersB2cOrdersGet(id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, legalEntities?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getB2cOrdersOrdersB2cOrdersGet(id, status, companies, warehouses, receivers, legalEntities, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Internal Transfers
     * @param {Array<number>} [id] 
     * @param {Array<string>} [status] 
     * @param {Array<number>} [companies] 
     * @param {Array<number>} [warehouses] 
     * @param {Array<number>} [receivers] 
     * @param {string} [fromDeliveryDate] 
     * @param {string} [toDeliveryDate] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getInternalTransfersOrdersInternalTransfersGet(id?: Array<number>, status?: Array<string>, companies?: Array<number>, warehouses?: Array<number>, receivers?: Array<number>, fromDeliveryDate?: string, toDeliveryDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getInternalTransfersOrdersInternalTransfersGet(id, status, companies, warehouses, receivers, fromDeliveryDate, toDeliveryDate, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Order Execution Percentage
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getOrderExecutionPercentageOrdersOrderIdExecutionPercentageGet(orderId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getOrderExecutionPercentageOrdersOrderIdExecutionPercentageGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Order
     * @param {number} orderId 
     * @param {PatchOrderRequest} patchOrderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public patchOrderOrdersOrderIdPatch(orderId: number, patchOrderRequest: PatchOrderRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).patchOrderOrdersOrderIdPatch(orderId, patchOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Collected Batch Items
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readCollectedBatchItemsOrdersOrderIdCollectedBatchItemsGet(orderId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readCollectedBatchItemsOrdersOrderIdCollectedBatchItemsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Batch
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readDeliveryBatchOrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readDeliveryBatchOrdersOrderIdDocumentsDeliveryBatchGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Item
     * @param {number} orderId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readOrderItemOrdersOrderIdItemsIdGet(orderId: number, id: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readOrderItemOrdersOrderIdItemsIdGet(orderId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Item
     * @param {number} orderId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readOrderItemOrdersOrderIdItemsIdGet_3(orderId: number, id: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readOrderItemOrdersOrderIdItemsIdGet_3(orderId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Items
     * @param {number} orderId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readOrderItemsOrdersOrderIdItemsGet(orderId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readOrderItemsOrdersOrderIdItemsGet(orderId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Items
     * @param {number} orderId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readOrderItemsOrdersOrderIdItemsGet_4(orderId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readOrderItemsOrdersOrderIdItemsGet_4(orderId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readOrderOrdersOrderIdGet(orderId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readOrderOrdersOrderIdGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Status Logs
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readOrderStatusLogsOrdersOrderIdStatusLogsGet(orderId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readOrderStatusLogsOrdersOrderIdStatusLogsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Order Item
     * @param {number} orderId 
     * @param {number} id 
     * @param {SaveOrderItemRequest} saveOrderItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public updateOrderItemOrdersOrderIdItemsIdPut(orderId: number, id: number, saveOrderItemRequest: SaveOrderItemRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).updateOrderItemOrdersOrderIdItemsIdPut(orderId, id, saveOrderItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Order Item
     * @param {number} orderId 
     * @param {number} id 
     * @param {SaveOrderItemRequest} saveOrderItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public updateOrderItemOrdersOrderIdItemsIdPut_5(orderId: number, id: number, saveOrderItemRequest: SaveOrderItemRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).updateOrderItemOrdersOrderIdItemsIdPut_5(orderId, id, saveOrderItemRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerApi - axios parameter creator
 * @export
 */
export const PartnerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Copy Partner Item Configurations
         * @param {number} partnerId 
         * @param {number} sourcePartnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost: async (partnerId: number, sourcePartnerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost', 'partnerId', partnerId)
            // verify required parameter 'sourcePartnerId' is not null or undefined
            assertParamExists('copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost', 'sourcePartnerId', sourcePartnerId)
            const localVarPath = `/partners/{partner_id}/item-configuration/copy/{source_partner_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"source_partner_id"}}`, encodeURIComponent(String(sourcePartnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Partner Item Configuration
         * @param {number} partnerId 
         * @param {SavePartnerItemConfigurationRequest} savePartnerItemConfigurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost: async (partnerId: number, savePartnerItemConfigurationRequest: SavePartnerItemConfigurationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost', 'partnerId', partnerId)
            // verify required parameter 'savePartnerItemConfigurationRequest' is not null or undefined
            assertParamExists('createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost', 'savePartnerItemConfigurationRequest', savePartnerItemConfigurationRequest)
            const localVarPath = `/partners/{partner_id}/item-configuration`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savePartnerItemConfigurationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Partner
         * @param {SavePartnerRequest} savePartnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartnerPartnersPost: async (savePartnerRequest: SavePartnerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'savePartnerRequest' is not null or undefined
            assertParamExists('createPartnerPartnersPost', 'savePartnerRequest', savePartnerRequest)
            const localVarPath = `/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savePartnerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Partner Item Configuration
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerItemConfigurationPartnersItemConfigurationIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePartnerItemConfigurationPartnersItemConfigurationIdDelete', 'id', id)
            const localVarPath = `/partners/item-configuration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Items By Partner
         * @param {number} partnerId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsByPartnerPartnersPartnerIdItemsGet: async (partnerId: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('readItemsByPartnerPartnersPartnerIdItemsGet', 'partnerId', partnerId)
            const localVarPath = `/partners/{partner_id}/items`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderingPolicyPartnersIdOrderingPolicyGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readOrderingPolicyPartnersIdOrderingPolicyGet', 'id', id)
            const localVarPath = `/partners/{id}/ordering-policy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Partner By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerByIdPartnersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPartnerByIdPartnersIdGet', 'id', id)
            const localVarPath = `/partners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Partner Item Configurations
         * @param {number} partnerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerItemConfigurationsPartnersPartnerIdItemConfigurationGet: async (partnerId: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('readPartnerItemConfigurationsPartnersPartnerIdItemConfigurationGet', 'partnerId', partnerId)
            const localVarPath = `/partners/{partner_id}/item-configuration`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnersPartnerIdItemCategoryAssociationGet: async (partnerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('readPartnersPartnerIdItemCategoryAssociationGet', 'partnerId', partnerId)
            const localVarPath = `/partners/{partner_id}/item-category-association`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Partners
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnersPartnersGet: async (id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderingPolicyPartnersIdOrderingPolicyPut: async (id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setOrderingPolicyPartnersIdOrderingPolicyPut', 'id', id)
            // verify required parameter 'setOrderingPolicyRequest' is not null or undefined
            assertParamExists('setOrderingPolicyPartnersIdOrderingPolicyPut', 'setOrderingPolicyRequest', setOrderingPolicyRequest)
            const localVarPath = `/partners/{id}/ordering-policy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setOrderingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Partner Integration Settings
         * @param {number} partnerId 
         * @param {Request1} request1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost: async (partnerId: number, request1: Request1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost', 'partnerId', partnerId)
            // verify required parameter 'request1' is not null or undefined
            assertParamExists('setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost', 'request1', request1)
            const localVarPath = `/partners/{partner_id}/integration-settings`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test Barsy Connection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testBarsyConnectionPartnersIdTestBarsyConnectionPost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testBarsyConnectionPartnersIdTestBarsyConnectionPost', 'id', id)
            const localVarPath = `/partners/{id}/test-barsy-connection`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Partner Item Configuration
         * @param {number} id 
         * @param {SavePartnerItemConfigurationRequest} savePartnerItemConfigurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerItemConfigurationPartnersItemConfigurationIdPut: async (id: number, savePartnerItemConfigurationRequest: SavePartnerItemConfigurationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePartnerItemConfigurationPartnersItemConfigurationIdPut', 'id', id)
            // verify required parameter 'savePartnerItemConfigurationRequest' is not null or undefined
            assertParamExists('updatePartnerItemConfigurationPartnersItemConfigurationIdPut', 'savePartnerItemConfigurationRequest', savePartnerItemConfigurationRequest)
            const localVarPath = `/partners/item-configuration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savePartnerItemConfigurationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Partner
         * @param {number} id 
         * @param {SavePartnerRequest} savePartnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerPartnersIdPut: async (id: number, savePartnerRequest: SavePartnerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePartnerPartnersIdPut', 'id', id)
            // verify required parameter 'savePartnerRequest' is not null or undefined
            assertParamExists('updatePartnerPartnersIdPut', 'savePartnerRequest', savePartnerRequest)
            const localVarPath = `/partners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savePartnerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnersPartnerIdItemCategoryAssociationPost: async (partnerId: number, requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('updatePartnersPartnerIdItemCategoryAssociationPost', 'partnerId', partnerId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updatePartnersPartnerIdItemCategoryAssociationPost', 'requestBody', requestBody)
            const localVarPath = `/partners/{partner_id}/item-category-association`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerApi - functional programming interface
 * @export
 */
export const PartnerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Copy Partner Item Configurations
         * @param {number} partnerId 
         * @param {number} sourcePartnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost(partnerId: number, sourcePartnerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePartnerItemConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost(partnerId, sourcePartnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Partner Item Configuration
         * @param {number} partnerId 
         * @param {SavePartnerItemConfigurationRequest} savePartnerItemConfigurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost(partnerId: number, savePartnerItemConfigurationRequest: SavePartnerItemConfigurationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerItemConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost(partnerId, savePartnerItemConfigurationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Partner
         * @param {SavePartnerRequest} savePartnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPartnerPartnersPost(savePartnerRequest: SavePartnerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPartnerPartnersPost(savePartnerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Partner Item Configuration
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePartnerItemConfigurationPartnersItemConfigurationIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerItemConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePartnerItemConfigurationPartnersItemConfigurationIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Items By Partner
         * @param {number} partnerId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readItemsByPartnerPartnersPartnerIdItemsGet(partnerId: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readItemsByPartnerPartnersPartnerIdItemsGet(partnerId, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrderingPolicyPartnersIdOrderingPolicyGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderingPolicyPartnersIdOrderingPolicyGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Partner By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPartnerByIdPartnersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPartnerByIdPartnersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Partner Item Configurations
         * @param {number} partnerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPartnerItemConfigurationsPartnersPartnerIdItemConfigurationGet(partnerId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePartnerItemConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPartnerItemConfigurationsPartnersPartnerIdItemConfigurationGet(partnerId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPartnersPartnerIdItemCategoryAssociationGet(partnerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPartnersPartnerIdItemCategoryAssociationGet(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Partners
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPartnersPartnersGet(id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePartnerListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPartnersPartnersGet(id, showInactive, query, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setOrderingPolicyPartnersIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setOrderingPolicyPartnersIdOrderingPolicyPut(id, setOrderingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set Partner Integration Settings
         * @param {number} partnerId 
         * @param {Request1} request1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost(partnerId: number, request1: Request1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost(partnerId, request1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Test Barsy Connection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testBarsyConnectionPartnersIdTestBarsyConnectionPost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestConnectionDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testBarsyConnectionPartnersIdTestBarsyConnectionPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Partner Item Configuration
         * @param {number} id 
         * @param {SavePartnerItemConfigurationRequest} savePartnerItemConfigurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePartnerItemConfigurationPartnersItemConfigurationIdPut(id: number, savePartnerItemConfigurationRequest: SavePartnerItemConfigurationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerItemConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartnerItemConfigurationPartnersItemConfigurationIdPut(id, savePartnerItemConfigurationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Partner
         * @param {number} id 
         * @param {SavePartnerRequest} savePartnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePartnerPartnersIdPut(id: number, savePartnerRequest: SavePartnerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartnerPartnersIdPut(id, savePartnerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePartnersPartnerIdItemCategoryAssociationPost(partnerId: number, requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartnersPartnerIdItemCategoryAssociationPost(partnerId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerApi - factory interface
 * @export
 */
export const PartnerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerApiFp(configuration)
    return {
        /**
         * 
         * @summary Copy Partner Item Configurations
         * @param {number} partnerId 
         * @param {number} sourcePartnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost(partnerId: number, sourcePartnerId: number, options?: any): AxiosPromise<PaginatedListResponsePartnerItemConfigurationResponse> {
            return localVarFp.copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost(partnerId, sourcePartnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Partner Item Configuration
         * @param {number} partnerId 
         * @param {SavePartnerItemConfigurationRequest} savePartnerItemConfigurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost(partnerId: number, savePartnerItemConfigurationRequest: SavePartnerItemConfigurationRequest, options?: any): AxiosPromise<PartnerItemConfigurationResponse> {
            return localVarFp.createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost(partnerId, savePartnerItemConfigurationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Partner
         * @param {SavePartnerRequest} savePartnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartnerPartnersPost(savePartnerRequest: SavePartnerRequest, options?: any): AxiosPromise<PartnerResponse> {
            return localVarFp.createPartnerPartnersPost(savePartnerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Partner Item Configuration
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerItemConfigurationPartnersItemConfigurationIdDelete(id: number, options?: any): AxiosPromise<PartnerItemConfigurationResponse> {
            return localVarFp.deletePartnerItemConfigurationPartnersItemConfigurationIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Items By Partner
         * @param {number} partnerId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readItemsByPartnerPartnersPartnerIdItemsGet(partnerId: number, search?: string, options?: any): AxiosPromise<Array<PartnerItemResponse>> {
            return localVarFp.readItemsByPartnerPartnersPartnerIdItemsGet(partnerId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrderingPolicyPartnersIdOrderingPolicyGet(id: number, options?: any): AxiosPromise<OrderingPolicyResponse> {
            return localVarFp.readOrderingPolicyPartnersIdOrderingPolicyGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Partner By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerByIdPartnersIdGet(id: number, options?: any): AxiosPromise<PartnerResponse> {
            return localVarFp.readPartnerByIdPartnersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Partner Item Configurations
         * @param {number} partnerId 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerItemConfigurationsPartnersPartnerIdItemConfigurationGet(partnerId: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponsePartnerItemConfigurationResponse> {
            return localVarFp.readPartnerItemConfigurationsPartnersPartnerIdItemConfigurationGet(partnerId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnersPartnerIdItemCategoryAssociationGet(partnerId: number, options?: any): AxiosPromise<Array<PartnerItemCategoryResponse>> {
            return localVarFp.readPartnersPartnerIdItemCategoryAssociationGet(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Partners
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnersPartnersGet(id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponsePartnerListResponse> {
            return localVarFp.readPartnersPartnersGet(id, showInactive, query, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrderingPolicyPartnersIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: any): AxiosPromise<OrderingPolicyResponse> {
            return localVarFp.setOrderingPolicyPartnersIdOrderingPolicyPut(id, setOrderingPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Partner Integration Settings
         * @param {number} partnerId 
         * @param {Request1} request1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost(partnerId: number, request1: Request1, options?: any): AxiosPromise<void> {
            return localVarFp.setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost(partnerId, request1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test Barsy Connection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testBarsyConnectionPartnersIdTestBarsyConnectionPost(id: number, options?: any): AxiosPromise<TestConnectionDetailsResponse> {
            return localVarFp.testBarsyConnectionPartnersIdTestBarsyConnectionPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Partner Item Configuration
         * @param {number} id 
         * @param {SavePartnerItemConfigurationRequest} savePartnerItemConfigurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerItemConfigurationPartnersItemConfigurationIdPut(id: number, savePartnerItemConfigurationRequest: SavePartnerItemConfigurationRequest, options?: any): AxiosPromise<PartnerItemConfigurationResponse> {
            return localVarFp.updatePartnerItemConfigurationPartnersItemConfigurationIdPut(id, savePartnerItemConfigurationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Partner
         * @param {number} id 
         * @param {SavePartnerRequest} savePartnerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerPartnersIdPut(id: number, savePartnerRequest: SavePartnerRequest, options?: any): AxiosPromise<PartnerResponse> {
            return localVarFp.updatePartnerPartnersIdPut(id, savePartnerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnersPartnerIdItemCategoryAssociationPost(partnerId: number, requestBody: Array<number>, options?: any): AxiosPromise<Array<PartnerItemCategoryResponse>> {
            return localVarFp.updatePartnersPartnerIdItemCategoryAssociationPost(partnerId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerApi - object-oriented interface
 * @export
 * @class PartnerApi
 * @extends {BaseAPI}
 */
export class PartnerApi extends BaseAPI {
    /**
     * 
     * @summary Copy Partner Item Configurations
     * @param {number} partnerId 
     * @param {number} sourcePartnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost(partnerId: number, sourcePartnerId: number, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).copyPartnerItemConfigurationsPartnersPartnerIdItemConfigurationCopySourcePartnerIdPost(partnerId, sourcePartnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Partner Item Configuration
     * @param {number} partnerId 
     * @param {SavePartnerItemConfigurationRequest} savePartnerItemConfigurationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost(partnerId: number, savePartnerItemConfigurationRequest: SavePartnerItemConfigurationRequest, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).createPartnerItemConfigurationPartnersPartnerIdItemConfigurationPost(partnerId, savePartnerItemConfigurationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Partner
     * @param {SavePartnerRequest} savePartnerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public createPartnerPartnersPost(savePartnerRequest: SavePartnerRequest, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).createPartnerPartnersPost(savePartnerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Partner Item Configuration
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public deletePartnerItemConfigurationPartnersItemConfigurationIdDelete(id: number, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).deletePartnerItemConfigurationPartnersItemConfigurationIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Items By Partner
     * @param {number} partnerId 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public readItemsByPartnerPartnersPartnerIdItemsGet(partnerId: number, search?: string, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).readItemsByPartnerPartnersPartnerIdItemsGet(partnerId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Ordering Policy
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public readOrderingPolicyPartnersIdOrderingPolicyGet(id: number, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).readOrderingPolicyPartnersIdOrderingPolicyGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Partner By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public readPartnerByIdPartnersIdGet(id: number, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).readPartnerByIdPartnersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Partner Item Configurations
     * @param {number} partnerId 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public readPartnerItemConfigurationsPartnersPartnerIdItemConfigurationGet(partnerId: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).readPartnerItemConfigurationsPartnersPartnerIdItemConfigurationGet(partnerId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} partnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public readPartnersPartnerIdItemCategoryAssociationGet(partnerId: number, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).readPartnersPartnerIdItemCategoryAssociationGet(partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Partners
     * @param {Array<number>} [id] 
     * @param {boolean} [showInactive] 
     * @param {string} [query] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public readPartnersPartnersGet(id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).readPartnersPartnersGet(id, showInactive, query, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Ordering Policy
     * @param {number} id 
     * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public setOrderingPolicyPartnersIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).setOrderingPolicyPartnersIdOrderingPolicyPut(id, setOrderingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Partner Integration Settings
     * @param {number} partnerId 
     * @param {Request1} request1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost(partnerId: number, request1: Request1, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).setPartnerIntegrationSettingsPartnersPartnerIdIntegrationSettingsPost(partnerId, request1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test Barsy Connection
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public testBarsyConnectionPartnersIdTestBarsyConnectionPost(id: number, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).testBarsyConnectionPartnersIdTestBarsyConnectionPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Partner Item Configuration
     * @param {number} id 
     * @param {SavePartnerItemConfigurationRequest} savePartnerItemConfigurationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public updatePartnerItemConfigurationPartnersItemConfigurationIdPut(id: number, savePartnerItemConfigurationRequest: SavePartnerItemConfigurationRequest, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).updatePartnerItemConfigurationPartnersItemConfigurationIdPut(id, savePartnerItemConfigurationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Partner
     * @param {number} id 
     * @param {SavePartnerRequest} savePartnerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public updatePartnerPartnersIdPut(id: number, savePartnerRequest: SavePartnerRequest, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).updatePartnerPartnersIdPut(id, savePartnerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {number} partnerId 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public updatePartnersPartnerIdItemCategoryAssociationPost(partnerId: number, requestBody: Array<number>, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).updatePartnersPartnerIdItemCategoryAssociationPost(partnerId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentOptionsApi - axios parameter creator
 * @export
 */
export const PaymentOptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Payment Option
         * @param {SavePaymentOptionRequest} savePaymentOptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentOptionPaymentOptionsPost: async (savePaymentOptionRequest: SavePaymentOptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'savePaymentOptionRequest' is not null or undefined
            assertParamExists('createPaymentOptionPaymentOptionsPost', 'savePaymentOptionRequest', savePaymentOptionRequest)
            const localVarPath = `/payment-options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savePaymentOptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Payment Option By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPaymentOptionByIdPaymentOptionsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPaymentOptionByIdPaymentOptionsIdGet', 'id', id)
            const localVarPath = `/payment-options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Payment Options
         * @param {Array<number>} [id] 
         * @param {number} [company] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPaymentOptionsPaymentOptionsGet: async (id?: Array<number>, company?: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment-options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Payment Option
         * @param {number} id 
         * @param {SavePaymentOptionRequest} savePaymentOptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentOptionPaymentOptionsIdPut: async (id: number, savePaymentOptionRequest: SavePaymentOptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePaymentOptionPaymentOptionsIdPut', 'id', id)
            // verify required parameter 'savePaymentOptionRequest' is not null or undefined
            assertParamExists('updatePaymentOptionPaymentOptionsIdPut', 'savePaymentOptionRequest', savePaymentOptionRequest)
            const localVarPath = `/payment-options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savePaymentOptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentOptionsApi - functional programming interface
 * @export
 */
export const PaymentOptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentOptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Payment Option
         * @param {SavePaymentOptionRequest} savePaymentOptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPaymentOptionPaymentOptionsPost(savePaymentOptionRequest: SavePaymentOptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPaymentOptionPaymentOptionsPost(savePaymentOptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Payment Option By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPaymentOptionByIdPaymentOptionsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPaymentOptionByIdPaymentOptionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Payment Options
         * @param {Array<number>} [id] 
         * @param {number} [company] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPaymentOptionsPaymentOptionsGet(id?: Array<number>, company?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePaymentOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPaymentOptionsPaymentOptionsGet(id, company, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Payment Option
         * @param {number} id 
         * @param {SavePaymentOptionRequest} savePaymentOptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePaymentOptionPaymentOptionsIdPut(id: number, savePaymentOptionRequest: SavePaymentOptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePaymentOptionPaymentOptionsIdPut(id, savePaymentOptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentOptionsApi - factory interface
 * @export
 */
export const PaymentOptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentOptionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Payment Option
         * @param {SavePaymentOptionRequest} savePaymentOptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentOptionPaymentOptionsPost(savePaymentOptionRequest: SavePaymentOptionRequest, options?: any): AxiosPromise<PaymentOptionResponse> {
            return localVarFp.createPaymentOptionPaymentOptionsPost(savePaymentOptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Payment Option By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPaymentOptionByIdPaymentOptionsIdGet(id: number, options?: any): AxiosPromise<PaymentOptionResponse> {
            return localVarFp.readPaymentOptionByIdPaymentOptionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Payment Options
         * @param {Array<number>} [id] 
         * @param {number} [company] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPaymentOptionsPaymentOptionsGet(id?: Array<number>, company?: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponsePaymentOptionResponse> {
            return localVarFp.readPaymentOptionsPaymentOptionsGet(id, company, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Payment Option
         * @param {number} id 
         * @param {SavePaymentOptionRequest} savePaymentOptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentOptionPaymentOptionsIdPut(id: number, savePaymentOptionRequest: SavePaymentOptionRequest, options?: any): AxiosPromise<PaymentOptionResponse> {
            return localVarFp.updatePaymentOptionPaymentOptionsIdPut(id, savePaymentOptionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentOptionsApi - object-oriented interface
 * @export
 * @class PaymentOptionsApi
 * @extends {BaseAPI}
 */
export class PaymentOptionsApi extends BaseAPI {
    /**
     * 
     * @summary Create Payment Option
     * @param {SavePaymentOptionRequest} savePaymentOptionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentOptionsApi
     */
    public createPaymentOptionPaymentOptionsPost(savePaymentOptionRequest: SavePaymentOptionRequest, options?: AxiosRequestConfig) {
        return PaymentOptionsApiFp(this.configuration).createPaymentOptionPaymentOptionsPost(savePaymentOptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Payment Option By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentOptionsApi
     */
    public readPaymentOptionByIdPaymentOptionsIdGet(id: number, options?: AxiosRequestConfig) {
        return PaymentOptionsApiFp(this.configuration).readPaymentOptionByIdPaymentOptionsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Payment Options
     * @param {Array<number>} [id] 
     * @param {number} [company] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentOptionsApi
     */
    public readPaymentOptionsPaymentOptionsGet(id?: Array<number>, company?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return PaymentOptionsApiFp(this.configuration).readPaymentOptionsPaymentOptionsGet(id, company, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Payment Option
     * @param {number} id 
     * @param {SavePaymentOptionRequest} savePaymentOptionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentOptionsApi
     */
    public updatePaymentOptionPaymentOptionsIdPut(id: number, savePaymentOptionRequest: SavePaymentOptionRequest, options?: AxiosRequestConfig) {
        return PaymentOptionsApiFp(this.configuration).updatePaymentOptionPaymentOptionsIdPut(id, savePaymentOptionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PortalUsersApi - axios parameter creator
 * @export
 */
export const PortalUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Portal User
         * @param {CreatePortalUserRequest} createPortalUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPortalUserPortalUsersPost: async (createPortalUserRequest: CreatePortalUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPortalUserRequest' is not null or undefined
            assertParamExists('createPortalUserPortalUsersPost', 'createPortalUserRequest', createPortalUserRequest)
            const localVarPath = `/portal-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPortalUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Portal User By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPortalUserByIdPortalUsersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPortalUserByIdPortalUsersIdGet', 'id', id)
            const localVarPath = `/portal-users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Portal Users
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPortalUsersPortalUsersGet: async (id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portal-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortalUsersApi - functional programming interface
 * @export
 */
export const PortalUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortalUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Portal User
         * @param {CreatePortalUserRequest} createPortalUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPortalUserPortalUsersPost(createPortalUserRequest: CreatePortalUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortalUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPortalUserPortalUsersPost(createPortalUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Portal User By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPortalUserByIdPortalUsersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortalUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPortalUserByIdPortalUsersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Portal Users
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPortalUsersPortalUsersGet(id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePortalUserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPortalUsersPortalUsersGet(id, showInactive, query, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortalUsersApi - factory interface
 * @export
 */
export const PortalUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortalUsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Portal User
         * @param {CreatePortalUserRequest} createPortalUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPortalUserPortalUsersPost(createPortalUserRequest: CreatePortalUserRequest, options?: any): AxiosPromise<PortalUserResponse> {
            return localVarFp.createPortalUserPortalUsersPost(createPortalUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Portal User By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPortalUserByIdPortalUsersIdGet(id: number, options?: any): AxiosPromise<PortalUserResponse> {
            return localVarFp.readPortalUserByIdPortalUsersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Portal Users
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPortalUsersPortalUsersGet(id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponsePortalUserListResponse> {
            return localVarFp.readPortalUsersPortalUsersGet(id, showInactive, query, page, size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortalUsersApi - object-oriented interface
 * @export
 * @class PortalUsersApi
 * @extends {BaseAPI}
 */
export class PortalUsersApi extends BaseAPI {
    /**
     * 
     * @summary Create Portal User
     * @param {CreatePortalUserRequest} createPortalUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalUsersApi
     */
    public createPortalUserPortalUsersPost(createPortalUserRequest: CreatePortalUserRequest, options?: AxiosRequestConfig) {
        return PortalUsersApiFp(this.configuration).createPortalUserPortalUsersPost(createPortalUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Portal User By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalUsersApi
     */
    public readPortalUserByIdPortalUsersIdGet(id: number, options?: AxiosRequestConfig) {
        return PortalUsersApiFp(this.configuration).readPortalUserByIdPortalUsersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Portal Users
     * @param {Array<number>} [id] 
     * @param {boolean} [showInactive] 
     * @param {string} [query] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalUsersApi
     */
    public readPortalUsersPortalUsersGet(id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return PortalUsersApiFp(this.configuration).readPortalUsersPortalUsersGet(id, showInactive, query, page, size, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PricingPoliciesApi - axios parameter creator
 * @export
 */
export const PricingPoliciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Pricing Policy
         * @param {SavePricingPolicyRequest} savePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPricingPolicyPricingPoliciesPost: async (savePricingPolicyRequest: SavePricingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'savePricingPolicyRequest' is not null or undefined
            assertParamExists('createPricingPolicyPricingPoliciesPost', 'savePricingPolicyRequest', savePricingPolicyRequest)
            const localVarPath = `/pricing-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savePricingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policies
         * @param {Array<number>} [id] 
         * @param {Array<number>} [companies] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPoliciesPricingPoliciesGet: async (id?: Array<number>, companies?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pricing-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyByIdPricingPoliciesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPricingPolicyByIdPricingPoliciesIdGet', 'id', id)
            const localVarPath = `/pricing-policies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoriesPricingPoliciesIdItemCategoriesGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPricingPolicyItemCategoriesPricingPoliciesIdItemCategoriesGet', 'id', id)
            const localVarPath = `/pricing-policies/{id}/item-categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Exceptions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemExceptionsPricingPoliciesIdItemExceptionsGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPricingPolicyItemExceptionsPricingPoliciesIdItemExceptionsGet', 'id', id)
            const localVarPath = `/pricing-policies/{id}/item-exceptions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {number} id 
         * @param {SavePricingPolicyRequest} savePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePricingPoliciesIdPut: async (id: number, savePricingPolicyRequest: SavePricingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePricingPoliciesIdPut', 'id', id)
            // verify required parameter 'savePricingPolicyRequest' is not null or undefined
            assertParamExists('updatePricingPoliciesIdPut', 'savePricingPolicyRequest', savePricingPolicyRequest)
            const localVarPath = `/pricing-policies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(savePricingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PricingPoliciesApi - functional programming interface
 * @export
 */
export const PricingPoliciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PricingPoliciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Pricing Policy
         * @param {SavePricingPolicyRequest} savePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPricingPolicyPricingPoliciesPost(savePricingPolicyRequest: SavePricingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPricingPolicyPricingPoliciesPost(savePricingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policies
         * @param {Array<number>} [id] 
         * @param {Array<number>} [companies] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPoliciesPricingPoliciesGet(id?: Array<number>, companies?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePricingPolicyListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPoliciesPricingPoliciesGet(id, companies, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPolicyByIdPricingPoliciesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyByIdPricingPoliciesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPolicyItemCategoriesPricingPoliciesIdItemCategoriesGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PricingPolicyItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemCategoriesPricingPoliciesIdItemCategoriesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Exceptions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPolicyItemExceptionsPricingPoliciesIdItemExceptionsGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PricingPolicyItemExceptionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemExceptionsPricingPoliciesIdItemExceptionsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {number} id 
         * @param {SavePricingPolicyRequest} savePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePricingPoliciesIdPut(id: number, savePricingPolicyRequest: SavePricingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePricingPoliciesIdPut(id, savePricingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PricingPoliciesApi - factory interface
 * @export
 */
export const PricingPoliciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PricingPoliciesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Pricing Policy
         * @param {SavePricingPolicyRequest} savePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPricingPolicyPricingPoliciesPost(savePricingPolicyRequest: SavePricingPolicyRequest, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.createPricingPolicyPricingPoliciesPost(savePricingPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policies
         * @param {Array<number>} [id] 
         * @param {Array<number>} [companies] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPoliciesPricingPoliciesGet(id?: Array<number>, companies?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponsePricingPolicyListResponse> {
            return localVarFp.readPricingPoliciesPricingPoliciesGet(id, companies, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyByIdPricingPoliciesIdGet(id: number, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.readPricingPolicyByIdPricingPoliciesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoriesPricingPoliciesIdItemCategoriesGet(id: number, options?: any): AxiosPromise<Array<PricingPolicyItemCategoryResponse>> {
            return localVarFp.readPricingPolicyItemCategoriesPricingPoliciesIdItemCategoriesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Exceptions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemExceptionsPricingPoliciesIdItemExceptionsGet(id: number, options?: any): AxiosPromise<Array<PricingPolicyItemExceptionResponse>> {
            return localVarFp.readPricingPolicyItemExceptionsPricingPoliciesIdItemExceptionsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {number} id 
         * @param {SavePricingPolicyRequest} savePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePricingPoliciesIdPut(id: number, savePricingPolicyRequest: SavePricingPolicyRequest, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.updatePricingPoliciesIdPut(id, savePricingPolicyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PricingPoliciesApi - object-oriented interface
 * @export
 * @class PricingPoliciesApi
 * @extends {BaseAPI}
 */
export class PricingPoliciesApi extends BaseAPI {
    /**
     * 
     * @summary Create Pricing Policy
     * @param {SavePricingPolicyRequest} savePricingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public createPricingPolicyPricingPoliciesPost(savePricingPolicyRequest: SavePricingPolicyRequest, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).createPricingPolicyPricingPoliciesPost(savePricingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policies
     * @param {Array<number>} [id] 
     * @param {Array<number>} [companies] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public readPricingPoliciesPricingPoliciesGet(id?: Array<number>, companies?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).readPricingPoliciesPricingPoliciesGet(id, companies, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public readPricingPolicyByIdPricingPoliciesIdGet(id: number, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).readPricingPolicyByIdPricingPoliciesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Categories
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public readPricingPolicyItemCategoriesPricingPoliciesIdItemCategoriesGet(id: number, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).readPricingPolicyItemCategoriesPricingPoliciesIdItemCategoriesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Exceptions
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public readPricingPolicyItemExceptionsPricingPoliciesIdItemExceptionsGet(id: number, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).readPricingPolicyItemExceptionsPricingPoliciesIdItemExceptionsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {number} id 
     * @param {SavePricingPolicyRequest} savePricingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public updatePricingPoliciesIdPut(id: number, savePricingPolicyRequest: SavePricingPolicyRequest, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).updatePricingPoliciesIdPut(id, savePricingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrintApi - axios parameter creator
 * @export
 */
export const PrintApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Print Document Queue
         * @param {string} [state] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPrintDocumentQueuePrintDocumentQueueGet: async (state?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/print-document-queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Print Document Queue
         * @param {number} id 
         * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrintDocumentQueuePrintDocumentQueueIdPatch: async (id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePrintDocumentQueuePrintDocumentQueueIdPatch', 'id', id)
            // verify required parameter 'updatePrintDocumentQueueRequest' is not null or undefined
            assertParamExists('updatePrintDocumentQueuePrintDocumentQueueIdPatch', 'updatePrintDocumentQueueRequest', updatePrintDocumentQueueRequest)
            const localVarPath = `/print-document-queue/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePrintDocumentQueueRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrintApi - functional programming interface
 * @export
 */
export const PrintApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrintApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Print Document Queue
         * @param {string} [state] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPrintDocumentQueuePrintDocumentQueueGet(state?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePrintDocumentQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPrintDocumentQueuePrintDocumentQueueGet(state, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Print Document Queue
         * @param {number} id 
         * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePrintDocumentQueuePrintDocumentQueueIdPatch(id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrintDocumentQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePrintDocumentQueuePrintDocumentQueueIdPatch(id, updatePrintDocumentQueueRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrintApi - factory interface
 * @export
 */
export const PrintApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrintApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Print Document Queue
         * @param {string} [state] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPrintDocumentQueuePrintDocumentQueueGet(state?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponsePrintDocumentQueueResponse> {
            return localVarFp.readPrintDocumentQueuePrintDocumentQueueGet(state, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Print Document Queue
         * @param {number} id 
         * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrintDocumentQueuePrintDocumentQueueIdPatch(id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options?: any): AxiosPromise<PrintDocumentQueueResponse> {
            return localVarFp.updatePrintDocumentQueuePrintDocumentQueueIdPatch(id, updatePrintDocumentQueueRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PrintApi - object-oriented interface
 * @export
 * @class PrintApi
 * @extends {BaseAPI}
 */
export class PrintApi extends BaseAPI {
    /**
     * 
     * @summary Read Print Document Queue
     * @param {string} [state] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintApi
     */
    public readPrintDocumentQueuePrintDocumentQueueGet(state?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return PrintApiFp(this.configuration).readPrintDocumentQueuePrintDocumentQueueGet(state, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Print Document Queue
     * @param {number} id 
     * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintApi
     */
    public updatePrintDocumentQueuePrintDocumentQueueIdPatch(id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options?: AxiosRequestConfig) {
        return PrintApiFp(this.configuration).updatePrintDocumentQueuePrintDocumentQueueIdPatch(id, updatePrintDocumentQueueRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromotionsApi - axios parameter creator
 * @export
 */
export const PromotionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Bulk Create Partner Bonus Quantity Promotions
         * @param {BulkCreatePartnerBonusQuantityRequest} bulkCreatePartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreatePartnerBonusQuantityPromotionsPromotionsBonusQuantityPost: async (bulkCreatePartnerBonusQuantityRequest: BulkCreatePartnerBonusQuantityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkCreatePartnerBonusQuantityRequest' is not null or undefined
            assertParamExists('bulkCreatePartnerBonusQuantityPromotionsPromotionsBonusQuantityPost', 'bulkCreatePartnerBonusQuantityRequest', bulkCreatePartnerBonusQuantityRequest)
            const localVarPath = `/promotions/bonus-quantity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkCreatePartnerBonusQuantityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Partner Bonus Quantity
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerBonusQuantityPromotionsBonusQuantityIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePartnerBonusQuantityPromotionsBonusQuantityIdDelete', 'id', id)
            const localVarPath = `/promotions/bonus-quantity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Partner Bonus Quantities
         * @param {Array<number>} [items] 
         * @param {Array<number>} [partners] 
         * @param {string} [fromStartDate] 
         * @param {string} [toStartDate] 
         * @param {string} [fromEndDate] 
         * @param {string} [toEndDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerBonusQuantitiesPromotionsBonusQuantityGet: async (items?: Array<number>, partners?: Array<number>, fromStartDate?: string, toStartDate?: string, fromEndDate?: string, toEndDate?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions/bonus-quantity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (items) {
                localVarQueryParameter['items'] = items;
            }

            if (partners) {
                localVarQueryParameter['partners'] = partners;
            }

            if (fromStartDate !== undefined) {
                localVarQueryParameter['from_start_date'] = (fromStartDate as any instanceof Date) ?
                    (fromStartDate as any).toISOString() :
                    fromStartDate;
            }

            if (toStartDate !== undefined) {
                localVarQueryParameter['to_start_date'] = (toStartDate as any instanceof Date) ?
                    (toStartDate as any).toISOString() :
                    toStartDate;
            }

            if (fromEndDate !== undefined) {
                localVarQueryParameter['from_end_date'] = (fromEndDate as any instanceof Date) ?
                    (fromEndDate as any).toISOString() :
                    fromEndDate;
            }

            if (toEndDate !== undefined) {
                localVarQueryParameter['to_end_date'] = (toEndDate as any instanceof Date) ?
                    (toEndDate as any).toISOString() :
                    toEndDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Promotion By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPromotionByIdPromotionsBonusQuantityIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPromotionByIdPromotionsBonusQuantityIdGet', 'id', id)
            const localVarPath = `/promotions/bonus-quantity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Partner Bonus Quantity
         * @param {number} id 
         * @param {PatchPartnerBonusQuantityRequest} patchPartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerBonusQuantityPromotionsBonusQuantityIdPut: async (id: number, patchPartnerBonusQuantityRequest: PatchPartnerBonusQuantityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePartnerBonusQuantityPromotionsBonusQuantityIdPut', 'id', id)
            // verify required parameter 'patchPartnerBonusQuantityRequest' is not null or undefined
            assertParamExists('updatePartnerBonusQuantityPromotionsBonusQuantityIdPut', 'patchPartnerBonusQuantityRequest', patchPartnerBonusQuantityRequest)
            const localVarPath = `/promotions/bonus-quantity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchPartnerBonusQuantityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionsApi - functional programming interface
 * @export
 */
export const PromotionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Bulk Create Partner Bonus Quantity Promotions
         * @param {BulkCreatePartnerBonusQuantityRequest} bulkCreatePartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkCreatePartnerBonusQuantityPromotionsPromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest: BulkCreatePartnerBonusQuantityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerBonusQuantityResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreatePartnerBonusQuantityPromotionsPromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Partner Bonus Quantity
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePartnerBonusQuantityPromotionsBonusQuantityIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerBonusQuantityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePartnerBonusQuantityPromotionsBonusQuantityIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Partner Bonus Quantities
         * @param {Array<number>} [items] 
         * @param {Array<number>} [partners] 
         * @param {string} [fromStartDate] 
         * @param {string} [toStartDate] 
         * @param {string} [fromEndDate] 
         * @param {string} [toEndDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPartnerBonusQuantitiesPromotionsBonusQuantityGet(items?: Array<number>, partners?: Array<number>, fromStartDate?: string, toStartDate?: string, fromEndDate?: string, toEndDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponsePartnerBonusQuantityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPartnerBonusQuantitiesPromotionsBonusQuantityGet(items, partners, fromStartDate, toStartDate, fromEndDate, toEndDate, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Promotion By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPromotionByIdPromotionsBonusQuantityIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerBonusQuantityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPromotionByIdPromotionsBonusQuantityIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Partner Bonus Quantity
         * @param {number} id 
         * @param {PatchPartnerBonusQuantityRequest} patchPartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePartnerBonusQuantityPromotionsBonusQuantityIdPut(id: number, patchPartnerBonusQuantityRequest: PatchPartnerBonusQuantityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerBonusQuantityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartnerBonusQuantityPromotionsBonusQuantityIdPut(id, patchPartnerBonusQuantityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromotionsApi - factory interface
 * @export
 */
export const PromotionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Bulk Create Partner Bonus Quantity Promotions
         * @param {BulkCreatePartnerBonusQuantityRequest} bulkCreatePartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkCreatePartnerBonusQuantityPromotionsPromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest: BulkCreatePartnerBonusQuantityRequest, options?: any): AxiosPromise<Array<PartnerBonusQuantityResponse>> {
            return localVarFp.bulkCreatePartnerBonusQuantityPromotionsPromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Partner Bonus Quantity
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerBonusQuantityPromotionsBonusQuantityIdDelete(id: number, options?: any): AxiosPromise<PartnerBonusQuantityResponse> {
            return localVarFp.deletePartnerBonusQuantityPromotionsBonusQuantityIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Partner Bonus Quantities
         * @param {Array<number>} [items] 
         * @param {Array<number>} [partners] 
         * @param {string} [fromStartDate] 
         * @param {string} [toStartDate] 
         * @param {string} [fromEndDate] 
         * @param {string} [toEndDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPartnerBonusQuantitiesPromotionsBonusQuantityGet(items?: Array<number>, partners?: Array<number>, fromStartDate?: string, toStartDate?: string, fromEndDate?: string, toEndDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponsePartnerBonusQuantityResponse> {
            return localVarFp.readPartnerBonusQuantitiesPromotionsBonusQuantityGet(items, partners, fromStartDate, toStartDate, fromEndDate, toEndDate, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Promotion By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPromotionByIdPromotionsBonusQuantityIdGet(id: number, options?: any): AxiosPromise<PartnerBonusQuantityResponse> {
            return localVarFp.readPromotionByIdPromotionsBonusQuantityIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Partner Bonus Quantity
         * @param {number} id 
         * @param {PatchPartnerBonusQuantityRequest} patchPartnerBonusQuantityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerBonusQuantityPromotionsBonusQuantityIdPut(id: number, patchPartnerBonusQuantityRequest: PatchPartnerBonusQuantityRequest, options?: any): AxiosPromise<PartnerBonusQuantityResponse> {
            return localVarFp.updatePartnerBonusQuantityPromotionsBonusQuantityIdPut(id, patchPartnerBonusQuantityRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionsApi - object-oriented interface
 * @export
 * @class PromotionsApi
 * @extends {BaseAPI}
 */
export class PromotionsApi extends BaseAPI {
    /**
     * 
     * @summary Bulk Create Partner Bonus Quantity Promotions
     * @param {BulkCreatePartnerBonusQuantityRequest} bulkCreatePartnerBonusQuantityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public bulkCreatePartnerBonusQuantityPromotionsPromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest: BulkCreatePartnerBonusQuantityRequest, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).bulkCreatePartnerBonusQuantityPromotionsPromotionsBonusQuantityPost(bulkCreatePartnerBonusQuantityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Partner Bonus Quantity
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public deletePartnerBonusQuantityPromotionsBonusQuantityIdDelete(id: number, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).deletePartnerBonusQuantityPromotionsBonusQuantityIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Partner Bonus Quantities
     * @param {Array<number>} [items] 
     * @param {Array<number>} [partners] 
     * @param {string} [fromStartDate] 
     * @param {string} [toStartDate] 
     * @param {string} [fromEndDate] 
     * @param {string} [toEndDate] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public readPartnerBonusQuantitiesPromotionsBonusQuantityGet(items?: Array<number>, partners?: Array<number>, fromStartDate?: string, toStartDate?: string, fromEndDate?: string, toEndDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).readPartnerBonusQuantitiesPromotionsBonusQuantityGet(items, partners, fromStartDate, toStartDate, fromEndDate, toEndDate, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Promotion By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public readPromotionByIdPromotionsBonusQuantityIdGet(id: number, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).readPromotionByIdPromotionsBonusQuantityIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Partner Bonus Quantity
     * @param {number} id 
     * @param {PatchPartnerBonusQuantityRequest} patchPartnerBonusQuantityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public updatePartnerBonusQuantityPromotionsBonusQuantityIdPut(id: number, patchPartnerBonusQuantityRequest: PatchPartnerBonusQuantityRequest, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).updatePartnerBonusQuantityPromotionsBonusQuantityIdPut(id, patchPartnerBonusQuantityRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Aggregated Item Movements
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [partners] 
         * @param {Array<number>} [warehouses] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAggregatedItemMovementsReportsItemMovementsBalanceGet: async (fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/item-movements-balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (partners) {
                localVarQueryParameter['partners'] = partners;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {boolean} [hidePaid] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryInvoicesReportsDeliveryInvoiceGet: async (fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/delivery-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (suppliers !== undefined) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (hidePaid !== undefined) {
                localVarQueryParameter['hide_paid'] = hidePaid;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [supplierId] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [itemCategories] 
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryItemsReportsDeliveryItemsGet: async (fromDeliveryDate?: string, toDeliveryDate?: string, supplierId?: number, companies?: Array<number>, warehouses?: Array<number>, itemCategories?: Array<number>, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/delivery-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDeliveryDate !== undefined) {
                localVarQueryParameter['from_delivery_date'] = (fromDeliveryDate as any instanceof Date) ?
                    (fromDeliveryDate as any).toISOString() :
                    fromDeliveryDate;
            }

            if (toDeliveryDate !== undefined) {
                localVarQueryParameter['to_delivery_date'] = (toDeliveryDate as any instanceof Date) ?
                    (toDeliveryDate as any).toISOString() :
                    toDeliveryDate;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplier_id'] = supplierId;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (itemCategories) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Depleted Amounts With Prices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDepletedAmountsWithPricesReportsDepletedAmountsWithPricesGet: async (fromDate?: string, toDate?: string, receivers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/depleted-amounts-with-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (receivers !== undefined) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Orders Balance
         * @param {string} [statusKeys] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersBalanceReportsOrdersBalanceGet: async (statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/orders-balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (statusKeys !== undefined) {
                localVarQueryParameter['status_keys'] = statusKeys;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (noInvoiceOnly !== undefined) {
                localVarQueryParameter['no_invoice_only'] = noInvoiceOnly;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Category Multipliers
         * @param {string} itemCategories 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoryMultipliersReportsPricingPolicyItemCategoryMultipliersGet: async (itemCategories: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemCategories' is not null or undefined
            assertParamExists('readPricingPolicyItemCategoryMultipliersReportsPricingPolicyItemCategoryMultipliersGet', 'itemCategories', itemCategories)
            const localVarPath = `/reports/pricing-policy-item-category-multipliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Prices
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemPricesReportsPricingPolicyItemPricesGet: async (items: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'items' is not null or undefined
            assertParamExists('readPricingPolicyItemPricesReportsPricingPolicyItemPricesGet', 'items', items)
            const localVarPath = `/reports/pricing-policy-item-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Sales
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [itemCategories] 
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSalesReportsSalesGet: async (fromDeliveryDate?: string, toDeliveryDate?: string, receivers?: Array<number>, legalEntities?: Array<number>, companies?: Array<number>, warehouses?: Array<number>, itemCategories?: Array<number>, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/sales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDeliveryDate !== undefined) {
                localVarQueryParameter['from_delivery_date'] = (fromDeliveryDate as any instanceof Date) ?
                    (fromDeliveryDate as any).toISOString() :
                    fromDeliveryDate;
            }

            if (toDeliveryDate !== undefined) {
                localVarQueryParameter['to_delivery_date'] = (toDeliveryDate as any instanceof Date) ?
                    (toDeliveryDate as any).toISOString() :
                    toDeliveryDate;
            }

            if (receivers) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (legalEntities) {
                localVarQueryParameter['legal_entities'] = legalEntities;
            }

            if (companies) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (itemCategories) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stock By Deliveries
         * @param {number} [companyId] 
         * @param {number} [warehouseId] 
         * @param {number} [itemId] 
         * @param {number} [manufacturerId] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [itemCategories] 
         * @param {boolean} [hideDepleted] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockByDeliveriesReportsStockByDeliveriesGet: async (companyId?: number, warehouseId?: number, itemId?: number, manufacturerId?: number, suppliers?: Array<number>, itemCategories?: Array<number>, hideDepleted?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/stock-by-deliveries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['company_id'] = companyId;
            }

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturer_id'] = manufacturerId;
            }

            if (suppliers) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (itemCategories) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (hideDepleted !== undefined) {
                localVarQueryParameter['hide_depleted'] = hideDepleted;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Warehouse Stock By Orders
         * @param {number} warehouseId 
         * @param {string} toDate 
         * @param {boolean} [hideOrdersWithoutDeliveryDate] 
         * @param {boolean} [hideAvailable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseStockByOrdersReportsStockByOrdersGet: async (warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('readWarehouseStockByOrdersReportsStockByOrdersGet', 'warehouseId', warehouseId)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('readWarehouseStockByOrdersReportsStockByOrdersGet', 'toDate', toDate)
            const localVarPath = `/reports/stock-by-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (hideOrdersWithoutDeliveryDate !== undefined) {
                localVarQueryParameter['hide_orders_without_delivery_date'] = hideOrdersWithoutDeliveryDate;
            }

            if (hideAvailable !== undefined) {
                localVarQueryParameter['hide_available'] = hideAvailable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Aggregated Item Movements
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [partners] 
         * @param {Array<number>} [warehouses] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAggregatedItemMovementsReportsItemMovementsBalanceGet(fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseItemMovementBalanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAggregatedItemMovementsReportsItemMovementsBalanceGet(fromDate, toDate, partners, warehouses, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {boolean} [hidePaid] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryInvoicesReportsDeliveryInvoiceGet(fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseReportDeliveryDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryInvoicesReportsDeliveryInvoiceGet(fromDate, toDate, suppliers, companies, hidePaid, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [supplierId] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [itemCategories] 
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDeliveryItemsReportsDeliveryItemsGet(fromDeliveryDate?: string, toDeliveryDate?: string, supplierId?: number, companies?: Array<number>, warehouses?: Array<number>, itemCategories?: Array<number>, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseReportDeliveryItemExtendedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryItemsReportsDeliveryItemsGet(fromDeliveryDate, toDeliveryDate, supplierId, companies, warehouses, itemCategories, itemId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Depleted Amounts With Prices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDepletedAmountsWithPricesReportsDepletedAmountsWithPricesGet(fromDate?: string, toDate?: string, receivers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseReportDepletedAmountWithPricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDepletedAmountsWithPricesReportsDepletedAmountsWithPricesGet(fromDate, toDate, receivers, companies, warehouses, itemCategories, itemId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Orders Balance
         * @param {string} [statusKeys] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readOrdersBalanceReportsOrdersBalanceGet(statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseReportOrderBalanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrdersBalanceReportsOrdersBalanceGet(statusKeys, companies, warehouses, fromDate, toDate, noInvoiceOnly, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Category Multipliers
         * @param {string} itemCategories 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPolicyItemCategoryMultipliersReportsPricingPolicyItemCategoryMultipliersGet(itemCategories: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportPricingPolicyItemCategoryMultipliersListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemCategoryMultipliersReportsPricingPolicyItemCategoryMultipliersGet(itemCategories, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Prices
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingPolicyItemPricesReportsPricingPolicyItemPricesGet(items: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportPricingPolicyItemPricesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemPricesReportsPricingPolicyItemPricesGet(items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Sales
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [itemCategories] 
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSalesReportsSalesGet(fromDeliveryDate?: string, toDeliveryDate?: string, receivers?: Array<number>, legalEntities?: Array<number>, companies?: Array<number>, warehouses?: Array<number>, itemCategories?: Array<number>, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseReportSalesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSalesReportsSalesGet(fromDeliveryDate, toDeliveryDate, receivers, legalEntities, companies, warehouses, itemCategories, itemId, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stock By Deliveries
         * @param {number} [companyId] 
         * @param {number} [warehouseId] 
         * @param {number} [itemId] 
         * @param {number} [manufacturerId] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [itemCategories] 
         * @param {boolean} [hideDepleted] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStockByDeliveriesReportsStockByDeliveriesGet(companyId?: number, warehouseId?: number, itemId?: number, manufacturerId?: number, suppliers?: Array<number>, itemCategories?: Array<number>, hideDepleted?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseReportDeliveryItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockByDeliveriesReportsStockByDeliveriesGet(companyId, warehouseId, itemId, manufacturerId, suppliers, itemCategories, hideDepleted, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Warehouse Stock By Orders
         * @param {number} warehouseId 
         * @param {string} toDate 
         * @param {boolean} [hideOrdersWithoutDeliveryDate] 
         * @param {boolean} [hideAvailable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readWarehouseStockByOrdersReportsStockByOrdersGet(warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseReportDeliveryItemByOrdersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readWarehouseStockByOrdersReportsStockByOrdersGet(warehouseId, toDate, hideOrdersWithoutDeliveryDate, hideAvailable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Aggregated Item Movements
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [partners] 
         * @param {Array<number>} [warehouses] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAggregatedItemMovementsReportsItemMovementsBalanceGet(fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseItemMovementBalanceResponse> {
            return localVarFp.readAggregatedItemMovementsReportsItemMovementsBalanceGet(fromDate, toDate, partners, warehouses, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {boolean} [hidePaid] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryInvoicesReportsDeliveryInvoiceGet(fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseReportDeliveryDocumentResponse> {
            return localVarFp.readDeliveryInvoicesReportsDeliveryInvoiceGet(fromDate, toDate, suppliers, companies, hidePaid, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {number} [supplierId] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [itemCategories] 
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDeliveryItemsReportsDeliveryItemsGet(fromDeliveryDate?: string, toDeliveryDate?: string, supplierId?: number, companies?: Array<number>, warehouses?: Array<number>, itemCategories?: Array<number>, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseReportDeliveryItemExtendedResponse> {
            return localVarFp.readDeliveryItemsReportsDeliveryItemsGet(fromDeliveryDate, toDeliveryDate, supplierId, companies, warehouses, itemCategories, itemId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Depleted Amounts With Prices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receivers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDepletedAmountsWithPricesReportsDepletedAmountsWithPricesGet(fromDate?: string, toDate?: string, receivers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseReportDepletedAmountWithPricesResponse> {
            return localVarFp.readDepletedAmountsWithPricesReportsDepletedAmountsWithPricesGet(fromDate, toDate, receivers, companies, warehouses, itemCategories, itemId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Orders Balance
         * @param {string} [statusKeys] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readOrdersBalanceReportsOrdersBalanceGet(statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseReportOrderBalanceResponse> {
            return localVarFp.readOrdersBalanceReportsOrdersBalanceGet(statusKeys, companies, warehouses, fromDate, toDate, noInvoiceOnly, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Category Multipliers
         * @param {string} itemCategories 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoryMultipliersReportsPricingPolicyItemCategoryMultipliersGet(itemCategories: string, options?: any): AxiosPromise<ReportPricingPolicyItemCategoryMultipliersListResponse> {
            return localVarFp.readPricingPolicyItemCategoryMultipliersReportsPricingPolicyItemCategoryMultipliersGet(itemCategories, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Prices
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingPolicyItemPricesReportsPricingPolicyItemPricesGet(items: string, options?: any): AxiosPromise<ReportPricingPolicyItemPricesListResponse> {
            return localVarFp.readPricingPolicyItemPricesReportsPricingPolicyItemPricesGet(items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Sales
         * @param {string} [fromDeliveryDate] 
         * @param {string} [toDeliveryDate] 
         * @param {Array<number>} [receivers] 
         * @param {Array<number>} [legalEntities] 
         * @param {Array<number>} [companies] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [itemCategories] 
         * @param {number} [itemId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSalesReportsSalesGet(fromDeliveryDate?: string, toDeliveryDate?: string, receivers?: Array<number>, legalEntities?: Array<number>, companies?: Array<number>, warehouses?: Array<number>, itemCategories?: Array<number>, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseReportSalesResponse> {
            return localVarFp.readSalesReportsSalesGet(fromDeliveryDate, toDeliveryDate, receivers, legalEntities, companies, warehouses, itemCategories, itemId, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stock By Deliveries
         * @param {number} [companyId] 
         * @param {number} [warehouseId] 
         * @param {number} [itemId] 
         * @param {number} [manufacturerId] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<number>} [itemCategories] 
         * @param {boolean} [hideDepleted] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockByDeliveriesReportsStockByDeliveriesGet(companyId?: number, warehouseId?: number, itemId?: number, manufacturerId?: number, suppliers?: Array<number>, itemCategories?: Array<number>, hideDepleted?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseReportDeliveryItemResponse> {
            return localVarFp.readStockByDeliveriesReportsStockByDeliveriesGet(companyId, warehouseId, itemId, manufacturerId, suppliers, itemCategories, hideDepleted, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Warehouse Stock By Orders
         * @param {number} warehouseId 
         * @param {string} toDate 
         * @param {boolean} [hideOrdersWithoutDeliveryDate] 
         * @param {boolean} [hideAvailable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseStockByOrdersReportsStockByOrdersGet(warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options?: any): AxiosPromise<PaginatedListResponseReportDeliveryItemByOrdersResponse> {
            return localVarFp.readWarehouseStockByOrdersReportsStockByOrdersGet(warehouseId, toDate, hideOrdersWithoutDeliveryDate, hideAvailable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary Read Aggregated Item Movements
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {Array<number>} [partners] 
     * @param {Array<number>} [warehouses] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readAggregatedItemMovementsReportsItemMovementsBalanceGet(fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readAggregatedItemMovementsReportsItemMovementsBalanceGet(fromDate, toDate, partners, warehouses, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Invoices
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [suppliers] 
     * @param {string} [companies] 
     * @param {boolean} [hidePaid] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readDeliveryInvoicesReportsDeliveryInvoiceGet(fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readDeliveryInvoicesReportsDeliveryInvoiceGet(fromDate, toDate, suppliers, companies, hidePaid, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Items
     * @param {string} [fromDeliveryDate] 
     * @param {string} [toDeliveryDate] 
     * @param {number} [supplierId] 
     * @param {Array<number>} [companies] 
     * @param {Array<number>} [warehouses] 
     * @param {Array<number>} [itemCategories] 
     * @param {number} [itemId] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readDeliveryItemsReportsDeliveryItemsGet(fromDeliveryDate?: string, toDeliveryDate?: string, supplierId?: number, companies?: Array<number>, warehouses?: Array<number>, itemCategories?: Array<number>, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readDeliveryItemsReportsDeliveryItemsGet(fromDeliveryDate, toDeliveryDate, supplierId, companies, warehouses, itemCategories, itemId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Depleted Amounts With Prices
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [receivers] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [itemCategories] 
     * @param {number} [itemId] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readDepletedAmountsWithPricesReportsDepletedAmountsWithPricesGet(fromDate?: string, toDate?: string, receivers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readDepletedAmountsWithPricesReportsDepletedAmountsWithPricesGet(fromDate, toDate, receivers, companies, warehouses, itemCategories, itemId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Orders Balance
     * @param {string} [statusKeys] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {boolean} [noInvoiceOnly] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readOrdersBalanceReportsOrdersBalanceGet(statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readOrdersBalanceReportsOrdersBalanceGet(statusKeys, companies, warehouses, fromDate, toDate, noInvoiceOnly, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Category Multipliers
     * @param {string} itemCategories 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readPricingPolicyItemCategoryMultipliersReportsPricingPolicyItemCategoryMultipliersGet(itemCategories: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readPricingPolicyItemCategoryMultipliersReportsPricingPolicyItemCategoryMultipliersGet(itemCategories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Prices
     * @param {string} items 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readPricingPolicyItemPricesReportsPricingPolicyItemPricesGet(items: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readPricingPolicyItemPricesReportsPricingPolicyItemPricesGet(items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Sales
     * @param {string} [fromDeliveryDate] 
     * @param {string} [toDeliveryDate] 
     * @param {Array<number>} [receivers] 
     * @param {Array<number>} [legalEntities] 
     * @param {Array<number>} [companies] 
     * @param {Array<number>} [warehouses] 
     * @param {Array<number>} [itemCategories] 
     * @param {number} [itemId] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readSalesReportsSalesGet(fromDeliveryDate?: string, toDeliveryDate?: string, receivers?: Array<number>, legalEntities?: Array<number>, companies?: Array<number>, warehouses?: Array<number>, itemCategories?: Array<number>, itemId?: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readSalesReportsSalesGet(fromDeliveryDate, toDeliveryDate, receivers, legalEntities, companies, warehouses, itemCategories, itemId, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stock By Deliveries
     * @param {number} [companyId] 
     * @param {number} [warehouseId] 
     * @param {number} [itemId] 
     * @param {number} [manufacturerId] 
     * @param {Array<number>} [suppliers] 
     * @param {Array<number>} [itemCategories] 
     * @param {boolean} [hideDepleted] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readStockByDeliveriesReportsStockByDeliveriesGet(companyId?: number, warehouseId?: number, itemId?: number, manufacturerId?: number, suppliers?: Array<number>, itemCategories?: Array<number>, hideDepleted?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readStockByDeliveriesReportsStockByDeliveriesGet(companyId, warehouseId, itemId, manufacturerId, suppliers, itemCategories, hideDepleted, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Warehouse Stock By Orders
     * @param {number} warehouseId 
     * @param {string} toDate 
     * @param {boolean} [hideOrdersWithoutDeliveryDate] 
     * @param {boolean} [hideAvailable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readWarehouseStockByOrdersReportsStockByOrdersGet(warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readWarehouseStockByOrdersReportsStockByOrdersGet(warehouseId, toDate, hideOrdersWithoutDeliveryDate, hideAvailable, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Role
         * @param {CreateRoleRequest} createRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleRolesPost: async (createRoleRequest: CreateRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRoleRequest' is not null or undefined
            assertParamExists('createRoleRolesPost', 'createRoleRequest', createRoleRequest)
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Role By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRoleByIdRolesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readRoleByIdRolesIdGet', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Roles
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRolesRolesGet: async (id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Role
         * @param {number} id 
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleRolesIdPut: async (id: number, updateRoleRequest: UpdateRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRoleRolesIdPut', 'id', id)
            // verify required parameter 'updateRoleRequest' is not null or undefined
            assertParamExists('updateRoleRolesIdPut', 'updateRoleRequest', updateRoleRequest)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Role
         * @param {CreateRoleRequest} createRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoleRolesPost(createRoleRequest: CreateRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoleRolesPost(createRoleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Role By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readRoleByIdRolesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readRoleByIdRolesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Roles
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readRolesRolesGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseRoleListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readRolesRolesGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Role
         * @param {number} id 
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoleRolesIdPut(id: number, updateRoleRequest: UpdateRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoleRolesIdPut(id, updateRoleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Role
         * @param {CreateRoleRequest} createRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleRolesPost(createRoleRequest: CreateRoleRequest, options?: any): AxiosPromise<RoleResponse> {
            return localVarFp.createRoleRolesPost(createRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Role By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRoleByIdRolesIdGet(id: number, options?: any): AxiosPromise<RoleResponse> {
            return localVarFp.readRoleByIdRolesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Roles
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRolesRolesGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseRoleListResponse> {
            return localVarFp.readRolesRolesGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Role
         * @param {number} id 
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleRolesIdPut(id: number, updateRoleRequest: UpdateRoleRequest, options?: any): AxiosPromise<RoleResponse> {
            return localVarFp.updateRoleRolesIdPut(id, updateRoleRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @summary Create Role
     * @param {CreateRoleRequest} createRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public createRoleRolesPost(createRoleRequest: CreateRoleRequest, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).createRoleRolesPost(createRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Role By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public readRoleByIdRolesIdGet(id: number, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).readRoleByIdRolesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Roles
     * @param {Array<number>} [id] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public readRolesRolesGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).readRolesRolesGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Role
     * @param {number} id 
     * @param {UpdateRoleRequest} updateRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public updateRoleRolesIdPut(id: number, updateRoleRequest: UpdateRoleRequest, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).updateRoleRolesIdPut(id, updateRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShippingRoutesApi - axios parameter creator
 * @export
 */
export const ShippingRoutesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Shipping Route By Id
         * @param {number} shippingRouteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShippingRouteByIdShippingRoutesShippingRouteIdGet: async (shippingRouteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shippingRouteId' is not null or undefined
            assertParamExists('readShippingRouteByIdShippingRoutesShippingRouteIdGet', 'shippingRouteId', shippingRouteId)
            const localVarPath = `/shipping-routes/{shipping_route_id}`
                .replace(`{${"shipping_route_id"}}`, encodeURIComponent(String(shippingRouteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Shipping Route Orders
         * @param {number} shippingRouteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShippingRouteOrdersShippingRoutesShippingRouteIdOrdersGet: async (shippingRouteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shippingRouteId' is not null or undefined
            assertParamExists('readShippingRouteOrdersShippingRoutesShippingRouteIdOrdersGet', 'shippingRouteId', shippingRouteId)
            const localVarPath = `/shipping-routes/{shipping_route_id}/orders`
                .replace(`{${"shipping_route_id"}}`, encodeURIComponent(String(shippingRouteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Shipping Routes
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [users] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShippingRoutesShippingRoutesGet: async (fromDate?: string, toDate?: string, users?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shipping-routes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShippingRoutesApi - functional programming interface
 * @export
 */
export const ShippingRoutesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShippingRoutesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Shipping Route By Id
         * @param {number} shippingRouteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readShippingRouteByIdShippingRoutesShippingRouteIdGet(shippingRouteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShippingRouteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readShippingRouteByIdShippingRoutesShippingRouteIdGet(shippingRouteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Shipping Route Orders
         * @param {number} shippingRouteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readShippingRouteOrdersShippingRoutesShippingRouteIdOrdersGet(shippingRouteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseOrderListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readShippingRouteOrdersShippingRoutesShippingRouteIdOrdersGet(shippingRouteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Shipping Routes
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [users] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readShippingRoutesShippingRoutesGet(fromDate?: string, toDate?: string, users?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseShippingRouteListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readShippingRoutesShippingRoutesGet(fromDate, toDate, users, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShippingRoutesApi - factory interface
 * @export
 */
export const ShippingRoutesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShippingRoutesApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Shipping Route By Id
         * @param {number} shippingRouteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShippingRouteByIdShippingRoutesShippingRouteIdGet(shippingRouteId: number, options?: any): AxiosPromise<ShippingRouteResponse> {
            return localVarFp.readShippingRouteByIdShippingRoutesShippingRouteIdGet(shippingRouteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Shipping Route Orders
         * @param {number} shippingRouteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShippingRouteOrdersShippingRoutesShippingRouteIdOrdersGet(shippingRouteId: number, options?: any): AxiosPromise<PaginatedListResponseOrderListResponse> {
            return localVarFp.readShippingRouteOrdersShippingRoutesShippingRouteIdOrdersGet(shippingRouteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Shipping Routes
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [users] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShippingRoutesShippingRoutesGet(fromDate?: string, toDate?: string, users?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseShippingRouteListResponse> {
            return localVarFp.readShippingRoutesShippingRoutesGet(fromDate, toDate, users, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShippingRoutesApi - object-oriented interface
 * @export
 * @class ShippingRoutesApi
 * @extends {BaseAPI}
 */
export class ShippingRoutesApi extends BaseAPI {
    /**
     * 
     * @summary Read Shipping Route By Id
     * @param {number} shippingRouteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingRoutesApi
     */
    public readShippingRouteByIdShippingRoutesShippingRouteIdGet(shippingRouteId: number, options?: AxiosRequestConfig) {
        return ShippingRoutesApiFp(this.configuration).readShippingRouteByIdShippingRoutesShippingRouteIdGet(shippingRouteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Shipping Route Orders
     * @param {number} shippingRouteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingRoutesApi
     */
    public readShippingRouteOrdersShippingRoutesShippingRouteIdOrdersGet(shippingRouteId: number, options?: AxiosRequestConfig) {
        return ShippingRoutesApiFp(this.configuration).readShippingRouteOrdersShippingRoutesShippingRouteIdOrdersGet(shippingRouteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Shipping Routes
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {Array<number>} [users] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingRoutesApi
     */
    public readShippingRoutesShippingRoutesGet(fromDate?: string, toDate?: string, users?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ShippingRoutesApiFp(this.configuration).readShippingRoutesShippingRoutesGet(fromDate, toDate, users, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShoppingCartsApi - axios parameter creator
 * @export
 */
export const ShoppingCartsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Shopping Cart By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShoppingCartByIdShoppingCartsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readShoppingCartByIdShoppingCartsIdGet', 'id', id)
            const localVarPath = `/shopping-carts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Shopping Cart Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShoppingCartItemsShoppingCartsIdItemsGet: async (id: number, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readShoppingCartItemsShoppingCartsIdItemsGet', 'id', id)
            const localVarPath = `/shopping-carts/{id}/items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Shopping Carts
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShoppingCartsShoppingCartsGet: async (id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopping-carts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShoppingCartsApi - functional programming interface
 * @export
 */
export const ShoppingCartsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShoppingCartsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Shopping Cart By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readShoppingCartByIdShoppingCartsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientShoppingCartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readShoppingCartByIdShoppingCartsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Shopping Cart Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readShoppingCartItemsShoppingCartsIdItemsGet(id: number, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseClientShoppingCartItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readShoppingCartItemsShoppingCartsIdItemsGet(id, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Shopping Carts
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readShoppingCartsShoppingCartsGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseClientShoppingCartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readShoppingCartsShoppingCartsGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShoppingCartsApi - factory interface
 * @export
 */
export const ShoppingCartsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShoppingCartsApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Shopping Cart By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShoppingCartByIdShoppingCartsIdGet(id: number, options?: any): AxiosPromise<ClientShoppingCartResponse> {
            return localVarFp.readShoppingCartByIdShoppingCartsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Shopping Cart Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShoppingCartItemsShoppingCartsIdItemsGet(id: number, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseClientShoppingCartItemResponse> {
            return localVarFp.readShoppingCartItemsShoppingCartsIdItemsGet(id, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Shopping Carts
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readShoppingCartsShoppingCartsGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseClientShoppingCartResponse> {
            return localVarFp.readShoppingCartsShoppingCartsGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShoppingCartsApi - object-oriented interface
 * @export
 * @class ShoppingCartsApi
 * @extends {BaseAPI}
 */
export class ShoppingCartsApi extends BaseAPI {
    /**
     * 
     * @summary Read Shopping Cart By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartsApi
     */
    public readShoppingCartByIdShoppingCartsIdGet(id: number, options?: AxiosRequestConfig) {
        return ShoppingCartsApiFp(this.configuration).readShoppingCartByIdShoppingCartsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Shopping Cart Items
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartsApi
     */
    public readShoppingCartItemsShoppingCartsIdItemsGet(id: number, page?: number, size?: number, options?: AxiosRequestConfig) {
        return ShoppingCartsApiFp(this.configuration).readShoppingCartItemsShoppingCartsIdItemsGet(id, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Shopping Carts
     * @param {Array<number>} [id] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingCartsApi
     */
    public readShoppingCartsShoppingCartsGet(id?: Array<number>, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return ShoppingCartsApiFp(this.configuration).readShoppingCartsShoppingCartsGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StockReceiveApi - axios parameter creator
 * @export
 */
export const StockReceiveApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Stock Receiving Process By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockReceivingProcessByIdStockReceivingProcessesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStockReceivingProcessByIdStockReceivingProcessesIdGet', 'id', id)
            const localVarPath = `/stock-receiving-processes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stock Receiving Process Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockReceivingProcessItemsStockReceivingProcessesIdItemsGet: async (id: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStockReceivingProcessItemsStockReceivingProcessesIdItemsGet', 'id', id)
            const localVarPath = `/stock-receiving-processes/{id}/items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stock Receiving Processes
         * @param {Array<number>} [id] 
         * @param {string} [fromCreated] 
         * @param {string} [toCreated] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockReceivingProcessesStockReceivingProcessesGet: async (id?: Array<number>, fromCreated?: string, toCreated?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stock-receiving-processes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (fromCreated !== undefined) {
                localVarQueryParameter['from_created'] = (fromCreated as any instanceof Date) ?
                    (fromCreated as any).toISOString() :
                    fromCreated;
            }

            if (toCreated !== undefined) {
                localVarQueryParameter['to_created'] = (toCreated as any instanceof Date) ?
                    (toCreated as any).toISOString() :
                    toCreated;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockReceiveApi - functional programming interface
 * @export
 */
export const StockReceiveApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockReceiveApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Stock Receiving Process By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStockReceivingProcessByIdStockReceivingProcessesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockReceivingProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockReceivingProcessByIdStockReceivingProcessesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stock Receiving Process Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStockReceivingProcessItemsStockReceivingProcessesIdItemsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseStockReceivingProcessItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockReceivingProcessItemsStockReceivingProcessesIdItemsGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stock Receiving Processes
         * @param {Array<number>} [id] 
         * @param {string} [fromCreated] 
         * @param {string} [toCreated] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStockReceivingProcessesStockReceivingProcessesGet(id?: Array<number>, fromCreated?: string, toCreated?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseStockReceivingProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockReceivingProcessesStockReceivingProcessesGet(id, fromCreated, toCreated, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StockReceiveApi - factory interface
 * @export
 */
export const StockReceiveApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockReceiveApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Stock Receiving Process By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockReceivingProcessByIdStockReceivingProcessesIdGet(id: number, options?: any): AxiosPromise<StockReceivingProcessResponse> {
            return localVarFp.readStockReceivingProcessByIdStockReceivingProcessesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stock Receiving Process Items
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockReceivingProcessItemsStockReceivingProcessesIdItemsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseStockReceivingProcessItemResponse> {
            return localVarFp.readStockReceivingProcessItemsStockReceivingProcessesIdItemsGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stock Receiving Processes
         * @param {Array<number>} [id] 
         * @param {string} [fromCreated] 
         * @param {string} [toCreated] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStockReceivingProcessesStockReceivingProcessesGet(id?: Array<number>, fromCreated?: string, toCreated?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseStockReceivingProcessResponse> {
            return localVarFp.readStockReceivingProcessesStockReceivingProcessesGet(id, fromCreated, toCreated, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StockReceiveApi - object-oriented interface
 * @export
 * @class StockReceiveApi
 * @extends {BaseAPI}
 */
export class StockReceiveApi extends BaseAPI {
    /**
     * 
     * @summary Read Stock Receiving Process By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockReceiveApi
     */
    public readStockReceivingProcessByIdStockReceivingProcessesIdGet(id: number, options?: AxiosRequestConfig) {
        return StockReceiveApiFp(this.configuration).readStockReceivingProcessByIdStockReceivingProcessesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stock Receiving Process Items
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockReceiveApi
     */
    public readStockReceivingProcessItemsStockReceivingProcessesIdItemsGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return StockReceiveApiFp(this.configuration).readStockReceivingProcessItemsStockReceivingProcessesIdItemsGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stock Receiving Processes
     * @param {Array<number>} [id] 
     * @param {string} [fromCreated] 
     * @param {string} [toCreated] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockReceiveApi
     */
    public readStockReceivingProcessesStockReceivingProcessesGet(id?: Array<number>, fromCreated?: string, toCreated?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return StockReceiveApiFp(this.configuration).readStockReceivingProcessesStockReceivingProcessesGet(id, fromCreated, toCreated, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SupplierReserveSubscriptionApi - axios parameter creator
 * @export
 */
export const SupplierReserveSubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Supplier Reserve Subscription
         * @param {CreateSupplierReserveSubscriptionRequest} createSupplierReserveSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupplierReserveSubscriptionSupplierReserveSubscriptionsPost: async (createSupplierReserveSubscriptionRequest: CreateSupplierReserveSubscriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSupplierReserveSubscriptionRequest' is not null or undefined
            assertParamExists('createSupplierReserveSubscriptionSupplierReserveSubscriptionsPost', 'createSupplierReserveSubscriptionRequest', createSupplierReserveSubscriptionRequest)
            const localVarPath = `/supplier-reserve-subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSupplierReserveSubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Supplier Reserve Subscription
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupplierReserveSubscriptionSupplierReserveSubscriptionsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSupplierReserveSubscriptionSupplierReserveSubscriptionsIdDelete', 'id', id)
            const localVarPath = `/supplier-reserve-subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Supplier Reserve Subscription By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierReserveSubscriptionByIdSupplierReserveSubscriptionsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSupplierReserveSubscriptionByIdSupplierReserveSubscriptionsIdGet', 'id', id)
            const localVarPath = `/supplier-reserve-subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Supplier Reserve Subscriptions
         * @param {Array<number>} [id] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<string>} [weekday] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierReserveSubscriptionsSupplierReserveSubscriptionsGet: async (id?: Array<number>, suppliers?: Array<number>, weekday?: Array<string>, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/supplier-reserve-subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (suppliers) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (weekday) {
                localVarQueryParameter['weekday'] = weekday;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Supplier Reserve Subscription
         * @param {number} id 
         * @param {PatchSupplierReserveSubscriptionRequest} patchSupplierReserveSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch: async (id: number, patchSupplierReserveSubscriptionRequest: PatchSupplierReserveSubscriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch', 'id', id)
            // verify required parameter 'patchSupplierReserveSubscriptionRequest' is not null or undefined
            assertParamExists('updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch', 'patchSupplierReserveSubscriptionRequest', patchSupplierReserveSubscriptionRequest)
            const localVarPath = `/supplier-reserve-subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchSupplierReserveSubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupplierReserveSubscriptionApi - functional programming interface
 * @export
 */
export const SupplierReserveSubscriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupplierReserveSubscriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Supplier Reserve Subscription
         * @param {CreateSupplierReserveSubscriptionRequest} createSupplierReserveSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSupplierReserveSubscriptionSupplierReserveSubscriptionsPost(createSupplierReserveSubscriptionRequest: CreateSupplierReserveSubscriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierReserveSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSupplierReserveSubscriptionSupplierReserveSubscriptionsPost(createSupplierReserveSubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Supplier Reserve Subscription
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSupplierReserveSubscriptionSupplierReserveSubscriptionsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierReserveSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSupplierReserveSubscriptionSupplierReserveSubscriptionsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Supplier Reserve Subscription By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSupplierReserveSubscriptionByIdSupplierReserveSubscriptionsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierReserveSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSupplierReserveSubscriptionByIdSupplierReserveSubscriptionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Supplier Reserve Subscriptions
         * @param {Array<number>} [id] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<string>} [weekday] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSupplierReserveSubscriptionsSupplierReserveSubscriptionsGet(id?: Array<number>, suppliers?: Array<number>, weekday?: Array<string>, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseSupplierReserveSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSupplierReserveSubscriptionsSupplierReserveSubscriptionsGet(id, suppliers, weekday, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Supplier Reserve Subscription
         * @param {number} id 
         * @param {PatchSupplierReserveSubscriptionRequest} patchSupplierReserveSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch(id: number, patchSupplierReserveSubscriptionRequest: PatchSupplierReserveSubscriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierReserveSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch(id, patchSupplierReserveSubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SupplierReserveSubscriptionApi - factory interface
 * @export
 */
export const SupplierReserveSubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupplierReserveSubscriptionApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Supplier Reserve Subscription
         * @param {CreateSupplierReserveSubscriptionRequest} createSupplierReserveSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupplierReserveSubscriptionSupplierReserveSubscriptionsPost(createSupplierReserveSubscriptionRequest: CreateSupplierReserveSubscriptionRequest, options?: any): AxiosPromise<SupplierReserveSubscriptionResponse> {
            return localVarFp.createSupplierReserveSubscriptionSupplierReserveSubscriptionsPost(createSupplierReserveSubscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Supplier Reserve Subscription
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupplierReserveSubscriptionSupplierReserveSubscriptionsIdDelete(id: number, options?: any): AxiosPromise<SupplierReserveSubscriptionResponse> {
            return localVarFp.deleteSupplierReserveSubscriptionSupplierReserveSubscriptionsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Supplier Reserve Subscription By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierReserveSubscriptionByIdSupplierReserveSubscriptionsIdGet(id: number, options?: any): AxiosPromise<SupplierReserveSubscriptionResponse> {
            return localVarFp.readSupplierReserveSubscriptionByIdSupplierReserveSubscriptionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Supplier Reserve Subscriptions
         * @param {Array<number>} [id] 
         * @param {Array<number>} [suppliers] 
         * @param {Array<string>} [weekday] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierReserveSubscriptionsSupplierReserveSubscriptionsGet(id?: Array<number>, suppliers?: Array<number>, weekday?: Array<string>, page?: number, size?: number, options?: any): AxiosPromise<PaginatedListResponseSupplierReserveSubscriptionResponse> {
            return localVarFp.readSupplierReserveSubscriptionsSupplierReserveSubscriptionsGet(id, suppliers, weekday, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Supplier Reserve Subscription
         * @param {number} id 
         * @param {PatchSupplierReserveSubscriptionRequest} patchSupplierReserveSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch(id: number, patchSupplierReserveSubscriptionRequest: PatchSupplierReserveSubscriptionRequest, options?: any): AxiosPromise<SupplierReserveSubscriptionResponse> {
            return localVarFp.updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch(id, patchSupplierReserveSubscriptionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupplierReserveSubscriptionApi - object-oriented interface
 * @export
 * @class SupplierReserveSubscriptionApi
 * @extends {BaseAPI}
 */
export class SupplierReserveSubscriptionApi extends BaseAPI {
    /**
     * 
     * @summary Create Supplier Reserve Subscription
     * @param {CreateSupplierReserveSubscriptionRequest} createSupplierReserveSubscriptionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierReserveSubscriptionApi
     */
    public createSupplierReserveSubscriptionSupplierReserveSubscriptionsPost(createSupplierReserveSubscriptionRequest: CreateSupplierReserveSubscriptionRequest, options?: AxiosRequestConfig) {
        return SupplierReserveSubscriptionApiFp(this.configuration).createSupplierReserveSubscriptionSupplierReserveSubscriptionsPost(createSupplierReserveSubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Supplier Reserve Subscription
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierReserveSubscriptionApi
     */
    public deleteSupplierReserveSubscriptionSupplierReserveSubscriptionsIdDelete(id: number, options?: AxiosRequestConfig) {
        return SupplierReserveSubscriptionApiFp(this.configuration).deleteSupplierReserveSubscriptionSupplierReserveSubscriptionsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Supplier Reserve Subscription By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierReserveSubscriptionApi
     */
    public readSupplierReserveSubscriptionByIdSupplierReserveSubscriptionsIdGet(id: number, options?: AxiosRequestConfig) {
        return SupplierReserveSubscriptionApiFp(this.configuration).readSupplierReserveSubscriptionByIdSupplierReserveSubscriptionsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Supplier Reserve Subscriptions
     * @param {Array<number>} [id] 
     * @param {Array<number>} [suppliers] 
     * @param {Array<string>} [weekday] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierReserveSubscriptionApi
     */
    public readSupplierReserveSubscriptionsSupplierReserveSubscriptionsGet(id?: Array<number>, suppliers?: Array<number>, weekday?: Array<string>, page?: number, size?: number, options?: AxiosRequestConfig) {
        return SupplierReserveSubscriptionApiFp(this.configuration).readSupplierReserveSubscriptionsSupplierReserveSubscriptionsGet(id, suppliers, weekday, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Supplier Reserve Subscription
     * @param {number} id 
     * @param {PatchSupplierReserveSubscriptionRequest} patchSupplierReserveSubscriptionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierReserveSubscriptionApi
     */
    public updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch(id: number, patchSupplierReserveSubscriptionRequest: PatchSupplierReserveSubscriptionRequest, options?: AxiosRequestConfig) {
        return SupplierReserveSubscriptionApiFp(this.configuration).updateSupplierReserveSubscriptionSupplierReserveSubscriptionsIdPatch(id, patchSupplierReserveSubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SuppliersApi - axios parameter creator
 * @export
 */
export const SuppliersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Supplier
         * @param {SaveSupplierRequest} saveSupplierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupplierSuppliersPost: async (saveSupplierRequest: SaveSupplierRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveSupplierRequest' is not null or undefined
            assertParamExists('createSupplierSuppliersPost', 'saveSupplierRequest', saveSupplierRequest)
            const localVarPath = `/suppliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveSupplierRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Supplier By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierByIdSuppliersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSupplierByIdSuppliersIdGet', 'id', id)
            const localVarPath = `/suppliers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Supplier Price Differences
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierPriceDifferencesSuppliersIdPriceDifferencesGet: async (id: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSupplierPriceDifferencesSuppliersIdPriceDifferencesGet', 'id', id)
            const localVarPath = `/suppliers/{id}/price-differences`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Supplier Quantity Differences
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierQuantityDifferencesSuppliersIdQuantityDifferencesGet: async (id: number, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readSupplierQuantityDifferencesSuppliersIdQuantityDifferencesGet', 'id', id)
            const localVarPath = `/suppliers/{id}/quantity-differences`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Suppliers
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [showInactive] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSuppliersSuppliersGet: async (id?: Array<number>, query?: string, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/suppliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Supplier
         * @param {number} id 
         * @param {SaveSupplierRequest} saveSupplierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplierSuppliersIdPut: async (id: number, saveSupplierRequest: SaveSupplierRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSupplierSuppliersIdPut', 'id', id)
            // verify required parameter 'saveSupplierRequest' is not null or undefined
            assertParamExists('updateSupplierSuppliersIdPut', 'saveSupplierRequest', saveSupplierRequest)
            const localVarPath = `/suppliers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveSupplierRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuppliersApi - functional programming interface
 * @export
 */
export const SuppliersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuppliersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Supplier
         * @param {SaveSupplierRequest} saveSupplierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSupplierSuppliersPost(saveSupplierRequest: SaveSupplierRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSupplierSuppliersPost(saveSupplierRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Supplier By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSupplierByIdSuppliersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSupplierByIdSuppliersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Supplier Price Differences
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSupplierPriceDifferencesSuppliersIdPriceDifferencesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseSupplierPriceDifferenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSupplierPriceDifferencesSuppliersIdPriceDifferencesGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Supplier Quantity Differences
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSupplierQuantityDifferencesSuppliersIdQuantityDifferencesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseSupplierQuantityDifferenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSupplierQuantityDifferencesSuppliersIdQuantityDifferencesGet(id, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Suppliers
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [showInactive] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSuppliersSuppliersGet(id?: Array<number>, query?: string, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseSupplierListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSuppliersSuppliersGet(id, query, showInactive, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Supplier
         * @param {number} id 
         * @param {SaveSupplierRequest} saveSupplierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSupplierSuppliersIdPut(id: number, saveSupplierRequest: SaveSupplierRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSupplierSuppliersIdPut(id, saveSupplierRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SuppliersApi - factory interface
 * @export
 */
export const SuppliersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuppliersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Supplier
         * @param {SaveSupplierRequest} saveSupplierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupplierSuppliersPost(saveSupplierRequest: SaveSupplierRequest, options?: any): AxiosPromise<SupplierResponse> {
            return localVarFp.createSupplierSuppliersPost(saveSupplierRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Supplier By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierByIdSuppliersIdGet(id: number, options?: any): AxiosPromise<SupplierResponse> {
            return localVarFp.readSupplierByIdSuppliersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Supplier Price Differences
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierPriceDifferencesSuppliersIdPriceDifferencesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseSupplierPriceDifferenceResponse> {
            return localVarFp.readSupplierPriceDifferencesSuppliersIdPriceDifferencesGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Supplier Quantity Differences
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSupplierQuantityDifferencesSuppliersIdQuantityDifferencesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseSupplierQuantityDifferenceResponse> {
            return localVarFp.readSupplierQuantityDifferencesSuppliersIdQuantityDifferencesGet(id, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Suppliers
         * @param {Array<number>} [id] 
         * @param {string} [query] 
         * @param {boolean} [showInactive] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSuppliersSuppliersGet(id?: Array<number>, query?: string, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseSupplierListResponse> {
            return localVarFp.readSuppliersSuppliersGet(id, query, showInactive, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Supplier
         * @param {number} id 
         * @param {SaveSupplierRequest} saveSupplierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplierSuppliersIdPut(id: number, saveSupplierRequest: SaveSupplierRequest, options?: any): AxiosPromise<SupplierResponse> {
            return localVarFp.updateSupplierSuppliersIdPut(id, saveSupplierRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SuppliersApi - object-oriented interface
 * @export
 * @class SuppliersApi
 * @extends {BaseAPI}
 */
export class SuppliersApi extends BaseAPI {
    /**
     * 
     * @summary Create Supplier
     * @param {SaveSupplierRequest} saveSupplierRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public createSupplierSuppliersPost(saveSupplierRequest: SaveSupplierRequest, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).createSupplierSuppliersPost(saveSupplierRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Supplier By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public readSupplierByIdSuppliersIdGet(id: number, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).readSupplierByIdSuppliersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Supplier Price Differences
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public readSupplierPriceDifferencesSuppliersIdPriceDifferencesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).readSupplierPriceDifferencesSuppliersIdPriceDifferencesGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Supplier Quantity Differences
     * @param {number} id 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public readSupplierQuantityDifferencesSuppliersIdQuantityDifferencesGet(id: number, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).readSupplierQuantityDifferencesSuppliersIdQuantityDifferencesGet(id, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Suppliers
     * @param {Array<number>} [id] 
     * @param {string} [query] 
     * @param {boolean} [showInactive] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public readSuppliersSuppliersGet(id?: Array<number>, query?: string, showInactive?: boolean, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).readSuppliersSuppliersGet(id, query, showInactive, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Supplier
     * @param {number} id 
     * @param {SaveSupplierRequest} saveSupplierRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public updateSupplierSuppliersIdPut(id: number, saveSupplierRequest: SaveSupplierRequest, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).updateSupplierSuppliersIdPut(id, saveSupplierRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransactionsApi - axios parameter creator
 * @export
 */
export const TransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Transaction
         * @param {CreateTransactionRequest} createTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransactionTransactionsPost: async (createTransactionRequest: CreateTransactionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTransactionRequest' is not null or undefined
            assertParamExists('createTransactionTransactionsPost', 'createTransactionRequest', createTransactionRequest)
            const localVarPath = `/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Transaction
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransactionTransactionsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTransactionTransactionsIdDelete', 'id', id)
            const localVarPath = `/transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Transactions
         * @param {Array<number>} [id] 
         * @param {number} [invoiceId] 
         * @param {number} [deliveryInvoiceId] 
         * @param {Array<number>} [treasuries] 
         * @param {string} [fromCreatedDate] 
         * @param {string} [toCreatedDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTransactionsTransactionsGet: async (id?: Array<number>, invoiceId?: number, deliveryInvoiceId?: number, treasuries?: Array<number>, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoice_id'] = invoiceId;
            }

            if (deliveryInvoiceId !== undefined) {
                localVarQueryParameter['delivery_invoice_id'] = deliveryInvoiceId;
            }

            if (treasuries) {
                localVarQueryParameter['treasuries'] = treasuries;
            }

            if (fromCreatedDate !== undefined) {
                localVarQueryParameter['from_created_date'] = (fromCreatedDate as any instanceof Date) ?
                    (fromCreatedDate as any).toISOString() :
                    fromCreatedDate;
            }

            if (toCreatedDate !== undefined) {
                localVarQueryParameter['to_created_date'] = (toCreatedDate as any instanceof Date) ?
                    (toCreatedDate as any).toISOString() :
                    toCreatedDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Transaction
         * @param {number} id 
         * @param {PatchTransactionRequest} patchTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionTransactionsIdPut: async (id: number, patchTransactionRequest: PatchTransactionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTransactionTransactionsIdPut', 'id', id)
            // verify required parameter 'patchTransactionRequest' is not null or undefined
            assertParamExists('updateTransactionTransactionsIdPut', 'patchTransactionRequest', patchTransactionRequest)
            const localVarPath = `/transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionsApi - functional programming interface
 * @export
 */
export const TransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Transaction
         * @param {CreateTransactionRequest} createTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTransactionTransactionsPost(createTransactionRequest: CreateTransactionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTransactionTransactionsPost(createTransactionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Transaction
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTransactionTransactionsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTransactionTransactionsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Transactions
         * @param {Array<number>} [id] 
         * @param {number} [invoiceId] 
         * @param {number} [deliveryInvoiceId] 
         * @param {Array<number>} [treasuries] 
         * @param {string} [fromCreatedDate] 
         * @param {string} [toCreatedDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTransactionsTransactionsGet(id?: Array<number>, invoiceId?: number, deliveryInvoiceId?: number, treasuries?: Array<number>, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseTransactionListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTransactionsTransactionsGet(id, invoiceId, deliveryInvoiceId, treasuries, fromCreatedDate, toCreatedDate, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Transaction
         * @param {number} id 
         * @param {PatchTransactionRequest} patchTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransactionTransactionsIdPut(id: number, patchTransactionRequest: PatchTransactionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransactionTransactionsIdPut(id, patchTransactionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionsApi - factory interface
 * @export
 */
export const TransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Transaction
         * @param {CreateTransactionRequest} createTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransactionTransactionsPost(createTransactionRequest: CreateTransactionRequest, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.createTransactionTransactionsPost(createTransactionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Transaction
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransactionTransactionsIdDelete(id: number, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.deleteTransactionTransactionsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Transactions
         * @param {Array<number>} [id] 
         * @param {number} [invoiceId] 
         * @param {number} [deliveryInvoiceId] 
         * @param {Array<number>} [treasuries] 
         * @param {string} [fromCreatedDate] 
         * @param {string} [toCreatedDate] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTransactionsTransactionsGet(id?: Array<number>, invoiceId?: number, deliveryInvoiceId?: number, treasuries?: Array<number>, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseTransactionListResponse> {
            return localVarFp.readTransactionsTransactionsGet(id, invoiceId, deliveryInvoiceId, treasuries, fromCreatedDate, toCreatedDate, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Transaction
         * @param {number} id 
         * @param {PatchTransactionRequest} patchTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionTransactionsIdPut(id: number, patchTransactionRequest: PatchTransactionRequest, options?: any): AxiosPromise<TransactionResponse> {
            return localVarFp.updateTransactionTransactionsIdPut(id, patchTransactionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 * @extends {BaseAPI}
 */
export class TransactionsApi extends BaseAPI {
    /**
     * 
     * @summary Create Transaction
     * @param {CreateTransactionRequest} createTransactionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public createTransactionTransactionsPost(createTransactionRequest: CreateTransactionRequest, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).createTransactionTransactionsPost(createTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Transaction
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public deleteTransactionTransactionsIdDelete(id: number, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).deleteTransactionTransactionsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Transactions
     * @param {Array<number>} [id] 
     * @param {number} [invoiceId] 
     * @param {number} [deliveryInvoiceId] 
     * @param {Array<number>} [treasuries] 
     * @param {string} [fromCreatedDate] 
     * @param {string} [toCreatedDate] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public readTransactionsTransactionsGet(id?: Array<number>, invoiceId?: number, deliveryInvoiceId?: number, treasuries?: Array<number>, fromCreatedDate?: string, toCreatedDate?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).readTransactionsTransactionsGet(id, invoiceId, deliveryInvoiceId, treasuries, fromCreatedDate, toCreatedDate, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Transaction
     * @param {number} id 
     * @param {PatchTransactionRequest} patchTransactionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public updateTransactionTransactionsIdPut(id: number, patchTransactionRequest: PatchTransactionRequest, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).updateTransactionTransactionsIdPut(id, patchTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create User
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserUsersPost: async (createUserRequest: CreateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('createUserUsersPost', 'createUserRequest', createUserRequest)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read User By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserByIdUsersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readUserByIdUsersIdGet', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Users
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {number} [company] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersUsersGet: async (id?: Array<number>, showInactive?: boolean, company?: number, query?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User
         * @param {number} id 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserUsersIdPut: async (id: number, updateUserRequest: UpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserUsersIdPut', 'id', id)
            // verify required parameter 'updateUserRequest' is not null or undefined
            assertParamExists('updateUserUsersIdPut', 'updateUserRequest', updateUserRequest)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create User
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserUsersPost(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserUsersPost(createUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read User By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUserByIdUsersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUserByIdUsersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Users
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {number} [company] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUsersUsersGet(id?: Array<number>, showInactive?: boolean, company?: number, query?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseUserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUsersUsersGet(id, showInactive, company, query, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update User
         * @param {number} id 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserUsersIdPut(id: number, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserUsersIdPut(id, updateUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create User
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserUsersPost(createUserRequest: CreateUserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.createUserUsersPost(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read User By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserByIdUsersIdGet(id: number, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.readUserByIdUsersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Users
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {number} [company] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersUsersGet(id?: Array<number>, showInactive?: boolean, company?: number, query?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseUserListResponse> {
            return localVarFp.readUsersUsersGet(id, showInactive, company, query, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User
         * @param {number} id 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserUsersIdPut(id: number, updateUserRequest: UpdateUserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.updateUserUsersIdPut(id, updateUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Create User
     * @param {CreateUserRequest} createUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUserUsersPost(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUserUsersPost(createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read User By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public readUserByIdUsersIdGet(id: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).readUserByIdUsersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Users
     * @param {Array<number>} [id] 
     * @param {boolean} [showInactive] 
     * @param {number} [company] 
     * @param {string} [query] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public readUsersUsersGet(id?: Array<number>, showInactive?: boolean, company?: number, query?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).readUsersUsersGet(id, showInactive, company, query, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User
     * @param {number} id 
     * @param {UpdateUserRequest} updateUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserUsersIdPut(id: number, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUserUsersIdPut(id, updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WarehouseMovementsApi - axios parameter creator
 * @export
 */
export const WarehouseMovementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Warehouse Movements
         * @param {Array<number>} [id] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [items] 
         * @param {string} [fromExpirationDate] 
         * @param {string} [toExpirationDate] 
         * @param {string} [batchNumber] 
         * @param {string} [movementType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseMovementsWarehouseMovementsGet: async (id?: Array<number>, warehouses?: Array<number>, items?: Array<number>, fromExpirationDate?: string, toExpirationDate?: string, batchNumber?: string, movementType?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/warehouse-movements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (items) {
                localVarQueryParameter['items'] = items;
            }

            if (fromExpirationDate !== undefined) {
                localVarQueryParameter['from_expiration_date'] = (fromExpirationDate as any instanceof Date) ?
                    (fromExpirationDate as any).toISOString().substr(0,10) :
                    fromExpirationDate;
            }

            if (toExpirationDate !== undefined) {
                localVarQueryParameter['to_expiration_date'] = (toExpirationDate as any instanceof Date) ?
                    (toExpirationDate as any).toISOString().substr(0,10) :
                    toExpirationDate;
            }

            if (batchNumber !== undefined) {
                localVarQueryParameter['batch_number'] = batchNumber;
            }

            if (movementType !== undefined) {
                localVarQueryParameter['movement_type'] = movementType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WarehouseMovementsApi - functional programming interface
 * @export
 */
export const WarehouseMovementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WarehouseMovementsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Warehouse Movements
         * @param {Array<number>} [id] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [items] 
         * @param {string} [fromExpirationDate] 
         * @param {string} [toExpirationDate] 
         * @param {string} [batchNumber] 
         * @param {string} [movementType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readWarehouseMovementsWarehouseMovementsGet(id?: Array<number>, warehouses?: Array<number>, items?: Array<number>, fromExpirationDate?: string, toExpirationDate?: string, batchNumber?: string, movementType?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseWarehouseMovementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readWarehouseMovementsWarehouseMovementsGet(id, warehouses, items, fromExpirationDate, toExpirationDate, batchNumber, movementType, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WarehouseMovementsApi - factory interface
 * @export
 */
export const WarehouseMovementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WarehouseMovementsApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Warehouse Movements
         * @param {Array<number>} [id] 
         * @param {Array<number>} [warehouses] 
         * @param {Array<number>} [items] 
         * @param {string} [fromExpirationDate] 
         * @param {string} [toExpirationDate] 
         * @param {string} [batchNumber] 
         * @param {string} [movementType] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseMovementsWarehouseMovementsGet(id?: Array<number>, warehouses?: Array<number>, items?: Array<number>, fromExpirationDate?: string, toExpirationDate?: string, batchNumber?: string, movementType?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseWarehouseMovementResponse> {
            return localVarFp.readWarehouseMovementsWarehouseMovementsGet(id, warehouses, items, fromExpirationDate, toExpirationDate, batchNumber, movementType, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WarehouseMovementsApi - object-oriented interface
 * @export
 * @class WarehouseMovementsApi
 * @extends {BaseAPI}
 */
export class WarehouseMovementsApi extends BaseAPI {
    /**
     * 
     * @summary Read Warehouse Movements
     * @param {Array<number>} [id] 
     * @param {Array<number>} [warehouses] 
     * @param {Array<number>} [items] 
     * @param {string} [fromExpirationDate] 
     * @param {string} [toExpirationDate] 
     * @param {string} [batchNumber] 
     * @param {string} [movementType] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseMovementsApi
     */
    public readWarehouseMovementsWarehouseMovementsGet(id?: Array<number>, warehouses?: Array<number>, items?: Array<number>, fromExpirationDate?: string, toExpirationDate?: string, batchNumber?: string, movementType?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return WarehouseMovementsApiFp(this.configuration).readWarehouseMovementsWarehouseMovementsGet(id, warehouses, items, fromExpirationDate, toExpirationDate, batchNumber, movementType, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WarehousesApi - axios parameter creator
 * @export
 */
export const WarehousesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create
         * @param {SaveWarehouseRequest} saveWarehouseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWarehousesPost: async (saveWarehouseRequest: SaveWarehouseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveWarehouseRequest' is not null or undefined
            assertParamExists('createWarehousesPost', 'saveWarehouseRequest', saveWarehouseRequest)
            const localVarPath = `/warehouses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveWarehouseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Warehouse By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseByIdWarehousesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readWarehouseByIdWarehousesIdGet', 'id', id)
            const localVarPath = `/warehouses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Warehouses
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehousesWarehousesGet: async (id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, sort?: string, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/warehouses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Warehouse
         * @param {number} id 
         * @param {SaveWarehouseRequest} saveWarehouseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWarehouseWarehousesIdPut: async (id: number, saveWarehouseRequest: SaveWarehouseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWarehouseWarehousesIdPut', 'id', id)
            // verify required parameter 'saveWarehouseRequest' is not null or undefined
            assertParamExists('updateWarehouseWarehousesIdPut', 'saveWarehouseRequest', saveWarehouseRequest)
            const localVarPath = `/warehouses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveWarehouseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WarehousesApi - functional programming interface
 * @export
 */
export const WarehousesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WarehousesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {SaveWarehouseRequest} saveWarehouseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWarehousesPost(saveWarehouseRequest: SaveWarehouseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWarehousesPost(saveWarehouseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Warehouse By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readWarehouseByIdWarehousesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readWarehouseByIdWarehousesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Warehouses
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readWarehousesWarehousesGet(id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListResponseWarehouseListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readWarehousesWarehousesGet(id, showInactive, query, page, size, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Warehouse
         * @param {number} id 
         * @param {SaveWarehouseRequest} saveWarehouseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWarehouseWarehousesIdPut(id: number, saveWarehouseRequest: SaveWarehouseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWarehouseWarehousesIdPut(id, saveWarehouseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WarehousesApi - factory interface
 * @export
 */
export const WarehousesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WarehousesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {SaveWarehouseRequest} saveWarehouseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWarehousesPost(saveWarehouseRequest: SaveWarehouseRequest, options?: any): AxiosPromise<WarehouseResponse> {
            return localVarFp.createWarehousesPost(saveWarehouseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Warehouse By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehouseByIdWarehousesIdGet(id: number, options?: any): AxiosPromise<WarehouseResponse> {
            return localVarFp.readWarehouseByIdWarehousesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Warehouses
         * @param {Array<number>} [id] 
         * @param {boolean} [showInactive] 
         * @param {string} [query] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarehousesWarehousesGet(id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, sort?: string, order?: string, options?: any): AxiosPromise<PaginatedListResponseWarehouseListResponse> {
            return localVarFp.readWarehousesWarehousesGet(id, showInactive, query, page, size, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Warehouse
         * @param {number} id 
         * @param {SaveWarehouseRequest} saveWarehouseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWarehouseWarehousesIdPut(id: number, saveWarehouseRequest: SaveWarehouseRequest, options?: any): AxiosPromise<WarehouseResponse> {
            return localVarFp.updateWarehouseWarehousesIdPut(id, saveWarehouseRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WarehousesApi - object-oriented interface
 * @export
 * @class WarehousesApi
 * @extends {BaseAPI}
 */
export class WarehousesApi extends BaseAPI {
    /**
     * 
     * @summary Create
     * @param {SaveWarehouseRequest} saveWarehouseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehousesApi
     */
    public createWarehousesPost(saveWarehouseRequest: SaveWarehouseRequest, options?: AxiosRequestConfig) {
        return WarehousesApiFp(this.configuration).createWarehousesPost(saveWarehouseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Warehouse By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehousesApi
     */
    public readWarehouseByIdWarehousesIdGet(id: number, options?: AxiosRequestConfig) {
        return WarehousesApiFp(this.configuration).readWarehouseByIdWarehousesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Warehouses
     * @param {Array<number>} [id] 
     * @param {boolean} [showInactive] 
     * @param {string} [query] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehousesApi
     */
    public readWarehousesWarehousesGet(id?: Array<number>, showInactive?: boolean, query?: string, page?: number, size?: number, sort?: string, order?: string, options?: AxiosRequestConfig) {
        return WarehousesApiFp(this.configuration).readWarehousesWarehousesGet(id, showInactive, query, page, size, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Warehouse
     * @param {number} id 
     * @param {SaveWarehouseRequest} saveWarehouseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehousesApi
     */
    public updateWarehouseWarehousesIdPut(id: number, saveWarehouseRequest: SaveWarehouseRequest, options?: AxiosRequestConfig) {
        return WarehousesApiFp(this.configuration).updateWarehouseWarehousesIdPut(id, saveWarehouseRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



import {
  AccessPermissions,
  OrderStatus,
  OrderStatusIds,
  OrderStatusKey,
  OrderTypeIds,
  PartnerContactResponse,
  UserListResponse,
  WarehouseListResponse,
  accessManagementService,
  amsLegacyService,
  amsV3Service,
  getOrderStatusIdByKey,
  getOrderStatusLabelByKey,
  snackbarService
} from '../../../../services';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
  createStyles,
  makeStyles
} from '@material-ui/core';
import {
  CreateOrderV2Request,
  OrderItemV2Response,
  OrderV2Response,
  UpdateOrderV2Request
} from '../../../../services/api';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Lookup, { userToLookup, warehouseToLookup } from '../../../../models/lookup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getDeliveryBatchUrl,
  getExtendedClientId,
  getExtendedOrderId,
  getExtendedPartnerId,
  getExtendedPaymentOptionId,
  getExtendedWarehouseId
} from '../../../../helpers/utils';
import { ignoreOffset, isValidDate } from '../../../../helpers/date-helper';
import { usePartners, usePermissions, useUsers, useWarehouses } from '../../../../helpers/hooks';

import AMSAutocomplete from '../../../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSButton from '../../../../helpers/ui/AMSButton/AMSButton';
import DateFnsUtils from '@date-io/date-fns';
import OrderItemsPanel from '../OrderItemsPanel/OrderItemsPanel';
import OutlinedDiv from '../../../../helpers/ui/OutlinedDiv';
import firebase from 'firebase';
import { isB2B } from '../../../../helpers/openapi-axios-helper';
import printJS from 'print-js';
import useClients from '../../../../helpers/hooks/useClients';
import { useHistory } from 'react-router-dom';

const beforePickingStatuses = [
  OrderStatus.PRELIMINARY.key,
  OrderStatus.FOR_PICKING.key,
  OrderStatus.PICKING_IN_PROGRESS.key,
  OrderStatus.DELAYED.key,
  OrderStatus.DELAYED_DUE_TO_SUPPLIER.key,
  OrderStatus.DELAYED_DUE_TO_WAREHOUSE_STOCK.key,
  OrderStatus.FORECLOSURE.key
];

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: 20
    },
    saveButton: {
      marginTop: 10,
      marginLeft: 10
    },
    label: {
      paddingTop: 6
    },
    addNewCheckboxWrapper: {
      paddingTop: 8
    }
  })
);

interface OrderInfoPanelProps {
  order: OrderV2Response;
  setOrder: (order: any) => void;
  sender: any;
  setSender: (sender: any) => void;
  receiver: any;
  setReceiver: (receiver: any) => void;
  setInvoiceNumber: (invoiceNumber: string) => void;
  originalStatusKey: OrderStatusKey;
  setOriginalStatusKey: (statusId: OrderStatusKey) => void;
  allowedSubsequentStatuses: OrderStatusKey[];
  setAllowedSubsequentStatuses: (statuses: OrderStatusKey[]) => void;
  reload: () => void;
}

const OrderInfoPanel = ({
  order,
  setOrder,
  sender,
  setSender,
  receiver,
  setReceiver,
  setInvoiceNumber,
  originalStatusKey,
  setOriginalStatusKey,
  allowedSubsequentStatuses,
  setAllowedSubsequentStatuses,
  reload
}: OrderInfoPanelProps) => {
  const history = useHistory();
  const classes = useStyles();
  const [actionLoading, setActionLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const { warehouses } = useWarehouses();
  const { partners } = usePartners();
  const { clients } = useClients();
  const { users } = useUsers();
  const [availableSenders, setAvailableSenders] = useState<any[]>([]);
  const [availableReceivers, setAvailableReceivers] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(
    order?.deliveryDate ? new Date(order.deliveryDate) : null
  );
  const [executionPercentage, setExecutionPercentage] = useState(0);

  const [addNew, setAddNew] = useState<boolean>(false);

  const [canCreate, canUpdate, canChangeUserResponsible] = usePermissions([
    AccessPermissions.CAN_CREATE_ORDER,
    AccessPermissions.CAN_UPDATE_ORDER,
    AccessPermissions.CAN_CHANGE_ORDER_USER_RESPONSIBLE
  ]);

  const shouldBeDisabled = useMemo(
    () => (order?.id > 0 ? !canUpdate : !canCreate),
    [order, canCreate, canUpdate]
  );

  useEffect(() => {
    setItems(order.items || []);
    if (order && order.id > 0 && order.totalPrice) {
      setTotalPrice(order.totalPrice);
    } else {
      setTotalPrice(
        order.items
          ? order.items.reduce(
              (total: number, item: OrderItemV2Response) =>
                (total += item.liveTotalPrice ?? item.totalPrice ?? 0),
              0
            )
          : 0
      );
    }
  }, [order]);

  useEffect(() => {
    setAvailableSenders(warehouses);
  }, [warehouses]);

  useEffect(() => {
    if (!sender && warehouses.length > 0) {
      setSender({
        id: warehouses[0].id,
        value: `${getExtendedWarehouseId(warehouses[0].id)} ${warehouses[0].name}`
      });
    }
  }, [warehouses, sender, setSender]);

  useEffect(() => {
    if (order.isInternal) {
      if (sender) {
        const selectedWarehouse: WarehouseListResponse | undefined = warehouses.find(
          (w: WarehouseListResponse) => w.id === sender.id
        );
        if (selectedWarehouse) {
          setAvailableReceivers(
            warehouses.filter(
              (w: WarehouseListResponse) =>
                w.companyId === selectedWarehouse.companyId && w.id !== selectedWarehouse.id
            )
          );
        } else {
          setAvailableReceivers(warehouses);
        }
      } else {
        setAvailableReceivers(warehouses);
      }
    } else {
      setAvailableReceivers(
        isB2B
          ? partners
          : clients.map((client) => ({ ...client, name: `${client.firstName} ${client.lastName}` }))
      );
    }
  }, [order, sender, partners, warehouses, clients]);

  const hasEmptyFields = () => {
    return !order.statusKey || !order.userResponsible || !sender || !receiver;
  };

  const handleOrderSave = async () => {
    setActionLoading(true);
    if (order && order.id === -1) {
      const createOrderRequest: CreateOrderV2Request = {
        items,
        warehouseId: sender.id,
        receiverId: receiver.id,
        orderType: order.isInternal
          ? OrderTypeIds.INTERNAL
          : isB2B
          ? OrderTypeIds.B2B
          : OrderTypeIds.B2C,
        orderPriority: 1,
        userResponsibleId: canChangeUserResponsible
          ? order.userResponsible?.id
          : accessManagementService.currentUser!.user!.id,
        note: order.note,
        deliveryDate: deliveryDate
          ? deliveryDate instanceof Date
            ? ignoreOffset(deliveryDate).toISOString()
            : deliveryDate
          : undefined
      };
      const createOrderResp = await amsLegacyService.createOrder(createOrderRequest);
      if (createOrderResp) {
        snackbarService.setSnackbarOpen(true);
        if (addNew) {
          reload();
        } else {
          history.push(`/order?id=${createOrderResp.data.id}`);
        }
      }
      setActionLoading(false);
    } else {
      const statusKey = originalStatusKey !== order.statusKey ? order.statusKey : undefined;
      const updateOrderRequest: UpdateOrderV2Request = {
        items:
          items?.length && !order.invoiceNumber && canEditItems
            ? items.map((item) => ({ ...item, bonusQuantity: undefined }))
            : undefined,
        statusKey: statusKey,
        userResponsibleId: canChangeUserResponsible ? order.userResponsible?.id : undefined,
        note: order.note ? order.note : undefined,
        deliveryDate: deliveryDate
          ? deliveryDate instanceof Date
            ? ignoreOffset(deliveryDate).toISOString()
            : deliveryDate
          : undefined
      };
      const resp = await amsLegacyService.updateOrder(order.id, updateOrderRequest);
      if (resp) {
        snackbarService.setSnackbarOpen(true);
        if (addNew) {
          reload();
        } else {
          const resp = await amsLegacyService.getOrder(order.id, true);
          setOriginalStatusKey(resp.data.statusKey as OrderStatusKey);
          setAllowedSubsequentStatuses([
            resp.data.statusKey as OrderStatusKey,
            ...(resp.data.allowedSubsequentStatuses as OrderStatusKey[])
          ]);
          setItems(resp.data.items || []);
          setOrder(order);
          setTotalPrice(
            (resp.data.items ?? []).reduce(
              (total: number, item: OrderItemV2Response) =>
                (total += item.liveTotalPrice ?? item.totalPrice ?? 0),
              0
            )
          );
        }
      }
      setActionLoading(false);
    }
  };

  const handleOrderPrint = async () => {
    setPrintLoading(true);
    await handleOrderSave();
    const idToken: string | undefined =
      (await firebase.auth().currentUser?.getIdToken(false)) ?? '';
    const content = await fetch(getDeliveryBatchUrl(order.id), {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
    var blob = await content.blob();
    var objectUrl = URL.createObjectURL(blob);
    printJS(objectUrl);
    setInvoiceNumber(`${order.id}`);
    setPrintLoading(false);
  };

  const orderStatusId = useMemo(
    () => getOrderStatusIdByKey(order.statusKey as OrderStatusKey),
    [order.statusKey]
  );

  const canEditItems = useMemo(
    () => orderStatusId < OrderStatusIds.FOR_SHIPPING && !order?.invoiceNumber,
    [orderStatusId, order]
  );

  const loadExecutionPercentage = useCallback((order: OrderV2Response, totalPrice: number) => {
    if (order && order.id > 0 && totalPrice > 0) {
      amsV3Service.getOrderExecutionPercentage(order.id).then((response) => {
        if (response) {
          setExecutionPercentage(+response.data);
        } else {
          setExecutionPercentage(0);
        }
      });
    }
  }, []);

  const shouldShowExecutionPercentage = useMemo(
    () => beforePickingStatuses.includes(order.statusKey as OrderStatusKey),
    [order.statusKey]
  );

  useEffect(() => {
    if (shouldShowExecutionPercentage) {
      loadExecutionPercentage(order, totalPrice);
    }
  }, [shouldShowExecutionPercentage, order, totalPrice, loadExecutionPercentage]);

  return (
    <Paper elevation={2} className={classes.paper}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={8} lg={7}>
            <OutlinedDiv label="Данни за доставката" disabled={shouldBeDisabled}>
              <Grid container spacing={1}>
                <Grid item sm={12} md={12} lg={9}>
                  <Grid container spacing={1}>
                    <Grid item sm={12} md={6} lg={3}>
                      <TextField
                        label="Номер"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={
                          order.id && order.id > 0 ? getExtendedOrderId(order.id) : 'Нов запис'
                        }
                        disabled={true}
                      />
                    </Grid>
                    <Grid item sm={12} md={6} lg={3}>
                      <TextField
                        label="Дата"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={
                          order && order.orderTimestamp
                            ? order.orderTimestamp.substring(0, 10)
                            : 'Нова дата'
                        }
                        disabled={true}
                      />
                    </Grid>
                    <Grid item sm={12} md={6} lg={6}>
                      <AMSAutocomplete
                        label="Статус"
                        options={allowedSubsequentStatuses.map((key: OrderStatusKey) => ({
                          id: key,
                          value: getOrderStatusLabelByKey(key)
                        }))}
                        value={
                          order && order.statusKey
                            ? {
                                id: order.statusKey,
                                value: getOrderStatusLabelByKey(order.statusKey as OrderStatusKey)
                              }
                            : null
                        }
                        disabled={
                          !order.id ||
                          order.id <= 0 ||
                          order.allowedSubsequentStatuses.length === 0 ||
                          shouldBeDisabled
                        }
                        onChange={(selectedValue: Lookup | null) => {
                          if (selectedValue) {
                            setOrder({
                              ...order,
                              statusKey: selectedValue.id
                            });
                          } else {
                            setOrder({
                              ...order,
                              statusKey: null
                            });
                          }
                        }}
                        minChar={0}
                        required
                      />
                    </Grid>
                    <Grid item sm={12} md={6} lg={6}>
                      <AMSAutocomplete
                        label="Склад"
                        options={availableSenders
                          .map(warehouseToLookup)
                          .sort((as1, as2) => as2.id - as1.id)}
                        value={sender ? sender : null}
                        onChange={(selectedValue: WarehouseListResponse | null) => {
                          setSender(selectedValue);
                        }}
                        disabled={!!order.id && order.id > 0}
                        minChar={0}
                        required
                      />
                    </Grid>
                    <Grid item sm={12} md={6} lg={6}>
                      <AMSAutocomplete
                        label="Отговорник"
                        options={users.map(userToLookup).sort((as1, as2) => as2.id - as1.id)}
                        value={
                          order && order.userResponsible
                            ? {
                                id: order.userResponsible.id,
                                value: `${order.userResponsible.firstName} ${order.userResponsible.lastName}`
                              }
                            : null
                        }
                        onChange={(selectedValue: UserListResponse | null) => {
                          if (selectedValue) {
                            const newUser = users.find(
                              (u: UserListResponse) => u.id === selectedValue.id
                            );
                            if (newUser) {
                              const [firstName, lastName] = newUser.name.split(' ');
                              setOrder({
                                ...order,
                                userResponsible: { ...newUser, firstName, lastName }
                              });
                            }
                          } else {
                            setOrder({
                              ...order,
                              userResponsible: null
                            });
                          }
                        }}
                        minChar={0}
                        disabled={
                          (order.id > 0 && !order.allowedSubsequentStatuses.length) ||
                          !canChangeUserResponsible ||
                          shouldBeDisabled
                        }
                        required
                      />
                    </Grid>
                    <Grid container item sm={12} md={6} lg={12} justify="flex-end">
                      <FormControlLabel
                        className={classes.label}
                        control={
                          <Checkbox
                            checked={order.isInternal}
                            onChange={() => {
                              setReceiver(null);
                              setOrder({ ...order, isInternal: !order.isInternal });
                            }}
                          />
                        }
                        label="Вътрешен трансфер"
                        labelPlacement="end"
                        disabled={order && order.id > 0}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} md={12} lg={3}>
                  <Grid container spacing={1}>
                    <Grid item sm={12} md={12} lg={12}>
                      <TextField
                        label="Забележка"
                        margin="dense"
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                        value={order && order.note ? order.note : ''}
                        onChange={(event: any) => {
                          if (event && event.target) {
                            setOrder({ ...order, note: event.target.value });
                          }
                        }}
                        disabled={!order.allowedSubsequentStatuses.length || shouldBeDisabled}
                      />
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                      <TextField
                        label="Обща сума"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={`${totalPrice ? totalPrice.toFixed(2) : '0.00'}лв.`}
                        disabled={true}
                        inputProps={{
                          min: 0,
                          style: { textAlign: 'right', fontSize: '125%', color: '#3D3D3D' }
                        }}
                        required
                      />
                    </Grid>
                    {shouldShowExecutionPercentage && (
                      <Grid item sm={12} md={12} lg={12}>
                        <TextField
                          label="Макс сума"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          value={`${((totalPrice * executionPercentage) / 100).toFixed(2)}лв.`}
                          disabled={true}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'right', fontSize: '125%', color: '#3D3D3D' }
                          }}
                          InputProps={{
                            startAdornment: (
                              <Typography variant="subtitle1">
                                ({executionPercentage.toFixed(2)}%)
                              </Typography>
                            )
                          }}
                          required
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </OutlinedDiv>
          </Grid>
          <Grid item sm={12} md={4} lg={5}>
            <OutlinedDiv label="Данни за получател" disabled={shouldBeDisabled}>
              <Grid container spacing={1}>
                <Grid item sm={12} md={12} lg={8}>
                  <AMSAutocomplete
                    label={order.isInternal ? 'Склад получател' : 'Получател'}
                    options={availableReceivers
                      .map((c) => ({
                        id: c.id,
                        value: order.isInternal
                          ? `${getExtendedWarehouseId(c.id)} ${c.name}`
                          : isB2B
                          ? `${getExtendedPartnerId(c.id)} ${c.name}`
                          : `${getExtendedClientId(c.id)} ${c.name}`
                      }))
                      .sort((ar1, ar2) => ar2.id - ar1.id)}
                    value={
                      receiver
                        ? {
                            id: receiver.id,
                            value: `${
                              order.isInternal
                                ? getExtendedWarehouseId(receiver.id)
                                : isB2B
                                ? `${getExtendedPartnerId(receiver.id)} ${receiver.name}`
                                : `${getExtendedClientId(receiver.id)} ${receiver.name}`
                            }`
                          }
                        : null
                    }
                    onChange={(selectedValue: any | null) => {
                      setReceiver(
                        selectedValue
                          ? availableReceivers.find((receiver) => receiver.id === selectedValue.id)
                          : null
                      );
                    }}
                    disabled={(!!order.id && order.id > 0) || items.length > 0}
                    minChar={0}
                    required
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={4}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    format="dd/MM/yy HH:mm"
                    margin="dense"
                    label="Доставка"
                    helperText={''}
                    value={deliveryDate ? deliveryDate : null}
                    onChange={(value: Date | null) => {
                      if (value) {
                        if (isValidDate(value)) {
                          setDeliveryDate(value);
                        }
                      } else {
                        setDeliveryDate(null);
                      }
                    }}
                    inputVariant="outlined"
                    fullWidth
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    disabled={
                      (!order.id && order.id <= 0) ||
                      !order.allowedSubsequentStatuses.length ||
                      orderStatusId > OrderStatusIds.FOR_SHIPPING ||
                      shouldBeDisabled
                    }
                  />
                </Grid>
                {!order.isInternal && isB2B && (
                  <Grid item sm={12} md={12} lg={12}>
                    <TextField
                      label="Начин на плащане"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      value={
                        order.receiverPaymentOptionInfo
                          ? `${getExtendedPaymentOptionId(order.receiverPaymentOptionId ?? 0)} ${
                              order.receiverPaymentOptionInfo
                            }`
                          : receiver && receiver.paymentOption
                          ? `${getExtendedPaymentOptionId(receiver.paymentOption.id)} ${
                              receiver.paymentOption.holder
                            } ${receiver.paymentOption.account}`
                          : ''
                      }
                      disabled={true}
                    />
                  </Grid>
                )}
                {isB2B && (
                  <Grid item sm={12} md={12} lg={6}>
                    <TextField
                      label={order.isInternal ? 'Компания' : 'Контакти'}
                      margin="dense"
                      variant="outlined"
                      multiline
                      rows={3}
                      fullWidth
                      value={
                        !order.isInternal && receiver && receiver.contacts
                          ? receiver.contacts.reduce(
                              (res: string, contact: PartnerContactResponse, index: number) => {
                                res += `${contact.name}${
                                  contact.phone ? `, ${contact.phone}` : ''
                                }${index < receiver.contacts.length - 1 ? '\n' : ''}`;
                                return res;
                              },
                              ''
                            )
                          : receiver != null && receiver.companyName && receiver.companyNote
                          ? `${receiver.companyName}, ${receiver.companyNote}`
                          : ''
                      }
                      disabled={true}
                    />
                  </Grid>
                )}
                {isB2B && (
                  <Grid item sm={12} md={12} lg={6}>
                    <TextField
                      label="Адрес за доставка"
                      margin="dense"
                      variant="outlined"
                      multiline
                      rows={3}
                      fullWidth
                      value={receiver && receiver.fullAddress ? receiver.fullAddress : ''}
                      disabled={true}
                    />
                  </Grid>
                )}
              </Grid>
            </OutlinedDiv>
            {order && order.id <= 0 && canCreate && (
              <Grid container item sm={12} lg={12} justify="flex-end">
                <Grid container sm={8} lg={8} justify="flex-end">
                  <div className={classes.addNewCheckboxWrapper}>
                    <FormControlLabel
                      className={classes.label}
                      control={<Checkbox checked={addNew} onChange={() => setAddNew(!addNew)} />}
                      label="Добави нова след запис"
                      labelPlacement="end"
                    />
                  </div>
                </Grid>
                <Grid container sm={4} lg={4} justify="flex-end">
                  <AMSButton
                    variant="contained"
                    color="primary"
                    disabled={actionLoading || hasEmptyFields()}
                    className={classes.saveButton}
                    onClick={handleOrderSave}
                    text={addNew ? 'Запиши и добави нова' : 'Запиши'}
                    loading={actionLoading}
                  />
                </Grid>
              </Grid>
            )}
            {order && order.id > 0 && canUpdate && (
              <Grid container item sm={12} lg={12} justify="flex-end">
                <Grid container item sm={12} lg={12} justify="flex-end">
                  {orderStatusId > OrderStatusIds.FOR_SHIPPING && (
                    <AMSButton
                      variant="contained"
                      color="secondary"
                      disabled={printLoading}
                      className={classes.saveButton}
                      onClick={handleOrderPrint}
                      text="Печат"
                      loading={printLoading}
                    />
                  )}
                  <AMSButton
                    variant="contained"
                    color="primary"
                    disabled={actionLoading || hasEmptyFields()}
                    className={classes.saveButton}
                    onClick={handleOrderSave}
                    text={addNew ? 'Запиши и добави нова' : 'Запиши'}
                    loading={actionLoading}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item sm={12} lg={12}>
            <OrderItemsPanel
              id={order.id}
              items={items}
              setItems={(items) => {
                setItems(items);
                setTotalPrice(
                  items.reduce(
                    (total: number, item: OrderItemV2Response) =>
                      (total += item.liveTotalPrice ?? item.totalPrice ?? 0),
                    0
                  )
                );
              }}
              partnerId={!order.isInternal ? receiver?.id : undefined}
              hasActiveIntegration={!!receiver?.integrationType}
              canAdd={!!receiver && canEditItems}
              canEditItems={canEditItems}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </Paper>
  );
};

export default OrderInfoPanel;

import { AMSAutocomplete, AMSButton } from '../../helpers/ui';
import { Checkbox, FormControlLabel, Grid, Paper, TextField } from '@material-ui/core';
import {
  CreateDeliveryItemRequest,
  CreateDeliveryRequest,
  UserAccessResponse,
  accessManagementService,
  amsV3Service,
  snackbarService
} from '../../services';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { supplierToLookup, warehouseToLookup } from '../../models/lookup';
import {
  useFlag,
  useLookup,
  useObservable,
  useStyles,
  useSuppliers,
  useWarehouses
} from '../../helpers/hooks';

import DateFnsUtils from '@date-io/date-fns';
import DeliveryItemsPanel from './Panels/DeliveryItemsPanel/DeliveryItemsPanel';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import OutlinedDiv from '../../helpers/ui/OutlinedDiv';
import { useHistory } from 'react-router-dom';

const CreateDeliveryComponent = () => {
  const history = useHistory();
  const classes = useStyles();
  const { suppliers } = useSuppliers();
  const { warehouses } = useWarehouses();

  const [warehouse, setWarehouse] = useLookup();
  const [supplier, setSupplier] = useLookup();
  const [deliveryItems, setDeliveryItems] = useState<CreateDeliveryItemRequest[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [note, setNote] = useState<string>('');
  const currentUser: UserAccessResponse | undefined = useObservable(
    accessManagementService.user
  ).user;

  const [actionLoading, setActionLoading] = useFlag(false);
  const [addNew, setAddNew] = useFlag(false);

  const hasEmptyFields = useMemo(() => !supplier || !warehouse, [supplier, warehouse]);

  const reload = useCallback(() => {
    setSupplier(null);
    setWarehouse(null);
    setNote('');
    setTotalPrice(0);
  }, [setSupplier, setWarehouse, setNote, setTotalPrice]);

  const handleDeliverySave = useCallback(async () => {
    if (warehouse && supplier) {
      setActionLoading(true);
      const createDeliveryRequest: CreateDeliveryRequest = {
        warehouseId: warehouse.id,
        supplierId: supplier.id,
        deliveryItems: deliveryItems
          ? deliveryItems
              .map((stock: any) => ({
                itemId: stock.itemId,
                country: stock.country,
                expirationDate: stock.expirationDate,
                batchNumber: stock.batchNumber,
                quantity: stock.quantity.toFixed(3),
                price: stock.price.toFixed(2)
              }))
              .sort((a: any, b: any) => (a.itemId === b.itemId ? +a.price - +b.price : 0))
          : [],
        note: note
      };
      const resp = await amsV3Service.createDelivery(createDeliveryRequest);
      setActionLoading(false);
      if (resp) {
        if (addNew) {
          reload();
          setDeliveryItems([]);
        } else {
          history.push(`delivery?id=${resp.data.id}`);
        }
        snackbarService.setSnackbarOpen(true);
      }
    }
  }, [
    warehouse,
    supplier,
    deliveryItems,
    note,
    addNew,
    setActionLoading,
    reload,
    history,
    setDeliveryItems
  ]);

  useEffect(() => {
    setTotalPrice(
      deliveryItems.reduce((result: number, item: any) => result + +item.totalIncludingVat, 0)
    );
  }, [deliveryItems, setTotalPrice]);

  return (
    <Paper elevation={2} className={classes.paper}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <OutlinedDiv label="Данни за доставката" loading={false}>
          <Grid container spacing={2}>
            <Grid container spacing={1} item sm={12} md={8} lg={8}>
              <Grid item sm={12} md={2} lg={2}>
                <TextField
                  label="Номер"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  value="Нова доставка"
                  disabled={true}
                />
              </Grid>
              <Grid item sm={12} md={2} lg={2}>
                <TextField
                  label="Дата"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  value={'Нова дата'}
                  disabled={true}
                />
              </Grid>
              <Grid item sm={12} md={8} lg={8}>
                <AMSAutocomplete
                  label="Склад"
                  options={warehouses.map(warehouseToLookup)}
                  value={warehouse ? warehouse : null}
                  onChange={setWarehouse}
                  disabled={deliveryItems.length > 0}
                  minChar={0}
                  required
                />
              </Grid>
              <Grid item sm={12} md={4} lg={4}>
                <TextField
                  label="Създадена от"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  value={`${currentUser?.firstName ?? ''} ${currentUser?.lastName ?? ''}`}
                  disabled={true}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <AMSAutocomplete
                  label="Доставчик"
                  options={suppliers.map(supplierToLookup)}
                  value={supplier ? supplier : null}
                  onChange={setSupplier}
                  disabled={deliveryItems.length > 0}
                  minChar={0}
                  required
                />
              </Grid>
              <Grid item sm={12} md={2} lg={2}>
                <TextField
                  label="Обща сума"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  value={totalPrice ? totalPrice.toFixed(2) : '0.00'}
                  inputProps={{
                    min: 0,
                    style: { textAlign: 'right', color: '#3D3D3D' }
                  }}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <Grid style={{ height: '100%' }}>
                <TextField
                  label="Забележка"
                  margin="dense"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  value={note}
                  onChange={(event: any) => {
                    if (event && event.target) {
                      setNote(event.target.value);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </OutlinedDiv>
        <Grid container spacing={2}>
          <Grid item sm={12} lg={12}>
            <DeliveryItemsPanel
              delivery={null}
              supplier={supplier}
              warehouse={warehouse}
              deliveryItems={deliveryItems}
              setDeliveryItems={setDeliveryItems}
              loading={false}
            />
          </Grid>
          <Grid container item sm={12} lg={12} justify="flex-end">
            <Grid item sm={10} lg={10} container justify="flex-end">
              <div className={classes.addNewCheckboxWrapper}>
                <FormControlLabel
                  className={classes.label}
                  control={<Checkbox checked={addNew} onChange={() => setAddNew(!addNew)} />}
                  label="Добави нова след запис"
                  labelPlacement="end"
                />
              </div>
            </Grid>
            <Grid item sm={2} lg={2} container justify="flex-end">
              <AMSButton
                variant="contained"
                color="primary"
                disabled={actionLoading || hasEmptyFields}
                className={classes.saveButton}
                onClick={handleDeliverySave}
                text={addNew ? 'Запиши и добави нова' : 'Запиши'}
                loading={actionLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </Paper>
  );
};
export default CreateDeliveryComponent;

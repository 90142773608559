import {
  AccessPermissions,
  accessManagementService,
  amsLegacyService,
  amsV3Service
} from '../../services';
import { CircularProgress, Container, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';

import { ItemCategoryResponse } from '../../services/api';
import ItemContainer from './tabs/ItemContainer';
import ItemPartnerConfigurationComponent from './tabs/ItemPartnerConfigurationComponent';
import ItemStockTakingComponent from './tabs/ItemStockTakingComponent';
import LinkedItemsContainer from './tabs/LinkedItemsContainer';
import SimilarItemsContainer from './tabs/SimilarItemsContainer';
import StockContainer from './tabs/StockContainer';
import TabPanel from '../../helpers/ui/TabPanel/TabPanel';
import { parseQuery } from '../../helpers/url';
import { useHistory } from 'react-router-dom';
import { useSuppliers } from '../../helpers/hooks';

const ItemComponent = ({ location }: { location: any }) => {
  const { id, tabId } = parseQuery(location.search);

  const history = useHistory();

  const [item, setItem] = useState<any>({
    isActive: true,
    isOwnedByUs: true,
    maintainStock: true,
    height: 0,
    width: 0,
    depth: 0
  });
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<ItemCategoryResponse[]>([]);
  const [activeOrderItems, setActiveOrderItems] = useState<any[]>([]);
  const [stockHistory, setStockHistory] = useState<any[]>([]);
  const [averagePrice, setAveragePrice] = useState<number>(0);
  const { suppliers } = useSuppliers();

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      if (id) {
        const [itemResp, activeOrderItemsResp, categoriesResp, stockHistoryResp] =
          await Promise.all([
            amsV3Service.getItem(id),
            amsV3Service.getItemActiveOrderItems(id),
            amsLegacyService.getCategoriesFlat(true),
            amsV3Service.getItemBatches(id, undefined, undefined, 25)
          ]);
        setItem(
          itemResp.data ?? {
            isActive: true,
            isOwnedByUs: true,
            maintainStock: true,
            height: 0,
            width: 0,
            depth: 0
          }
        );
        setActiveOrderItems(activeOrderItemsResp?.data?.data ?? []);
        setCategories(categoriesResp ? categoriesResp.data : []);
        setStockHistory(
          stockHistoryResp.data?.data.map((s: any) => ({
            ...s,
            deliveryDate: new Date(s.deliveryDate)
          })) ?? []
        );
        setAveragePrice(
          stockHistoryResp?.data.data.reduce((res: any, item: any) => {
            res += item.price;
            return res;
          }, 0) ?? 0 / stockHistoryResp.data.total
        );
        setLoading(false);
      } else {
        const categoriesResp = await amsLegacyService.getCategoriesFlat(true);
        if (categoriesResp && categoriesResp.data) {
          setCategories(
            categoriesResp.data.sort(
              (c1: ItemCategoryResponse, c2: ItemCategoryResponse) => c2.id - c1.id
            )
          );
        }
        setItem({
          isActive: true,
          isOwnedByUs: true,
          maintainStock: true,
          height: 0,
          width: 0,
          depth: 0
        });
        setActiveOrderItems([]);
        setLoading(false);
      }
    };
    loadData();
  }, [id]);

  useEffect(() => {
    if (tabId) {
      if (id) {
        setValue(+tabId);
      } else {
        setValue(0);
        history.push(`item?${id ? `id=${id}` : ''}&tabId=0`);
      }
    } else {
      setValue(0);
    }
  }, [tabId, id, history]);

  const onChange = (field: string, value: any) => {
    setItem({ ...item, [field]: value });
  };

  function a11yProps(index: any) {
    return {
      id: `item-tab-${index}`,
      'aria-controls': `item-tabpanel-${index}`
    };
  }

  const canReadStockTakingReports = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_READ_STOCKTAKING_REPORTS),
    []
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    history.replace(`item?${id ? `id=${id}` : ''}&tabId=${newValue}`);
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <div className="text-align-left">
      <Container maxWidth="xl">
        <Typography component="h5" variant="h5">
          {item.id && item.artNo ? `${item.artNo} - ` : ''}
          {item.id && item.name ? item.name : ''}
        </Typography>
        <Grid container>
          <Grid item lg={12}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Информация" {...a11yProps(0)} />
              <Tab disabled={!id} label="Наличности" {...a11yProps(1)} />
              <Tab disabled={!id} label="Мапинги" {...a11yProps(2)} />
              {canReadStockTakingReports && (
                <Tab disabled={!id} label="Ревизии" {...a11yProps(3)} />
              )}
              <Tab
                disabled={!id}
                label="Свързани продукти"
                {...a11yProps(canReadStockTakingReports ? 4 : 3)}
              />
              <Tab
                disabled={!id}
                label="Аналози"
                {...a11yProps(canReadStockTakingReports ? 5 : 4)}
              />
            </Tabs>
          </Grid>
          <Grid item lg={12}>
            <TabPanel value={value} index={0}>
              <ItemContainer
                item={item}
                onChange={onChange}
                categories={categories}
                suppliers={suppliers}
                reload={() => {
                  setItem({
                    isActive: true,
                    isOwnedByUs: true,
                    maintainStock: true,
                    height: 0,
                    width: 0,
                    depth: 0
                  });
                }}
                stockData={{
                  labels: stockHistory
                    .sort((a, b) => a.deliveryDate - b.deliveryDate)
                    .map((s) => s.deliveryDate.toISOString().substr(0, 10)),
                  datasets: [
                    {
                      label: 'Цена',
                      data: stockHistory.map((s) => s.price),
                      fill: true,
                      backgroundColor: 'rgba(75,192,192,0.2)',
                      borderColor: 'rgba(75,192,192,1)'
                    },
                    {
                      label: 'Средна цена',
                      data: stockHistory.map(() => averagePrice.toFixed(2)),
                      fill: true,
                      backgroundColor: 'rgba(62,86,176,0.2)',
                      borderColor: 'rgba(62,86,176,1)'
                    }
                  ]
                }}
                activeOrderItems={activeOrderItems}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <StockContainer id={id} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ItemPartnerConfigurationComponent itemId={id} />
            </TabPanel>
            {canReadStockTakingReports && (
              <TabPanel value={value} index={3}>
                <ItemStockTakingComponent itemId={id} />
              </TabPanel>
            )}
            <TabPanel value={value} index={canReadStockTakingReports ? 4 : 3}>
              <LinkedItemsContainer id={id} />
            </TabPanel>
            <TabPanel value={value} index={canReadStockTakingReports ? 5 : 4}>
              <SimilarItemsContainer id={id} />
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ItemComponent;

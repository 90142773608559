import Lookup from '../models/lookup';

export * from './AMSV3Service';
export * from './AMSLegacyService';
export * from './services';
export * from './api-v3';
export * from './accessManagementService';

export enum OrderStatusIds {
  PRELIMINARY = 10,
  CANCELLED = 20,
  DELAYED = 30,
  DELAYED_DUE_TO_SUPPLIER = 31,
  DELAYED_DUE_TO_WAREHOUSE_STOCK = 32,
  FORECLOSURE = 35,
  FOR_PICKING = 40,
  PICKING_IN_PROGRESS = 45,
  FOR_CLARIFICATION = 50,
  CLARIFICATION_IN_PROGRESS = 55,
  FOR_INSPECTION = 60,
  INSPECTION_IN_PROGRESS = 65,
  FOR_SHIPPING = 70,
  SHIPPED = 75,
  RECEIVED_WITH_REMARKS = 77,
  DELIVERED = 78,
  AWAITING_PAYMENT = 79,
  FINISHED_SUCCESSFULLY = 80,
  FINISHED_UNSUCCESSFULLY = 85
}

export enum OrderStatusKeys {
  PRELIMINARY = 'PRELIMINARY',
  CANCELLED = 'CANCELLED',
  DELAYED = 'DELAYED',
  DELAYED_DUE_TO_WAREHOUSE_STOCK = 'DELAYED_DUE_TO_WAREHOUSE_STOCK',
  DELAYED_DUE_TO_SUPPLIER = 'DELAYED_DUE_TO_SUPPLIER',
  FORECLOSURE = 'FORECLOSURE',
  FOR_PICKING = 'FOR_PICKING',
  PICKING_IN_PROGRESS = 'PICKING_IN_PROGRESS',
  FOR_CLARIFICATION = 'FOR_CLARIFICATION',
  CLARIFICATION_IN_PROGRESS = 'CLARIFICATION_IN_PROGRESS',
  FOR_INSPECTION = 'FOR_INSPECTION',
  INSPECTION_IN_PROGRESS = 'INSPECTION_IN_PROGRESS',
  FOR_SHIPPING = 'FOR_SHIPPING',
  SHIPPED = 'SHIPPED',
  RECEIVED_WITH_REMARKS = 'RECEIVED_WITH_REMARKS',
  DELIVERED = 'DELIVERED',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  FINISHED_SUCCESSFULLY = 'FINISHED_SUCCESSFULLY',
  FINISHED_UNSUCCESSFULLY = 'FINISHED_UNSUCCESSFULLY'
}

export enum OrderStatusLabels {
  PRELIMINARY = 'Предварителен',
  CANCELLED = 'Прекратена',
  DELAYED = 'Забавена',
  DELAYED_DUE_TO_WAREHOUSE_STOCK = 'Забавена от вътрешен склад',
  DELAYED_DUE_TO_SUPPLIER = 'Забавена заради доставчик',
  FORECLOSURE = 'Възбрана',
  FOR_PICKING = 'За пикиране',
  PICKING_IN_PROGRESS = 'В процес на пикиране',
  FOR_CLARIFICATION = 'За разяснение',
  CLARIFICATION_IN_PROGRESS = 'В процес на разясняване',
  FOR_INSPECTION = 'За проверка',
  INSPECTION_IN_PROGRESS = 'В процес на проверка',
  FOR_SHIPPING = 'За експедиране',
  SHIPPED = 'Експедирана',
  RECEIVED_WITH_REMARKS = 'Приета със забележки',
  DELIVERED = 'Доставена',
  AWAITING_PAYMENT = 'Очаква плащане',
  FINISHED_UNSUCCESSFULLY = 'Неуспешно приключена',
  FINISHED_SUCCESSFULLY = 'Успешно приключена'
}

export type OrderStatusId =
  | OrderStatusIds.PRELIMINARY
  | OrderStatusIds.CANCELLED
  | OrderStatusIds.DELAYED
  | OrderStatusIds.DELAYED_DUE_TO_WAREHOUSE_STOCK
  | OrderStatusIds.DELAYED_DUE_TO_SUPPLIER
  | OrderStatusIds.FORECLOSURE
  | OrderStatusIds.FOR_PICKING
  | OrderStatusIds.PICKING_IN_PROGRESS
  | OrderStatusIds.FOR_CLARIFICATION
  | OrderStatusIds.CLARIFICATION_IN_PROGRESS
  | OrderStatusIds.FOR_INSPECTION
  | OrderStatusIds.INSPECTION_IN_PROGRESS
  | OrderStatusIds.FOR_SHIPPING
  | OrderStatusIds.SHIPPED
  | OrderStatusIds.RECEIVED_WITH_REMARKS
  | OrderStatusIds.DELIVERED
  | OrderStatusIds.AWAITING_PAYMENT
  | OrderStatusIds.FINISHED_SUCCESSFULLY
  | OrderStatusIds.FINISHED_UNSUCCESSFULLY;

export type OrderStatusKey =
  | OrderStatusKeys.PRELIMINARY
  | OrderStatusKeys.CANCELLED
  | OrderStatusKeys.DELAYED
  | OrderStatusKeys.DELAYED_DUE_TO_WAREHOUSE_STOCK
  | OrderStatusKeys.DELAYED_DUE_TO_SUPPLIER
  | OrderStatusKeys.FORECLOSURE
  | OrderStatusKeys.FOR_PICKING
  | OrderStatusKeys.PICKING_IN_PROGRESS
  | OrderStatusKeys.FOR_CLARIFICATION
  | OrderStatusKeys.CLARIFICATION_IN_PROGRESS
  | OrderStatusKeys.FOR_INSPECTION
  | OrderStatusKeys.INSPECTION_IN_PROGRESS
  | OrderStatusKeys.FOR_SHIPPING
  | OrderStatusKeys.SHIPPED
  | OrderStatusKeys.RECEIVED_WITH_REMARKS
  | OrderStatusKeys.DELIVERED
  | OrderStatusKeys.AWAITING_PAYMENT
  | OrderStatusKeys.FINISHED_SUCCESSFULLY
  | OrderStatusKeys.FINISHED_UNSUCCESSFULLY;

export type OrderStatusLabel =
  | OrderStatusLabels.PRELIMINARY
  | OrderStatusLabels.CANCELLED
  | OrderStatusLabels.DELAYED
  | OrderStatusLabels.DELAYED_DUE_TO_WAREHOUSE_STOCK
  | OrderStatusLabels.DELAYED_DUE_TO_SUPPLIER
  | OrderStatusLabels.FORECLOSURE
  | OrderStatusLabels.FOR_PICKING
  | OrderStatusLabels.PICKING_IN_PROGRESS
  | OrderStatusLabels.FOR_CLARIFICATION
  | OrderStatusLabels.CLARIFICATION_IN_PROGRESS
  | OrderStatusLabels.FOR_INSPECTION
  | OrderStatusLabels.INSPECTION_IN_PROGRESS
  | OrderStatusLabels.FOR_SHIPPING
  | OrderStatusLabels.SHIPPED
  | OrderStatusLabels.RECEIVED_WITH_REMARKS
  | OrderStatusLabels.DELIVERED
  | OrderStatusLabels.AWAITING_PAYMENT
  | OrderStatusLabels.FINISHED_SUCCESSFULLY
  | OrderStatusLabels.FINISHED_UNSUCCESSFULLY;

export class OrderStatus {
  static readonly PRELIMINARY = new OrderStatus(
    OrderStatusIds.PRELIMINARY,
    OrderStatusKeys.PRELIMINARY,
    OrderStatusLabels.PRELIMINARY,
    '#52FFFF'
  );
  static readonly CANCELLED = new OrderStatus(
    OrderStatusIds.CANCELLED,
    OrderStatusKeys.CANCELLED,
    OrderStatusLabels.CANCELLED,
    '#FF3300'
  );
  static readonly DELAYED = new OrderStatus(
    OrderStatusIds.DELAYED,
    OrderStatusKeys.DELAYED,
    OrderStatusLabels.DELAYED,
    '#52A8FF'
  );
  static readonly DELAYED_DUE_TO_WAREHOUSE_STOCK = new OrderStatus(
    OrderStatusIds.DELAYED_DUE_TO_WAREHOUSE_STOCK,
    OrderStatusKeys.DELAYED_DUE_TO_WAREHOUSE_STOCK,
    OrderStatusLabels.DELAYED_DUE_TO_WAREHOUSE_STOCK,
    '#52A8FF'
  );
  static readonly DELAYED_DUE_TO_SUPPLIER = new OrderStatus(
    OrderStatusIds.DELAYED_DUE_TO_SUPPLIER,
    OrderStatusKeys.DELAYED_DUE_TO_SUPPLIER,
    OrderStatusLabels.DELAYED_DUE_TO_SUPPLIER,
    '#52A8FF'
  );
  static readonly FORECLOSURE = new OrderStatus(
    OrderStatusIds.FORECLOSURE,
    OrderStatusKeys.FORECLOSURE,
    OrderStatusLabels.FORECLOSURE,
    '#FF3300'
  );
  static readonly FOR_PICKING = new OrderStatus(
    OrderStatusIds.FOR_PICKING,
    OrderStatusKeys.FOR_PICKING,
    OrderStatusLabels.FOR_PICKING,
    '#FFD452'
  );
  static readonly PICKING_IN_PROGRESS = new OrderStatus(
    OrderStatusIds.PICKING_IN_PROGRESS,
    OrderStatusKeys.PICKING_IN_PROGRESS,
    OrderStatusLabels.PICKING_IN_PROGRESS,
    '#FFD452'
  );
  static readonly FOR_CLARIFICATION = new OrderStatus(
    OrderStatusIds.FOR_CLARIFICATION,
    OrderStatusKeys.FOR_CLARIFICATION,
    OrderStatusLabels.FOR_CLARIFICATION,
    '#00CC33'
  );
  static readonly CLARIFICATION_IN_PROGRESS = new OrderStatus(
    OrderStatusIds.CLARIFICATION_IN_PROGRESS,
    OrderStatusKeys.CLARIFICATION_IN_PROGRESS,
    OrderStatusLabels.CLARIFICATION_IN_PROGRESS,
    '#00CC33'
  );
  static readonly FOR_INSPECTION = new OrderStatus(
    OrderStatusIds.FOR_INSPECTION,
    OrderStatusKeys.FOR_INSPECTION,
    OrderStatusLabels.FOR_INSPECTION,
    '#00CC00'
  );
  static readonly INSPECTION_IN_PROGRESS = new OrderStatus(
    OrderStatusIds.INSPECTION_IN_PROGRESS,
    OrderStatusKeys.INSPECTION_IN_PROGRESS,
    OrderStatusLabels.INSPECTION_IN_PROGRESS,
    '#00CC00'
  );
  static readonly FOR_SHIPPING = new OrderStatus(
    OrderStatusIds.FOR_SHIPPING,
    OrderStatusKeys.FOR_SHIPPING,
    OrderStatusLabels.FOR_SHIPPING,
    '#00CC66'
  );
  static readonly SHIPPED = new OrderStatus(
    OrderStatusIds.SHIPPED,
    OrderStatusKeys.SHIPPED,
    OrderStatusLabels.SHIPPED,
    '#00CC66'
  );
  static readonly RECEIVED_WITH_REMARKS = new OrderStatus(
    OrderStatusIds.RECEIVED_WITH_REMARKS,
    OrderStatusKeys.RECEIVED_WITH_REMARKS,
    OrderStatusLabels.RECEIVED_WITH_REMARKS,
    '#00BB00'
  );
  static readonly DELIVERED = new OrderStatus(
    OrderStatusIds.DELIVERED,
    OrderStatusKeys.DELIVERED,
    OrderStatusLabels.DELIVERED,
    '#00BB00'
  );
  static readonly AWAITING_PAYMENT = new OrderStatus(
    OrderStatusIds.AWAITING_PAYMENT,
    OrderStatusKeys.AWAITING_PAYMENT,
    OrderStatusLabels.AWAITING_PAYMENT,
    '#00АА00'
  );
  static readonly FINISHED_SUCCESSFULLY = new OrderStatus(
    OrderStatusIds.FINISHED_SUCCESSFULLY,
    OrderStatusKeys.FINISHED_SUCCESSFULLY,
    OrderStatusLabels.FINISHED_SUCCESSFULLY,
    '#008800'
  );
  static readonly FINISHED_UNSUCCESSFULLY = new OrderStatus(
    OrderStatusIds.FINISHED_UNSUCCESSFULLY,
    OrderStatusKeys.FINISHED_UNSUCCESSFULLY,
    OrderStatusLabels.FINISHED_UNSUCCESSFULLY,
    '#FF3300'
  );

  private constructor(
    public readonly id: OrderStatusId,
    public readonly key: OrderStatusKey,
    public readonly label: OrderStatusLabel,
    public readonly color: string
  ) {}

  toString() {
    return this.key + ' ' + this.label;
  }
}

export const orderStatuses: OrderStatus[] = [
  OrderStatus.PRELIMINARY,
  OrderStatus.CANCELLED,
  OrderStatus.DELAYED,
  OrderStatus.DELAYED_DUE_TO_WAREHOUSE_STOCK,
  OrderStatus.DELAYED_DUE_TO_SUPPLIER,
  OrderStatus.FORECLOSURE,
  OrderStatus.FOR_PICKING,
  OrderStatus.PICKING_IN_PROGRESS,
  OrderStatus.FOR_CLARIFICATION,
  OrderStatus.CLARIFICATION_IN_PROGRESS,
  OrderStatus.FOR_INSPECTION,
  OrderStatus.INSPECTION_IN_PROGRESS,
  OrderStatus.FOR_SHIPPING,
  OrderStatus.SHIPPED,
  OrderStatus.RECEIVED_WITH_REMARKS,
  OrderStatus.DELIVERED,
  OrderStatus.AWAITING_PAYMENT,
  OrderStatus.FINISHED_SUCCESSFULLY,
  OrderStatus.FINISHED_UNSUCCESSFULLY
];

const invalidType = {
  id: 0 as OrderTypeId,
  key: 'INVALID_TYPE' as OrderTypeKey,
  label: '<НЕВАЛИДЕН ТИП>'
};

const getOrderStatusById = (id: OrderStatusId) =>
  orderStatuses.find((status: OrderStatus) => status.id === id) || {
    id: 0 as OrderStatusId,
    key: 'INVALID_STATUS' as OrderStatusKey,
    label: '<НЕВАЛИДЕН СТАТУС>',
    color: '#BBBBBB'
  };

export const getOrderStatusByKey = (key: OrderStatusKey) =>
  orderStatuses.find((status: OrderStatus) => status.key === key) || {
    id: 0 as OrderStatusId,
    key: 'INVALID_STATUS' as OrderStatusKey,
    label: '<НЕВАЛИДЕН СТАТУС>',
    color: '#BBBBBB'
  };

export const getOrderTypeById = (id: OrderTypeId) =>
  orderTypes.find((type: OrderType) => type.id === id) || invalidType;

export const getOrderTypeByKey = (key: OrderTypeKey) =>
  orderTypes.find((type: OrderType) => type.key === key) || invalidType;

export const getOrderStatusKeyById = (id: OrderStatusId) => getOrderStatusById(id).key;

export const getOrderStatusIdByKey = (key: OrderStatusKey) => getOrderStatusByKey(key).id;

export const getOrderStatusLabelByKey = (key: OrderStatusKey) => getOrderStatusByKey(key).label;

export const getOrderTypeLabelById = (id: OrderTypeId) => getOrderTypeById(id).label;

export const getOrderTypeLabelByKey = (key: OrderTypeKey) => getOrderTypeByKey(key).label;

export enum OrderTypeIds {
  INTERNAL = 0,
  B2B = 1,
  B2C = 2
}

export enum OrderTypeKeys {
  INTERNAL = 'INTERNAL',
  B2B = 'EXTERNAL_B2B',
  B2C = 'EXTERNAL_B2C'
}

export enum OrderTypeLabels {
  INTERNAL = 'Вътрешен трансфер',
  B2B = 'B2B поръчка',
  B2C = 'B2C поръчка'
}

export enum OrderTypes {
  INTERNAL = 'INTERNAL',
  EXTERNAL_B2B = 'EXTERNAL_B2B',
  EXTERNAL_B2C = 'EXTERNAL_B2C'
}

export type OrderTypeId = OrderTypeIds.INTERNAL | OrderTypeIds.B2B | OrderTypeIds.B2C;

export type OrderTypeKey = OrderTypeKeys.INTERNAL | OrderTypeKeys.B2B | OrderTypeKeys.B2C;

export type OrderTypeLabel = OrderTypeLabels.INTERNAL | OrderTypeLabels.B2B | OrderTypeLabels.B2C;

export class OrderType {
  static readonly INTERNAL = new OrderType(
    OrderTypeIds.INTERNAL,
    OrderTypeKeys.INTERNAL,
    OrderTypeLabels.INTERNAL
  );
  static readonly B2B = new OrderType(OrderTypeIds.B2B, OrderTypeKeys.B2B, OrderTypeLabels.B2B);
  static readonly B2C = new OrderType(OrderTypeIds.B2C, OrderTypeKeys.B2C, OrderTypeLabels.B2C);

  private constructor(
    public readonly id: OrderTypeId,
    public readonly key: OrderTypeKey,
    public readonly label: OrderTypeLabel
  ) {}

  toString() {
    return this.key + ' ' + this.label;
  }
}

export const orderTypes: OrderType[] = [OrderType.INTERNAL, OrderType.B2B, OrderType.B2C];

export const orderTypeLookups: Lookup[] = [
  { id: OrderTypeIds.INTERNAL, value: OrderTypeLabels.INTERNAL },
  { id: OrderTypeIds.B2B, value: OrderTypeLabels.B2B },
  { id: OrderTypeIds.B2C, value: OrderTypeLabels.B2C }
];

export const documentTypeAliasMap = [
  'ПРИЕМО-ПРЕДАВАТЕЛЕН ПРОТОКОЛ',
  'ФАКТУРА',
  'КРЕДИТНО ИЗВЕСТИЕ'
];

export const deliveryDocumentTypeAliasMap = {
  PROFORMA: 'ПРОФОРМА',
  INVOICE: 'ФАКТУРА',
  CREDIT_NOTE: 'КРЕДИТНО ИЗВЕСТИЕ',
  0: 'ПРОФОРМА',
  1: 'ФАКТУРА',
  2: 'КРЕДИТНО ИЗВЕСТИЕ'
};

export enum DocumentTypes {
  ACT_OF_ACCEPTANCE = 0,
  INVOICE = 1,
  CREDIT_NOTE = 2
}

export enum DeliveryDocumentTypes {
  PROFORMA = 'PROFORMA',
  INVOICE = 'INVOICE',
  CREDIT_NOTE = 'CREDIT_NOTE'
}

export type DeliveryDocumentType = 'PROFORMA' | 'INVOICE' | 'CREDIT_NOTE';

export enum DeliveryRequestStatusKeys {
  DRAFT = 'DRAFT',
  REQUESTED = 'REQUESTED',
  DELAYED = 'DELAYED',
  FOR_INSPECTION = 'FOR_INSPECTION',
  INSPECTION_IN_PROGRESS = 'INSPECTION_IN_PROGRESS',
  INSPECTED = 'INSPECTED',
  INSPECTED_WITH_REMARKS = 'INSPECTED_WITH_REMARKS',
  VERIFIED = 'VERIFIED',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
  DECLINED_AFTER_VERIFICATION = 'DECLINED_AFTER_VERIFICATION'
}

export enum DeliveryRequestStatusLabels {
  DRAFT = 'Чернова',
  REQUESTED = 'Заявена',
  DELAYED = 'Забавена',
  FOR_INSPECTION = 'За проверка',
  INSPECTION_IN_PROGRESS = 'В процес на проверка',
  INSPECTED = 'Проверена',
  INSPECTED_WITH_REMARKS = 'Проверена, със забележки',
  VERIFIED = 'Потвърдена',
  DELIVERED = 'Доставена',
  CANCELLED = 'Отказана',
  DECLINED_AFTER_VERIFICATION = 'Отказана след проверка'
}

export type DeliveryRequestStatusKey =
  | DeliveryRequestStatusKeys.DRAFT
  | DeliveryRequestStatusKeys.REQUESTED
  | DeliveryRequestStatusKeys.DELAYED
  | DeliveryRequestStatusKeys.FOR_INSPECTION
  | DeliveryRequestStatusKeys.INSPECTION_IN_PROGRESS
  | DeliveryRequestStatusKeys.INSPECTED
  | DeliveryRequestStatusKeys.INSPECTED_WITH_REMARKS
  | DeliveryRequestStatusKeys.VERIFIED
  | DeliveryRequestStatusKeys.DELIVERED
  | DeliveryRequestStatusKeys.CANCELLED
  | DeliveryRequestStatusKeys.DECLINED_AFTER_VERIFICATION;

export type DeliveryRequestStatusLabel =
  | DeliveryRequestStatusLabels.DRAFT
  | DeliveryRequestStatusLabels.REQUESTED
  | DeliveryRequestStatusLabels.DELAYED
  | DeliveryRequestStatusLabels.FOR_INSPECTION
  | DeliveryRequestStatusLabels.INSPECTION_IN_PROGRESS
  | DeliveryRequestStatusLabels.INSPECTED
  | DeliveryRequestStatusLabels.INSPECTED_WITH_REMARKS
  | DeliveryRequestStatusLabels.VERIFIED
  | DeliveryRequestStatusLabels.DELIVERED
  | DeliveryRequestStatusLabels.CANCELLED
  | DeliveryRequestStatusLabels.DECLINED_AFTER_VERIFICATION;

export class DeliveryRequestStatus {
  static readonly DRAFT = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.DRAFT,
    DeliveryRequestStatusLabels.DRAFT,
    '#BBBBBB'
  );

  static readonly REQUESTED = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.REQUESTED,
    DeliveryRequestStatusLabels.REQUESTED,
    '#52FFFF'
  );
  static readonly DELAYED = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.DELAYED,
    DeliveryRequestStatusLabels.DELAYED,
    '#52A8FF'
  );
  static readonly FOR_INSPECTION = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.FOR_INSPECTION,
    DeliveryRequestStatusLabels.FOR_INSPECTION,
    '#FFD452'
  );
  static readonly INSPECTION_IN_PROGRESS = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.INSPECTION_IN_PROGRESS,
    DeliveryRequestStatusLabels.INSPECTION_IN_PROGRESS,
    '#FFD452'
  );
  static readonly INSPECTED = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.INSPECTED,
    DeliveryRequestStatusLabels.INSPECTED,
    '#00CC66'
  );
  static readonly INSPECTED_WITH_REMARKS = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.INSPECTED_WITH_REMARKS,
    DeliveryRequestStatusLabels.INSPECTED_WITH_REMARKS,
    '#00BB00'
  );
  static readonly VERIFIED = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.VERIFIED,
    DeliveryRequestStatusLabels.VERIFIED,
    '#00AA00'
  );
  static readonly DELIVERED = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.DELIVERED,
    DeliveryRequestStatusLabels.DELIVERED,
    '#009900'
  );
  static readonly CANCELLED = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.CANCELLED,
    DeliveryRequestStatusLabels.CANCELLED,
    '#FF3300'
  );
  static readonly DECLINED_AFTER_VERIFICATION = new DeliveryRequestStatus(
    DeliveryRequestStatusKeys.DECLINED_AFTER_VERIFICATION,
    DeliveryRequestStatusLabels.DECLINED_AFTER_VERIFICATION,
    '#FF3300'
  );

  private constructor(
    public readonly key: DeliveryRequestStatusKey,
    public readonly label: DeliveryRequestStatusLabel,
    public readonly color: string
  ) {}

  toString() {
    return this.key + ' ' + this.label;
  }
}

export const deliveryRequestStatuses: DeliveryRequestStatus[] = [
  DeliveryRequestStatus.DRAFT,
  DeliveryRequestStatus.REQUESTED,
  DeliveryRequestStatus.DELAYED,
  DeliveryRequestStatus.FOR_INSPECTION,
  DeliveryRequestStatus.INSPECTION_IN_PROGRESS,
  DeliveryRequestStatus.INSPECTED,
  DeliveryRequestStatus.INSPECTED_WITH_REMARKS,
  DeliveryRequestStatus.VERIFIED,
  DeliveryRequestStatus.DELIVERED,
  DeliveryRequestStatus.CANCELLED,
  DeliveryRequestStatus.DECLINED_AFTER_VERIFICATION
];

const invalidStatus = {
  key: 'INVALID_STATUS' as DeliveryRequestStatusKey,
  label: '<НЕВАЛИДЕН СТАТУС>' as DeliveryRequestStatusLabel,
  color: '#BBBBBB'
};

export const getDeliveryRequestStatusByKey = (
  key: DeliveryRequestStatusKey
): DeliveryRequestStatus =>
  deliveryRequestStatuses.find((status: DeliveryRequestStatus) => status.key === key) ||
  invalidStatus;

export const getDeliveryRequestStatusLabelByKey = (key: DeliveryRequestStatusKey) =>
  getDeliveryRequestStatusByKey(key).label;

export enum PricingPolicyItemExceptionTypeKeys {
  FIXED = 'FIXED',
  MULTIPLIER = 'MULTIPLIER'
}

export enum StocktakingTaskStatusKeys {
  ASSIGNED = 'ASSIGNED',
  IN_PROGRESS = 'IN_PROGRESS',
  PAUSED = 'PAUSED',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED'
}

export enum StocktakingTaskStatusLabels {
  ASSIGNED = 'Зададена',
  IN_PROGRESS = 'В процес',
  PAUSED = 'На пауза',
  DONE = 'Приключена',
  CANCELLED = 'Отказана'
}

export type StocktakingTaskStatusKey =
  | StocktakingTaskStatusKeys.ASSIGNED
  | StocktakingTaskStatusKeys.IN_PROGRESS
  | StocktakingTaskStatusKeys.PAUSED
  | StocktakingTaskStatusKeys.DONE
  | StocktakingTaskStatusKeys.CANCELLED;

export type StocktakingTaskStatusLabel =
  | StocktakingTaskStatusLabels.ASSIGNED
  | StocktakingTaskStatusLabels.IN_PROGRESS
  | StocktakingTaskStatusLabels.PAUSED
  | StocktakingTaskStatusLabels.DONE
  | StocktakingTaskStatusLabels.CANCELLED;

export class StocktakingTaskStatus {
  static readonly ASSIGNED = new StocktakingTaskStatus(
    StocktakingTaskStatusKeys.ASSIGNED,
    StocktakingTaskStatusLabels.ASSIGNED,
    '#52FFFF'
  );
  static readonly IN_PROGRESS = new StocktakingTaskStatus(
    StocktakingTaskStatusKeys.IN_PROGRESS,
    StocktakingTaskStatusLabels.IN_PROGRESS,
    '#FFD452'
  );
  static readonly PAUSED = new StocktakingTaskStatus(
    StocktakingTaskStatusKeys.PAUSED,
    StocktakingTaskStatusLabels.PAUSED,
    '#00CC66'
  );
  static readonly DONE = new StocktakingTaskStatus(
    StocktakingTaskStatusKeys.DONE,
    StocktakingTaskStatusLabels.DONE,
    '#00BB00'
  );
  static readonly CANCELLED = new StocktakingTaskStatus(
    StocktakingTaskStatusKeys.CANCELLED,
    StocktakingTaskStatusLabels.CANCELLED,
    '#FF3300'
  );

  private constructor(
    public readonly key: StocktakingTaskStatusKey,
    public readonly label: StocktakingTaskStatusLabel,
    public readonly color: string
  ) {}

  toString() {
    return this.key + ' ' + this.label;
  }
}

export const stocktakingTaskStatuses: StocktakingTaskStatus[] = [
  StocktakingTaskStatus.ASSIGNED,
  StocktakingTaskStatus.IN_PROGRESS,
  StocktakingTaskStatus.PAUSED,
  StocktakingTaskStatus.DONE,
  StocktakingTaskStatus.CANCELLED
];

export const getStocktakingTaskStatusByKey = (key: StocktakingTaskStatusKey) =>
  stocktakingTaskStatuses.find((status: StocktakingTaskStatus) => status.key === key) || {
    key: 'INVALID_STATUS' as StocktakingTaskStatusKey,
    label: '<НЕВАЛИДЕН СТАТУС>',
    color: '#BBBBBB'
  };

export const transactionTypesAliasMap = [
  'Статуси',
  'Приход по банка',
  'Приход в брой',
  'Разход по банка',
  'Разход в брой'
];

export enum TransactionType {
  BANK_ACCOUNT = 1,
  CASH = 2,
  EXPENSE_BANK_ACCOUNT = 3,
  EXPENSE_CASH = 4
}

export const transactionTypes = [
  {
    id: TransactionType.BANK_ACCOUNT,
    name: transactionTypesAliasMap[TransactionType.BANK_ACCOUNT]
  },
  { id: TransactionType.CASH, name: transactionTypesAliasMap[TransactionType.CASH] },
  {
    id: TransactionType.EXPENSE_BANK_ACCOUNT,
    name: transactionTypesAliasMap[TransactionType.EXPENSE_BANK_ACCOUNT]
  },
  { id: TransactionType.EXPENSE_CASH, name: transactionTypesAliasMap[TransactionType.EXPENSE_CASH] }
];

export const getTransactionName = (transactionId: number) =>
  transactionTypesAliasMap[transactionId];

export enum UnitEnum {
  Kilogram = 1,
  Piece = 2,
  Stack = 3,
  Bottle = 4,
  Box = 5,
  Set = 6,
  Package = 7,
  Jar = 8,
  PlasticBucket = 9,
  Canister = 10,
  WeightBarcode = 11,
  Other = 12
}

export const unitEnumLocalizations = {
  [UnitEnum.Kilogram]: 'Килограм',
  [UnitEnum.Piece]: 'Брой',
  [UnitEnum.Stack]: 'Кутия',
  [UnitEnum.Bottle]: 'Бутилка',
  [UnitEnum.Box]: 'Кашон',
  [UnitEnum.Set]: 'Комплект',
  [UnitEnum.Package]: 'Пакет',
  [UnitEnum.Jar]: 'Буркан',
  [UnitEnum.PlasticBucket]: 'Пластмасова кофа',
  [UnitEnum.Canister]: 'Бидон',
  [UnitEnum.WeightBarcode]: 'Теглови баркод',
  [UnitEnum.Other]: 'Друг'
};

export const unitLookups: Lookup[] = [
  { id: UnitEnum.Kilogram, value: unitEnumLocalizations[UnitEnum.Kilogram] },
  { id: UnitEnum.Piece, value: unitEnumLocalizations[UnitEnum.Piece] },
  { id: UnitEnum.Stack, value: unitEnumLocalizations[UnitEnum.Stack] },
  { id: UnitEnum.Bottle, value: unitEnumLocalizations[UnitEnum.Bottle] },
  { id: UnitEnum.Box, value: unitEnumLocalizations[UnitEnum.Box] },
  { id: UnitEnum.Set, value: unitEnumLocalizations[UnitEnum.Set] },
  { id: UnitEnum.Package, value: unitEnumLocalizations[UnitEnum.Package] },
  { id: UnitEnum.Jar, value: unitEnumLocalizations[UnitEnum.Jar] },
  { id: UnitEnum.PlasticBucket, value: unitEnumLocalizations[UnitEnum.PlasticBucket] },
  { id: UnitEnum.Canister, value: unitEnumLocalizations[UnitEnum.Canister] },
  { id: UnitEnum.WeightBarcode, value: unitEnumLocalizations[UnitEnum.WeightBarcode] },
  { id: UnitEnum.Other, value: unitEnumLocalizations[UnitEnum.Other] }
];

export enum TestConnectionResultKeys {
  SUCCESSFUL = 'SUCCESSFUL',
  HTTP_ERROR = 'HTTP_ERROR',
  MISSING_REQUIRED_CONFIGURATION = 'MISSING_REQUIRED_CONFIGURATION',
  INCORRECT_CREDENTIALS = 'INCORRECT_CREDENTIALS',
  DEPOT_NOT_FOUND = 'DEPOT_NOT_FOUND',
  SUPPLIER_NOT_FOUND = 'SUPPLIER_NOT_FOUND'
}

export enum TestConnectionResultLabels {
  SUCCESSFUL = 'Успешно',
  HTTP_ERROR = 'Грешка при връзка',
  MISSING_REQUIRED_CONFIGURATION = 'Липсваща конфигурация',
  INCORRECT_CREDENTIALS = 'Невалидни данни',
  DEPOT_NOT_FOUND = 'Не е намерен склад',
  SUPPLIER_NOT_FOUND = 'Не е намерен доставчик'
}

export const getTestConnectionLabelByKey = (key: TestConnectionResultKeys) => {
  switch (key) {
    case TestConnectionResultKeys.SUCCESSFUL:
      return TestConnectionResultLabels.SUCCESSFUL;
    case TestConnectionResultKeys.HTTP_ERROR:
      return TestConnectionResultLabels.HTTP_ERROR;
    case TestConnectionResultKeys.MISSING_REQUIRED_CONFIGURATION:
      return TestConnectionResultLabels.MISSING_REQUIRED_CONFIGURATION;
    case TestConnectionResultKeys.INCORRECT_CREDENTIALS:
      return TestConnectionResultLabels.INCORRECT_CREDENTIALS;
    case TestConnectionResultKeys.DEPOT_NOT_FOUND:
      return TestConnectionResultLabels.DEPOT_NOT_FOUND;
    case TestConnectionResultKeys.SUPPLIER_NOT_FOUND:
      return TestConnectionResultLabels.SUPPLIER_NOT_FOUND;
    default:
      return `<${key}>`;
  }
};

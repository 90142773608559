import { AMSPdfViewerDialog, AMSViewOnlyTextField } from '../../helpers/ui';
import {
  ActOfAcceptanceResponse,
  OrderTypeKey,
  OrderTypeKeys,
  amsV3Service,
  getOrderTypeLabelByKey
} from '../../services';
import { Button, CircularProgress, Container, Grid, Paper, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import ActOfAcceptanceItemsPanel from './ActOfAcceptanceItemsPanel';
import OutlinedDiv from '../../helpers/ui/OutlinedDiv';
import { getActOfAcceptanceUrl } from '../../helpers/utils';
import { parseQuery } from '../../helpers/url';
import { useActOfAcceptance } from '../../helpers/hooks';
import { useStyles } from '../../helpers/hooks';

interface ActOfAcceptanceMetaData {
  companyId: number;
  companyName: string;
  receiverId: number;
  receiverName: string;
  receiverLegalEntityId: number;
  receiverLegalEntityName: string;
  createdById: number;
  createdByName: string;
}

const ActOfAcceptanceComponent = ({ location }: { location: any }) => {
  const { id } = parseQuery(location.search);

  const classes = useStyles();

  const { actOfAcceptance, loading } = useActOfAcceptance(id);

  const [metaData, setMetaData] = useState<ActOfAcceptanceMetaData>();

  const loadMetaData = useCallback(
    async (actOfAcceptance: ActOfAcceptanceResponse) => {
      const isInternal = actOfAcceptance.orderType === OrderTypeKeys.INTERNAL;
      const [companyResp, receiverResp, receiverLegalEntityResp, createdByResp] = await Promise.all(
        [
          amsV3Service.getCompany(actOfAcceptance.companyId),
          isInternal
            ? amsV3Service.getWarehouse(actOfAcceptance.receiverId)
            : amsV3Service.getPartner(actOfAcceptance.receiverId),
          isInternal
            ? Promise.resolve({ data: { id: 0, name: '' } })
            : amsV3Service.getLegalEntity(actOfAcceptance.receiverLegalEntityId!),
          amsV3Service.getUser(actOfAcceptance.createdById)
        ]
      );
      if (
        companyResp.data &&
        receiverResp.data &&
        receiverLegalEntityResp.data &&
        createdByResp.data
      ) {
        setMetaData({
          companyId: companyResp.data.id,
          companyName: companyResp.data.name,
          receiverId: receiverResp.data.id,
          receiverName: receiverResp.data.name,
          receiverLegalEntityId: receiverLegalEntityResp.data.id,
          receiverLegalEntityName: receiverLegalEntityResp.data.name,
          createdById: createdByResp.data.id,
          createdByName: createdByResp.data.name
        });
      }
    },
    [setMetaData]
  );

  useEffect(() => {
    if (actOfAcceptance) {
      loadMetaData(actOfAcceptance);
    }
  }, [actOfAcceptance, loadMetaData]);

  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [openActOfAcceptancePreview, setOpenActOfAcceptancePreview] = useState(false);

  return loading ? (
    <CircularProgress />
  ) : (
    <Container maxWidth="xl" className={classes.container}>
      <Grid item lg={12}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setTitle(actOfAcceptance!.number);
            setUrl(getActOfAcceptanceUrl(actOfAcceptance!.id));
            setOpenActOfAcceptancePreview(true);
          }}
        >
          <Typography component="h5" variant="h5">
            {id && actOfAcceptance?.number}
          </Typography>
        </Button>
      </Grid>
      <Paper elevation={2} className={classes.root}>
        <OutlinedDiv label="Данни за протокола" disabled={true}>
          <Grid container spacing={1}>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <AMSViewOnlyTextField
                label="Дата на създаване"
                value={actOfAcceptance!.createdAt ?? 0}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <AMSViewOnlyTextField label="Компания" value={metaData?.companyName ?? ''} />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <AMSViewOnlyTextField label="Получатели" value={metaData?.receiverName ?? ''} />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <AMSViewOnlyTextField
                label="Юридическо лице"
                value={metaData?.receiverLegalEntityName ?? ''}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <AMSViewOnlyTextField label="Потребител" value={metaData?.createdByName ?? ''} />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <AMSViewOnlyTextField
                label="Тип на поръчката"
                value={getOrderTypeLabelByKey(actOfAcceptance?.orderType as OrderTypeKey)}
              />
            </Grid>
          </Grid>
        </OutlinedDiv>
      </Paper>
      <Grid item sm={12} lg={12}>
        <ActOfAcceptanceItemsPanel id={id} />
      </Grid>
      <AMSPdfViewerDialog
        title={title}
        url={url}
        open={openActOfAcceptancePreview}
        onClose={() => setOpenActOfAcceptancePreview(false)}
      />
    </Container>
  );
};

export default ActOfAcceptanceComponent;

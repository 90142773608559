import '@react-pdf-viewer/core/lib/styles/index.css';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';

import firebase from 'firebase/app';
import printJS from 'print-js';

interface AMSPdfViewerDialogProps {
  open: boolean;
  onClose?: () => void;
  title?: string;
  url: string;
}

const AMSPdfViewerDialog = ({ open, onClose, title, url }: AMSPdfViewerDialogProps) => {
  const [idtoken, setIdToken] = useState<string | null>(null);

  const loadIdToken = useCallback(async () => {
    const idToken: string | undefined = await firebase.auth().currentUser?.getIdToken(false);
    setIdToken(idToken ?? '');
  }, []);

  useEffect(() => {
    loadIdToken();
  }, [loadIdToken]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      // fullScreen={true}
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
      <DialogContent dividers={true}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <Viewer fileUrl={url} httpHeaders={{ Authorization: `Bearer ${idtoken}` }} />
        </Worker>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            const idToken: string | undefined =
              (await firebase.auth().currentUser?.getIdToken(false)) ?? '';
            const content = await fetch(url, {
              headers: {
                Authorization: `Bearer ${idToken}`
              }
            });
            var blob = await content.blob();
            var objectUrl = URL.createObjectURL(blob);
            printJS(objectUrl);
          }}
          color="primary"
          variant="outlined"
        >
          Печат
        </Button>
        <Button onClick={onClose} color="primary" variant="contained">
          Затвори
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AMSPdfViewerDialog;

/* tslint:disable */
/* eslint-disable */
/**
 * AMS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BatchOrderItemV2Response
 */
export interface BatchOrderItemV2Response {
    /**
     * 
     * @type {number}
     * @memberof BatchOrderItemV2Response
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof BatchOrderItemV2Response
     */
    'orderItemId': number;
    /**
     * 
     * @type {string}
     * @memberof BatchOrderItemV2Response
     */
    'artNo': string;
    /**
     * 
     * @type {string}
     * @memberof BatchOrderItemV2Response
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof BatchOrderItemV2Response
     */
    'batchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof BatchOrderItemV2Response
     */
    'expirationDate': string;
    /**
     * 
     * @type {number}
     * @memberof BatchOrderItemV2Response
     */
    'collected': number;
    /**
     * 
     * @type {number}
     * @memberof BatchOrderItemV2Response
     */
    'warehouseId': number;
    /**
     * 
     * @type {string}
     * @memberof BatchOrderItemV2Response
     */
    'warehouseName': string;
    /**
     * 
     * @type {number}
     * @memberof BatchOrderItemV2Response
     */
    'stockId': number;
}
/**
 * 
 * @export
 * @interface CompanyCategoryBase
 */
export interface CompanyCategoryBase {
    /**
     * 
     * @type {string}
     * @memberof CompanyCategoryBase
     */
    'name': string;
    /**
     * 
     * @type {Array<CompanyCategoryBase>}
     * @memberof CompanyCategoryBase
     */
    'children'?: Array<CompanyCategoryBase>;
}
/**
 * 
 * @export
 * @interface CompanyCategoryResponse
 */
export interface CompanyCategoryResponse {
    /**
     * 
     * @type {string}
     * @memberof CompanyCategoryResponse
     */
    'name': string;
    /**
     * 
     * @type {Array<CompanyCategoryResponse>}
     * @memberof CompanyCategoryResponse
     */
    'children'?: Array<CompanyCategoryResponse>;
    /**
     * 
     * @type {number}
     * @memberof CompanyCategoryResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyCategoryResponse
     */
    'parentId'?: number;
}
/**
 * 
 * @export
 * @interface CreateCompanyCategoryRequest
 */
export interface CreateCompanyCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyCategoryRequest
     */
    'name': string;
    /**
     * 
     * @type {Array<CompanyCategoryBase>}
     * @memberof CreateCompanyCategoryRequest
     */
    'children'?: Array<CompanyCategoryBase>;
    /**
     * 
     * @type {number}
     * @memberof CreateCompanyCategoryRequest
     */
    'parentId'?: number;
}
/**
 * 
 * @export
 * @interface CreateItemCategoryRequest
 */
export interface CreateItemCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateItemCategoryRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemCategoryRequest
     */
    'aliasId'?: string;
    /**
     * 
     * @type {Array<ItemCategoryBase>}
     * @memberof CreateItemCategoryRequest
     */
    'children'?: Array<ItemCategoryBase>;
    /**
     * 
     * @type {number}
     * @memberof CreateItemCategoryRequest
     */
    'parentId'?: number;
}
/**
 * 
 * @export
 * @interface CreateOrderItemV2Request
 */
export interface CreateOrderItemV2Request {
    /**
     * 
     * @type {number}
     * @memberof CreateOrderItemV2Request
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderItemV2Request
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderItemV2Request
     */
    'orderedQuantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderItemV2Request
     */
    'bonusQuantity'?: string;
}
/**
 * 
 * @export
 * @interface CreateOrderV2Request
 */
export interface CreateOrderV2Request {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderV2Request
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {Array<CreateOrderItemV2Request>}
     * @memberof CreateOrderV2Request
     */
    'items'?: Array<CreateOrderItemV2Request>;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderV2Request
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderV2Request
     */
    'orderPriority'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderV2Request
     */
    'orderType': number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderV2Request
     */
    'receiverId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderV2Request
     */
    'userResponsibleId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderV2Request
     */
    'warehouseId': number;
}
/**
 * 
 * @export
 * @interface CreatePricingPolicyRequest
 */
export interface CreatePricingPolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePricingPolicyRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePricingPolicyRequest
     */
    'companyId': number;
    /**
     * 
     * @type {Array<PricingPolicyItemCategoryRequest>}
     * @memberof CreatePricingPolicyRequest
     */
    'itemCategories': Array<PricingPolicyItemCategoryRequest>;
    /**
     * 
     * @type {Array<PricingPolicyItemExceptionRequest>}
     * @memberof CreatePricingPolicyRequest
     */
    'itemExceptions'?: Array<PricingPolicyItemExceptionRequest>;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface ItemCategoryBase
 */
export interface ItemCategoryBase {
    /**
     * 
     * @type {string}
     * @memberof ItemCategoryBase
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ItemCategoryBase
     */
    'aliasId'?: string;
    /**
     * 
     * @type {Array<ItemCategoryBase>}
     * @memberof ItemCategoryBase
     */
    'children'?: Array<ItemCategoryBase>;
}
/**
 * 
 * @export
 * @interface ItemCategoryResponse
 */
export interface ItemCategoryResponse {
    /**
     * 
     * @type {string}
     * @memberof ItemCategoryResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ItemCategoryResponse
     */
    'aliasId'?: string;
    /**
     * 
     * @type {Array<ItemCategoryResponse>}
     * @memberof ItemCategoryResponse
     */
    'children'?: Array<ItemCategoryResponse>;
    /**
     * 
     * @type {number}
     * @memberof ItemCategoryResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ItemCategoryResponse
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemCategoryResponse
     */
    'parentName'?: string;
}
/**
 * 
 * @export
 * @interface ItemMovementBalance
 */
export interface ItemMovementBalance {
    /**
     * 
     * @type {number}
     * @memberof ItemMovementBalance
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof ItemMovementBalance
     */
    'itemArtNo': string;
    /**
     * 
     * @type {string}
     * @memberof ItemMovementBalance
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof ItemMovementBalance
     */
    'quantity': string;
    /**
     * 
     * @type {string}
     * @memberof ItemMovementBalance
     */
    'itemNetPrice': string;
}
/**
 * 
 * @export
 * @interface LegalEntityOrdersForCollectionAmountResponse
 */
export interface LegalEntityOrdersForCollectionAmountResponse {
    /**
     * 
     * @type {string}
     * @memberof LegalEntityOrdersForCollectionAmountResponse
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface LegalEntityOrdersForDistributionAmountResponse
 */
export interface LegalEntityOrdersForDistributionAmountResponse {
    /**
     * 
     * @type {string}
     * @memberof LegalEntityOrdersForDistributionAmountResponse
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface LegalEntityOverduePaymentsAmountResponse
 */
export interface LegalEntityOverduePaymentsAmountResponse {
    /**
     * 
     * @type {string}
     * @memberof LegalEntityOverduePaymentsAmountResponse
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface LegalEntityPendingPaymentsAmountResponse
 */
export interface LegalEntityPendingPaymentsAmountResponse {
    /**
     * 
     * @type {string}
     * @memberof LegalEntityPendingPaymentsAmountResponse
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface OrderDocumentV2Response
 */
export interface OrderDocumentV2Response {
    /**
     * 
     * @type {number}
     * @memberof OrderDocumentV2Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderDocumentV2Response
     */
    'number': string;
    /**
     * 
     * @type {number}
     * @memberof OrderDocumentV2Response
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof OrderDocumentV2Response
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDocumentV2Response
     */
    'documentPath': string;
    /**
     * 
     * @type {number}
     * @memberof OrderDocumentV2Response
     */
    'totalAmount': number;
}
/**
 * 
 * @export
 * @interface OrderItemRequest
 */
export interface OrderItemRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderItemRequest
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemRequest
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemRequest
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemRequest
     */
    'priceOverride'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemRequest
     */
    'orderedQuantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemRequest
     */
    'bonusQuantity'?: string;
}
/**
 * 
 * @export
 * @interface OrderItemV2Response
 */
export interface OrderItemV2Response {
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemV2Response
     */
    'orderedQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemV2Response
     */
    'bonusQuantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemV2Response
     */
    'collected': string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'receivedQuantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemV2Response
     */
    'artNo': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemV2Response
     */
    'barcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemV2Response
     */
    'additionalBarcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemV2Response
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemV2Response
     */
    'unitName': string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'packageQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'weight': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'livePrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'liveTotalPrice'?: number;
    /**
     * 
     * @type {Array<BatchOrderItemV2Response>}
     * @memberof OrderItemV2Response
     */
    'batchItems': Array<BatchOrderItemV2Response>;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'availableQuantity'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemV2Response
     */
    'stockSelectable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemV2Response
     */
    'isOwnedByUs': boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderItemV2Response
     */
    'minimumAllowedDaysToExpiration': number;
}
/**
 * 
 * @export
 * @interface OrderStatusLogResponse
 */
export interface OrderStatusLogResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderStatusLogResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderStatusLogResponse
     */
    'orderId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderStatusLogResponse
     */
    'statusKey': string;
    /**
     * 
     * @type {string}
     * @memberof OrderStatusLogResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof OrderStatusLogResponse
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderStatusLogResponse
     */
    'userName': string;
}
/**
 * 
 * @export
 * @interface OrderStatusUpdateRequest
 */
export interface OrderStatusUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderStatusUpdateRequest
     */
    'orderId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderStatusUpdateRequest
     */
    'statusKey': string;
}
/**
 * 
 * @export
 * @interface OrderTransactionResponse
 */
export interface OrderTransactionResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderTransactionResponse
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof OrderTransactionResponse
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof OrderTransactionResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderTransactionResponse
     */
    'invoiceNumber': string;
    /**
     * 
     * @type {number}
     * @memberof OrderTransactionResponse
     */
    'transactionType': number;
    /**
     * 
     * @type {string}
     * @memberof OrderTransactionResponse
     */
    'treasuryName': string;
}
/**
 * 
 * @export
 * @interface OrderV2Response
 */
export interface OrderV2Response {
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'orderType': number;
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'orderPriority': number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'orderTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'statusKey': string;
    /**
     * 
     * @type {Array<OrderItemV2Response>}
     * @memberof OrderV2Response
     */
    'items'?: Array<OrderItemV2Response>;
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'totalPrice': number;
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'packageQty'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'note'?: string;
    /**
     * 
     * @type {UserV2Response}
     * @memberof OrderV2Response
     */
    'userResponsible': UserV2Response;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'addressTextField'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderV2Response
     */
    'allowedSubsequentStatuses': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof OrderV2Response
     */
    'inProgress'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'companyNote'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'warehouseId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'warehouseName'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'receiverId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'receiverName'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'receiverPaymentOptionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'receiverPaymentOptionInfo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderV2Response
     */
    'receiverShouldPayOnDelivery'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'invoiceNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderV2Response
     */
    'isInternal'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderV2Response
     */
    'executionPercentage'?: string;
    /**
     * 
     * @type {Array<OrderTransactionResponse>}
     * @memberof OrderV2Response
     */
    'transactions'?: Array<OrderTransactionResponse>;
    /**
     * 
     * @type {number}
     * @memberof OrderV2Response
     */
    'invoiceRemainingAmount': number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderV2Response
     */
    'shouldCollectOnDeliveryDate': boolean;
}
/**
 * 
 * @export
 * @interface OrderingPolicyResponse
 */
export interface OrderingPolicyResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderingPolicyResponse
     */
    'allowedWeekdays': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrderingPolicyResponse
     */
    'nextDayDeliveryCutoff': string;
}
/**
 * 
 * @export
 * @interface PartnerItemCategoryResponse
 */
export interface PartnerItemCategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof PartnerItemCategoryResponse
     */
    'partnerId': number;
    /**
     * 
     * @type {number}
     * @memberof PartnerItemCategoryResponse
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof PartnerItemCategoryResponse
     */
    'itemCategoryParentId'?: number;
}
/**
 * 
 * @export
 * @interface PermissionResponse
 */
export interface PermissionResponse {
    /**
     * 
     * @type {string}
     * @memberof PermissionResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PermissionResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface PricingPolicyItemCategoryRequest
 */
export interface PricingPolicyItemCategoryRequest {
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyItemCategoryRequest
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyItemCategoryRequest
     */
    'multiplier': string;
}
/**
 * 
 * @export
 * @interface PricingPolicyItemCategoryResponse
 */
export interface PricingPolicyItemCategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyItemCategoryResponse
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyItemCategoryResponse
     */
    'multiplier': string;
}
/**
 * 
 * @export
 * @interface PricingPolicyItemExceptionRequest
 */
export interface PricingPolicyItemExceptionRequest {
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyItemExceptionRequest
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyItemExceptionRequest
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyItemExceptionRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface PricingPolicyItemExceptionResponse
 */
export interface PricingPolicyItemExceptionResponse {
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyItemExceptionResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyItemExceptionResponse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyItemExceptionResponse
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface PricingPolicyResponse
 */
export interface PricingPolicyResponse {
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyResponse
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyResponse
     */
    'assignedPartnersNames'?: string;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyResponse
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PricingPolicyResponse
     */
    'companyNote'?: string;
}
/**
 * 
 * @export
 * @interface PrintDocumentQueueResponse
 */
export interface PrintDocumentQueueResponse {
    /**
     * 
     * @type {number}
     * @memberof PrintDocumentQueueResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PrintDocumentQueueResponse
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof PrintDocumentQueueResponse
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof PrintDocumentQueueResponse
     */
    'printer': string;
    /**
     * 
     * @type {number}
     * @memberof PrintDocumentQueueResponse
     */
    'createdById': number;
    /**
     * 
     * @type {string}
     * @memberof PrintDocumentQueueResponse
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof PrintDocumentQueueResponse
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface ReportDeliveryDocumentResponse
 */
export interface ReportDeliveryDocumentResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryDocumentResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryDocumentResponse
     */
    'deliveryId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryDocumentResponse
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryDocumentResponse
     */
    'paymentDue': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryDocumentResponse
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryDocumentResponse
     */
    'senderName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryDocumentResponse
     */
    'receiverName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryDocumentResponse
     */
    'totalIncludingVat': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryDocumentResponse
     */
    'paymentOption': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryDocumentResponse
     */
    'remainingAmount': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryDocumentResponse
     */
    'deliveryDocumentType': number;
}
/**
 * 
 * @export
 * @interface ReportDeliveryItemByOrdersResponse
 */
export interface ReportDeliveryItemByOrdersResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'itemArtNo': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'itemName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'itemAvailableQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'orderRequiredQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'stockBalance': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'supplierId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'supplierSubscriptionEmail'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportDeliveryItemByOrdersResponse
     */
    'supplierHasSubscription': boolean;
}
/**
 * 
 * @export
 * @interface ReportDeliveryItemExtendedResponse
 */
export interface ReportDeliveryItemExtendedResponse {
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'companyNote': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'warehouseName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemArtNo': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemBarcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemAdditionalBarcodeValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemPrice': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemExpirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemBatchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemCountry': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemAvailableQuantity': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'deliveryId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'deliveryDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'supplierName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'deliveryNote'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemQuantity': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDeliveryItemExtendedResponse
     */
    'itemCategoryName'?: string;
}
/**
 * 
 * @export
 * @interface ReportDepletedAmountWithPrices
 */
export interface ReportDepletedAmountWithPrices {
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'orderId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPrices
     */
    'orderDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPrices
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'receiverId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPrices
     */
    'receiverName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'companyId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'warehouseId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPrices
     */
    'warehouseName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'deliveryId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'supplierId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPrices
     */
    'supplierName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPrices
     */
    'itemCategoryName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportDepletedAmountWithPrices
     */
    'itemName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'itemQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'deliveryItemPrice': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'profitRatio': number;
    /**
     * 
     * @type {number}
     * @memberof ReportDepletedAmountWithPrices
     */
    'orderItemPrice': number;
}
/**
 * 
 * @export
 * @interface ReportOrderBalanceResponse
 */
export interface ReportOrderBalanceResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportOrderBalanceResponse
     */
    'orderType': number;
    /**
     * 
     * @type {number}
     * @memberof ReportOrderBalanceResponse
     */
    'receiverId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportOrderBalanceResponse
     */
    'receiverName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportOrderBalanceResponse
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportOrderBalanceResponse
     */
    'companyName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportOrderBalanceResponse
     */
    'warehouseId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportOrderBalanceResponse
     */
    'warehouseName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportOrderBalanceResponse
     */
    'totalAmount': number;
}
/**
 * 
 * @export
 * @interface ReportPricingPolicyItemCategoryMultipliersListResponse
 */
export interface ReportPricingPolicyItemCategoryMultipliersListResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportPricingPolicyItemCategoryMultipliersListResponse
     */
    'pricingPolicyIds': Array<number>;
    /**
     * 
     * @type {Array<ReportPricingPolicyItemCategoryMultipliersResponse>}
     * @memberof ReportPricingPolicyItemCategoryMultipliersListResponse
     */
    'values': Array<ReportPricingPolicyItemCategoryMultipliersResponse>;
}
/**
 * 
 * @export
 * @interface ReportPricingPolicyItemCategoryMultipliersResponse
 */
export interface ReportPricingPolicyItemCategoryMultipliersResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportPricingPolicyItemCategoryMultipliersResponse
     */
    'itemCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportPricingPolicyItemCategoryMultipliersResponse
     */
    'itemCategoryName': string;
    /**
     * 
     * @type {Array<ReportPricingPolicyValueResponse>}
     * @memberof ReportPricingPolicyItemCategoryMultipliersResponse
     */
    'values': Array<ReportPricingPolicyValueResponse>;
}
/**
 * 
 * @export
 * @interface ReportPricingPolicyItemPricesListResponse
 */
export interface ReportPricingPolicyItemPricesListResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportPricingPolicyItemPricesListResponse
     */
    'pricingPolicyIds': Array<number>;
    /**
     * 
     * @type {Array<ReportPricingPolicyItemPricesResponse>}
     * @memberof ReportPricingPolicyItemPricesListResponse
     */
    'values': Array<ReportPricingPolicyItemPricesResponse>;
}
/**
 * 
 * @export
 * @interface ReportPricingPolicyItemPricesResponse
 */
export interface ReportPricingPolicyItemPricesResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportPricingPolicyItemPricesResponse
     */
    'itemId': number;
    /**
     * 
     * @type {Array<ReportPricingPolicyValueResponse>}
     * @memberof ReportPricingPolicyItemPricesResponse
     */
    'values': Array<ReportPricingPolicyValueResponse>;
}
/**
 * 
 * @export
 * @interface ReportPricingPolicyValueResponse
 */
export interface ReportPricingPolicyValueResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportPricingPolicyValueResponse
     */
    'pricingPolicyId': number;
    /**
     * 
     * @type {number}
     * @memberof ReportPricingPolicyValueResponse
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface ReportWarehouseReserveItemResponse
 */
export interface ReportWarehouseReserveItemResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportWarehouseReserveItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportWarehouseReserveItemResponse
     */
    'itemArtNo': string;
    /**
     * 
     * @type {string}
     * @memberof ReportWarehouseReserveItemResponse
     */
    'itemName': string;
    /**
     * 
     * @type {number}
     * @memberof ReportWarehouseReserveItemResponse
     */
    'availableQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof ReportWarehouseReserveItemResponse
     */
    'requiredQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof ReportWarehouseReserveItemResponse
     */
    'deliveryRequestQuantity': number;
}
/**
 * 
 * @export
 * @interface ReportWarehouseReserveResponse
 */
export interface ReportWarehouseReserveResponse {
    /**
     * 
     * @type {number}
     * @memberof ReportWarehouseReserveResponse
     */
    'supplierId': number;
    /**
     * 
     * @type {string}
     * @memberof ReportWarehouseReserveResponse
     */
    'supplierSubscriptionEmail': string;
    /**
     * 
     * @type {Array<ReportWarehouseReserveItemResponse>}
     * @memberof ReportWarehouseReserveResponse
     */
    'items': Array<ReportWarehouseReserveItemResponse>;
}
/**
 * 
 * @export
 * @interface ReturnOrderItemV2Request
 */
export interface ReturnOrderItemV2Request {
    /**
     * 
     * @type {number}
     * @memberof ReturnOrderItemV2Request
     */
    'orderItemId': number;
    /**
     * 
     * @type {number}
     * @memberof ReturnOrderItemV2Request
     */
    'depletedAmountId': number;
    /**
     * 
     * @type {number}
     * @memberof ReturnOrderItemV2Request
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface ReturnOrderItemsV2Response
 */
export interface ReturnOrderItemsV2Response {
    /**
     * 
     * @type {number}
     * @memberof ReturnOrderItemsV2Response
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof ReturnOrderItemsV2Response
     */
    'creditNoteId'?: number;
}
/**
 * 
 * @export
 * @interface RoleResponse
 */
export interface RoleResponse {
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof RoleResponse
     */
    'id': number;
    /**
     * 
     * @type {Array<UserSimpleResponse>}
     * @memberof RoleResponse
     */
    'users'?: Array<UserSimpleResponse>;
    /**
     * 
     * @type {Array<PermissionResponse>}
     * @memberof RoleResponse
     */
    'permissions'?: Array<PermissionResponse>;
}
/**
 * 
 * @export
 * @interface SetOrderingPolicyRequest
 */
export interface SetOrderingPolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof SetOrderingPolicyRequest
     */
    'allowedWeekdays': string;
    /**
     * 
     * @type {string}
     * @memberof SetOrderingPolicyRequest
     */
    'nextDayDeliveryCutoff': string;
}
/**
 * 
 * @export
 * @interface StockTakingReportItemResponse
 */
export interface StockTakingReportItemResponse {
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportItemResponse
     */
    'stockTakingReportId': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof StockTakingReportItemResponse
     */
    'itemName': string;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportItemResponse
     */
    'expectedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportItemResponse
     */
    'countedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportItemResponse
     */
    'confirmedQuantity': number;
}
/**
 * 
 * @export
 * @interface StockTakingReportResponse
 */
export interface StockTakingReportResponse {
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportResponse
     */
    'stockTakingId': number;
    /**
     * 
     * @type {string}
     * @memberof StockTakingReportResponse
     */
    'executionDate': string;
    /**
     * 
     * @type {string}
     * @memberof StockTakingReportResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof StockTakingReportResponse
     */
    'userResponsibleId': number;
    /**
     * 
     * @type {string}
     * @memberof StockTakingReportResponse
     */
    'userResponsibleName': string;
    /**
     * 
     * @type {Array<StockTakingReportItemResponse>}
     * @memberof StockTakingReportResponse
     */
    'items'?: Array<StockTakingReportItemResponse>;
}
/**
 * 
 * @export
 * @interface StockTakingTaskItemResponse
 */
export interface StockTakingTaskItemResponse {
    /**
     * 
     * @type {number}
     * @memberof StockTakingTaskItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingTaskItemResponse
     */
    'stockTakingTaskId': number;
    /**
     * 
     * @type {number}
     * @memberof StockTakingTaskItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskItemResponse
     */
    'lastModifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskItemResponse
     */
    'artNo': string;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskItemResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskItemResponse
     */
    'unitName': string;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskItemResponse
     */
    'barcodeValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskItemResponse
     */
    'additionalBarcodeValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof StockTakingTaskItemResponse
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface StockTakingTaskResponse
 */
export interface StockTakingTaskResponse {
    /**
     * 
     * @type {number}
     * @memberof StockTakingTaskResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskResponse
     */
    'status': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StockTakingTaskResponse
     */
    'allowedSubsequentStatuses': Array<string>;
    /**
     * 
     * @type {Array<StockTakingTaskItemResponse>}
     * @memberof StockTakingTaskResponse
     */
    'items': Array<StockTakingTaskItemResponse>;
    /**
     * 
     * @type {number}
     * @memberof StockTakingTaskResponse
     */
    'userResponsibleId': number;
    /**
     * 
     * @type {string}
     * @memberof StockTakingTaskResponse
     */
    'userResponsibleName': string;
}
/**
 * 
 * @export
 * @interface StocktakingItemResponse
 */
export interface StocktakingItemResponse {
    /**
     * 
     * @type {number}
     * @memberof StocktakingItemResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StocktakingItemResponse
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof StocktakingItemResponse
     */
    'itemName': string;
    /**
     * 
     * @type {string}
     * @memberof StocktakingItemResponse
     */
    'itemArtNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof StocktakingItemResponse
     */
    'itemUnitName'?: string;
}
/**
 * 
 * @export
 * @interface StocktakingRequest
 */
export interface StocktakingRequest {
    /**
     * 
     * @type {number}
     * @memberof StocktakingRequest
     */
    'warehouseId': number;
    /**
     * 
     * @type {number}
     * @memberof StocktakingRequest
     */
    'userId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof StocktakingRequest
     */
    'items': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof StocktakingRequest
     */
    'executionDate': string;
}
/**
 * 
 * @export
 * @interface StocktakingResponse
 */
export interface StocktakingResponse {
    /**
     * 
     * @type {number}
     * @memberof StocktakingResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof StocktakingResponse
     */
    'warehouseId': number;
    /**
     * 
     * @type {string}
     * @memberof StocktakingResponse
     */
    'warehouseName': string;
    /**
     * 
     * @type {Array<StocktakingItemResponse>}
     * @memberof StocktakingResponse
     */
    'items': Array<StocktakingItemResponse>;
    /**
     * 
     * @type {string}
     * @memberof StocktakingResponse
     */
    'executionDate': string;
}
/**
 * 
 * @export
 * @interface UpdateCompanyCategoryRequest
 */
export interface UpdateCompanyCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyCategoryRequest
     */
    'name'?: string;
    /**
     * 
     * @type {Array<UpdateCompanyCategoryRequest>}
     * @memberof UpdateCompanyCategoryRequest
     */
    'children'?: Array<UpdateCompanyCategoryRequest>;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyCategoryRequest
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyCategoryRequest
     */
    'parentId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateItemCategoryRequest
 */
export interface UpdateItemCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateItemCategoryRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateItemCategoryRequest
     */
    'aliasId'?: string;
    /**
     * 
     * @type {Array<ItemCategoryBase>}
     * @memberof UpdateItemCategoryRequest
     */
    'children'?: Array<ItemCategoryBase>;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemCategoryRequest
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemCategoryRequest
     */
    'parentId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateOrderV2Request
 */
export interface UpdateOrderV2Request {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderV2Request
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderV2Request
     */
    'packageQty'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderV2Request
     */
    'userResponsibleId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderV2Request
     */
    'orderPriority'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderV2Request
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderV2Request
     */
    'statusKey'?: string;
    /**
     * 
     * @type {Array<OrderItemRequest>}
     * @memberof UpdateOrderV2Request
     */
    'items'?: Array<OrderItemRequest>;
}
/**
 * 
 * @export
 * @interface UpdatePricingPolicyRequest
 */
export interface UpdatePricingPolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePricingPolicyRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePricingPolicyRequest
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePricingPolicyRequest
     */
    'id': number;
    /**
     * 
     * @type {Array<PricingPolicyItemCategoryRequest>}
     * @memberof UpdatePricingPolicyRequest
     */
    'itemCategories': Array<PricingPolicyItemCategoryRequest>;
    /**
     * 
     * @type {Array<PricingPolicyItemExceptionRequest>}
     * @memberof UpdatePricingPolicyRequest
     */
    'itemExceptions'?: Array<PricingPolicyItemExceptionRequest>;
}
/**
 * 
 * @export
 * @interface UpdatePrintDocumentQueueRequest
 */
export interface UpdatePrintDocumentQueueRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePrintDocumentQueueRequest
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePrintDocumentQueueRequest
     */
    'printer'?: string;
}
/**
 * 
 * @export
 * @interface UserAccessResponse
 */
export interface UserAccessResponse {
    /**
     * 
     * @type {number}
     * @memberof UserAccessResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    'lastName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAccessResponse
     */
    'groups'?: Array<string>;
    /**
     * 
     * @type {Array<RoleResponse>}
     * @memberof UserAccessResponse
     */
    'roles'?: Array<RoleResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAccessResponse
     */
    'permissions'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserAccessResponse
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponse
     */
    'companyNote': string;
}
/**
 * 
 * @export
 * @interface UserSimpleResponse
 */
export interface UserSimpleResponse {
    /**
     * 
     * @type {number}
     * @memberof UserSimpleResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserSimpleResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserSimpleResponse
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface UserV2Response
 */
export interface UserV2Response {
    /**
     * 
     * @type {number}
     * @memberof UserV2Response
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserV2Response
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserV2Response
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationError
     */
    'loc': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}

/**
 * AMSApi - axios parameter creator
 * @export
 */
export const AMSApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderV2Request} createOrderV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createOrderV2OrdersPost: async (createOrderV2Request: CreateOrderV2Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderV2Request' is not null or undefined
            assertParamExists('createOrderV2OrdersPost', 'createOrderV2Request', createOrderV2Request)
            const localVarPath = `/v2/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Stocktaking
         * @param {StocktakingRequest} stocktakingRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createStocktakingV2StocktakingsPost: async (stocktakingRequest: StocktakingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stocktakingRequest' is not null or undefined
            assertParamExists('createStocktakingV2StocktakingsPost', 'stocktakingRequest', stocktakingRequest)
            const localVarPath = `/v2/stocktakings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stocktakingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createV1CompanyCategoriesPost: async (createCompanyCategoryRequest: CreateCompanyCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyCategoryRequest' is not null or undefined
            assertParamExists('createV1CompanyCategoriesPost', 'createCompanyCategoryRequest', createCompanyCategoryRequest)
            const localVarPath = `/v1/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {CreateItemCategoryRequest} createItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createV1ItemCategoriesPost: async (createItemCategoryRequest: CreateItemCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createItemCategoryRequest' is not null or undefined
            assertParamExists('createV1ItemCategoriesPost', 'createItemCategoryRequest', createItemCategoryRequest)
            const localVarPath = `/v1/item-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createItemCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create
         * @param {CreatePricingPolicyRequest} createPricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createV1PricingPoliciesPost: async (createPricingPolicyRequest: CreatePricingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPricingPolicyRequest' is not null or undefined
            assertParamExists('createV1PricingPoliciesPost', 'createPricingPolicyRequest', createPricingPolicyRequest)
            const localVarPath = `/v1/pricing-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPricingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteV1CompanyCategoriesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1CompanyCategoriesIdDelete', 'id', id)
            const localVarPath = `/v1/company-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteV1ItemCategoriesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1ItemCategoriesIdDelete', 'id', id)
            const localVarPath = `/v1/item-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteV1PricingPoliciesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1PricingPoliciesIdDelete', 'id', id)
            const localVarPath = `/v1/pricing-policies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMeV1MeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Orders For Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/orders-for-collection/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Orders For Distribution Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/orders-for-distribution/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Overdue Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/overdue-payments/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Pending Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/pending-payments/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Stock Taking Report Items
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getStockTakingReportItemsV2StockTakingReportItemsGet: async (itemId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/stock-taking-report-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Stock Taking Reports
         * @param {string} [fromExecutionDate] 
         * @param {string} [toExecutionDate] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getStockTakingReportsV2StockTakingReportsGet: async (fromExecutionDate?: string, toExecutionDate?: string, userId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/stock-taking-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromExecutionDate !== undefined) {
                localVarQueryParameter['from_execution_date'] = fromExecutionDate;
            }

            if (toExecutionDate !== undefined) {
                localVarQueryParameter['to_execution_date'] = toExecutionDate;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        healthCheckV1HealthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {boolean} [withItems] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        listOrdersV2OrdersGet: async (status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (noInvoiceOnly !== undefined) {
                localVarQueryParameter['no_invoice_only'] = noInvoiceOnly;
            }

            if (withExecutionPercentage !== undefined) {
                localVarQueryParameter['with_execution_percentage'] = withExecutionPercentage;
            }

            if (withItems !== undefined) {
                localVarQueryParameter['with_items'] = withItems;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Aggregated Item Movements
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [partners] 
         * @param {Array<number>} [warehouses] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAggregatedItemMovementsV2ReportsItemMovementsBalanceGet: async (fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/reports/item-movements-balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (partners) {
                localVarQueryParameter['partners'] = partners;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All Flat
         * @param {boolean} [leafOnly] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllFlatV1ItemCategoriesFlatGet: async (leafOnly?: boolean, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/item-categories/flat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (leafOnly !== undefined) {
                localVarQueryParameter['leaf_only'] = leafOnly;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllV1CompanyCategoriesGet: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [simple] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllV1ItemCategoriesGet: async (skip?: number, limit?: number, simple?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/item-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (simple !== undefined) {
                localVarQueryParameter['simple'] = simple;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents/delivery-batch`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {boolean} [hidePaid] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryInvoicesV1ReportsDeliveryInvoiceGet: async (fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/delivery-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (suppliers !== undefined) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (hidePaid !== undefined) {
                localVarQueryParameter['hide_paid'] = hidePaid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [supplierId] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryItemsV1ReportsDeliveryItemsGet: async (fromDate?: string, toDate?: string, supplierId?: number, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/delivery-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplier_id'] = supplierId;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Depleted Amounts With Prices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receviers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet: async (fromDate?: string, toDate?: string, receviers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/depleted-amounts-with-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (receviers !== undefined) {
                localVarQueryParameter['receviers'] = receviers;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readListV1PricingPoliciesGet: async (search?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pricing-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order By Order Id
         * @param {number} orderId 
         * @param {boolean} [withLivePrices] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderByOrderIdV2OrdersOrderIdGet: async (orderId: number, withLivePrices?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderByOrderIdV2OrdersOrderIdGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (withLivePrices !== undefined) {
                localVarQueryParameter['with_live_prices'] = withLivePrices;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdDocumentsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderDocumentsV2OrdersOrderIdDocumentsGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdStatusLogsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderDocumentsV2OrdersOrderIdStatusLogsGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/status-logs`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Transactions
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderTransactionsV2OrdersOrderIdTransactionsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderTransactionsV2OrdersOrderIdTransactionsGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/transactions`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/ordering-policy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Orders Balance
         * @param {string} [statusKeys] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrdersBalanceV1ReportsOrdersBalanceGet: async (statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/orders-balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (statusKeys !== undefined) {
                localVarQueryParameter['status_keys'] = statusKeys;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (noInvoiceOnly !== undefined) {
                localVarQueryParameter['no_invoice_only'] = noInvoiceOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet', 'id', id)
            const localVarPath = `/v1/pricing-policies/{id}/item-categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Category Multipliers
         * @param {string} itemCategories 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet: async (itemCategories: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemCategories' is not null or undefined
            assertParamExists('readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet', 'itemCategories', itemCategories)
            const localVarPath = `/v1/reports/pricing-policy-item-category-multipliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Exceptions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet', 'id', id)
            const localVarPath = `/v1/pricing-policies/{id}/item-exceptions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Prices
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet: async (items: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'items' is not null or undefined
            assertParamExists('readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet', 'items', items)
            const localVarPath = `/v1/reports/pricing-policy-item-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Print Document Queue
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPrintDocumentQueueV2PrintDocumentQueueGet: async (state?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/print-document-queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Quantities To Fill Up Warehouse Reserve
         * @param {number} warehouseId 
         * @param {number} [baseDays] 
         * @param {number} [reserveDays] 
         * @param {number} [supplierId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet: async (warehouseId: number, baseDays?: number, reserveDays?: number, supplierId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet', 'warehouseId', warehouseId)
            const localVarPath = `/v1/reports/warehouse-reserve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (baseDays !== undefined) {
                localVarQueryParameter['base_days'] = baseDays;
            }

            if (reserveDays !== undefined) {
                localVarQueryParameter['reserve_days'] = reserveDays;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplier_id'] = supplierId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stock Taking Report
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStockTakingReportV2StockTakingReportsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStockTakingReportV2StockTakingReportsIdGet', 'id', id)
            const localVarPath = `/v2/stock-taking-reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stocktaking Tasks
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStocktakingTasksV2StocktakingsIdTasksGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStocktakingTasksV2StocktakingsIdTasksGet', 'id', id)
            const localVarPath = `/v2/stocktakings/{id}/tasks`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stocktaking
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStocktakingV2StocktakingsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStocktakingV2StocktakingsIdGet', 'id', id)
            const localVarPath = `/v2/stocktakings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stocktakings
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [warehouses] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStocktakingsV2StocktakingsGet: async (fromDate?: string, toDate?: string, warehouses?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/stocktakings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1CompanyCategoriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readV1CompanyCategoriesIdGet', 'id', id)
            const localVarPath = `/v1/company-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1ItemCategoriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readV1ItemCategoriesIdGet', 'id', id)
            const localVarPath = `/v1/item-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1PartnersPartnerIdItemCategoryAssociationGet: async (partnerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('readV1PartnersPartnerIdItemCategoryAssociationGet', 'partnerId', partnerId)
            const localVarPath = `/v1/partners/{partner_id}/item-category-association`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1PricingPoliciesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readV1PricingPoliciesIdGet', 'id', id)
            const localVarPath = `/v1/pricing-policies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Warehouse Stock By Orders
         * @param {number} warehouseId 
         * @param {string} toDate 
         * @param {boolean} [hideOrdersWithoutDeliveryDate] 
         * @param {boolean} [hideAvailable] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readWarehouseStockByOrdersV1ReportsStockByOrdersGet: async (warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('readWarehouseStockByOrdersV1ReportsStockByOrdersGet', 'warehouseId', warehouseId)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('readWarehouseStockByOrdersV1ReportsStockByOrdersGet', 'toDate', toDate)
            const localVarPath = `/v1/reports/stock-by-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (hideOrdersWithoutDeliveryDate !== undefined) {
                localVarQueryParameter['hide_orders_without_delivery_date'] = hideOrdersWithoutDeliveryDate;
            }

            if (hideAvailable !== undefined) {
                localVarQueryParameter['hide_available'] = hideAvailable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return Items
         * @param {number} orderId 
         * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        returnItemsV2OrdersOrderIdReturnItemsPut: async (orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('returnItemsV2OrdersOrderIdReturnItemsPut', 'orderId', orderId)
            // verify required parameter 'returnOrderItemV2Request' is not null or undefined
            assertParamExists('returnItemsV2OrdersOrderIdReturnItemsPut', 'returnOrderItemV2Request', returnOrderItemV2Request)
            const localVarPath = `/v2/orders/{order_id}/return-items`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnOrderItemV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut: async (id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut', 'id', id)
            // verify required parameter 'setOrderingPolicyRequest' is not null or undefined
            assertParamExists('setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut', 'setOrderingPolicyRequest', setOrderingPolicyRequest)
            const localVarPath = `/v2/legal-entities/{id}/ordering-policy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setOrderingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Order Status
         * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateOrderStatusV2OrdersStatusPut: async (orderStatusUpdateRequest: OrderStatusUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderStatusUpdateRequest' is not null or undefined
            assertParamExists('updateOrderStatusV2OrdersStatusPut', 'orderStatusUpdateRequest', orderStatusUpdateRequest)
            const localVarPath = `/v2/orders/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderStatusUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Order
         * @param {number} orderId 
         * @param {UpdateOrderV2Request} updateOrderV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateOrderV2OrdersOrderIdPatch: async (orderId: number, updateOrderV2Request: UpdateOrderV2Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('updateOrderV2OrdersOrderIdPatch', 'orderId', orderId)
            // verify required parameter 'updateOrderV2Request' is not null or undefined
            assertParamExists('updateOrderV2OrdersOrderIdPatch', 'updateOrderV2Request', updateOrderV2Request)
            const localVarPath = `/v2/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Print Document Queue
         * @param {number} id 
         * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updatePrintDocumentQueueV2PrintDocumentQueueIdPatch: async (id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePrintDocumentQueueV2PrintDocumentQueueIdPatch', 'id', id)
            // verify required parameter 'updatePrintDocumentQueueRequest' is not null or undefined
            assertParamExists('updatePrintDocumentQueueV2PrintDocumentQueueIdPatch', 'updatePrintDocumentQueueRequest', updatePrintDocumentQueueRequest)
            const localVarPath = `/v2/print-document-queue/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePrintDocumentQueueRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateV1CompanyCategoriesPut: async (updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCompanyCategoryRequest' is not null or undefined
            assertParamExists('updateV1CompanyCategoriesPut', 'updateCompanyCategoryRequest', updateCompanyCategoryRequest)
            const localVarPath = `/v1/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {UpdateItemCategoryRequest} updateItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateV1ItemCategoriesPut: async (updateItemCategoryRequest: UpdateItemCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateItemCategoryRequest' is not null or undefined
            assertParamExists('updateV1ItemCategoriesPut', 'updateItemCategoryRequest', updateItemCategoryRequest)
            const localVarPath = `/v1/item-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateItemCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateV1PartnersPartnerIdItemCategoryAssociationPost: async (partnerId: number, requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('updateV1PartnersPartnerIdItemCategoryAssociationPost', 'partnerId', partnerId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateV1PartnersPartnerIdItemCategoryAssociationPost', 'requestBody', requestBody)
            const localVarPath = `/v1/partners/{partner_id}/item-category-association`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {UpdatePricingPolicyRequest} updatePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateV1PricingPoliciesPut: async (updatePricingPolicyRequest: UpdatePricingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePricingPolicyRequest' is not null or undefined
            assertParamExists('updateV1PricingPoliciesPut', 'updatePricingPolicyRequest', updatePricingPolicyRequest)
            const localVarPath = `/v1/pricing-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePricingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AMSApi - functional programming interface
 * @export
 */
export const AMSApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AMSApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderV2Request} createOrderV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createOrderV2OrdersPost(createOrderV2Request: CreateOrderV2Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderV2OrdersPost(createOrderV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Stocktaking
         * @param {StocktakingRequest} stocktakingRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createStocktakingV2StocktakingsPost(stocktakingRequest: StocktakingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StocktakingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStocktakingV2StocktakingsPost(stocktakingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createV1CompanyCategoriesPost(createCompanyCategoryRequest: CreateCompanyCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1CompanyCategoriesPost(createCompanyCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {CreateItemCategoryRequest} createItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createV1ItemCategoriesPost(createItemCategoryRequest: CreateItemCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1ItemCategoriesPost(createItemCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create
         * @param {CreatePricingPolicyRequest} createPricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createV1PricingPoliciesPost(createPricingPolicyRequest: CreatePricingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1PricingPoliciesPost(createPricingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteV1CompanyCategoriesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1CompanyCategoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteV1ItemCategoriesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1ItemCategoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteV1PricingPoliciesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1PricingPoliciesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getMeV1MeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeV1MeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Orders For Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityOrdersForCollectionAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Orders For Distribution Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityOrdersForDistributionAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Overdue Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityOverduePaymentsAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Pending Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityPendingPaymentsAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Stock Taking Report Items
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getStockTakingReportItemsV2StockTakingReportItemsGet(itemId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockTakingReportItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStockTakingReportItemsV2StockTakingReportItemsGet(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Stock Taking Reports
         * @param {string} [fromExecutionDate] 
         * @param {string} [toExecutionDate] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getStockTakingReportsV2StockTakingReportsGet(fromExecutionDate?: string, toExecutionDate?: string, userId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockTakingReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStockTakingReportsV2StockTakingReportsGet(fromExecutionDate, toExecutionDate, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async healthCheckV1HealthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheckV1HealthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {boolean} [withItems] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async listOrdersV2OrdersGet(status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrdersV2OrdersGet(status, companies, warehouses, fromDate, toDate, noInvoiceOnly, withExecutionPercentage, withItems, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Aggregated Item Movements
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [partners] 
         * @param {Array<number>} [warehouses] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItemMovementBalance>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate, toDate, partners, warehouses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All Flat
         * @param {boolean} [leafOnly] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readAllFlatV1ItemCategoriesFlatGet(leafOnly?: boolean, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllFlatV1ItemCategoriesFlatGet(leafOnly, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readAllV1CompanyCategoriesGet(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllV1CompanyCategoriesGet(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [simple] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readAllV1ItemCategoriesGet(skip?: number, limit?: number, simple?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllV1ItemCategoriesGet(skip, limit, simple, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {boolean} [hidePaid] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDeliveryDocumentResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate, toDate, suppliers, companies, hidePaid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [supplierId] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate?: string, toDate?: string, supplierId?: number, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDeliveryItemExtendedResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate, toDate, supplierId, companies, warehouses, itemCategories, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Depleted Amounts With Prices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receviers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate?: string, toDate?: string, receviers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDepletedAmountWithPrices>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate, toDate, receviers, companies, warehouses, itemCategories, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readListV1PricingPoliciesGet(search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PricingPolicyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readListV1PricingPoliciesGet(search, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order By Order Id
         * @param {number} orderId 
         * @param {boolean} [withLivePrices] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readOrderByOrderIdV2OrdersOrderIdGet(orderId: number, withLivePrices?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderByOrderIdV2OrdersOrderIdGet(orderId, withLivePrices, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDocumentV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderStatusLogResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Transactions
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderTransactionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Orders Balance
         * @param {string} [statusKeys] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportOrderBalanceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys, companies, warehouses, fromDate, toDate, noInvoiceOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PricingPolicyItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Category Multipliers
         * @param {string} itemCategories 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportPricingPolicyItemCategoryMultipliersListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Exceptions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PricingPolicyItemExceptionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Prices
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportPricingPolicyItemPricesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Print Document Queue
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readPrintDocumentQueueV2PrintDocumentQueueGet(state?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrintDocumentQueueResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPrintDocumentQueueV2PrintDocumentQueueGet(state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Quantities To Fill Up Warehouse Reserve
         * @param {number} warehouseId 
         * @param {number} [baseDays] 
         * @param {number} [reserveDays] 
         * @param {number} [supplierId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId: number, baseDays?: number, reserveDays?: number, supplierId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportWarehouseReserveResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId, baseDays, reserveDays, supplierId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stock Taking Report
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readStockTakingReportV2StockTakingReportsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockTakingReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockTakingReportV2StockTakingReportsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stocktaking Tasks
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readStocktakingTasksV2StocktakingsIdTasksGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockTakingTaskResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStocktakingTasksV2StocktakingsIdTasksGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stocktaking
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readStocktakingV2StocktakingsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StocktakingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStocktakingV2StocktakingsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stocktakings
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [warehouses] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readStocktakingsV2StocktakingsGet(fromDate?: string, toDate?: string, warehouses?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StocktakingResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStocktakingsV2StocktakingsGet(fromDate, toDate, warehouses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readV1CompanyCategoriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1CompanyCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readV1ItemCategoriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1ItemCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readV1PricingPoliciesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1PricingPoliciesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Warehouse Stock By Orders
         * @param {number} warehouseId 
         * @param {string} toDate 
         * @param {boolean} [hideOrdersWithoutDeliveryDate] 
         * @param {boolean} [hideAvailable] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDeliveryItemByOrdersResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId, toDate, hideOrdersWithoutDeliveryDate, hideAvailable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return Items
         * @param {number} orderId 
         * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async returnItemsV2OrdersOrderIdReturnItemsPut(orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnOrderItemsV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.returnItemsV2OrdersOrderIdReturnItemsPut(orderId, returnOrderItemV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id, setOrderingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Order Status
         * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest: OrderStatusUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Order
         * @param {number} orderId 
         * @param {UpdateOrderV2Request} updateOrderV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateOrderV2OrdersOrderIdPatch(orderId: number, updateOrderV2Request: UpdateOrderV2Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderV2OrdersOrderIdPatch(orderId, updateOrderV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Print Document Queue
         * @param {number} id 
         * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrintDocumentQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id, updatePrintDocumentQueueRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateV1CompanyCategoriesPut(updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1CompanyCategoriesPut(updateCompanyCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {UpdateItemCategoryRequest} updateItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateV1ItemCategoriesPut(updateItemCategoryRequest: UpdateItemCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1ItemCategoriesPut(updateItemCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId: number, requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {UpdatePricingPolicyRequest} updatePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateV1PricingPoliciesPut(updatePricingPolicyRequest: UpdatePricingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1PricingPoliciesPut(updatePricingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AMSApi - factory interface
 * @export
 */
export const AMSApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AMSApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderV2Request} createOrderV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createOrderV2OrdersPost(createOrderV2Request: CreateOrderV2Request, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.createOrderV2OrdersPost(createOrderV2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Stocktaking
         * @param {StocktakingRequest} stocktakingRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createStocktakingV2StocktakingsPost(stocktakingRequest: StocktakingRequest, options?: any): AxiosPromise<StocktakingResponse> {
            return localVarFp.createStocktakingV2StocktakingsPost(stocktakingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createV1CompanyCategoriesPost(createCompanyCategoryRequest: CreateCompanyCategoryRequest, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.createV1CompanyCategoriesPost(createCompanyCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {CreateItemCategoryRequest} createItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createV1ItemCategoriesPost(createItemCategoryRequest: CreateItemCategoryRequest, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.createV1ItemCategoriesPost(createItemCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create
         * @param {CreatePricingPolicyRequest} createPricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createV1PricingPoliciesPost(createPricingPolicyRequest: CreatePricingPolicyRequest, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.createV1PricingPoliciesPost(createPricingPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteV1CompanyCategoriesIdDelete(id: number, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.deleteV1CompanyCategoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteV1ItemCategoriesIdDelete(id: number, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.deleteV1ItemCategoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteV1PricingPoliciesIdDelete(id: number, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.deleteV1PricingPoliciesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMeV1MeGet(options?: any): AxiosPromise<UserAccessResponse> {
            return localVarFp.getMeV1MeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Orders For Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id: number, options?: any): AxiosPromise<LegalEntityOrdersForCollectionAmountResponse> {
            return localVarFp.getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Orders For Distribution Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id: number, options?: any): AxiosPromise<LegalEntityOrdersForDistributionAmountResponse> {
            return localVarFp.getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Overdue Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id: number, options?: any): AxiosPromise<LegalEntityOverduePaymentsAmountResponse> {
            return localVarFp.getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Pending Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id: number, options?: any): AxiosPromise<LegalEntityPendingPaymentsAmountResponse> {
            return localVarFp.getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Stock Taking Report Items
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getStockTakingReportItemsV2StockTakingReportItemsGet(itemId?: number, options?: any): AxiosPromise<Array<StockTakingReportItemResponse>> {
            return localVarFp.getStockTakingReportItemsV2StockTakingReportItemsGet(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Stock Taking Reports
         * @param {string} [fromExecutionDate] 
         * @param {string} [toExecutionDate] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getStockTakingReportsV2StockTakingReportsGet(fromExecutionDate?: string, toExecutionDate?: string, userId?: number, options?: any): AxiosPromise<Array<StockTakingReportResponse>> {
            return localVarFp.getStockTakingReportsV2StockTakingReportsGet(fromExecutionDate, toExecutionDate, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        healthCheckV1HealthGet(options?: any): AxiosPromise<any> {
            return localVarFp.healthCheckV1HealthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {boolean} [withItems] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        listOrdersV2OrdersGet(status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options?: any): AxiosPromise<Array<OrderV2Response>> {
            return localVarFp.listOrdersV2OrdersGet(status, companies, warehouses, fromDate, toDate, noInvoiceOnly, withExecutionPercentage, withItems, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Aggregated Item Movements
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [partners] 
         * @param {Array<number>} [warehouses] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, options?: any): AxiosPromise<Array<ItemMovementBalance>> {
            return localVarFp.readAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate, toDate, partners, warehouses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All Flat
         * @param {boolean} [leafOnly] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllFlatV1ItemCategoriesFlatGet(leafOnly?: boolean, skip?: number, limit?: number, options?: any): AxiosPromise<Array<ItemCategoryResponse>> {
            return localVarFp.readAllFlatV1ItemCategoriesFlatGet(leafOnly, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllV1CompanyCategoriesGet(skip?: number, limit?: number, options?: any): AxiosPromise<Array<CompanyCategoryResponse>> {
            return localVarFp.readAllV1CompanyCategoriesGet(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [simple] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllV1ItemCategoriesGet(skip?: number, limit?: number, simple?: boolean, options?: any): AxiosPromise<Array<ItemCategoryResponse>> {
            return localVarFp.readAllV1ItemCategoriesGet(skip, limit, simple, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {boolean} [hidePaid] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, options?: any): AxiosPromise<Array<ReportDeliveryDocumentResponse>> {
            return localVarFp.readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate, toDate, suppliers, companies, hidePaid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [supplierId] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate?: string, toDate?: string, supplierId?: number, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: any): AxiosPromise<Array<ReportDeliveryItemExtendedResponse>> {
            return localVarFp.readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate, toDate, supplierId, companies, warehouses, itemCategories, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Depleted Amounts With Prices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receviers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate?: string, toDate?: string, receviers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: any): AxiosPromise<Array<ReportDepletedAmountWithPrices>> {
            return localVarFp.readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate, toDate, receviers, companies, warehouses, itemCategories, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readListV1PricingPoliciesGet(search?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<PricingPolicyResponse>> {
            return localVarFp.readListV1PricingPoliciesGet(search, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order By Order Id
         * @param {number} orderId 
         * @param {boolean} [withLivePrices] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderByOrderIdV2OrdersOrderIdGet(orderId: number, withLivePrices?: boolean, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.readOrderByOrderIdV2OrdersOrderIdGet(orderId, withLivePrices, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId: number, options?: any): AxiosPromise<Array<OrderDocumentV2Response>> {
            return localVarFp.readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId: number, options?: any): AxiosPromise<Array<OrderStatusLogResponse>> {
            return localVarFp.readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Transactions
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId: number, options?: any): AxiosPromise<Array<OrderTransactionResponse>> {
            return localVarFp.readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id: number, options?: any): AxiosPromise<OrderingPolicyResponse> {
            return localVarFp.readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Orders Balance
         * @param {string} [statusKeys] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, options?: any): AxiosPromise<Array<ReportOrderBalanceResponse>> {
            return localVarFp.readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys, companies, warehouses, fromDate, toDate, noInvoiceOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id: number, options?: any): AxiosPromise<Array<PricingPolicyItemCategoryResponse>> {
            return localVarFp.readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Category Multipliers
         * @param {string} itemCategories 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories: string, options?: any): AxiosPromise<ReportPricingPolicyItemCategoryMultipliersListResponse> {
            return localVarFp.readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Exceptions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id: number, options?: any): AxiosPromise<Array<PricingPolicyItemExceptionResponse>> {
            return localVarFp.readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Prices
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items: string, options?: any): AxiosPromise<ReportPricingPolicyItemPricesListResponse> {
            return localVarFp.readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Print Document Queue
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPrintDocumentQueueV2PrintDocumentQueueGet(state?: string, options?: any): AxiosPromise<Array<PrintDocumentQueueResponse>> {
            return localVarFp.readPrintDocumentQueueV2PrintDocumentQueueGet(state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Quantities To Fill Up Warehouse Reserve
         * @param {number} warehouseId 
         * @param {number} [baseDays] 
         * @param {number} [reserveDays] 
         * @param {number} [supplierId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId: number, baseDays?: number, reserveDays?: number, supplierId?: number, options?: any): AxiosPromise<Array<ReportWarehouseReserveResponse>> {
            return localVarFp.readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId, baseDays, reserveDays, supplierId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stock Taking Report
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStockTakingReportV2StockTakingReportsIdGet(id: number, options?: any): AxiosPromise<StockTakingReportResponse> {
            return localVarFp.readStockTakingReportV2StockTakingReportsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stocktaking Tasks
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStocktakingTasksV2StocktakingsIdTasksGet(id: number, options?: any): AxiosPromise<Array<StockTakingTaskResponse>> {
            return localVarFp.readStocktakingTasksV2StocktakingsIdTasksGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stocktaking
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStocktakingV2StocktakingsIdGet(id: number, options?: any): AxiosPromise<StocktakingResponse> {
            return localVarFp.readStocktakingV2StocktakingsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stocktakings
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [warehouses] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStocktakingsV2StocktakingsGet(fromDate?: string, toDate?: string, warehouses?: string, options?: any): AxiosPromise<Array<StocktakingResponse>> {
            return localVarFp.readStocktakingsV2StocktakingsGet(fromDate, toDate, warehouses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1CompanyCategoriesIdGet(id: number, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.readV1CompanyCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1ItemCategoriesIdGet(id: number, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.readV1ItemCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId: number, options?: any): AxiosPromise<Array<PartnerItemCategoryResponse>> {
            return localVarFp.readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1PricingPoliciesIdGet(id: number, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.readV1PricingPoliciesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Warehouse Stock By Orders
         * @param {number} warehouseId 
         * @param {string} toDate 
         * @param {boolean} [hideOrdersWithoutDeliveryDate] 
         * @param {boolean} [hideAvailable] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options?: any): AxiosPromise<Array<ReportDeliveryItemByOrdersResponse>> {
            return localVarFp.readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId, toDate, hideOrdersWithoutDeliveryDate, hideAvailable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return Items
         * @param {number} orderId 
         * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        returnItemsV2OrdersOrderIdReturnItemsPut(orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options?: any): AxiosPromise<ReturnOrderItemsV2Response> {
            return localVarFp.returnItemsV2OrdersOrderIdReturnItemsPut(orderId, returnOrderItemV2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: any): AxiosPromise<OrderingPolicyResponse> {
            return localVarFp.setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id, setOrderingPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Order Status
         * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest: OrderStatusUpdateRequest, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Order
         * @param {number} orderId 
         * @param {UpdateOrderV2Request} updateOrderV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateOrderV2OrdersOrderIdPatch(orderId: number, updateOrderV2Request: UpdateOrderV2Request, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.updateOrderV2OrdersOrderIdPatch(orderId, updateOrderV2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Print Document Queue
         * @param {number} id 
         * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options?: any): AxiosPromise<PrintDocumentQueueResponse> {
            return localVarFp.updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id, updatePrintDocumentQueueRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateV1CompanyCategoriesPut(updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.updateV1CompanyCategoriesPut(updateCompanyCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {UpdateItemCategoryRequest} updateItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateV1ItemCategoriesPut(updateItemCategoryRequest: UpdateItemCategoryRequest, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.updateV1ItemCategoriesPut(updateItemCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId: number, requestBody: Array<number>, options?: any): AxiosPromise<Array<PartnerItemCategoryResponse>> {
            return localVarFp.updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {UpdatePricingPolicyRequest} updatePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateV1PricingPoliciesPut(updatePricingPolicyRequest: UpdatePricingPolicyRequest, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.updateV1PricingPoliciesPut(updatePricingPolicyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AMSApi - object-oriented interface
 * @export
 * @class AMSApi
 * @extends {BaseAPI}
 */
export class AMSApi extends BaseAPI {
    /**
     * 
     * @summary Create Order
     * @param {CreateOrderV2Request} createOrderV2Request 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createOrderV2OrdersPost(createOrderV2Request: CreateOrderV2Request, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createOrderV2OrdersPost(createOrderV2Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Stocktaking
     * @param {StocktakingRequest} stocktakingRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createStocktakingV2StocktakingsPost(stocktakingRequest: StocktakingRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createStocktakingV2StocktakingsPost(stocktakingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createV1CompanyCategoriesPost(createCompanyCategoryRequest: CreateCompanyCategoryRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createV1CompanyCategoriesPost(createCompanyCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {CreateItemCategoryRequest} createItemCategoryRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createV1ItemCategoriesPost(createItemCategoryRequest: CreateItemCategoryRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createV1ItemCategoriesPost(createItemCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create
     * @param {CreatePricingPolicyRequest} createPricingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public createV1PricingPoliciesPost(createPricingPolicyRequest: CreatePricingPolicyRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).createV1PricingPoliciesPost(createPricingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteV1CompanyCategoriesIdDelete(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteV1CompanyCategoriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteV1ItemCategoriesIdDelete(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteV1ItemCategoriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public deleteV1PricingPoliciesIdDelete(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).deleteV1PricingPoliciesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Me
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getMeV1MeGet(options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getMeV1MeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Orders For Collection
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Orders For Distribution Amount
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Overdue Payments Amount
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Pending Payments Amount
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Stock Taking Report Items
     * @param {number} [itemId] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getStockTakingReportItemsV2StockTakingReportItemsGet(itemId?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getStockTakingReportItemsV2StockTakingReportItemsGet(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Stock Taking Reports
     * @param {string} [fromExecutionDate] 
     * @param {string} [toExecutionDate] 
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public getStockTakingReportsV2StockTakingReportsGet(fromExecutionDate?: string, toExecutionDate?: string, userId?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).getStockTakingReportsV2StockTakingReportsGet(fromExecutionDate, toExecutionDate, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public healthCheckV1HealthGet(options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).healthCheckV1HealthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Orders
     * @param {string} [status] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {boolean} [noInvoiceOnly] 
     * @param {boolean} [withExecutionPercentage] 
     * @param {boolean} [withItems] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public listOrdersV2OrdersGet(status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).listOrdersV2OrdersGet(status, companies, warehouses, fromDate, toDate, noInvoiceOnly, withExecutionPercentage, withItems, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Aggregated Item Movements
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {Array<number>} [partners] 
     * @param {Array<number>} [warehouses] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate, toDate, partners, warehouses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All Flat
     * @param {boolean} [leafOnly] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readAllFlatV1ItemCategoriesFlatGet(leafOnly?: boolean, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readAllFlatV1ItemCategoriesFlatGet(leafOnly, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readAllV1CompanyCategoriesGet(skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readAllV1CompanyCategoriesGet(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {boolean} [simple] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readAllV1ItemCategoriesGet(skip?: number, limit?: number, simple?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readAllV1ItemCategoriesGet(skip, limit, simple, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Batch
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Invoices
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [suppliers] 
     * @param {string} [companies] 
     * @param {boolean} [hidePaid] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate, toDate, suppliers, companies, hidePaid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Items
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {number} [supplierId] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [itemCategories] 
     * @param {number} [itemId] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate?: string, toDate?: string, supplierId?: number, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate, toDate, supplierId, companies, warehouses, itemCategories, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Depleted Amounts With Prices
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [receviers] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [itemCategories] 
     * @param {number} [itemId] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate?: string, toDate?: string, receviers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate, toDate, receviers, companies, warehouses, itemCategories, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read List
     * @param {string} [search] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readListV1PricingPoliciesGet(search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readListV1PricingPoliciesGet(search, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order By Order Id
     * @param {number} orderId 
     * @param {boolean} [withLivePrices] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderByOrderIdV2OrdersOrderIdGet(orderId: number, withLivePrices?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderByOrderIdV2OrdersOrderIdGet(orderId, withLivePrices, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Documents
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Documents
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Transactions
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Ordering Policy
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Orders Balance
     * @param {string} [statusKeys] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {boolean} [noInvoiceOnly] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys, companies, warehouses, fromDate, toDate, noInvoiceOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Categories
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Category Multipliers
     * @param {string} itemCategories 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Exceptions
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Prices
     * @param {string} items 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Print Document Queue
     * @param {string} [state] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readPrintDocumentQueueV2PrintDocumentQueueGet(state?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readPrintDocumentQueueV2PrintDocumentQueueGet(state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Quantities To Fill Up Warehouse Reserve
     * @param {number} warehouseId 
     * @param {number} [baseDays] 
     * @param {number} [reserveDays] 
     * @param {number} [supplierId] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId: number, baseDays?: number, reserveDays?: number, supplierId?: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId, baseDays, reserveDays, supplierId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stock Taking Report
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStockTakingReportV2StockTakingReportsIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStockTakingReportV2StockTakingReportsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stocktaking Tasks
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStocktakingTasksV2StocktakingsIdTasksGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStocktakingTasksV2StocktakingsIdTasksGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stocktaking
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStocktakingV2StocktakingsIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStocktakingV2StocktakingsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stocktakings
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [warehouses] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readStocktakingsV2StocktakingsGet(fromDate?: string, toDate?: string, warehouses?: string, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readStocktakingsV2StocktakingsGet(fromDate, toDate, warehouses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readV1CompanyCategoriesIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readV1CompanyCategoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readV1ItemCategoriesIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readV1ItemCategoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} partnerId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readV1PricingPoliciesIdGet(id: number, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readV1PricingPoliciesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Warehouse Stock By Orders
     * @param {number} warehouseId 
     * @param {string} toDate 
     * @param {boolean} [hideOrdersWithoutDeliveryDate] 
     * @param {boolean} [hideAvailable] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId, toDate, hideOrdersWithoutDeliveryDate, hideAvailable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return Items
     * @param {number} orderId 
     * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public returnItemsV2OrdersOrderIdReturnItemsPut(orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).returnItemsV2OrdersOrderIdReturnItemsPut(orderId, returnOrderItemV2Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Ordering Policy
     * @param {number} id 
     * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id, setOrderingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Order Status
     * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest: OrderStatusUpdateRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Order
     * @param {number} orderId 
     * @param {UpdateOrderV2Request} updateOrderV2Request 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateOrderV2OrdersOrderIdPatch(orderId: number, updateOrderV2Request: UpdateOrderV2Request, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateOrderV2OrdersOrderIdPatch(orderId, updateOrderV2Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Print Document Queue
     * @param {number} id 
     * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id, updatePrintDocumentQueueRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateV1CompanyCategoriesPut(updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateV1CompanyCategoriesPut(updateCompanyCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {UpdateItemCategoryRequest} updateItemCategoryRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateV1ItemCategoriesPut(updateItemCategoryRequest: UpdateItemCategoryRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateV1ItemCategoriesPut(updateItemCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {number} partnerId 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId: number, requestBody: Array<number>, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {UpdatePricingPolicyRequest} updatePricingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AMSApi
     */
    public updateV1PricingPoliciesPut(updatePricingPolicyRequest: UpdatePricingPolicyRequest, options?: AxiosRequestConfig) {
        return AMSApiFp(this.configuration).updateV1PricingPoliciesPut(updatePricingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommonApi - axios parameter creator
 * @export
 */
export const CommonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMeV1MeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        healthCheckV1HealthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonApi - functional programming interface
 * @export
 */
export const CommonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getMeV1MeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeV1MeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async healthCheckV1HealthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheckV1HealthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommonApi - factory interface
 * @export
 */
export const CommonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMeV1MeGet(options?: any): AxiosPromise<UserAccessResponse> {
            return localVarFp.getMeV1MeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        healthCheckV1HealthGet(options?: any): AxiosPromise<any> {
            return localVarFp.healthCheckV1HealthGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
export class CommonApi extends BaseAPI {
    /**
     * 
     * @summary Get Me
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public getMeV1MeGet(options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).getMeV1MeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public healthCheckV1HealthGet(options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).healthCheckV1HealthGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginTokenPost: async (username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('loginTokenPost', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('loginTokenPost', 'password', password)
            const localVarPath = `/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginTokenPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginTokenPost(username, password, grantType, scope, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginTokenPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: any): AxiosPromise<any> {
            return localVarFp.loginTokenPost(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Login
     * @param {string} username 
     * @param {string} password 
     * @param {string} [grantType] 
     * @param {string} [scope] 
     * @param {string} [clientId] 
     * @param {string} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loginTokenPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).loginTokenPost(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsApi - axios parameter creator
 * @export
 */
export const ItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create
         * @param {CreateItemCategoryRequest} createItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createV1ItemCategoriesPost: async (createItemCategoryRequest: CreateItemCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createItemCategoryRequest' is not null or undefined
            assertParamExists('createV1ItemCategoriesPost', 'createItemCategoryRequest', createItemCategoryRequest)
            const localVarPath = `/v1/item-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createItemCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteV1ItemCategoriesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1ItemCategoriesIdDelete', 'id', id)
            const localVarPath = `/v1/item-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All Flat
         * @param {boolean} [leafOnly] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllFlatV1ItemCategoriesFlatGet: async (leafOnly?: boolean, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/item-categories/flat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (leafOnly !== undefined) {
                localVarQueryParameter['leaf_only'] = leafOnly;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [simple] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllV1ItemCategoriesGet: async (skip?: number, limit?: number, simple?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/item-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (simple !== undefined) {
                localVarQueryParameter['simple'] = simple;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1ItemCategoriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readV1ItemCategoriesIdGet', 'id', id)
            const localVarPath = `/v1/item-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {UpdateItemCategoryRequest} updateItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateV1ItemCategoriesPut: async (updateItemCategoryRequest: UpdateItemCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateItemCategoryRequest' is not null or undefined
            assertParamExists('updateV1ItemCategoriesPut', 'updateItemCategoryRequest', updateItemCategoryRequest)
            const localVarPath = `/v1/item-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateItemCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsApi - functional programming interface
 * @export
 */
export const ItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreateItemCategoryRequest} createItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createV1ItemCategoriesPost(createItemCategoryRequest: CreateItemCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1ItemCategoriesPost(createItemCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteV1ItemCategoriesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1ItemCategoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All Flat
         * @param {boolean} [leafOnly] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readAllFlatV1ItemCategoriesFlatGet(leafOnly?: boolean, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllFlatV1ItemCategoriesFlatGet(leafOnly, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [simple] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readAllV1ItemCategoriesGet(skip?: number, limit?: number, simple?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllV1ItemCategoriesGet(skip, limit, simple, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readV1ItemCategoriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1ItemCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {UpdateItemCategoryRequest} updateItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateV1ItemCategoriesPut(updateItemCategoryRequest: UpdateItemCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1ItemCategoriesPut(updateItemCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsApi - factory interface
 * @export
 */
export const ItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreateItemCategoryRequest} createItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createV1ItemCategoriesPost(createItemCategoryRequest: CreateItemCategoryRequest, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.createV1ItemCategoriesPost(createItemCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteV1ItemCategoriesIdDelete(id: number, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.deleteV1ItemCategoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All Flat
         * @param {boolean} [leafOnly] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllFlatV1ItemCategoriesFlatGet(leafOnly?: boolean, skip?: number, limit?: number, options?: any): AxiosPromise<Array<ItemCategoryResponse>> {
            return localVarFp.readAllFlatV1ItemCategoriesFlatGet(leafOnly, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [simple] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllV1ItemCategoriesGet(skip?: number, limit?: number, simple?: boolean, options?: any): AxiosPromise<Array<ItemCategoryResponse>> {
            return localVarFp.readAllV1ItemCategoriesGet(skip, limit, simple, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1ItemCategoriesIdGet(id: number, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.readV1ItemCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {UpdateItemCategoryRequest} updateItemCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateV1ItemCategoriesPut(updateItemCategoryRequest: UpdateItemCategoryRequest, options?: any): AxiosPromise<ItemCategoryResponse> {
            return localVarFp.updateV1ItemCategoriesPut(updateItemCategoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsApi - object-oriented interface
 * @export
 * @class ItemsApi
 * @extends {BaseAPI}
 */
export class ItemsApi extends BaseAPI {
    /**
     * 
     * @summary Create
     * @param {CreateItemCategoryRequest} createItemCategoryRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createV1ItemCategoriesPost(createItemCategoryRequest: CreateItemCategoryRequest, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).createV1ItemCategoriesPost(createItemCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteV1ItemCategoriesIdDelete(id: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).deleteV1ItemCategoriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All Flat
     * @param {boolean} [leafOnly] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readAllFlatV1ItemCategoriesFlatGet(leafOnly?: boolean, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readAllFlatV1ItemCategoriesFlatGet(leafOnly, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {boolean} [simple] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readAllV1ItemCategoriesGet(skip?: number, limit?: number, simple?: boolean, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readAllV1ItemCategoriesGet(skip, limit, simple, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public readV1ItemCategoriesIdGet(id: number, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).readV1ItemCategoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {UpdateItemCategoryRequest} updateItemCategoryRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateV1ItemCategoriesPut(updateItemCategoryRequest: UpdateItemCategoryRequest, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).updateV1ItemCategoriesPut(updateItemCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MobileApi - axios parameter creator
 * @export
 */
export const MobileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderV2Request} createOrderV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createOrderV2OrdersPost: async (createOrderV2Request: CreateOrderV2Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderV2Request' is not null or undefined
            assertParamExists('createOrderV2OrdersPost', 'createOrderV2Request', createOrderV2Request)
            const localVarPath = `/v2/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {boolean} [withItems] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        listOrdersV2OrdersGet: async (status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (noInvoiceOnly !== undefined) {
                localVarQueryParameter['no_invoice_only'] = noInvoiceOnly;
            }

            if (withExecutionPercentage !== undefined) {
                localVarQueryParameter['with_execution_percentage'] = withExecutionPercentage;
            }

            if (withItems !== undefined) {
                localVarQueryParameter['with_items'] = withItems;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents/delivery-batch`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order By Order Id
         * @param {number} orderId 
         * @param {boolean} [withLivePrices] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderByOrderIdV2OrdersOrderIdGet: async (orderId: number, withLivePrices?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderByOrderIdV2OrdersOrderIdGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (withLivePrices !== undefined) {
                localVarQueryParameter['with_live_prices'] = withLivePrices;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdDocumentsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderDocumentsV2OrdersOrderIdDocumentsGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdStatusLogsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderDocumentsV2OrdersOrderIdStatusLogsGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/status-logs`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Transactions
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderTransactionsV2OrdersOrderIdTransactionsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderTransactionsV2OrdersOrderIdTransactionsGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/transactions`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return Items
         * @param {number} orderId 
         * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        returnItemsV2OrdersOrderIdReturnItemsPut: async (orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('returnItemsV2OrdersOrderIdReturnItemsPut', 'orderId', orderId)
            // verify required parameter 'returnOrderItemV2Request' is not null or undefined
            assertParamExists('returnItemsV2OrdersOrderIdReturnItemsPut', 'returnOrderItemV2Request', returnOrderItemV2Request)
            const localVarPath = `/v2/orders/{order_id}/return-items`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnOrderItemV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Order Status
         * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateOrderStatusV2OrdersStatusPut: async (orderStatusUpdateRequest: OrderStatusUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderStatusUpdateRequest' is not null or undefined
            assertParamExists('updateOrderStatusV2OrdersStatusPut', 'orderStatusUpdateRequest', orderStatusUpdateRequest)
            const localVarPath = `/v2/orders/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderStatusUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Order
         * @param {number} orderId 
         * @param {UpdateOrderV2Request} updateOrderV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateOrderV2OrdersOrderIdPatch: async (orderId: number, updateOrderV2Request: UpdateOrderV2Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('updateOrderV2OrdersOrderIdPatch', 'orderId', orderId)
            // verify required parameter 'updateOrderV2Request' is not null or undefined
            assertParamExists('updateOrderV2OrdersOrderIdPatch', 'updateOrderV2Request', updateOrderV2Request)
            const localVarPath = `/v2/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileApi - functional programming interface
 * @export
 */
export const MobileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderV2Request} createOrderV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createOrderV2OrdersPost(createOrderV2Request: CreateOrderV2Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderV2OrdersPost(createOrderV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {boolean} [withItems] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async listOrdersV2OrdersGet(status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrdersV2OrdersGet(status, companies, warehouses, fromDate, toDate, noInvoiceOnly, withExecutionPercentage, withItems, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order By Order Id
         * @param {number} orderId 
         * @param {boolean} [withLivePrices] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readOrderByOrderIdV2OrdersOrderIdGet(orderId: number, withLivePrices?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderByOrderIdV2OrdersOrderIdGet(orderId, withLivePrices, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDocumentV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderStatusLogResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Transactions
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderTransactionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return Items
         * @param {number} orderId 
         * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async returnItemsV2OrdersOrderIdReturnItemsPut(orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnOrderItemsV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.returnItemsV2OrdersOrderIdReturnItemsPut(orderId, returnOrderItemV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Order Status
         * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest: OrderStatusUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Order
         * @param {number} orderId 
         * @param {UpdateOrderV2Request} updateOrderV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateOrderV2OrdersOrderIdPatch(orderId: number, updateOrderV2Request: UpdateOrderV2Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderV2OrdersOrderIdPatch(orderId, updateOrderV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MobileApi - factory interface
 * @export
 */
export const MobileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderV2Request} createOrderV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createOrderV2OrdersPost(createOrderV2Request: CreateOrderV2Request, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.createOrderV2OrdersPost(createOrderV2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {boolean} [withItems] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        listOrdersV2OrdersGet(status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options?: any): AxiosPromise<Array<OrderV2Response>> {
            return localVarFp.listOrdersV2OrdersGet(status, companies, warehouses, fromDate, toDate, noInvoiceOnly, withExecutionPercentage, withItems, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order By Order Id
         * @param {number} orderId 
         * @param {boolean} [withLivePrices] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderByOrderIdV2OrdersOrderIdGet(orderId: number, withLivePrices?: boolean, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.readOrderByOrderIdV2OrdersOrderIdGet(orderId, withLivePrices, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId: number, options?: any): AxiosPromise<Array<OrderDocumentV2Response>> {
            return localVarFp.readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId: number, options?: any): AxiosPromise<Array<OrderStatusLogResponse>> {
            return localVarFp.readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Transactions
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId: number, options?: any): AxiosPromise<Array<OrderTransactionResponse>> {
            return localVarFp.readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return Items
         * @param {number} orderId 
         * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        returnItemsV2OrdersOrderIdReturnItemsPut(orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options?: any): AxiosPromise<ReturnOrderItemsV2Response> {
            return localVarFp.returnItemsV2OrdersOrderIdReturnItemsPut(orderId, returnOrderItemV2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Order Status
         * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest: OrderStatusUpdateRequest, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Order
         * @param {number} orderId 
         * @param {UpdateOrderV2Request} updateOrderV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateOrderV2OrdersOrderIdPatch(orderId: number, updateOrderV2Request: UpdateOrderV2Request, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.updateOrderV2OrdersOrderIdPatch(orderId, updateOrderV2Request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MobileApi - object-oriented interface
 * @export
 * @class MobileApi
 * @extends {BaseAPI}
 */
export class MobileApi extends BaseAPI {
    /**
     * 
     * @summary Create Order
     * @param {CreateOrderV2Request} createOrderV2Request 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public createOrderV2OrdersPost(createOrderV2Request: CreateOrderV2Request, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).createOrderV2OrdersPost(createOrderV2Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Orders
     * @param {string} [status] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {boolean} [noInvoiceOnly] 
     * @param {boolean} [withExecutionPercentage] 
     * @param {boolean} [withItems] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public listOrdersV2OrdersGet(status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).listOrdersV2OrdersGet(status, companies, warehouses, fromDate, toDate, noInvoiceOnly, withExecutionPercentage, withItems, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Batch
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order By Order Id
     * @param {number} orderId 
     * @param {boolean} [withLivePrices] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public readOrderByOrderIdV2OrdersOrderIdGet(orderId: number, withLivePrices?: boolean, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).readOrderByOrderIdV2OrdersOrderIdGet(orderId, withLivePrices, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Documents
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId: number, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Documents
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId: number, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Transactions
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId: number, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return Items
     * @param {number} orderId 
     * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public returnItemsV2OrdersOrderIdReturnItemsPut(orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).returnItemsV2OrdersOrderIdReturnItemsPut(orderId, returnOrderItemV2Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Order Status
     * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest: OrderStatusUpdateRequest, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Order
     * @param {number} orderId 
     * @param {UpdateOrderV2Request} updateOrderV2Request 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    public updateOrderV2OrdersOrderIdPatch(orderId: number, updateOrderV2Request: UpdateOrderV2Request, options?: AxiosRequestConfig) {
        return MobileApiFp(this.configuration).updateOrderV2OrdersOrderIdPatch(orderId, updateOrderV2Request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderV2Request} createOrderV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createOrderV2OrdersPost: async (createOrderV2Request: CreateOrderV2Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderV2Request' is not null or undefined
            assertParamExists('createOrderV2OrdersPost', 'createOrderV2Request', createOrderV2Request)
            const localVarPath = `/v2/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {boolean} [withItems] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        listOrdersV2OrdersGet: async (status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (noInvoiceOnly !== undefined) {
                localVarQueryParameter['no_invoice_only'] = noInvoiceOnly;
            }

            if (withExecutionPercentage !== undefined) {
                localVarQueryParameter['with_execution_percentage'] = withExecutionPercentage;
            }

            if (withItems !== undefined) {
                localVarQueryParameter['with_items'] = withItems;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents/delivery-batch`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order By Order Id
         * @param {number} orderId 
         * @param {boolean} [withLivePrices] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderByOrderIdV2OrdersOrderIdGet: async (orderId: number, withLivePrices?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderByOrderIdV2OrdersOrderIdGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (withLivePrices !== undefined) {
                localVarQueryParameter['with_live_prices'] = withLivePrices;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdDocumentsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderDocumentsV2OrdersOrderIdDocumentsGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/documents`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdStatusLogsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderDocumentsV2OrdersOrderIdStatusLogsGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/status-logs`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Order Transactions
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderTransactionsV2OrdersOrderIdTransactionsGet: async (orderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('readOrderTransactionsV2OrdersOrderIdTransactionsGet', 'orderId', orderId)
            const localVarPath = `/v2/orders/{order_id}/transactions`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return Items
         * @param {number} orderId 
         * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        returnItemsV2OrdersOrderIdReturnItemsPut: async (orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('returnItemsV2OrdersOrderIdReturnItemsPut', 'orderId', orderId)
            // verify required parameter 'returnOrderItemV2Request' is not null or undefined
            assertParamExists('returnItemsV2OrdersOrderIdReturnItemsPut', 'returnOrderItemV2Request', returnOrderItemV2Request)
            const localVarPath = `/v2/orders/{order_id}/return-items`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnOrderItemV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Order Status
         * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateOrderStatusV2OrdersStatusPut: async (orderStatusUpdateRequest: OrderStatusUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderStatusUpdateRequest' is not null or undefined
            assertParamExists('updateOrderStatusV2OrdersStatusPut', 'orderStatusUpdateRequest', orderStatusUpdateRequest)
            const localVarPath = `/v2/orders/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderStatusUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Order
         * @param {number} orderId 
         * @param {UpdateOrderV2Request} updateOrderV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateOrderV2OrdersOrderIdPatch: async (orderId: number, updateOrderV2Request: UpdateOrderV2Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('updateOrderV2OrdersOrderIdPatch', 'orderId', orderId)
            // verify required parameter 'updateOrderV2Request' is not null or undefined
            assertParamExists('updateOrderV2OrdersOrderIdPatch', 'updateOrderV2Request', updateOrderV2Request)
            const localVarPath = `/v2/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderV2Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderV2Request} createOrderV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createOrderV2OrdersPost(createOrderV2Request: CreateOrderV2Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderV2OrdersPost(createOrderV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {boolean} [withItems] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async listOrdersV2OrdersGet(status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrdersV2OrdersGet(status, companies, warehouses, fromDate, toDate, noInvoiceOnly, withExecutionPercentage, withItems, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order By Order Id
         * @param {number} orderId 
         * @param {boolean} [withLivePrices] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readOrderByOrderIdV2OrdersOrderIdGet(orderId: number, withLivePrices?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderByOrderIdV2OrdersOrderIdGet(orderId, withLivePrices, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDocumentV2Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderStatusLogResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Order Transactions
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderTransactionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return Items
         * @param {number} orderId 
         * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async returnItemsV2OrdersOrderIdReturnItemsPut(orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnOrderItemsV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.returnItemsV2OrdersOrderIdReturnItemsPut(orderId, returnOrderItemV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Order Status
         * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest: OrderStatusUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Order
         * @param {number} orderId 
         * @param {UpdateOrderV2Request} updateOrderV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateOrderV2OrdersOrderIdPatch(orderId: number, updateOrderV2Request: UpdateOrderV2Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderV2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderV2OrdersOrderIdPatch(orderId, updateOrderV2Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Order
         * @param {CreateOrderV2Request} createOrderV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createOrderV2OrdersPost(createOrderV2Request: CreateOrderV2Request, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.createOrderV2OrdersPost(createOrderV2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Orders
         * @param {string} [status] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {boolean} [withExecutionPercentage] 
         * @param {boolean} [withItems] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        listOrdersV2OrdersGet(status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options?: any): AxiosPromise<Array<OrderV2Response>> {
            return localVarFp.listOrdersV2OrdersGet(status, companies, warehouses, fromDate, toDate, noInvoiceOnly, withExecutionPercentage, withItems, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Batch
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: any): AxiosPromise<any> {
            return localVarFp.readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order By Order Id
         * @param {number} orderId 
         * @param {boolean} [withLivePrices] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderByOrderIdV2OrdersOrderIdGet(orderId: number, withLivePrices?: boolean, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.readOrderByOrderIdV2OrdersOrderIdGet(orderId, withLivePrices, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId: number, options?: any): AxiosPromise<Array<OrderDocumentV2Response>> {
            return localVarFp.readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Documents
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId: number, options?: any): AxiosPromise<Array<OrderStatusLogResponse>> {
            return localVarFp.readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Order Transactions
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId: number, options?: any): AxiosPromise<Array<OrderTransactionResponse>> {
            return localVarFp.readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return Items
         * @param {number} orderId 
         * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        returnItemsV2OrdersOrderIdReturnItemsPut(orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options?: any): AxiosPromise<ReturnOrderItemsV2Response> {
            return localVarFp.returnItemsV2OrdersOrderIdReturnItemsPut(orderId, returnOrderItemV2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Order Status
         * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest: OrderStatusUpdateRequest, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Order
         * @param {number} orderId 
         * @param {UpdateOrderV2Request} updateOrderV2Request 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateOrderV2OrdersOrderIdPatch(orderId: number, updateOrderV2Request: UpdateOrderV2Request, options?: any): AxiosPromise<OrderV2Response> {
            return localVarFp.updateOrderV2OrdersOrderIdPatch(orderId, updateOrderV2Request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * 
     * @summary Create Order
     * @param {CreateOrderV2Request} createOrderV2Request 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public createOrderV2OrdersPost(createOrderV2Request: CreateOrderV2Request, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).createOrderV2OrdersPost(createOrderV2Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Orders
     * @param {string} [status] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {boolean} [noInvoiceOnly] 
     * @param {boolean} [withExecutionPercentage] 
     * @param {boolean} [withItems] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public listOrdersV2OrdersGet(status?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, withExecutionPercentage?: boolean, withItems?: boolean, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).listOrdersV2OrdersGet(status, companies, warehouses, fromDate, toDate, noInvoiceOnly, withExecutionPercentage, withItems, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Batch
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readDeliveryBatchV2OrdersOrderIdDocumentsDeliveryBatchGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order By Order Id
     * @param {number} orderId 
     * @param {boolean} [withLivePrices] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readOrderByOrderIdV2OrdersOrderIdGet(orderId: number, withLivePrices?: boolean, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readOrderByOrderIdV2OrdersOrderIdGet(orderId, withLivePrices, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Documents
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readOrderDocumentsV2OrdersOrderIdDocumentsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Documents
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readOrderDocumentsV2OrdersOrderIdStatusLogsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Order Transactions
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).readOrderTransactionsV2OrdersOrderIdTransactionsGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return Items
     * @param {number} orderId 
     * @param {Array<ReturnOrderItemV2Request>} returnOrderItemV2Request 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public returnItemsV2OrdersOrderIdReturnItemsPut(orderId: number, returnOrderItemV2Request: Array<ReturnOrderItemV2Request>, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).returnItemsV2OrdersOrderIdReturnItemsPut(orderId, returnOrderItemV2Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Order Status
     * @param {OrderStatusUpdateRequest} orderStatusUpdateRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest: OrderStatusUpdateRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).updateOrderStatusV2OrdersStatusPut(orderStatusUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Order
     * @param {number} orderId 
     * @param {UpdateOrderV2Request} updateOrderV2Request 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public updateOrderV2OrdersOrderIdPatch(orderId: number, updateOrderV2Request: UpdateOrderV2Request, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).updateOrderV2OrdersOrderIdPatch(orderId, updateOrderV2Request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnersApi - axios parameter creator
 * @export
 */
export const PartnersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create
         * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createV1CompanyCategoriesPost: async (createCompanyCategoryRequest: CreateCompanyCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyCategoryRequest' is not null or undefined
            assertParamExists('createV1CompanyCategoriesPost', 'createCompanyCategoryRequest', createCompanyCategoryRequest)
            const localVarPath = `/v1/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteV1CompanyCategoriesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1CompanyCategoriesIdDelete', 'id', id)
            const localVarPath = `/v1/company-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Orders For Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/orders-for-collection/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Orders For Distribution Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/orders-for-distribution/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Overdue Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/overdue-payments/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Pending Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/pending-payments/amount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllV1CompanyCategoriesGet: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet', 'id', id)
            const localVarPath = `/v2/legal-entities/{id}/ordering-policy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1CompanyCategoriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readV1CompanyCategoriesIdGet', 'id', id)
            const localVarPath = `/v1/company-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1PartnersPartnerIdItemCategoryAssociationGet: async (partnerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('readV1PartnersPartnerIdItemCategoryAssociationGet', 'partnerId', partnerId)
            const localVarPath = `/v1/partners/{partner_id}/item-category-association`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut: async (id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut', 'id', id)
            // verify required parameter 'setOrderingPolicyRequest' is not null or undefined
            assertParamExists('setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut', 'setOrderingPolicyRequest', setOrderingPolicyRequest)
            const localVarPath = `/v2/legal-entities/{id}/ordering-policy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setOrderingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateV1CompanyCategoriesPut: async (updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCompanyCategoryRequest' is not null or undefined
            assertParamExists('updateV1CompanyCategoriesPut', 'updateCompanyCategoryRequest', updateCompanyCategoryRequest)
            const localVarPath = `/v1/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateV1PartnersPartnerIdItemCategoryAssociationPost: async (partnerId: number, requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('updateV1PartnersPartnerIdItemCategoryAssociationPost', 'partnerId', partnerId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateV1PartnersPartnerIdItemCategoryAssociationPost', 'requestBody', requestBody)
            const localVarPath = `/v1/partners/{partner_id}/item-category-association`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnersApi - functional programming interface
 * @export
 */
export const PartnersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createV1CompanyCategoriesPost(createCompanyCategoryRequest: CreateCompanyCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1CompanyCategoriesPost(createCompanyCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteV1CompanyCategoriesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1CompanyCategoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Orders For Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityOrdersForCollectionAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Orders For Distribution Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityOrdersForDistributionAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Overdue Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityOverduePaymentsAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Pending Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntityPendingPaymentsAmountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readAllV1CompanyCategoriesGet(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllV1CompanyCategoriesGet(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readV1CompanyCategoriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1CompanyCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id, setOrderingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateV1CompanyCategoriesPut(updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1CompanyCategoriesPut(updateCompanyCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId: number, requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnersApi - factory interface
 * @export
 */
export const PartnersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createV1CompanyCategoriesPost(createCompanyCategoryRequest: CreateCompanyCategoryRequest, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.createV1CompanyCategoriesPost(createCompanyCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteV1CompanyCategoriesIdDelete(id: number, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.deleteV1CompanyCategoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Orders For Collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id: number, options?: any): AxiosPromise<LegalEntityOrdersForCollectionAmountResponse> {
            return localVarFp.getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Orders For Distribution Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id: number, options?: any): AxiosPromise<LegalEntityOrdersForDistributionAmountResponse> {
            return localVarFp.getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Overdue Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id: number, options?: any): AxiosPromise<LegalEntityOverduePaymentsAmountResponse> {
            return localVarFp.getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Pending Payments Amount
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id: number, options?: any): AxiosPromise<LegalEntityPendingPaymentsAmountResponse> {
            return localVarFp.getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllV1CompanyCategoriesGet(skip?: number, limit?: number, options?: any): AxiosPromise<Array<CompanyCategoryResponse>> {
            return localVarFp.readAllV1CompanyCategoriesGet(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Ordering Policy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id: number, options?: any): AxiosPromise<OrderingPolicyResponse> {
            return localVarFp.readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1CompanyCategoriesIdGet(id: number, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.readV1CompanyCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} partnerId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId: number, options?: any): AxiosPromise<Array<PartnerItemCategoryResponse>> {
            return localVarFp.readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Ordering Policy
         * @param {number} id 
         * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: any): AxiosPromise<OrderingPolicyResponse> {
            return localVarFp.setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id, setOrderingPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateV1CompanyCategoriesPut(updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.updateV1CompanyCategoriesPut(updateCompanyCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {number} partnerId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId: number, requestBody: Array<number>, options?: any): AxiosPromise<Array<PartnerItemCategoryResponse>> {
            return localVarFp.updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnersApi - object-oriented interface
 * @export
 * @class PartnersApi
 * @extends {BaseAPI}
 */
export class PartnersApi extends BaseAPI {
    /**
     * 
     * @summary Create
     * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public createV1CompanyCategoriesPost(createCompanyCategoryRequest: CreateCompanyCategoryRequest, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).createV1CompanyCategoriesPost(createCompanyCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public deleteV1CompanyCategoriesIdDelete(id: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).deleteV1CompanyCategoriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Orders For Collection
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).getOrdersForCollectionV2LegalEntitiesIdOrdersForCollectionAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Orders For Distribution Amount
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).getOrdersForDistributionAmountV2LegalEntitiesIdOrdersForDistributionAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Overdue Payments Amount
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).getOverduePaymentsAmountV2LegalEntitiesIdOverduePaymentsAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Pending Payments Amount
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).getPendingPaymentsAmountV2LegalEntitiesIdPendingPaymentsAmountGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public readAllV1CompanyCategoriesGet(skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).readAllV1CompanyCategoriesGet(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Ordering Policy
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).readOrderingPolicyV2LegalEntitiesIdOrderingPolicyGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public readV1CompanyCategoriesIdGet(id: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).readV1CompanyCategoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} partnerId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId: number, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).readV1PartnersPartnerIdItemCategoryAssociationGet(partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Ordering Policy
     * @param {number} id 
     * @param {SetOrderingPolicyRequest} setOrderingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id: number, setOrderingPolicyRequest: SetOrderingPolicyRequest, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).setOrderingPolicyV2LegalEntitiesIdOrderingPolicyPut(id, setOrderingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public updateV1CompanyCategoriesPut(updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).updateV1CompanyCategoriesPut(updateCompanyCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {number} partnerId 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId: number, requestBody: Array<number>, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).updateV1PartnersPartnerIdItemCategoryAssociationPost(partnerId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PricingPoliciesApi - axios parameter creator
 * @export
 */
export const PricingPoliciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create
         * @param {CreatePricingPolicyRequest} createPricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createV1PricingPoliciesPost: async (createPricingPolicyRequest: CreatePricingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPricingPolicyRequest' is not null or undefined
            assertParamExists('createV1PricingPoliciesPost', 'createPricingPolicyRequest', createPricingPolicyRequest)
            const localVarPath = `/v1/pricing-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPricingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteV1PricingPoliciesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1PricingPoliciesIdDelete', 'id', id)
            const localVarPath = `/v1/pricing-policies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readListV1PricingPoliciesGet: async (search?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pricing-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet', 'id', id)
            const localVarPath = `/v1/pricing-policies/{id}/item-categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Exceptions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet', 'id', id)
            const localVarPath = `/v1/pricing-policies/{id}/item-exceptions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1PricingPoliciesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readV1PricingPoliciesIdGet', 'id', id)
            const localVarPath = `/v1/pricing-policies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {UpdatePricingPolicyRequest} updatePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateV1PricingPoliciesPut: async (updatePricingPolicyRequest: UpdatePricingPolicyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePricingPolicyRequest' is not null or undefined
            assertParamExists('updateV1PricingPoliciesPut', 'updatePricingPolicyRequest', updatePricingPolicyRequest)
            const localVarPath = `/v1/pricing-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePricingPolicyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PricingPoliciesApi - functional programming interface
 * @export
 */
export const PricingPoliciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PricingPoliciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreatePricingPolicyRequest} createPricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createV1PricingPoliciesPost(createPricingPolicyRequest: CreatePricingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1PricingPoliciesPost(createPricingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteV1PricingPoliciesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1PricingPoliciesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readListV1PricingPoliciesGet(search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PricingPolicyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readListV1PricingPoliciesGet(search, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PricingPolicyItemCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Exceptions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PricingPolicyItemExceptionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readV1PricingPoliciesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1PricingPoliciesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {UpdatePricingPolicyRequest} updatePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateV1PricingPoliciesPut(updatePricingPolicyRequest: UpdatePricingPolicyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1PricingPoliciesPut(updatePricingPolicyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PricingPoliciesApi - factory interface
 * @export
 */
export const PricingPoliciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PricingPoliciesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreatePricingPolicyRequest} createPricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createV1PricingPoliciesPost(createPricingPolicyRequest: CreatePricingPolicyRequest, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.createV1PricingPoliciesPost(createPricingPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteV1PricingPoliciesIdDelete(id: number, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.deleteV1PricingPoliciesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read List
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readListV1PricingPoliciesGet(search?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<PricingPolicyResponse>> {
            return localVarFp.readListV1PricingPoliciesGet(search, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Categories
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id: number, options?: any): AxiosPromise<Array<PricingPolicyItemCategoryResponse>> {
            return localVarFp.readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Exceptions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id: number, options?: any): AxiosPromise<Array<PricingPolicyItemExceptionResponse>> {
            return localVarFp.readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1PricingPoliciesIdGet(id: number, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.readV1PricingPoliciesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {UpdatePricingPolicyRequest} updatePricingPolicyRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateV1PricingPoliciesPut(updatePricingPolicyRequest: UpdatePricingPolicyRequest, options?: any): AxiosPromise<PricingPolicyResponse> {
            return localVarFp.updateV1PricingPoliciesPut(updatePricingPolicyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PricingPoliciesApi - object-oriented interface
 * @export
 * @class PricingPoliciesApi
 * @extends {BaseAPI}
 */
export class PricingPoliciesApi extends BaseAPI {
    /**
     * 
     * @summary Create
     * @param {CreatePricingPolicyRequest} createPricingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public createV1PricingPoliciesPost(createPricingPolicyRequest: CreatePricingPolicyRequest, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).createV1PricingPoliciesPost(createPricingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public deleteV1PricingPoliciesIdDelete(id: number, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).deleteV1PricingPoliciesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read List
     * @param {string} [search] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public readListV1PricingPoliciesGet(search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).readListV1PricingPoliciesGet(search, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Categories
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id: number, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).readPricingPolicyItemCategoriesV1PricingPoliciesIdItemCategoriesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Exceptions
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id: number, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).readPricingPolicyItemExceptionsV1PricingPoliciesIdItemExceptionsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public readV1PricingPoliciesIdGet(id: number, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).readV1PricingPoliciesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {UpdatePricingPolicyRequest} updatePricingPolicyRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PricingPoliciesApi
     */
    public updateV1PricingPoliciesPut(updatePricingPolicyRequest: UpdatePricingPolicyRequest, options?: AxiosRequestConfig) {
        return PricingPoliciesApiFp(this.configuration).updateV1PricingPoliciesPut(updatePricingPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrintingApi - axios parameter creator
 * @export
 */
export const PrintingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Print Document Queue
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPrintDocumentQueueV2PrintDocumentQueueGet: async (state?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/print-document-queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Print Document Queue
         * @param {number} id 
         * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updatePrintDocumentQueueV2PrintDocumentQueueIdPatch: async (id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePrintDocumentQueueV2PrintDocumentQueueIdPatch', 'id', id)
            // verify required parameter 'updatePrintDocumentQueueRequest' is not null or undefined
            assertParamExists('updatePrintDocumentQueueV2PrintDocumentQueueIdPatch', 'updatePrintDocumentQueueRequest', updatePrintDocumentQueueRequest)
            const localVarPath = `/v2/print-document-queue/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePrintDocumentQueueRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrintingApi - functional programming interface
 * @export
 */
export const PrintingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrintingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Print Document Queue
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readPrintDocumentQueueV2PrintDocumentQueueGet(state?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrintDocumentQueueResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPrintDocumentQueueV2PrintDocumentQueueGet(state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Print Document Queue
         * @param {number} id 
         * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrintDocumentQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id, updatePrintDocumentQueueRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrintingApi - factory interface
 * @export
 */
export const PrintingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrintingApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Print Document Queue
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPrintDocumentQueueV2PrintDocumentQueueGet(state?: string, options?: any): AxiosPromise<Array<PrintDocumentQueueResponse>> {
            return localVarFp.readPrintDocumentQueueV2PrintDocumentQueueGet(state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Print Document Queue
         * @param {number} id 
         * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options?: any): AxiosPromise<PrintDocumentQueueResponse> {
            return localVarFp.updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id, updatePrintDocumentQueueRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PrintingApi - object-oriented interface
 * @export
 * @class PrintingApi
 * @extends {BaseAPI}
 */
export class PrintingApi extends BaseAPI {
    /**
     * 
     * @summary Read Print Document Queue
     * @param {string} [state] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PrintingApi
     */
    public readPrintDocumentQueueV2PrintDocumentQueueGet(state?: string, options?: AxiosRequestConfig) {
        return PrintingApiFp(this.configuration).readPrintDocumentQueueV2PrintDocumentQueueGet(state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Print Document Queue
     * @param {number} id 
     * @param {UpdatePrintDocumentQueueRequest} updatePrintDocumentQueueRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PrintingApi
     */
    public updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id: number, updatePrintDocumentQueueRequest: UpdatePrintDocumentQueueRequest, options?: AxiosRequestConfig) {
        return PrintingApiFp(this.configuration).updatePrintDocumentQueueV2PrintDocumentQueueIdPatch(id, updatePrintDocumentQueueRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Aggregated Item Movements
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [partners] 
         * @param {Array<number>} [warehouses] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAggregatedItemMovementsV2ReportsItemMovementsBalanceGet: async (fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/reports/item-movements-balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (partners) {
                localVarQueryParameter['partners'] = partners;
            }

            if (warehouses) {
                localVarQueryParameter['warehouses'] = warehouses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {boolean} [hidePaid] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryInvoicesV1ReportsDeliveryInvoiceGet: async (fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/delivery-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (suppliers !== undefined) {
                localVarQueryParameter['suppliers'] = suppliers;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (hidePaid !== undefined) {
                localVarQueryParameter['hide_paid'] = hidePaid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [supplierId] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryItemsV1ReportsDeliveryItemsGet: async (fromDate?: string, toDate?: string, supplierId?: number, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/delivery-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplier_id'] = supplierId;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Depleted Amounts With Prices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receviers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet: async (fromDate?: string, toDate?: string, receviers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/depleted-amounts-with-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (receviers !== undefined) {
                localVarQueryParameter['receviers'] = receviers;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Orders Balance
         * @param {string} [statusKeys] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrdersBalanceV1ReportsOrdersBalanceGet: async (statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/reports/orders-balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (statusKeys !== undefined) {
                localVarQueryParameter['status_keys'] = statusKeys;
            }

            if (companies !== undefined) {
                localVarQueryParameter['companies'] = companies;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (noInvoiceOnly !== undefined) {
                localVarQueryParameter['no_invoice_only'] = noInvoiceOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Category Multipliers
         * @param {string} itemCategories 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet: async (itemCategories: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemCategories' is not null or undefined
            assertParamExists('readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet', 'itemCategories', itemCategories)
            const localVarPath = `/v1/reports/pricing-policy-item-category-multipliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (itemCategories !== undefined) {
                localVarQueryParameter['item_categories'] = itemCategories;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Policy Item Prices
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet: async (items: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'items' is not null or undefined
            assertParamExists('readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet', 'items', items)
            const localVarPath = `/v1/reports/pricing-policy-item-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Quantities To Fill Up Warehouse Reserve
         * @param {number} warehouseId 
         * @param {number} [baseDays] 
         * @param {number} [reserveDays] 
         * @param {number} [supplierId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet: async (warehouseId: number, baseDays?: number, reserveDays?: number, supplierId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet', 'warehouseId', warehouseId)
            const localVarPath = `/v1/reports/warehouse-reserve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (baseDays !== undefined) {
                localVarQueryParameter['base_days'] = baseDays;
            }

            if (reserveDays !== undefined) {
                localVarQueryParameter['reserve_days'] = reserveDays;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplier_id'] = supplierId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Warehouse Stock By Orders
         * @param {number} warehouseId 
         * @param {string} toDate 
         * @param {boolean} [hideOrdersWithoutDeliveryDate] 
         * @param {boolean} [hideAvailable] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readWarehouseStockByOrdersV1ReportsStockByOrdersGet: async (warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('readWarehouseStockByOrdersV1ReportsStockByOrdersGet', 'warehouseId', warehouseId)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('readWarehouseStockByOrdersV1ReportsStockByOrdersGet', 'toDate', toDate)
            const localVarPath = `/v1/reports/stock-by-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (hideOrdersWithoutDeliveryDate !== undefined) {
                localVarQueryParameter['hide_orders_without_delivery_date'] = hideOrdersWithoutDeliveryDate;
            }

            if (hideAvailable !== undefined) {
                localVarQueryParameter['hide_available'] = hideAvailable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Aggregated Item Movements
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [partners] 
         * @param {Array<number>} [warehouses] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItemMovementBalance>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate, toDate, partners, warehouses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {boolean} [hidePaid] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDeliveryDocumentResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate, toDate, suppliers, companies, hidePaid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [supplierId] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate?: string, toDate?: string, supplierId?: number, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDeliveryItemExtendedResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate, toDate, supplierId, companies, warehouses, itemCategories, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Depleted Amounts With Prices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receviers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate?: string, toDate?: string, receviers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDepletedAmountWithPrices>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate, toDate, receviers, companies, warehouses, itemCategories, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Orders Balance
         * @param {string} [statusKeys] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportOrderBalanceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys, companies, warehouses, fromDate, toDate, noInvoiceOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Category Multipliers
         * @param {string} itemCategories 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportPricingPolicyItemCategoryMultipliersListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Pricing Policy Item Prices
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportPricingPolicyItemPricesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Quantities To Fill Up Warehouse Reserve
         * @param {number} warehouseId 
         * @param {number} [baseDays] 
         * @param {number} [reserveDays] 
         * @param {number} [supplierId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId: number, baseDays?: number, reserveDays?: number, supplierId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportWarehouseReserveResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId, baseDays, reserveDays, supplierId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Warehouse Stock By Orders
         * @param {number} warehouseId 
         * @param {string} toDate 
         * @param {boolean} [hideOrdersWithoutDeliveryDate] 
         * @param {boolean} [hideAvailable] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportDeliveryItemByOrdersResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId, toDate, hideOrdersWithoutDeliveryDate, hideAvailable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Aggregated Item Movements
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<number>} [partners] 
         * @param {Array<number>} [warehouses] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, options?: any): AxiosPromise<Array<ItemMovementBalance>> {
            return localVarFp.readAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate, toDate, partners, warehouses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Invoices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [suppliers] 
         * @param {string} [companies] 
         * @param {boolean} [hidePaid] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, options?: any): AxiosPromise<Array<ReportDeliveryDocumentResponse>> {
            return localVarFp.readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate, toDate, suppliers, companies, hidePaid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Delivery Items
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [supplierId] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate?: string, toDate?: string, supplierId?: number, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: any): AxiosPromise<Array<ReportDeliveryItemExtendedResponse>> {
            return localVarFp.readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate, toDate, supplierId, companies, warehouses, itemCategories, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Depleted Amounts With Prices
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [receviers] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [itemCategories] 
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate?: string, toDate?: string, receviers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: any): AxiosPromise<Array<ReportDepletedAmountWithPrices>> {
            return localVarFp.readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate, toDate, receviers, companies, warehouses, itemCategories, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Orders Balance
         * @param {string} [statusKeys] 
         * @param {string} [companies] 
         * @param {string} [warehouses] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {boolean} [noInvoiceOnly] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, options?: any): AxiosPromise<Array<ReportOrderBalanceResponse>> {
            return localVarFp.readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys, companies, warehouses, fromDate, toDate, noInvoiceOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Category Multipliers
         * @param {string} itemCategories 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories: string, options?: any): AxiosPromise<ReportPricingPolicyItemCategoryMultipliersListResponse> {
            return localVarFp.readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Policy Item Prices
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items: string, options?: any): AxiosPromise<ReportPricingPolicyItemPricesListResponse> {
            return localVarFp.readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Quantities To Fill Up Warehouse Reserve
         * @param {number} warehouseId 
         * @param {number} [baseDays] 
         * @param {number} [reserveDays] 
         * @param {number} [supplierId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId: number, baseDays?: number, reserveDays?: number, supplierId?: number, options?: any): AxiosPromise<Array<ReportWarehouseReserveResponse>> {
            return localVarFp.readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId, baseDays, reserveDays, supplierId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Warehouse Stock By Orders
         * @param {number} warehouseId 
         * @param {string} toDate 
         * @param {boolean} [hideOrdersWithoutDeliveryDate] 
         * @param {boolean} [hideAvailable] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options?: any): AxiosPromise<Array<ReportDeliveryItemByOrdersResponse>> {
            return localVarFp.readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId, toDate, hideOrdersWithoutDeliveryDate, hideAvailable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary Read Aggregated Item Movements
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {Array<number>} [partners] 
     * @param {Array<number>} [warehouses] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate?: string, toDate?: string, partners?: Array<number>, warehouses?: Array<number>, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readAggregatedItemMovementsV2ReportsItemMovementsBalanceGet(fromDate, toDate, partners, warehouses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Invoices
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [suppliers] 
     * @param {string} [companies] 
     * @param {boolean} [hidePaid] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate?: string, toDate?: string, suppliers?: string, companies?: string, hidePaid?: boolean, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readDeliveryInvoicesV1ReportsDeliveryInvoiceGet(fromDate, toDate, suppliers, companies, hidePaid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Delivery Items
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {number} [supplierId] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [itemCategories] 
     * @param {number} [itemId] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate?: string, toDate?: string, supplierId?: number, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readDeliveryItemsV1ReportsDeliveryItemsGet(fromDate, toDate, supplierId, companies, warehouses, itemCategories, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Depleted Amounts With Prices
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [receviers] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [itemCategories] 
     * @param {number} [itemId] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate?: string, toDate?: string, receviers?: string, companies?: string, warehouses?: string, itemCategories?: string, itemId?: number, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readDepletedAmountsWithPricesV1ReportsDepletedAmountsWithPricesGet(fromDate, toDate, receviers, companies, warehouses, itemCategories, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Orders Balance
     * @param {string} [statusKeys] 
     * @param {string} [companies] 
     * @param {string} [warehouses] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {boolean} [noInvoiceOnly] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys?: string, companies?: string, warehouses?: string, fromDate?: string, toDate?: string, noInvoiceOnly?: boolean, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readOrdersBalanceV1ReportsOrdersBalanceGet(statusKeys, companies, warehouses, fromDate, toDate, noInvoiceOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Category Multipliers
     * @param {string} itemCategories 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readPricingPolicyItemCategoryMultipliersV1ReportsPricingPolicyItemCategoryMultipliersGet(itemCategories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Policy Item Prices
     * @param {string} items 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readPricingPolicyItemPricesV1ReportsPricingPolicyItemPricesGet(items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Quantities To Fill Up Warehouse Reserve
     * @param {number} warehouseId 
     * @param {number} [baseDays] 
     * @param {number} [reserveDays] 
     * @param {number} [supplierId] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId: number, baseDays?: number, reserveDays?: number, supplierId?: number, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readQuantitiesToFillUpWarehouseReserveV1ReportsWarehouseReserveGet(warehouseId, baseDays, reserveDays, supplierId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Warehouse Stock By Orders
     * @param {number} warehouseId 
     * @param {string} toDate 
     * @param {boolean} [hideOrdersWithoutDeliveryDate] 
     * @param {boolean} [hideAvailable] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId: number, toDate: string, hideOrdersWithoutDeliveryDate?: boolean, hideAvailable?: boolean, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).readWarehouseStockByOrdersV1ReportsStockByOrdersGet(warehouseId, toDate, hideOrdersWithoutDeliveryDate, hideAvailable, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StockTakingReportsApi - axios parameter creator
 * @export
 */
export const StockTakingReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Stock Taking Report Items
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getStockTakingReportItemsV2StockTakingReportItemsGet: async (itemId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/stock-taking-report-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Stock Taking Reports
         * @param {string} [fromExecutionDate] 
         * @param {string} [toExecutionDate] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getStockTakingReportsV2StockTakingReportsGet: async (fromExecutionDate?: string, toExecutionDate?: string, userId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/stock-taking-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromExecutionDate !== undefined) {
                localVarQueryParameter['from_execution_date'] = fromExecutionDate;
            }

            if (toExecutionDate !== undefined) {
                localVarQueryParameter['to_execution_date'] = toExecutionDate;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stock Taking Report
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStockTakingReportV2StockTakingReportsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStockTakingReportV2StockTakingReportsIdGet', 'id', id)
            const localVarPath = `/v2/stock-taking-reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockTakingReportsApi - functional programming interface
 * @export
 */
export const StockTakingReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockTakingReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Stock Taking Report Items
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getStockTakingReportItemsV2StockTakingReportItemsGet(itemId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockTakingReportItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStockTakingReportItemsV2StockTakingReportItemsGet(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Stock Taking Reports
         * @param {string} [fromExecutionDate] 
         * @param {string} [toExecutionDate] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getStockTakingReportsV2StockTakingReportsGet(fromExecutionDate?: string, toExecutionDate?: string, userId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockTakingReportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStockTakingReportsV2StockTakingReportsGet(fromExecutionDate, toExecutionDate, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stock Taking Report
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readStockTakingReportV2StockTakingReportsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockTakingReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStockTakingReportV2StockTakingReportsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StockTakingReportsApi - factory interface
 * @export
 */
export const StockTakingReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockTakingReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Stock Taking Report Items
         * @param {number} [itemId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getStockTakingReportItemsV2StockTakingReportItemsGet(itemId?: number, options?: any): AxiosPromise<Array<StockTakingReportItemResponse>> {
            return localVarFp.getStockTakingReportItemsV2StockTakingReportItemsGet(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Stock Taking Reports
         * @param {string} [fromExecutionDate] 
         * @param {string} [toExecutionDate] 
         * @param {number} [userId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getStockTakingReportsV2StockTakingReportsGet(fromExecutionDate?: string, toExecutionDate?: string, userId?: number, options?: any): AxiosPromise<Array<StockTakingReportResponse>> {
            return localVarFp.getStockTakingReportsV2StockTakingReportsGet(fromExecutionDate, toExecutionDate, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stock Taking Report
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStockTakingReportV2StockTakingReportsIdGet(id: number, options?: any): AxiosPromise<StockTakingReportResponse> {
            return localVarFp.readStockTakingReportV2StockTakingReportsIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StockTakingReportsApi - object-oriented interface
 * @export
 * @class StockTakingReportsApi
 * @extends {BaseAPI}
 */
export class StockTakingReportsApi extends BaseAPI {
    /**
     * 
     * @summary Get Stock Taking Report Items
     * @param {number} [itemId] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof StockTakingReportsApi
     */
    public getStockTakingReportItemsV2StockTakingReportItemsGet(itemId?: number, options?: AxiosRequestConfig) {
        return StockTakingReportsApiFp(this.configuration).getStockTakingReportItemsV2StockTakingReportItemsGet(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Stock Taking Reports
     * @param {string} [fromExecutionDate] 
     * @param {string} [toExecutionDate] 
     * @param {number} [userId] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof StockTakingReportsApi
     */
    public getStockTakingReportsV2StockTakingReportsGet(fromExecutionDate?: string, toExecutionDate?: string, userId?: number, options?: AxiosRequestConfig) {
        return StockTakingReportsApiFp(this.configuration).getStockTakingReportsV2StockTakingReportsGet(fromExecutionDate, toExecutionDate, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stock Taking Report
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof StockTakingReportsApi
     */
    public readStockTakingReportV2StockTakingReportsIdGet(id: number, options?: AxiosRequestConfig) {
        return StockTakingReportsApiFp(this.configuration).readStockTakingReportV2StockTakingReportsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StocktakingApi - axios parameter creator
 * @export
 */
export const StocktakingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Stocktaking
         * @param {StocktakingRequest} stocktakingRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createStocktakingV2StocktakingsPost: async (stocktakingRequest: StocktakingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stocktakingRequest' is not null or undefined
            assertParamExists('createStocktakingV2StocktakingsPost', 'stocktakingRequest', stocktakingRequest)
            const localVarPath = `/v2/stocktakings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stocktakingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stocktaking Tasks
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStocktakingTasksV2StocktakingsIdTasksGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStocktakingTasksV2StocktakingsIdTasksGet', 'id', id)
            const localVarPath = `/v2/stocktakings/{id}/tasks`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stocktaking
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStocktakingV2StocktakingsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readStocktakingV2StocktakingsIdGet', 'id', id)
            const localVarPath = `/v2/stocktakings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Stocktakings
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [warehouses] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStocktakingsV2StocktakingsGet: async (fromDate?: string, toDate?: string, warehouses?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/stocktakings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (warehouses !== undefined) {
                localVarQueryParameter['warehouses'] = warehouses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StocktakingApi - functional programming interface
 * @export
 */
export const StocktakingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StocktakingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Stocktaking
         * @param {StocktakingRequest} stocktakingRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createStocktakingV2StocktakingsPost(stocktakingRequest: StocktakingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StocktakingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStocktakingV2StocktakingsPost(stocktakingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stocktaking Tasks
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readStocktakingTasksV2StocktakingsIdTasksGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockTakingTaskResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStocktakingTasksV2StocktakingsIdTasksGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stocktaking
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readStocktakingV2StocktakingsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StocktakingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStocktakingV2StocktakingsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Stocktakings
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [warehouses] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readStocktakingsV2StocktakingsGet(fromDate?: string, toDate?: string, warehouses?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StocktakingResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStocktakingsV2StocktakingsGet(fromDate, toDate, warehouses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StocktakingApi - factory interface
 * @export
 */
export const StocktakingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StocktakingApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Stocktaking
         * @param {StocktakingRequest} stocktakingRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createStocktakingV2StocktakingsPost(stocktakingRequest: StocktakingRequest, options?: any): AxiosPromise<StocktakingResponse> {
            return localVarFp.createStocktakingV2StocktakingsPost(stocktakingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stocktaking Tasks
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStocktakingTasksV2StocktakingsIdTasksGet(id: number, options?: any): AxiosPromise<Array<StockTakingTaskResponse>> {
            return localVarFp.readStocktakingTasksV2StocktakingsIdTasksGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stocktaking
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStocktakingV2StocktakingsIdGet(id: number, options?: any): AxiosPromise<StocktakingResponse> {
            return localVarFp.readStocktakingV2StocktakingsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Stocktakings
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [warehouses] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readStocktakingsV2StocktakingsGet(fromDate?: string, toDate?: string, warehouses?: string, options?: any): AxiosPromise<Array<StocktakingResponse>> {
            return localVarFp.readStocktakingsV2StocktakingsGet(fromDate, toDate, warehouses, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StocktakingApi - object-oriented interface
 * @export
 * @class StocktakingApi
 * @extends {BaseAPI}
 */
export class StocktakingApi extends BaseAPI {
    /**
     * 
     * @summary Create Stocktaking
     * @param {StocktakingRequest} stocktakingRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof StocktakingApi
     */
    public createStocktakingV2StocktakingsPost(stocktakingRequest: StocktakingRequest, options?: AxiosRequestConfig) {
        return StocktakingApiFp(this.configuration).createStocktakingV2StocktakingsPost(stocktakingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stocktaking Tasks
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof StocktakingApi
     */
    public readStocktakingTasksV2StocktakingsIdTasksGet(id: number, options?: AxiosRequestConfig) {
        return StocktakingApiFp(this.configuration).readStocktakingTasksV2StocktakingsIdTasksGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stocktaking
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof StocktakingApi
     */
    public readStocktakingV2StocktakingsIdGet(id: number, options?: AxiosRequestConfig) {
        return StocktakingApiFp(this.configuration).readStocktakingV2StocktakingsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Stocktakings
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [warehouses] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof StocktakingApi
     */
    public readStocktakingsV2StocktakingsGet(fromDate?: string, toDate?: string, warehouses?: string, options?: AxiosRequestConfig) {
        return StocktakingApiFp(this.configuration).readStocktakingsV2StocktakingsGet(fromDate, toDate, warehouses, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SuppliersApi - axios parameter creator
 * @export
 */
export const SuppliersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create
         * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createV1CompanyCategoriesPost: async (createCompanyCategoryRequest: CreateCompanyCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyCategoryRequest' is not null or undefined
            assertParamExists('createV1CompanyCategoriesPost', 'createCompanyCategoryRequest', createCompanyCategoryRequest)
            const localVarPath = `/v1/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteV1CompanyCategoriesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteV1CompanyCategoriesIdDelete', 'id', id)
            const localVarPath = `/v1/company-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllV1CompanyCategoriesGet: async (skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1CompanyCategoriesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readV1CompanyCategoriesIdGet', 'id', id)
            const localVarPath = `/v1/company-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update
         * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateV1CompanyCategoriesPut: async (updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCompanyCategoryRequest' is not null or undefined
            assertParamExists('updateV1CompanyCategoriesPut', 'updateCompanyCategoryRequest', updateCompanyCategoryRequest)
            const localVarPath = `/v1/company-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuppliersApi - functional programming interface
 * @export
 */
export const SuppliersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuppliersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createV1CompanyCategoriesPost(createCompanyCategoryRequest: CreateCompanyCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createV1CompanyCategoriesPost(createCompanyCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteV1CompanyCategoriesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteV1CompanyCategoriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readAllV1CompanyCategoriesGet(skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllV1CompanyCategoriesGet(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async readV1CompanyCategoriesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readV1CompanyCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update
         * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateV1CompanyCategoriesPut(updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateV1CompanyCategoriesPut(updateCompanyCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SuppliersApi - factory interface
 * @export
 */
export const SuppliersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuppliersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create
         * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createV1CompanyCategoriesPost(createCompanyCategoryRequest: CreateCompanyCategoryRequest, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.createV1CompanyCategoriesPost(createCompanyCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteV1CompanyCategoriesIdDelete(id: number, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.deleteV1CompanyCategoriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read All
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readAllV1CompanyCategoriesGet(skip?: number, limit?: number, options?: any): AxiosPromise<Array<CompanyCategoryResponse>> {
            return localVarFp.readAllV1CompanyCategoriesGet(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        readV1CompanyCategoriesIdGet(id: number, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.readV1CompanyCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update
         * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateV1CompanyCategoriesPut(updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options?: any): AxiosPromise<CompanyCategoryResponse> {
            return localVarFp.updateV1CompanyCategoriesPut(updateCompanyCategoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SuppliersApi - object-oriented interface
 * @export
 * @class SuppliersApi
 * @extends {BaseAPI}
 */
export class SuppliersApi extends BaseAPI {
    /**
     * 
     * @summary Create
     * @param {CreateCompanyCategoryRequest} createCompanyCategoryRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public createV1CompanyCategoriesPost(createCompanyCategoryRequest: CreateCompanyCategoryRequest, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).createV1CompanyCategoriesPost(createCompanyCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public deleteV1CompanyCategoriesIdDelete(id: number, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).deleteV1CompanyCategoriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read All
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public readAllV1CompanyCategoriesGet(skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).readAllV1CompanyCategoriesGet(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public readV1CompanyCategoriesIdGet(id: number, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).readV1CompanyCategoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update
     * @param {UpdateCompanyCategoryRequest} updateCompanyCategoryRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SuppliersApi
     */
    public updateV1CompanyCategoriesPut(updateCompanyCategoryRequest: UpdateCompanyCategoryRequest, options?: AxiosRequestConfig) {
        return SuppliersApiFp(this.configuration).updateV1CompanyCategoriesPut(updateCompanyCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



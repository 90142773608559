import {
  ItemListResponse,
  StockReceivingProcessItemResponse,
  amsV3Service
} from '../../../../services';
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';

import { AMSTextField } from '../../../../helpers/ui';
import { findCountry } from '../../../../helpers/country-helper';
import { toDDMMYY } from '../../../../helpers/date-helper';

interface FinalizeStockReceivingProcessTableComponentProps {
  items: StockReceivingProcessItemResponse[];
  setItems: (value: StockReceivingProcessItemResponse[]) => StockReceivingProcessItemResponse[];
}

const FinalizeStockReceivingProcessTableComponent = ({
  items,
  setItems
}: FinalizeStockReceivingProcessTableComponentProps) => {
  const cellStyle: CSSProperties = useMemo(
    () => ({
      width: '10%',
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 15,
      paddingRight: 15
    }),
    []
  );
  const wideCellStyle = useMemo(() => ({ ...cellStyle, width: '20%' }), [cellStyle]);

  const [itemsData, setItemsData] = useState<ItemListResponse[]>([]);

  const loadData = useCallback(async () => {
    if (items) {
      const itemsIds = items.map((si: StockReceivingProcessItemResponse) => si.itemId);
      const itemsResp = await amsV3Service.getItems(itemsIds);
      setItemsData(itemsResp.data.data);
    }
  }, [items]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const itemLookup = useMemo(
    () =>
      itemsData.reduce((res: any, item: ItemListResponse) => {
        res[item.id] = item;
        return res;
      }, {}),
    [itemsData]
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={wideCellStyle}>Артикул</TableCell>
          <TableCell style={cellStyle} align="right">
            Партида
          </TableCell>
          <TableCell style={cellStyle} align="right">
            Срок на годност
          </TableCell>
          <TableCell style={cellStyle} align="right">
            Държава
          </TableCell>
          <TableCell style={cellStyle} align="right">
            Потвърдено количество
          </TableCell>
          <TableCell style={cellStyle} align="right">
            Финално количество
          </TableCell>
          <TableCell style={cellStyle} align="right">
            Цена
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item: StockReceivingProcessItemResponse) => {
          const itemData = itemLookup[item.itemId] as ItemListResponse;
          if (!itemData) {
            return (
              <TableRow key={item.id}>
                <TableCell colSpan={7} style={cellStyle}>
                  <Typography variant="body2">Зареждане ...</Typography>
                </TableCell>
              </TableRow>
            );
          }
          return (
            <TableRow key={item.id}>
              <TableCell style={wideCellStyle}>
                <Typography variant="body2">
                  {itemData.artNo}, {itemData.name}
                </Typography>
              </TableCell>
              <TableCell style={cellStyle} align="right">
                <Typography variant="body2">
                  {itemData.tracksBatches ? item.batchNumber : '-'}
                </Typography>
              </TableCell>
              <TableCell style={cellStyle}>
                <Typography variant="body2" align="right">
                  {itemData.tracksBatches && item.expirationDate
                    ? toDDMMYY(new Date(item.expirationDate))
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell style={wideCellStyle}>
                <Typography variant="body2" align="right">
                  {itemData.tracksBatches ? findCountry(item.countryOfOrigin)?.name : '-'}
                </Typography>
              </TableCell>
              <TableCell style={cellStyle}>
                <Typography variant="body2" align="right">
                  {item.verifiedQuantity}
                </Typography>
              </TableCell>
              <TableCell style={cellStyle}>
                <AMSTextField
                  label=""
                  defaultValue={item.finalQuantity}
                  onChange={(text?: string) => {
                    setItems(
                      items.reduce((res: any[], i: StockReceivingProcessItemResponse) => {
                        if (item.id === i.id) {
                          i.finalQuantity = text;
                        }
                        res.push(i);
                        return res;
                      }, [])
                    );
                  }}
                  placeholder={`по подр. ${item.verifiedQuantity}`}
                />
              </TableCell>
              <TableCell style={cellStyle}>
                <AMSTextField
                  label=""
                  defaultValue={item.price}
                  onChange={(text?: string) => {
                    setItems(
                      items.reduce((res: any[], i: StockReceivingProcessItemResponse) => {
                        if (item.id === i.id) {
                          i.price = text;
                        }
                        res.push(i);
                        return res;
                      }, [])
                    );
                  }}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default FinalizeStockReceivingProcessTableComponent;
